import React, { useState, useCallback } from 'react';
import { ScrollView, View, Text } from 'react-native';

import { useIntl } from '@utils/intl';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { Loader } from '@views/shared/Loader';
import { ColleagueListCard } from '@views/Colleagues/List/ColleagueListCard';

import {
  limitUsers,
  limitUsersDesktop,
} from '@views/Colleagues/List/Department/hooks';
import { ShowMore, styles } from '@views/Colleagues/List/Department';
import {
  spacings,
  globalStyles,
  globalStylesDesktop,
} from '@views/shared/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { useOrganizationUsersByPresence } from './hooks';

export default function WhosInTabView({ type }: { type: string }) {
  const { t } = useIntl();
  const [page, setPage] = useState(1);
  const isDesktop = useIsDesktop();

  const toggleMore = useCallback(() => setPage(prevState => prevState + 1), [
    setPage,
  ]);

  const { data, count, loading, error } = useOrganizationUsersByPresence(type);

  const limit = isDesktop ? limitUsersDesktop : limitUsers;

  return (
    <ScrollView>
      <View style={globalStyles.container}>
        <View style={globalStyles.shadow}>
          {error ? (
            <UnexpectedNoDataOrError style={styles.error} />
          ) : loading ? (
            <Loader />
          ) : data.length ? (
            <>
              <View style={{ padding: spacings.smallMedium }}>
                <Text style={globalStyles.title}>{`${count} ${t(
                  'Colleagues.List.Colleagues',
                )}`}</Text>
              </View>
              <View
                style={
                  isDesktop && [
                    globalStylesDesktop.bookingsContainer,
                    { marginTop: spacings.huge },
                  ]
                }
              >
                {[...Array(page)].map((_, i) => (
                  <ColleaguesListRender
                    key={`${type}-${i}`}
                    type={type}
                    page={i}
                  />
                ))}
              </View>
              {count > limit ? (
                <ShowMore
                  onPress={toggleMore}
                  isDisabled={page * limit >= count}
                  t={t}
                  isDesktop={isDesktop}
                />
              ) : null}
            </>
          ) : (
            <Text style={globalStyles.noDataText}>
              {t(`Colleagues.WhosIn.EmptyList.${type}`)}
            </Text>
          )}
        </View>
      </View>
    </ScrollView>
  );
}

function ColleaguesListRender({ type, page }: { type: string; page: number }) {
  const { data, loading, error } = useOrganizationUsersByPresence(type, page);

  if (error) {
    return <UnexpectedNoDataOrError style={styles.error} />;
  }

  return loading ? (
    <Loader />
  ) : data.length ? (
    data.map((colleague, index) => (
      <ColleagueListCard
        key={`${type}-${page}-${index}`}
        colleague={colleague}
      />
    ))
  ) : null;
}
