import { useState } from 'react';

import { useMyUser } from '@providers/User';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';

export default function useSlot(date, onSlotSelect) {
  const [selectedType, setSelectedType] = useState();
  const {
    permissions: {
      canBookDesk,
      canBookFreeSpace,
      canBookHomeOffice,
      canBookTravelDay,
    },
  } = useMyUser();
  const homeOfficeEnabled = useHasFeature('home_office');
  const travelDayEnabled = useHasFeature('travel_day');

  const toggle = type =>
    setSelectedType(prevState => {
      onSlotSelect(
        {
          date,
          type,
          idx: universalDateFormatter({ date, format: dateFormatShort }),
        },
        prevState !== type,
      );
      if (prevState !== type) {
        return type;
      }
    });

  return {
    homeOfficeEnabled: canBookHomeOffice && homeOfficeEnabled,
    selectedType,
    travelDayEnabled: canBookTravelDay && travelDayEnabled,
    canBookDesk,
    canBookFreeSpace,
    toggle,
  };
}
