import React from 'react';

import { useIntl } from '@utils/intl';
import { BookingIcon } from '@views/shared/BookingIcon';
import { Panel } from '@views/Book/Bookings/Panel';
import {
  BOOK_CATEGORY_TELEFON_BOX,
  getBookingIcon,
} from '@views/shared/consts';

import { useTelefonBox } from './hooks';

export default function TelefonBox() {
  const { t } = useIntl();
  const errorMessage = t('views.Home.Bookings.TelefonBox.hooks.DataFail');

  const {
    numberOfTelefonBoxes,
    loading,
    error,
    goToStructure,
    worklight,
  } = useTelefonBox(BOOK_CATEGORY_TELEFON_BOX, errorMessage);

  if (error) {
    return null;
  }

  return (
    <Panel
      loading={loading}
      text={t('views.Home.Bookings.TelefonBox.total', {
        total: numberOfTelefonBoxes,
      })}
      icon={
        <BookingIcon
          name={getBookingIcon(BOOK_CATEGORY_TELEFON_BOX)}
          worklight={worklight}
        />
      }
      name={t('views.Home.Bookings.TelefonBox.title')}
      onAction={goToStructure}
    />
  );
}
