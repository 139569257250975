import { useCallback, useEffect, useState } from 'react';
import { append, isEmpty, isNil, without } from 'ramda';
import { useNavigation, useRoute } from '@react-navigation/native';
import { gql, useQuery } from '@apollo/client';

import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { useMyOrganization } from '@providers/Organization';
import { useMyBuildings } from '@providers/Buildings';

import { useCurrentDate } from '@views/Calendar/hooks';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { generateDefaultSlots } from '@views/shared/TimeSlots/helper';
import {
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TELEFON_BOX,
} from '@views/shared/consts';
import {
  BaseFloor,
  Equipment,
  ParkingSpotType,
} from 'views/shared/interfaces/buildingStructure';
import { OptionsData, TimeState } from './interfaces';

const getFilterableResources = gql`
  query filterableResources($buildingId: ID!, $areaType: AreaTypeEnum!) {
    filterableResources(buildingId: $buildingId, areaType: $areaType) {
      floors {
        floorLabel
        floorNumber
        floorType
        id
      }
      equipment {
        category {
          id
          name
        }
        id
        name
      }
      parkings
    }
  }
`;

const defaultFilter = {
  equipmentIds: <string[]>[],
  floorIds: <string[]>[],
  parkingTypes: <ParkingSpotType[]>[],
  timeRange: <TimeState>{ from: {}, to: {}, start: undefined, end: undefined },
  isFilterUsed: false,
};

const filterAtom = atom({
  key: `filterAtom`,
  default: {
    [BOOK_CATEGORY_MEETING_ROOM]: defaultFilter,
    [BOOK_CATEGORY_PARKING]: defaultFilter,
    [BOOK_CATEGORY_ROOM]: defaultFilter,
    [BOOK_CATEGORY_TELEFON_BOX]: defaultFilter,
  },
});

export function useFilter() {
  return useRecoilValue(filterAtom);
}

export function useOptions(): OptionsData {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const {
    params: { name, screen, type },
  } = route;

  const { selectedBuildingId: buildingId } = useMyBuildings();

  const isHalfDayBookingEnabled = useHasFeature('halfday_booking');
  const isHourlyBookingEnabled = useHasFeature('hourly_booking');

  const isMeetingRoom = type === BOOK_CATEGORY_MEETING_ROOM;

  const date = useCurrentDate();
  const {
    meetingRoomHoursStart,
    meetingRoomHoursEnd,
    workingHoursStart,
    workingHoursEnd,
  } = useMyOrganization();

  const setRecoilFilter = useSetRecoilState(filterAtom);
  const recoilFilter = useRecoilValue(filterAtom);

  const timeSlots =
    (isHalfDayBookingEnabled || isHourlyBookingEnabled || isMeetingRoom) &&
    type !== BOOK_CATEGORY_PARKING
      ? generateDefaultSlots({
          //bookablePeriod: { start: '04:00:00', end: '23:00:00' },
          bookablePeriod: {
            start: isMeetingRoom ? meetingRoomHoursStart : workingHoursStart,
            end: isMeetingRoom ? meetingRoomHoursEnd : workingHoursEnd,
          },
          date,
        })
      : [];

  const [selectedEquipment, setSelectedEquipment] = useState<string[]>(
    defaultFilter.equipmentIds,
  );
  const [selectedFloors, setSelectedFloors] = useState<string[]>(
    defaultFilter.floorIds,
  );
  const [selectedParkingTypes, setSelectedParkingTypes] = useState<
    ParkingSpotType[]
  >(defaultFilter.parkingTypes);
  const [selectedTime, setSelectedTime] = useState<TimeState>(
    defaultFilter.timeRange /*recoilFilter[type].timeRange,*/,
  );

  const [equipment, setEquipment] = useState<Equipment[]>([]);
  const [floors, setFloors] = useState<BaseFloor[]>([]);
  const [parkingTypes, setParkingTypes] = useState<ParkingSpotType[]>([]);

  const { data, loading } = useQuery(getFilterableResources, {
    variables: {
      areaType: type,
      buildingId,
    },
  });

  useEffect(() => {
    if (data?.filterableResources) {
      const {
        filterableResources: { floors, equipment, parkings },
      } = data;
      setEquipment(equipment);
      setFloors(floors);
      const parkingTypes = parkings.map(
        type => `VirtualParking${type.toUpperCase()}`,
      );

      setParkingTypes(parkingTypes);
    }
  }, [data]);

  useEffect(() => {
    setSelectedEquipment(recoilFilter[type].equipmentIds);
    setSelectedFloors(recoilFilter[type].floorIds);
    setSelectedParkingTypes(recoilFilter[type].parkingTypes);
    setSelectedTime(recoilFilter[type].timeRange);
  }, [type]);

  const isFilterUsed =
    !isEmpty(selectedEquipment) ||
    !isEmpty(selectedFloors) ||
    !isEmpty(selectedParkingTypes) ||
    !isEmpty(selectedTime.from) ||
    !isEmpty(selectedTime.to);

  const onEquipmentSelect = useCallback(
    (id, isActive) => {
      const newList = isActive
        ? without([id], selectedEquipment)
        : append(id, selectedEquipment);
      setSelectedEquipment(newList);
    },
    [selectedEquipment, setSelectedEquipment],
  );

  const onFloorSelect = useCallback(
    (id, isActive) => {
      const newList = isNil(id)
        ? []
        : isActive
        ? without([id], selectedFloors)
        : append(id, selectedFloors);
      setSelectedFloors(newList);
    },
    [selectedFloors, setSelectedFloors],
  );

  const onParkingTypeSelect = useCallback(
    (type, isActive) => {
      const newList = isActive
        ? without([type], selectedParkingTypes)
        : append(type, selectedParkingTypes);
      setSelectedParkingTypes(newList);
    },
    [selectedParkingTypes, setSelectedParkingTypes],
  );

  const onTimeSelect = useCallback(
    (from, to) =>
      setSelectedTime({
        from,
        to,
        start: from.startIndex,
        end: !isEmpty(to) ? to.endIndex : from.endIndex,
      }),
    [setSelectedTime],
  );

  const resetFilter = useCallback(() => {
    const clonedFilter = { ...recoilFilter };
    clonedFilter[type] = defaultFilter;
    setRecoilFilter(clonedFilter);
    setSelectedEquipment([]);
    setSelectedFloors([]);
    setSelectedTime({ from: {}, to: {}, start: undefined, end: undefined });
    setSelectedParkingTypes([]);
  }, [
    recoilFilter,
    setRecoilFilter,
    setSelectedEquipment,
    setSelectedFloors,
    type,
  ]);

  const goBack = useCallback(() => {
    navigation.navigate(name, { screen });
    if (!isFilterUsed) {
      resetFilter();
    }
  }, [navigation, name, screen, isFilterUsed, resetFilter]);

  const applyFilter = useCallback(() => {
    const clonedFilter = { ...recoilFilter };
    clonedFilter[type] = {
      equipmentIds: selectedEquipment,
      floorIds: selectedFloors,
      parkingTypes: selectedParkingTypes,
      timeRange: selectedTime,
      isFilterUsed,
    };

    setRecoilFilter(clonedFilter);
    goBack();
  }, [
    isFilterUsed,
    goBack,
    recoilFilter,
    selectedEquipment,
    setRecoilFilter,
    selectedFloors,
    selectedParkingTypes,
    selectedTime,
    type,
  ]);

  return {
    applyFilter,
    equipment,
    goBack,
    floors,
    isFilterUsed,
    isFloorplan: name === 'floorplan',
    isMeetingRoom,
    loading,
    onEquipmentSelect,
    onFloorSelect,
    onParkingTypeSelect,
    onTimeSelect,
    parkingTypes,
    resetFilter,
    selectedEquipment,
    selectedFloors,
    selectedParkingTypes,
    selectedTime,
    timeSlots,
    type,
  };
}
