import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import {
  getWeekDays,
  getWeekEnd,
  getWeekStart,
  getWorkingDaysOfWeek,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';
import { getBookings } from '@views/Colleagues/Profile/Bookings/hooks';
import { useMyOrganization } from '@providers/Organization';

import { assignBookingsToDays } from '@views/Bookings/MyBookings/core.hooks';
import { Booking } from '@views/shared/interfaces/booking';

export function useBookings(userId: string) {
  const [bookings, setBookings] = useState<Map<string, Booking[]>>();
  const currentDate = useCurrentDate();
  const weekStart = getWeekStart(currentDate);

  const { workingDaysStart, workingDaysEnd } = useMyOrganization();

  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );

  const weekDays = getWeekDays(weekStart, enabledWorkingDays);

  const { data, error, loading } = useQuery(getBookings, {
    variables: {
      start: universalDateFormatter({ date: weekStart }),
      end: universalDateFormatter({ date: getWeekEnd(weekStart) }),
      userId,
    },
  });

  useEffect(() => {
    if (data?.bookings) {
      const mappedData = assignBookingsToDays(data.bookings ?? []);

      setBookings(mappedData);
    }
  }, [data]);

  return {
    bookings,
    loading,
    error,
    weekDays,
  };
}
