import { partialRight, compose } from 'ramda';
import { useCallback } from 'react';
import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { useMyOrganization } from '@providers/Organization';
import {
  getTodayAsDate,
  transformToDate,
  TIME_TRANSFORM,
  getNextDayFromThis,
  getTodayAsDayJs,
  getWorkingDaysOfWeek,
} from '@utils/DateAndTime';

export interface Props {
  navigationRef: {
    current: Navigation;
  };
}

interface Navigation {
  getCurrentRoute: () => { [key: string]: any };
  navigate: (any) => void;
}

export const featurKey = 'calendar';

const isOpenAtom = atom({
  key: `${featurKey}/open`,
  default: false,
});

export const dateAtom = atom({
  key: `${featurKey}/current-date`,
  default: getTodayAsDate(),
});

export function useCalendar(navigation: Navigation) {
  const [currentDate, setCurrentDate] = useRecoilState(dateAtom);
  const { close } = useOpenCalendar();

  const {
    bookingRange,
    workingDaysStart,
    workingDaysEnd,
  } = useMyOrganization();

  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );

  const addDays = partialRight(getNextDayFromThis, [bookingRange]);

  const maxAvailableDate =
    bookingRange > 0
      ? compose(transformToDate, addDays, getTodayAsDayJs)()
      : undefined;

  const onDayChange = useCallback(
    (day: any) => {
      const date = transformToDate(day.dateString, TIME_TRANSFORM.START_OF_DAY);

      setCurrentDate(date);

      const { name } = navigation.getCurrentRoute();

      if (name === 'book' || name.startsWith('book-')) {
        navigation.navigate('index');
      }
    },
    [navigation, setCurrentDate],
  );

  return {
    close,
    currentDate,
    enabledWorkingDays,
    onDayChange,
    maxAvailableDate,
  };
}

export function useOpenCalendar() {
  const [isOpen, setOpen] = useRecoilState(isOpenAtom);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const open = useCallback(() => setOpen(true), [setOpen]);

  return {
    isOpen,
    close,
    open,
  };
}

export function useCurrentDate() {
  return useRecoilValue(dateAtom);
}

export function useSetCurrentDate() {
  return useSetRecoilState(dateAtom);
}
