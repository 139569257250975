import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { useMyOrganization } from '@providers/Organization';

import { Loader } from '@views/shared/Loader';
import { useGoTo } from '@views/shared/utils/useGoTo';
import { MainButton } from '@views/shared/Button';
import { CustomIcon } from '@views/shared/CustomIcon';
import { globalStyles, globalStylesDesktop } from '@views/shared/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { Booking } from './Booking';
import { useCurrentBookings } from './hooks';

import { styles } from './styles';

export default function ActiveBookings() {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();
  return (
    <View style={globalStyles.cardContainer}>
      <Text
        style={
          isDesktop ? globalStylesDesktop.cardTitle : globalStyles.cardTitle
        }
      >
        {t('Home.ActiveBookings.Title')}
      </Text>
      <WrappedComponent />
    </View>
  );
}

const WrappedComponent = () => {
  const {
    backgroundColor,
    isWhiteBackground,
    contrastColor,
  } = useMyOrganization();

  const isDesktop = useIsDesktop();

  const { bookings, loading, onUpdate } = useCurrentBookings();

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {!bookings.length && !loading ? (
        <Empty
          backgroundColor={backgroundColor}
          isWhiteBackground={isWhiteBackground}
          contrastColor={contrastColor}
          isDesktop={isDesktop}
        />
      ) : (
        <View style={isDesktop && globalStylesDesktop.bookingsContainer}>
          {bookings.map((booking, idx) => (
            <Booking key={idx} booking={booking} onUpdate={onUpdate} />
          ))}
        </View>
      )}
      {!isDesktop && (
        <BookMore
          backgroundColor={backgroundColor}
          isWhiteBackground={isWhiteBackground}
          contrastColor={contrastColor}
          isMore={bookings.length > 0}
        />
      )}
    </>
  );
};

interface Props {
  backgroundColor: string;
  isWhiteBackground: boolean;
  contrastColor: string;
  isDesktop: boolean;
}

function Empty({
  backgroundColor,
  isWhiteBackground,
  contrastColor,
  isDesktop,
}: Props) {
  const { t } = useIntl() as any;

  return (
    <>
      <View
        style={[
          globalStyles.shadow,
          styles.cardSpacer,
          isDesktop && { width: '30%', marginHorizontal: 0 },
        ]}
      >
        <View style={styles.row}>
          <View
            style={[
              styles.left,
              { backgroundColor },
              isWhiteBackground && styles.spaceBorder,
            ]}
          >
            <CustomIcon name="warning" size={22} color={contrastColor} />
          </View>

          <View style={styles.right}>
            <View style={[styles.row, styles.between, styles.itemsCenter]}>
              <View style={isDesktop && { width: '100%' }}>
                <Text
                  style={[
                    globalStyles.title,
                    isDesktop && { overflowWrap: 'break-word' },
                  ]}
                >
                  {t('Home.NoBookings.Title')}
                </Text>
                <Text
                  style={[
                    globalStyles.description,
                    isDesktop && { overflowWrap: 'break-word' },
                  ]}
                >
                  {t('Home.NoBookings.Description')}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
    </>
  );
}

function BookMore({
  backgroundColor,
  isWhiteBackground,
  contrastColor,
  isMore,
}: Props) {
  const { t } = useIntl() as any;
  const { goToStructurePage, goToFloorplan } = useGoTo();

  return (
    <View style={[globalStyles.shadow, styles.cardSpacer]}>
      <View style={[styles.row]}>
        <View
          style={[
            styles.left,
            { backgroundColor },
            isWhiteBackground && styles.spaceBorder,
          ]}
        >
          <CustomIcon name="plus" size={20} color={contrastColor} />
        </View>
        <View
          style={[
            styles.right,
            styles.row,
            styles.buttons,
            { margin: 0, height: 66 },
          ]}
        >
          <MainButton
            onPress={goToStructurePage}
            buttonStyle={styles.button}
            titleStyle={styles.buttonTitle}
            title={t(
              isMore ? 'Home.Bookings.BookMore' : 'Home.Bookings.BookNow',
            )}
          />
          <MainButton
            onPress={goToFloorplan}
            buttonStyle={[styles.button, styles.greenButton]}
            titleStyle={styles.greenButtonTitle}
            title={t('Home.Bookings.UseFloorplan')}
          />
        </View>
      </View>
    </View>
  );
}
