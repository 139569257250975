import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

const isOpenAtom = atom({
  key: `calenderPicker`,
  default: false,
});

export function useCalendarPicker() {
  const [isOpen, setOpen] = useRecoilState(isOpenAtom);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const open = useCallback(() => setOpen(true), [setOpen]);

  return {
    isOpen,
    close,
    open,
  };
}
