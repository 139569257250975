import { useState, useEffect, useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { flatten, isEmpty } from 'ramda';

import { useIntl } from '@utils/intl';
import {
  dateFormatShort,
  MixDate,
  universalDateFormatter,
  getWeekStart,
  getWeekEnd,
} from '@utils/DateAndTime';
import { useErrorQuery } from '@providers/Errors';
import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useCurrentDate } from '@views/Calendar/hooks';
import { BOOK_CATEGORY_ROOM } from '@views/shared/consts';
import { STATUS } from '@views/shared/TimeSlots/helper';
import { queryUnavailableTimePeriodsMap } from '@views/Book/Book/BasicBook/Booking/hooks';
import {
  Booking,
  BookingType,
  QueryUnavailableTimePeriods,
} from '@views/shared/interfaces/booking';
import {
  AreaWithStatus,
  Point,
  AvailabilityInfo,
} from '@views/shared/interfaces/floorplan';

import { AreaType } from '@views/shared/interfaces/buildingStructure';
import { Colleague } from '@views/shared/TimeSlots/interfaces';

import { checkActiveAreaBooking } from '../Areas/hooks';
import { checkActiveBooking } from '../Points/hooks';

interface Data {
  colleague: Colleague;
}

const getColleague = gql`
  query getColleague($userID: ID!) {
    colleague(userId: $userID) {
      id
      email
      name
      initials
      avatar
      phoneNumber
      licensePlate
      department {
        id
        name
      }
      visibleForColleagues
    }
  }
`;

const getParamsByType = {
  area: ({ element }: AvailabilityInfo) => {
    const area = element as AreaWithStatus;

    return { areaId: area.id };
  },
  point: ({ element }: AvailabilityInfo) => {
    const point = element as Point;

    return point.areaType === 'parking'
      ? { parkingId: point.id }
      : { deskId: point.id };
  },
};

const getAvailabilityBaseInfosByType = {
  area: ({ element }: AvailabilityInfo) => {
    const area = element as AreaWithStatus;

    return { type: area.areaType as BookingType, status: area.status };
  },
  point: ({ element }: AvailabilityInfo) => {
    const point = element as Point;

    return {
      type: (point.areaType !== 'parking'
        ? BOOK_CATEGORY_ROOM
        : point.type) as BookingType,
      status: point.status,
    };
  },
};

const getCheckActiveParamsByType = {
  area: ({ element }: AvailabilityInfo): { id: string; areaType: AreaType } => {
    const area = element as AreaWithStatus;

    return { areaType: area.areaType, id: area.id };
  },
  point: ({
    element,
  }: AvailabilityInfo): { id: string; areaType: AreaType } => {
    const point = element as Point;

    return { areaType: point.areaType, id: point.id };
  },
};

const checkAvailabilityByType = {
  area: checkActiveAreaBooking,
  point: checkActiveBooking,
};

interface TimeWithColleague {
  end: MixDate;
  start: MixDate;
  colleagues: Colleague[];
}

export function useInfos({ info }: { info: AvailabilityInfo }) {
  const client = useApolloClient();
  const currentDate = useCurrentDate();
  const { t } = useIntl();
  const { bookings } = useMyActiveBookings();

  const [unavailableTimePeriods, setUnavailableTimePeriods] = useState<
    TimeWithColleague[]
  >([]);

  const { type: infoType } = info;
  const params = getParamsByType[infoType](info);
  const { type, status } = getAvailabilityBaseInfosByType[infoType](info);

  const { data, loading, error } = useErrorQuery<QueryUnavailableTimePeriods>(
    queryUnavailableTimePeriodsMap[type],
    {
      fetchPolicy: 'no-cache',
      variables: {
        ...params,
        date: universalDateFormatter({
          date: currentDate,
          format: dateFormatShort,
        }),
        start: getWeekStart(currentDate),
        end: getWeekEnd(currentDate),
      },
      skip: [STATUS.BLOCKED, STATUS.BOOKED, STATUS.DISABLED].includes(status),
      finderError: {
        type: 'fatal',
        message: t('Home.Booking.TimeSlots.hooks.error'),
      },
    },
  );

  useEffect(() => {
    if (
      data &&
      data.unavailableTimePeriods &&
      !isEmpty(data.unavailableTimePeriods)
    ) {
      const request = flatten(
        data.unavailableTimePeriods.map(slot =>
          slot.userId.map(userID =>
            client.query<Data>({
              query: getColleague,
              fetchPolicy: 'no-cache',
              variables: {
                userID,
              },
            }),
          ),
        ),
      );

      if (request.length) {
        Promise.all(request)
          .then(response => {
            const colleaguesData = flatten(
              response.map(dataXHR => dataXHR.data?.colleague),
            )
              // remove undefined
              .filter(colleagueData => colleagueData)
              // normalize the type
              .map(colleagueData => colleagueData as Colleague);

            const timeWithColleague = data.unavailableTimePeriods.map(slot => {
              const { start, end, userId } = slot;
              const colleagues = userId
                .map(userID =>
                  colleaguesData.find(
                    colleague => colleague && userID === colleague.id,
                  ),
                )
                // remove undefined
                .filter(colleagueData => colleagueData)
                // normalize the type
                .map(colleagueData => colleagueData as Colleague);

              return { start, end, colleagues };
            });

            setUnavailableTimePeriods(timeWithColleague);
          })
          .catch(e => {
            console.log(e);
          });
      }
    }
  }, [client, data]);

  const findActiveBooking = useCallback((): Booking | undefined => {
    const { type: infoCheckType } = info;
    const { areaType, id } = getCheckActiveParamsByType[infoCheckType](info);

    return bookings.find(checkAvailabilityByType[infoCheckType](areaType, id));
  }, [bookings, info]);

  return {
    error,
    findActiveBooking,
    infoType,
    loading,
    status,
    unavailableTimePeriods,
    type,
  };
}
