import { defineMessages } from 'react-intl';

const dictionary = defineMessages({
  'Filter.BookingTime.Title': {
    id: 'Filter.BookingTime.Title',
    defaultMessage: 'Booking time',
  },
  'Filter.BookingTime.Description': {
    id: 'Filter.BookingTime.Description',
    defaultMessage: 'Start and End',
  },
  'Filter.BookingTime.Overlay.Title': {
    id: 'Filter.BookingTime.Overlay.Title',
    defaultMessage: 'Select the preferred time',
  },
  'Filter.BookingTime.Overlay.Button.Apply': {
    id: 'Filter.BookingTime.Overlay.Button.Apply',
    defaultMessage: 'Apply selection',
  },
  'Filter.BookingTime.Overlay.Button.Cancel': {
    id: 'Filter.BookingTime.Overlay.Button.Cancel',
    defaultMessage: 'Unset selection',
  },
  'Filter.Button.Apply': {
    id: 'Filter.Button.Apply',
    defaultMessage: 'Apply filter',
  },
  'Filter.Button.Cancel': {
    id: 'Filter.Button.Cancel',
    defaultMessage: 'Unset filter',
  },
  'Filter.Floors.Title': {
    id: 'Filter.Floors.Title',
    defaultMessage: 'Floors',
  },
  'Filter.Select.Type': {
    id: 'Filter.Select.Type',
    defaultMessage: 'Select your preferred filter type',
  },
  'Filter.Headline.Title': {
    id: 'Filter.Headline.Title',
    defaultMessage: 'Filter',
  },
});

export default {
  ...dictionary,
};
