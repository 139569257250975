import { useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { ReactNativeFile } from 'apollo-upload-client';
import { isNil } from 'ramda';

import { useIntl } from '@utils/intl';
import { useContent } from '@views/Home/Profile/Overview/UserData/hooks';
import { User } from '@views/Home/Profile/Overview/UserData/interfaces';
import { useErrorMutation } from '@providers/Errors';
import { useMyUser } from '@providers/User';

import { UploadClient } from '@app/client';
import { useMyBuildings } from '@providers/Buildings';

interface PartialProfile {
  avatar?: string;
  buildingId: string | null;
  name: string;
  phoneNumber: string;
  licensePlate: string;
}

export function useUserFields(me: User) {
  const [name, setName] = useState(me.name);
  const [phoneNumber, setPhoneNumber] = useState(me.userprofile.phoneNumber);
  const [buildingId, setBuildingId] = useState(me.userprofile.myBuilding.id);
  const [licensePlate, setLicensePlate] = useState(me.userprofile.licensePlate);

  return {
    editState: {
      avatar: me.userprofile.avatar,
      buildingId,
      name,
      phoneNumber,
      licensePlate,
    },
    setBuildingId,
    setName,
    setPhoneNumber,
    setLicensePlate,
  };
}

const updateUserMutation = gql`
  mutation updateUser(
    $avatar: FileType
    $buildingId: ID
    $name: String!
    $phoneNumber: String!
    $licensePlate: String
  ) {
    updateMe(
      appName: FINDER
      user: {
        name: $name
        userprofile: {
          avatar: $avatar
          defaultBuildingId: $buildingId
          phoneNumber: $phoneNumber
          licensePlate: $licensePlate
        }
      }
    ) {
      name
      userprofile {
        avatar
        phoneNumber
        defaultBuilding {
          id
          name
        }
        licensePlate
      }
    }
  }
`;

function useUpdate(
  avatar: ReactNativeFile | undefined,
  editState: PartialProfile | undefined,
  reorderBuildings: (id: string) => null,
) {
  const { t } = useIntl();
  const navigation = useNavigation<any>();
  const AvatarClient = UploadClient();
  const { refetch } = useMyUser();

  const { buildingId, name, phoneNumber, licensePlate } = editState || {};

  const [
    updateAvatar,
    { loading: updateAvatarLoading, error: updateAvatarError },
  ] = useErrorMutation(updateUserMutation, {
    client: AvatarClient,
    variables: {
      avatar,
      buildingId,
      name,
      phoneNumber,
      licensePlate,
    },
    finderError: {
      type: 'fatal',
      message: t('Profile.Edit.hooks.updateError'),
    },
  });

  const [
    updateUser,
    { loading: updateLoading, error: updateError },
  ] = useErrorMutation(updateUserMutation, {
    onCompleted: () => {
      refetch();
      if (!isNil(buildingId)) {
        reorderBuildings(buildingId);
      }
    },
    variables: {
      buildingId,
      name,
      phoneNumber,
      licensePlate,
    },
    finderError: {
      type: 'fatal',
      message: t('Profile.Edit.hooks.updateError'),
    },
  });

  const update = useCallback(async () => {
    await updateAvatar();
    await updateUser();
    navigation.navigate('overview');
  }, [updateAvatar, updateUser, navigation]);

  return {
    update,
    updateLoading: updateLoading && updateAvatarLoading,
    updateError: updateError && updateAvatarError,
  };
}
// TODO cleanup homeoffice and department selection
export function useEdit() {
  const navigation = useNavigation<any>();
  const { buildings, reorderBuildings } = useMyBuildings();
  const [avatar, setAvatar] = useState<ReactNativeFile>();
  const { me, loading: meLoading, error: meError } = useContent();

  const {
    editState,
    setBuildingId,
    setName,
    setPhoneNumber,
    setLicensePlate,
  } = useUserFields(me);

  const { update, updateLoading, updateError } = useUpdate(
    avatar,
    editState,
    reorderBuildings,
  );

  const cancel = useCallback(() => {
    navigation.navigate('overview');
  }, [navigation]);

  return {
    buildings: buildings.map(({ id: value, name: label }) => ({
      label,
      value,
    })),
    cancel,
    error: meError || updateError,
    initials: me?.initials,
    loading: meLoading || updateLoading,
    me: editState,
    setAvatar,
    setName,
    setBuildingId,
    setPhoneNumber,
    setLicensePlate,
    update,
  };
}
