import React from 'react';
import { View } from 'react-native';

import { useMyUser } from '@providers/User';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { Loader } from '@views/shared/Loader';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import { globalStyles, globalStylesDesktop } from '@views/shared/styles';

import Desks from './Desks';
import FreeSeats from './FreeSeats';
import MeetingRooms from './MeetingRooms';
import HomeOffice from './HomeOffice';
import TravelDay from './TravelDay';
import ParkingSpots from './ParkingSpots';
import Vacation from './Vacation';
import SickDay from './SickDay';
import TelefonBox from './TelefonBox';
import { useBookings } from './hooks';

export function Bookings() {
  const { permissions } = useMyUser();
  const isDesktop = useIsDesktop();

  const {
    canBookHomeOffice,
    canBookVacation,
    canBookSickDay,
    canBookTravelDay,
    canBookDesk,
    canBookFreeSpace,
  } = permissions;

  const styleSelection = isDesktop ? globalStylesDesktop : globalStyles;

  const homeOfficeEnabled = useHasFeature('home_office');
  const travelDayEnabled = useHasFeature('travel_day');
  const hasParkingFeature = useHasFeature('parkings_enabled');
  const vacationEnabled = useHasFeature('vacation');
  const sickDayEnabled = useHasFeature('sick_day');

  const {
    error,
    loading,
    numberOfFreeSpacesRooms,
    numberOfMeetingRooms,
    numberOfDesks,
    numberOfTotalParking,
    numberOfTelefonBoxes,
  } = useBookings(permissions);

  if (error) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <View style={styleSelection['bookingsContainer']}>
      {canBookDesk && numberOfDesks > 0 && <Desks />}
      {numberOfMeetingRooms > 0 && <MeetingRooms />}
      {canBookFreeSpace && numberOfFreeSpacesRooms > 0 && <FreeSeats />}
      {numberOfTelefonBoxes > 0 && <TelefonBox />}
      {hasParkingFeature && numberOfTotalParking > 0 && <ParkingSpots />}

      {canBookHomeOffice && homeOfficeEnabled && <HomeOffice />}
      {canBookTravelDay && travelDayEnabled && <TravelDay />}
      {canBookVacation && vacationEnabled && <Vacation />}
      {canBookSickDay && sickDayEnabled && <SickDay />}
    </View>
  );
}
