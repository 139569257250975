import { Formats } from '@utils/intl';
import { BOOKING_TYPE_TELEFON_BOX } from '@views/shared/consts';
import { useBaseValuesWithArea } from '@views/Book/Book/BasicBook/hooks';

export default function useValues(formats: Formats, message: string) {
  const result = useBaseValuesWithArea({
    formats,
    message,
    type: BOOKING_TYPE_TELEFON_BOX,
  });

  return {
    ...result,
    // we're using time slots
    time: undefined,
    // telefon box has the hourly booking enabled
    enabledHourlyBooking: true,
    hasNextDaysBooking: false,
  };
}
