import React from 'react';
import { Text, View } from 'react-native';
import { isEmpty } from 'ramda';

import { MainButton } from '@views/shared/Button';
import { useIntl } from '@utils/intl';

import Order from '@views/Book/Book/MeetingRoom/AdditionalOrder/Order';

import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/Book/Book/MeetingRoom/AdditionalOrder/styles';

import { ItemsProps } from '@views/Book/Book/MeetingRoom/AdditionalOrder/types';

import { useItems } from './hooks';

const translationMap = {
  catering: {
    button: 'Home.Book.Catering.Button.Book',
    error: 'Home.Book.Catering.Order.Error',
    title: 'Home.Book.Catering.Title',
  },
  equipping: {
    button: 'Home.Book.Equipping.Button.Book',
    error: 'Home.Book.Equipping.Order.Error',
    title: 'Home.Book.Equipping.Title',
  },
};
export default function Items(props: ItemsProps) {
  const { t } = useIntl();
  const { type } = props;

  const {
    createOrder,
    data,
    isDirty,
    isError,
    items,
    onItemUpdate,
    disableOrder,
    prepTime,
  } = useItems(props);

  // show the order notice
  if (!isEmpty(data)) {
    return <Order data={data} showNotice type={type} />;
  }

  const translation =
    type === 'catering'
      ? prepTime < 60
        ? `Home.Book.Catering.DisabledMinutes`
        : `Home.Book.Catering.DisabledHours`
      : prepTime < 60
      ? `Home.Book.Equipment.DisabledMinutes`
      : `Home.Book.Equipment.DisabledHours`;

  const prepTimeFormatted = prepTime < 60 ? prepTime : prepTime / 60;

  return (
    <View>
      <Text style={styles.description}>{t(translationMap[type].title)}</Text>
      {items.map((item, index) => {
        const { description, itemId, title, quantity = 0 } = item;

        return (
          <View key={index} style={styles.itemRow}>
            <View style={styles.itemText}>
              <Text style={globalStyles.title}>{title}</Text>
              {description ? (
                <Text style={globalStyles.description}>{description}</Text>
              ) : null}
            </View>
            <View style={styles.quantityContainer}>
              <MainButton
                buttonStyle={styles.itemButton}
                disabled={quantity === 0}
                onPress={() => onItemUpdate(itemId, false)}
                title="-"
                titleStyle={styles.itemButtonTitle}
              />
              <Text style={styles.quantityText}>{quantity}</Text>
              <MainButton
                buttonStyle={styles.itemButton}
                disabled={disableOrder}
                onPress={() => onItemUpdate(itemId)}
                title="+"
                titleStyle={[
                  styles.itemButtonTitle,
                  styles.itemButtonTitlePlus,
                ]}
              />
            </View>
          </View>
        );
      })}

      {isError ? (
        <Text style={styles.error}>{t(translationMap[type].error)}</Text>
      ) : null}

      {disableOrder && (
        <Text style={styles.bookingDisableContainer}>
          {t(translation, { value: prepTimeFormatted })}
        </Text>
      )}
      <View style={styles.buttonContainer}>
        <MainButton
          disabled={isDirty || disableOrder}
          onPress={createOrder}
          title={t(translationMap[type].button)}
        />
      </View>
    </View>
  );
}
