import React, { CSSProperties } from 'react';
import { Platform, Modal, StyleSheet, Text, View } from 'react-native';
import { Overlay as ReactOverlay } from 'react-native-elements';
import WebModal from 'modal-react-native-web';

import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';
import { useModalWidth } from '@views/shared/Overlay/hooks';

const ModalComponent = Platform.OS === 'web' ? WebModal : Modal;

const { huge, extraSmall } = spacings;
const { white, blackAlpha } = colors;

const styles = StyleSheet.create({
  overlay: {
    padding: 0,
    borderRadius: extraSmall,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: white,
  },
  header: {
    padding: huge,
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderColor: blackAlpha,
    borderBottomWidth: 1,
    alignItems: 'center',
  },
  title: {
    fontFamily: 'mrt-semi-bold',
    fontSize: fonts.sizeTitle,
    textAlign: 'center',
    flex: 1,
  },
  content: {
    padding: huge,
  },
});

// ariaHideApp needs to be set to false to avoid React Warning
// For more info look here: https://stackoverflow.com/questions/48269381/warning-react-modal-app-element-is-not-defined-please-use-modal-setappeleme

interface Props {
  onClose: () => void;
  title?: string;
  noHeader?: boolean;
  width?: number;
  height?: number;
  headerStyle?: undefined | CSSProperties;
  contentStyle?: undefined | CSSProperties;
  overlayStyle?: undefined | CSSProperties;
  titleStyle?: undefined | CSSProperties;
  hideClose?: boolean;
}

export function Overlay({
  onClose,
  title,
  children,
  noHeader = false,
  width,
  height,
  headerStyle,
  contentStyle,
  overlayStyle,
  titleStyle,
  hideClose = false,
}: Props) {
  const modalWidth = useModalWidth();

  return (
    <ReactOverlay
      // @ts-ignore
      ariaHideApp={false}
      ModalComponent={ModalComponent}
      isVisible
      onBackdropPress={onClose}
      overlayStyle={[
        styles.overlay,
        { width: width || modalWidth, height },
        globalStyles.shadow,
        overlayStyle,
      ]}
    >
      <>
        {noHeader ? null : (
          <View style={[styles.header, headerStyle]}>
            <Text style={[styles.title, titleStyle]}>{title}</Text>
            {hideClose ? null : (
              <CustomIcon name="close" onPress={onClose} size={20} />
            )}
          </View>
        )}
        <View style={[styles.content, contentStyle]}>{children}</View>
      </>
    </ReactOverlay>
  );
}
