import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { compose, pathOr } from 'ramda';

import { useCurrentDate } from '@views/Calendar/hooks';
import {
  sortParkingByTypeAndLabel,
  getAvailableParkingSpots,
} from '@views/shared/Booking';
import { universalDateFormatter } from '@utils/DateAndTime';
import {
  AreaParkingSpots,
  AreaStructureForParkingSpots,
  ParkingStructure,
} from '@views/shared/interfaces/buildingStructure';
import { useFilter } from '@views/Book/Filter/hooks';
import { BOOK_CATEGORY_PARKING } from '@views/shared/consts';

interface Data {
  areasParkings: AreaParkingSpots[];
}

// TODO remove not bookable parking spots and add the same logic to the desks
const getParkingSpots = gql`
  query getParkingSpots($areaId: ID!, $date: DateTime) {
    areasParkings(areaIds: [$areaId], date: $date) {
      areaId
      parkings {
        blocked
        isFree
        isVipForbidden
        isOccupied
        isForbidden
        id
        label
        numberOnFloor
        type: combinedType
      }
    }
  }
`;

export default function useParkingSpots({ id }: AreaStructureForParkingSpots) {
  const [items, setItems] = useState<ParkingStructure[]>([]);
  const date = useCurrentDate();
  const filterData = useFilter();
  const { parkingTypes } = filterData[BOOK_CATEGORY_PARKING];

  const { data, loading, error } = useQuery<Data>(getParkingSpots, {
    variables: {
      areaId: id,
      date: universalDateFormatter({ date }),
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data?.areasParkings) {
      const parkingSpots = compose(
        sortParkingByTypeAndLabel,
        getAvailableParkingSpots,
      )(pathOr([], ['areasParkings', 0, 'parkings'], data));

      const filteredData = parkingSpots.filter(({ type }: ParkingStructure) =>
        !parkingTypes.length ? true : parkingTypes.includes(type),
      );

      setItems(filteredData);
    }
  }, [data, parkingTypes]);

  return {
    items,
    error,
    loading,
  };
}
