import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Formik, Field } from 'formik';

import { useIntl } from '@utils/intl';
import { Overlay } from '@views/shared/Overlay';
import { Input } from '@views/shared/Form';
import { MainButton } from '@views/shared/Button';
import { Booking } from '@views/shared/interfaces/booking';
import { CustomIcon } from '@views/shared/CustomIcon';
import { colors, globalStyles, spacings } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';

import { useProblem } from './hooks';
import { Subject } from './Subject';

const { large, small } = spacings;
const { aquaMarine, warmPink } = colors;

export const styles = StyleSheet.create({
  buttonSend: {
    alignSelf: 'flex-end',
    marginTop: small,
  },
  error: {
    borderColor: warmPink,
  },
  success: {
    color: aquaMarine,
    textAlign: 'center',
    fontFamily: 'mrt-semi-bold',
    paddingBottom: large,
  },
  failed: { color: warmPink },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  loader: {
    width: 20,
    height: 20,
    marginRight: small,
  },
});

interface Props {
  booking: Booking;
  location: string;
}

const validate = (values: any) => {
  const errors: { subject?: boolean; body?: boolean } = {};

  if (!values.subject) {
    errors.subject = true;
  }

  if (!values.body || values.body.length < 3) {
    errors.body = true;
  }

  return errors;
};

export function Problem({ booking, location }: Props) {
  const { t } = useIntl();
  const { close, isOpen, loading, open, sendReport, submit } = useProblem(
    booking,
  );

  return (
    <>
      <CustomIcon name="warning" onPress={open} />
      {isOpen ? (
        <Overlay
          onClose={close}
          title={t('Home.ActiveBookings.Booking.Problem.title')}
        >
          <View>
            {sendReport.send && (
              <Text style={styles.success}>
                {t('Home.ActiveBookings.Booking.Problem.reportSend')}
              </Text>
            )}
            {sendReport.error && (
              <Text style={[styles.success, styles.failed]}>
                {t('Home.ActiveBookings.Booking.Problem.reportFail')}
              </Text>
            )}
            <View>
              <Text style={globalStyles.label}>
                {t('Home.ActiveBookings.Booking.Problem.located')}
              </Text>
              <Text style={globalStyles.input}>{location}</Text>
            </View>
            <Formik
              initialValues={{}}
              onSubmit={(values, actions) => submit(values, actions.resetForm)}
              validate={validate}
            >
              {/* TODO: please check this  */}
              {/* @ts-ignore */}
              {({ errors: { body, subject }, handleSubmit }) => (
                <>
                  <Subject errorStyle={subject && styles.error} />
                  <Field
                    component={Input}
                    name="body"
                    label={t('Home.ActiveBookings.Booking.Problem.details')}
                    multiline
                    numberOfLines={4}
                    errorStyle={body && styles.error}
                  />
                  <View style={styles.footer}>
                    {loading ? (
                      <Loader iconSize={20} animationStyle={styles.loader} />
                    ) : null}
                    <MainButton
                      buttonStyle={styles.buttonSend}
                      onPress={() => handleSubmit()}
                      title={t('Home.ActiveBookings.Booking.Problem.send')}
                    />
                  </View>
                </>
              )}
            </Formik>
          </View>
        </Overlay>
      ) : null}
    </>
  );
}
