import React from 'react';
import { Text, View } from 'react-native';
import { path } from 'ramda';

import { Accordion } from '@views/shared/Accordion';

import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/shared/Structure';
import { styles as fStyles } from '@views/Book/Favourites/styles';

import {
  FavouriteItem,
  Building as BuildingType,
} from '@views/shared/interfaces/favourites';

import Item from './Item';

interface Props {
  items: FavouriteItem[];
}

export default function Building({ items }: Props) {
  const building = path([0, 'floor', 'building'], items);
  const { address, name } = building as BuildingType;

  const title = (
    <View style={[styles.middle, { borderLeftWidth: 0 }]}>
      {name && <Text style={globalStyles.title}>{name}</Text>}
      {address && <Text style={globalStyles.description}>{address}</Text>}
    </View>
  );

  return (
    <Accordion open={true} title={title} headerStyle={fStyles.building}>
      {items.map((item, index) => (
        <Item item={item} key={index} />
      ))}
    </Accordion>
  );
}
