import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { colors, globalStyles, spacings } from '@views/shared/styles';
import { BookingIcon } from '@views/shared/BookingIcon';
import {
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TRAVEL,
} from '@views/shared/consts';
import { getBookingIcon } from '@views/shared/consts';
import { DateDisplayForBookingsTab } from '@views/Bookings/MyAttendances/AttendanceWeek/DailyAttendance';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { styles } from '@views/Bookings/MyBookings/Day/Ticket/Booking';

import { useBookable } from './hooks';

const { blackAlpha, blackAlpha2, aquaMarineLight } = colors;
const { smallMedium, small } = spacings;

const myStyles = StyleSheet.create({
  actions: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  actionsDesktop: {
    flexDirection: 'row',
    marginTop: smallMedium,
  },
  iconWrapper: {
    paddingHorizontal: small,
    alignItems: 'center',
  },
  iconBorder: {
    borderLeftWidth: 1,
    borderColor: blackAlpha,
  },
  icon: {
    fontSize: 22,
  },
  iconDisabled: {
    color: blackAlpha2,
  },
  bookMore: {
    borderRightWidth: 1,
    borderRightColor: blackAlpha,
  },
  containerDesktop: {
    paddingVertical: small,
    backgroundColor: aquaMarineLight,
  },
  desktopCol: {
    alignItems: 'center',
    marginBottom: small,
  },
});

interface Props {
  date: Date;
  showBookMoreTitle: boolean;
  isToday: boolean;
}

export default function Bookable({ date, showBookMoreTitle, isToday }: Props) {
  const { t } = useIntl();
  const {
    goToPage,
    isHomeOfficeEnabled,
    isDisabledToBook,
    isTravelDayEnabled,
    isDeskEnabled,
    noBookable,
  } = useBookable(date);

  const isDesktop = useIsDesktop();

  const getIconComponent = (type, showBorder = false) => (
    <View style={[myStyles.iconWrapper, showBorder && myStyles.iconBorder]}>
      <BookingIcon
        name={getBookingIcon(type)}
        onPress={!isDisabledToBook ? () => goToPage(type) : undefined}
        style={[myStyles.icon, isDisabledToBook && myStyles.iconDisabled]}
      />
    </View>
  );

  const title = showBookMoreTitle
    ? 'Bookings.Day.book.more'
    : 'Bookings.Day.book';

  return (
    <View
      style={
        !showBookMoreTitle && [styles.item, isDesktop && { borderTopWidth: 0 }]
      }
    >
      <View style={isDesktop ? myStyles.desktopCol : styles.row}>
        {showBookMoreTitle ? (
          <View style={[styles.left, myStyles.bookMore]} />
        ) : (
          <DateDisplayForBookingsTab
            isDesktop={isDesktop}
            date={date}
            isToday={isToday}
          />
        )}
        {noBookable ? (
          <View style={styles.right}>
            <Text style={globalStyles.title}>
              {t('Bookings.Day.noBookable')}
            </Text>
          </View>
        ) : (
          <>
            <View style={styles.right}>
              <Text style={globalStyles.title}>{t(title)}</Text>
            </View>
            <View
              style={isDesktop ? myStyles.actionsDesktop : myStyles.actions}
            >
              {isDeskEnabled && getIconComponent(BOOK_CATEGORY_ROOM)}
              {isHomeOfficeEnabled &&
                getIconComponent(BOOK_CATEGORY_HOME, true)}
              {isTravelDayEnabled &&
                getIconComponent(BOOK_CATEGORY_TRAVEL, true)}
            </View>
          </>
        )}
      </View>
    </View>
  );
}
