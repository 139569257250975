import React, { useCallback, useState } from 'react';
import { Text, View } from 'react-native';
import { isNil, isEmpty } from 'ramda';

import {
  getBookableWorkingTime,
  getTodayAsDayJs,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { globalStyles } from '@views/shared/styles';
import { SecondaryButton } from '@views/shared/Button';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { CustomIcon } from '@views/shared/CustomIcon';
import { BookingIcon } from '@views/shared/BookingIcon';
import TitleByType from '@views/shared/TitleByType';
import { useMyOrganization } from '@providers/Organization';
import { getLocation } from '@views/shared/hooks/getLocation';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { getBookingIcon } from '@views/shared/consts';
import {
  Ordered as AdditionalOrder,
  ItemIcon as AdditionalOrderIcon,
} from '@views/Book/Book/MeetingRoom/AdditionalOrder/Order';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { styles } from '@views/Home/ActiveBookings/styles';

import { useBooking } from './hooks';
import { Problem } from './Problem';

interface Props {
  booking: BookingType;
  onUpdate: () => void;
}

interface TimeProps {
  end?: Date | string;
  start?: Date | string;
  timeFormatShort: string;
  workingHoursStart: Date | string;
  workingHoursEnd: Date | string;
}

const calculateWorkingTime = (
  timeFormatShort: string,
  workingHoursStart: string,
  workingHoursEnd: string,
): string => {
  const date = getTodayAsDayJs();
  const { start, end } = getBookableWorkingTime(
    date,
    workingHoursStart,
    workingHoursEnd,
  );

  const startOfWorkingDay = universalDateFormatter({
    date: start,
    format: timeFormatShort,
  });

  const endOfWorkingDay = universalDateFormatter({
    date: end,
    format: timeFormatShort,
  });

  return `${startOfWorkingDay} - ${endOfWorkingDay}`;
};

export function getTimeByType({
  end,
  start,
  timeFormatShort,
  workingHoursStart,
  workingHoursEnd,
}: TimeProps) {
  /*if (type === BOOKING_TYPE_MEETING_ROOM) {*/
  if (start && end) {
    return `${start} - ${end}`;
  }

  return calculateWorkingTime(
    timeFormatShort,
    workingHoursStart as string,
    workingHoursEnd as string,
  );
}

export function Booking({ booking, onUpdate }: Props) {
  const {
    t,
    formats: { timeFormatShort },
  } = useIntl() as any;

  const isDesktop = useIsDesktop();

  const [showCatering, setShowCatering] = useState(false);
  const [showEquipping, setShowEquipping] = useState(false);
  const [showDetails, setShowDetails] = useState(isDesktop);

  const toggleCatering = useCallback(
    () => setShowCatering(prevState => !prevState),
    [setShowCatering],
  );

  const toggleEquipping = useCallback(
    () => setShowEquipping(prevState => !prevState),
    [setShowEquipping],
  );

  const hideAdditionalOrders = useCallback(() => {
    setShowEquipping(false);
    setShowCatering(false);
  }, [setShowEquipping, setShowCatering]);

  const toggleDetails = useCallback(
    () =>
      setShowDetails(prevState => {
        if (prevState) {
          hideAdditionalOrders();
        }
        return !prevState;
      }),
    [setShowDetails, hideAdditionalOrders],
  );

  const {
    cancel,
    changeCheckStatus,
    checkinEnabled,
    isCancelable,
    isReportEnabled,
    end,
    start,
    type,
  } = useBooking(booking, onUpdate);

  const {
    backgroundColor,
    workingHoursStart,
    workingHoursEnd,
    isWhiteBackground,
    contrastColor,
  } = useMyOrganization();

  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const location = getLocation({
    booking,
    t,
    showDeskNumber,
  });

  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });

  const {
    catering,
    checkedIn,
    equipping,
    homeOffice,
    travelDay,
    vacation,
    sickDay,
    comment,
  } = booking;
  const hasCatering = !isNil(catering) && !isEmpty(catering);
  const hasEquipping = !isNil(equipping) && !isEmpty(equipping);

  return (
    <View
      style={[
        globalStyles.shadow,
        styles.cardSpacer,
        isDesktop && { width: '30%', marginHorizontal: 0 },
      ]}
    >
      <View style={[styles.row, isDesktop && { minHeight: 131 }]}>
        <View
          style={[
            styles.left,
            { backgroundColor },
            isWhiteBackground && styles.spaceBorder,
          ]}
        >
          <BookingIcon
            name={getBookingIcon(type)}
            style={{ color: contrastColor }}
          />
        </View>

        <View style={[styles.right, isDesktop && { justifyContent: 'center' }]}>
          <View style={[styles.row, styles.between, styles.itemsCenter]}>
            <View>
              <TitleByType type={type} />
              <Text style={globalStyles.description}>{time}</Text>
            </View>
            {!isDesktop && (
              <CustomIcon
                name={`arrow-${showDetails ? 'up' : 'down'}`}
                size={20}
                onPress={toggleDetails}
              />
            )}
          </View>
          {showDetails ? (
            <>
              {!homeOffice && !travelDay && !vacation && !sickDay ? (
                <Text style={globalStyles.description}>{location}</Text>
              ) : null}
              {comment && comment.length ? (
                <Text style={globalStyles.description}>{comment}</Text>
              ) : null}
              <View style={[styles.row, styles.buttons, styles.between]}>
                <View style={styles.row}>
                  {isReportEnabled ? (
                    <Problem booking={booking} location={location} />
                  ) : null}
                  {hasCatering ? (
                    <AdditionalOrderIcon
                      type="catering"
                      onPress={toggleCatering}
                    />
                  ) : null}
                  {hasEquipping ? (
                    <AdditionalOrderIcon
                      type="equipping"
                      onPress={toggleEquipping}
                    />
                  ) : null}
                </View>
                <View style={styles.row}>
                  {checkinEnabled ? (
                    <SecondaryButton
                      onPress={changeCheckStatus}
                      buttonStyle={styles.button}
                      title={t(
                        checkedIn
                          ? 'Home.ActiveBookings.Booking.check-out'
                          : 'Home.ActiveBookings.Booking.check-in',
                      )}
                    />
                  ) : null}
                  {isCancelable ? (
                    <SecondaryButton
                      buttonStyle={[styles.button, globalStyles.cancelButton]}
                      titleStyle={globalStyles.cancelButtonText}
                      title={t('Home.ActiveBookings.Booking.cancel')}
                      onPress={cancel}
                    />
                  ) : null}
                </View>
              </View>
            </>
          ) : null}
          {showCatering && hasCatering
            ? catering.map((data, index) => (
                <AdditionalOrder data={data} key={index} type="catering" />
              ))
            : null}
          {showEquipping && hasEquipping
            ? equipping.map((data, index) => (
                <AdditionalOrder data={data} key={index} type="equipping" />
              ))
            : null}
        </View>
      </View>
    </View>
  );
}
