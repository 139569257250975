import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { isNil, pathOr, pluck } from 'ramda';
import { gql } from '@apollo/client';

import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useErrorQuery } from '@providers/Errors';
import { useMyUser, Permissions } from '@providers/User';

import { STATUS } from '@views/shared/TimeSlots/helper';
import {
  imageHeightSelector,
  rotatedSelector,
  scaleSelector,
} from '@views/Floorplan/ZoomImage/state';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_TELEFON_BOX,
} from '@views/shared/consts';

import { getDeskSlots } from '@views/Book/Bookings/Desks/hooks';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import {
  getBookableWorkingTime,
  universalDateFormatter,
  dateFormatShort,
  isToday,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useFilter } from '@views/Book/Filter/hooks';

import {
  AreaType,
  WorklightStatus,
} from '@views/shared/interfaces/buildingStructure';
import {
  AreasData,
  AreaFloor,
  AreaCoordinates,
  AreaWithStatus,
} from '@views/shared/interfaces/floorplan';
import { Status } from '@views/shared/TimeSlots/interfaces';
import { Booking } from '@views/shared/interfaces/booking';

interface HookProps {
  ids: string[];
  onLoading: (type: string, loading: boolean) => any;
}

const getAreasQuery = gql`
  query getAreas(
    $ids: [ID]!
    $date: Date
    $start: DateTime
    $end: DateTime
    $startForTB: DateTime
    $endForTB: DateTime
    $isToday: Boolean!
  ) {
    floorplanAreas(
      areasIds: $ids
      date: $date
      start: $start
      end: $end
      startForTB: $startForTB
      endForTB: $endForTB
    ) {
      id
      areaType
      name
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        freeSlots
        totalSlots
      }
      meetingRoomTimeStats {
        booked
        total
      }
      telefonBoxTimeStats {
        booked
        total
      }
      isTelefonBoxFree
      coordinates {
        x
        y
        height
        width
      }
      isMeetingRoomFree
      isMeetingRoomOccupied @include(if: $isToday)
      isAreaOccupied @include(if: $isToday)
      equipment {
        category {
          id
          name
        }
        id
        name
      }
      isForbidden
    }
  }
`;

const getStatusByType = (
  area: AreaFloor,
  isBooked: boolean,
  isAvailable: boolean,
): Status => {
  const { areaType } = area;
  const areasTypes = {
    [AREA_TYPE_FREE_SPACES_ROOM]: () => {
      const free = getDeskSlots(
        area,
        'freeSlots',
        0,
        AREA_TYPE_FREE_SPACES_ROOM,
      );
      const total = getDeskSlots(
        area,
        'totalSlots',
        1,
        AREA_TYPE_FREE_SPACES_ROOM,
      );

      const worklight: WorklightStatus = getWorklightStatus(free, total);

      return getAreaStatus({
        worklight,
        isBooked,
        areaType,
      });
    },
    [AREA_TYPE_MEETING_ROOM]: () => {
      const {
        meetingRoomTimeStats,
        isAreaOccupied: isOccupied,
        isMeetingRoomFree: isFree,
      } = area;
      const bookedTime = pathOr(0, ['booked'], meetingRoomTimeStats);
      const totalTime = pathOr(0, ['total'], meetingRoomTimeStats);
      const free = totalTime - bookedTime;

      const worklight: WorklightStatus = getWorklightStatus(free, totalTime);

      return getAreaStatus({
        worklight,
        isBooked,
        isAvailable,
        isOccupied,
        isFree,
        areaType,
      });
    },
    [AREA_TYPE_TELEFON_BOX]: () => {
      const {
        telefonBoxTimeStats,
        isTelefonBoxFree: isFree,
        isAreaOccupied: isOccupied,
      } = area;
      const bookedTime = pathOr(0, ['booked'], telefonBoxTimeStats);
      const totalTime = pathOr(0, ['total'], telefonBoxTimeStats);
      const free = totalTime - bookedTime;

      const worklight: WorklightStatus = getWorklightStatus(free, totalTime);
      return getAreaStatus({
        worklight,
        isBooked,
        isAvailable,
        isFree,
        isOccupied,
        areaType,
      });
    },
  };

  return areasTypes[areaType]();
};

const getAreaStatus = ({
  worklight,
  isAvailable = true,
  isBooked = false,
  isFree = true,
  isOccupied = false,
  areaType,
}: {
  worklight: WorklightStatus;
  isBooked: boolean;
  isAvailable?: boolean;
  isFree?: boolean;
  isOccupied?: boolean;
  areaType: AreaType;
}): Status => {
  const worklights = {
    low: STATUS.FREE,
    medium: STATUS.BOOKED_BY_COLLEAGUE,
    high: STATUS.BLOCKED,
    none: STATUS.BLOCKED,
  };

  if (isBooked) {
    return STATUS.BOOKED;
  }
  if (!isAvailable) {
    return STATUS.DISABLED;
  }

  if (isFree && isOccupied) {
    return areaType === AREA_TYPE_MEETING_ROOM
      ? STATUS.OCCUPIED
      : STATUS.BLOCKED;
  }

  return worklights[worklight];
};

export const checkActiveAreaBooking = (areaType: AreaType, id: string) => ({
  area,
}: Booking) => area?.id === id;

const checkMeetingRoomAvailability = (
  { equipment, isMeetingRoomFree }: AreaFloor,
  equipmentIds: string[],
  start,
) => {
  let hasEquipment = true;

  if (equipmentIds.length) {
    const equipmentList = pluck('id', equipment);
    hasEquipment = equipmentIds.some(id => equipmentList.includes(id));
  }

  // check isMeetingRoomFree only if timeRange is selected
  return hasEquipment && (start ? isMeetingRoomFree : true);
};

const checkTelefonBoxAvailability = (
  { equipment, isTelefonBoxFree }: AreaFloor,
  equipmentIds: string[],
  startForTB,
) => {
  let hasEquipment = true;

  if (equipmentIds.length) {
    const equipmentList = pluck('id', equipment);
    hasEquipment = equipmentIds.some(id => equipmentList.includes(id));
  }

  return hasEquipment && (startForTB ? isTelefonBoxFree : true);
};

function decorateArea({
  area,
  rotated,
  imageHeight,
  findActiveBooking,
  checkIsAvailableToBook,
  equipmentIds,
  isFilterUsed,
  start,
  equipmentIdsforTB,
  startForTB,
  isFilterUsedForTB,
}: {
  area: AreaFloor;
  rotated: boolean;
  imageHeight: number;
  findActiveBooking: (id: string, areaType: AreaType) => boolean;
  checkIsAvailableToBook: (status: Status, areaType: AreaType) => boolean;
  equipmentIds: string[];
  isFilterUsed: boolean;
  start: any;
  equipmentIdsforTB: string[];
  isFilterUsedForTB: boolean;
  startForTB: any;
}): AreaWithStatus {
  const { areaType, coordinates, id } = area;
  const isAvailable =
    isFilterUsed && areaType === AREA_TYPE_MEETING_ROOM
      ? checkMeetingRoomAvailability(area, equipmentIds, start)
      : isFilterUsedForTB && areaType === AREA_TYPE_TELEFON_BOX
      ? checkTelefonBoxAvailability(area, equipmentIdsforTB, startForTB)
      : true;

  const isBooked = findActiveBooking(id, areaType);
  const status: Status = getStatusByType(area, isBooked, isAvailable);

  // Always defined because we are specifically checking for it in filter
  const { x, y, width, height } = coordinates as AreaCoordinates;
  // Distance from top for new top left corner.
  // Distance from right is just y, because after rotating -90 deg y is new x.
  const y2 = imageHeight - width - x;

  return {
    ...area,
    status,
    isAvailableToBook: checkIsAvailableToBook(status, areaType),
    coordinates: {
      // If rotated flip dimensions and use new top left corner
      x: rotated ? y : x,
      y: rotated ? y2 : y,
      width: rotated ? height : width,
      height: rotated ? width : height,
    },
  };
}

const checkPermission = (
  areaType: AreaType,
  { canBookPhoneBox, canBookMeetingRoom, canBookFreeSpace }: Permissions,
) => {
  const types = {
    [AREA_TYPE_FREE_SPACES_ROOM]: canBookFreeSpace,
    [AREA_TYPE_MEETING_ROOM]: canBookMeetingRoom,
    [AREA_TYPE_TELEFON_BOX]: canBookPhoneBox,
  };
  return types[areaType];
};

const isOwnDailyBookFreeSeats = ({
  status,
  areaType,
  enabledHourlyBooking,
}: {
  status: Status;
  areaType: AreaType;
  enabledHourlyBooking: boolean;
}) =>
  !enabledHourlyBooking &&
  areaType === AREA_TYPE_FREE_SPACES_ROOM &&
  status === STATUS.BOOKED;

export function useArea({ ids, onLoading }: HookProps) {
  const rotated = useRecoilValue(rotatedSelector);
  const height = useRecoilValue(imageHeightSelector) ?? 1;
  const scale = useRecoilValue(scaleSelector);
  const date = useCurrentDate();
  const { t } = useIntl();

  const { permissions } = useMyUser();
  const { bookings } = useMyActiveBookings();
  const enabledHourlyBooking = useHasFeature('hourly_booking');
  const [areas, setAreas] = useState<AreaWithStatus[]>([]);
  const filterData = useFilter();
  const {
    equipmentIds,
    timeRange: { start, end },
    isFilterUsed,
  } = filterData[AREA_TYPE_MEETING_ROOM];

  const { start: queryStart, end: queryEnd } = start
    ? getBookableWorkingTime(date, start, end)
    : { start, end };

  const {
    equipmentIds: equipmentIdsforTB,
    timeRange: { start: startForTB, end: endForTB },
    isFilterUsed: isFilterUsedForTB,
  } = filterData[AREA_TYPE_TELEFON_BOX];

  const { start: queryStartForTB, end: queryEndForTB } = startForTB
    ? getBookableWorkingTime(date, startForTB, endForTB)
    : { startForTB, endForTB };

  const findActiveBooking = useCallback(
    (id: string, areaType: AreaType): boolean =>
      !isNil(bookings.find(checkActiveAreaBooking(areaType, id))),
    [bookings],
  );

  const checkIsAvailableToBook = useCallback(
    (status: Status, areaType: AreaType) =>
      status === STATUS.FREE ||
      (status === STATUS.OCCUPIED && areaType === AREA_TYPE_MEETING_ROOM) ||
      (status !== STATUS.BLOCKED &&
        status !== STATUS.DISABLED &&
        !isOwnDailyBookFreeSeats({ enabledHourlyBooking, status, areaType })),
    [enabledHourlyBooking],
  );

  const { data, loading } = useErrorQuery<AreasData>(getAreasQuery, {
    variables: {
      ids,
      date: universalDateFormatter({ date, format: dateFormatShort }),
      start: queryStart && universalDateFormatter({ date: queryStart }),
      end: queryEnd && universalDateFormatter({ date: queryEnd }),
      startForTB:
        queryStartForTB && universalDateFormatter({ date: queryStartForTB }),
      endForTB:
        queryEndForTB && universalDateFormatter({ date: queryEndForTB }),
      isToday: isToday(date),
    },
    fetchPolicy: 'no-cache',
    skip: !ids,
    finderError: {
      type: 'fatal',
      message: t('Floorplan.ZoomImage.Areas.fetch'),
    },
  });

  useEffect(() => {
    onLoading('areas', loading);
  }, [loading, onLoading]);

  useEffect(() => {
    if (data?.floorplanAreas) {
      const transformedData = data.floorplanAreas
        .filter(({ coordinates, isForbidden, areaType }) => {
          const hasCoordinatesAndEnabled = !isNil(coordinates) && !isForbidden;
          const canBook = checkPermission(areaType, permissions);
          return hasCoordinatesAndEnabled && canBook;
        })
        .map(area =>
          decorateArea({
            area,
            rotated,
            imageHeight: height,
            findActiveBooking,
            checkIsAvailableToBook,
            equipmentIds,
            isFilterUsed,
            start,
            equipmentIdsforTB,
            startForTB,
            isFilterUsedForTB,
          }),
        );

      setAreas(transformedData);
    }
  }, [
    data,
    rotated,
    height,
    findActiveBooking,
    checkIsAvailableToBook,
    equipmentIds,
    isFilterUsed,
    start,
    equipmentIdsforTB,
    startForTB,
    isFilterUsedForTB,
  ]);

  return {
    scale,
    areas,
  };
}
