import React from 'react';
import { Avatar } from 'react-native-elements';

import { avatarWithBadgeStyle } from '@views/shared/styles';

import {
  ImageUploadProps,
  ImageComponentProps,
  PlaceholderComponentProps,
} from './interfaces';
import { useImageUpload } from './hooks';

export * from './interfaces';

function ImageComponent({ title, image, onPress }: ImageComponentProps) {
  return (
    <Avatar
      rounded
      size={100}
      source={{ uri: image }}
      title={title}
      titleStyle={[avatarWithBadgeStyle.title]}
      placeholderStyle={[avatarWithBadgeStyle.placeholder]}
      overlayContainerStyle={[avatarWithBadgeStyle.avatarOverlayContainer]}
      onPress={onPress}
    />
  );
}

function PlaceholderComponent({ title, onPress }: PlaceholderComponentProps) {
  return (
    <Avatar
      rounded
      size={100}
      title={title}
      titleStyle={[avatarWithBadgeStyle.title]}
      placeholderStyle={[avatarWithBadgeStyle.placeholder]}
      overlayContainerStyle={[avatarWithBadgeStyle.avatarOverlayContainer]}
      containerStyle={[avatarWithBadgeStyle.avatarContainer]}
      onPress={onPress}
    />
  );
}

export function ImageUpload(props: ImageUploadProps) {
  const {
    title,
    uri,
    imageComponent: Image = ImageComponent,
    placeholder: Placeholder = PlaceholderComponent,
  } = props;
  const { image, pickImage } = useImageUpload(props);

  return image ?? uri ? (
    <Image image={image ?? uri ?? ''} onPress={pickImage} />
  ) : (
    <Placeholder title={title} onPress={pickImage} />
  );
}
