import { WorklightStatus } from '@views/shared/interfaces/buildingStructure';

export default function getWorklightStatus(
  free: number,
  total: number,
): WorklightStatus {
  if (total === 0) {
    return 'high';
  }
  const ratio = free / total;

  return ratio > 0.5 ? 'low' : ratio > 0 ? 'medium' : 'high';
}
