import React, { useEffect, useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { colors, spacings, globalStylesDesktop } from '@views/shared/styles';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { Empty } from '@views/Colleagues/Profile/Bookings/Day/Booking/empty';
import {
  getStartOfTheDay,
  getTodayAsDayJs,
  isAfter,
  isDayExpired,
  sortByDateAdapter,
  isToday,
} from '@utils/DateAndTime';
import { getBookingType } from '@views/shared/utils';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { DateDisplayForBookingsTab } from '@views/Bookings/MyAttendances/AttendanceWeek/DailyAttendance';

import {
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TRAVEL,
} from '@views/shared/consts';

import Bookable from './Ticket/Bookable';
import Booking, { styles as bStyles } from './Ticket/Booking';
import BookingForDesktop from './Ticket/BookingForDesktop';

const bookableTypes = [
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TRAVEL,
];

export const styles = StyleSheet.create({
  container: {
    paddingVertical: spacings.medium,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  selected: {
    color: colors.aquaMarine,
  },
});

interface Props {
  bookings: BookingType[];
  date: Date;
  enabledHourlyBooking: boolean;
  refreshBookings: () => void;
  numberOfDays: number;
  last: boolean;
}

export default function Day({
  bookings,
  date,
  enabledHourlyBooking,
  refreshBookings,
  last,
  numberOfDays,
}: Props) {
  const isTodayDate = isToday(date);
  const isDesktop = useIsDesktop();

  const widthForDesktop = `${100 / numberOfDays}%`;

  const BookingComponent = isDesktop ? BookingForDesktop : Booking;

  const bookingActive =
    isAfter(date, getStartOfTheDay(getTodayAsDayJs())) && bookings.length === 0;

  const [bookedTypes, setBookedTypes] = useState([]);

  useEffect(() => {
    if (bookings.length) {
      const types = bookings.map(booking => getBookingType(booking));

      // @ts-ignore
      setBookedTypes(types);
    }
  }, [bookings]);

  const bookableTicket = (showBookMoreTitle = false) =>
    !isDayExpired(date) ? (
      <Bookable
        showBookMoreTitle={showBookMoreTitle}
        date={date}
        isToday={isTodayDate}
      />
    ) : null;

  // 'book more' is available only for hourly bookings
  // and if not all these types: desk, home, travel are booked
  const canCreateMoreHourlyBookings =
    enabledHourlyBooking &&
    // @ts-ignore
    !bookableTypes.every(t => bookedTypes.includes(t));

  const bookingsTickets = !bookings.length ? (
    <View style={[bStyles.item, isDesktop && { borderTopWidth: 0 }]}>
      <Empty date={date} isToday={isTodayDate} isDesktop={isDesktop} />
    </View>
  ) : (
    <View style={[bStyles.item, isDesktop && { borderTopWidth: 0 }]}>
      <View style={isDesktop ? { alignItems: 'center' } : bStyles.row}>
        <DateDisplayForBookingsTab
          isDesktop={isDesktop}
          date={date}
          isToday={isTodayDate}
        />
        {isDesktop && canCreateMoreHourlyBookings ? bookableTicket(true) : null}
        <View
          style={[
            bStyles.right,
            { paddingLeft: 0 },
            isDesktop && { width: '92%' },
          ]}
        >
          {bookings.sort(sortByDateAdapter).map(booking => (
            <BookingComponent
              key={booking.id}
              booking={booking}
              onCancel={refreshBookings}
              date={date}
              isToday={isTodayDate}
            />
          ))}
        </View>
      </View>
      {!isDesktop && canCreateMoreHourlyBookings ? bookableTicket(true) : null}
    </View>
  );

  return (
    <View
      style={[
        isDesktop
          ? [
              {
                width: widthForDesktop,
              },
              globalStylesDesktop.desktopConWithBorder,
              last && { borderRightWidth: 0 },
            ]
          : last && bStyles.itemLast,
      ]}
    >
      {bookingActive ? bookableTicket() : bookingsTickets}
    </View>
  );
}
