import { gql, useMutation } from '@apollo/client';

import { useBookForDesk } from '@views/Book/Book/BasicBook/Booking/hooks';
import {
  BookingHooksBasicProps,
  BookingWithAreaAndDesk,
  CreateBookingDeskResponse,
} from '@views/shared/interfaces/booking';

export const bookDeskMutation = gql`
  mutation bookDesk(
    $areaId: ID!
    $start: DateTime!
    $end: DateTime!
    $deskId: ID
    $comment: String
  ) {
    createBooking(
      booking: {
        areaId: $areaId
        start: $start
        end: $end
        deskId: $deskId
        comment: $comment
      }
    ) {
      start
      end
      area {
        areaType
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
          }
        }
        name
      }
      desk {
        label
        numberOnFloor
        type
      }
      comment
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  params: { areaId, deskId },
  t,
  type,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingDeskResponse },
    BookingWithAreaAndDesk
  >(bookDeskMutation);

  return useBookForDesk({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params: {
      areaId,
      deskId,
    },
    formats,
    bookingMutation: mutation,
  });
}
