import React, { useContext, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { isEmpty, pathOr } from 'ramda';
import { useRecoilValue } from 'recoil';

import { useErrorQuery } from '@providers/Errors';
import { useMyUser, DEFAULT_BOOKING_RANGE } from '@providers/User';
import { Feature } from '@views/shared/hooks/hasFeature';
import { colors } from '@views/shared/styles';
import { loggedInAtom } from '@views/Login';
import { timeFromHours, WeekDayType } from '@utils/DateAndTime';

interface MyOrganizationProps {
  children: React.ReactElement;
}

export interface Translation {
  id: string;
  key: string;
  value: string;
}

interface IntlTranslations {
  [key: string]: string;
}

type contrastType = 'dark' | 'light';

interface IconColorsEnum {
  DARK: contrastType;
  LIGHT: contrastType;
}

interface HrSystem {
  hrSystem: 'LTX' | 'PERSONIO';
}

interface MyOrganizationContext {
  workingDaysStart: WeekDayType;
  workingDaysEnd: WeekDayType;
  workingHoursStart: string;
  workingHoursEnd: string;
  meetingRoomHoursStart: string;
  meetingRoomHoursEnd: string;
  enabledFeatures: Feature[];
  translations: IntlTranslations;
  backgroundColor: string;
  bookingRange: number;
  midDayHour: string;
  preparationTime: {
    catering: string;
    equipment: string;
  };
  id: string;
  logo?: string;
  iconColor: contrastType;
  name: string;
  hrSystem: string;
  error: any;
  loading: boolean;
  loaded: boolean;
  isWhiteBackground: boolean;
  contrastColor: string;
}

interface Data {
  myOrganization: {
    workingDaysStart: WeekDayType;
    workingDaysEnd: WeekDayType;
    workingHoursStart: Date;
    workingHoursEnd: Date;
    meetingRoomHoursStart: string;
    meetingRoomHoursEnd: string;
    enabledFeatures: Feature[];
    translations: Translation[];
    backgroundColor: string;
    bookingRange: number;
    midDayHour: number;
    cateringPreparationTime: string;
    equipmentPreparationTime: string;
    id: string;
    iconColor: contrastType;
    logo: string;
    name: string;
    hrSystemConfiguration: HrSystem;
  };
}

const { white, black } = colors;

const getMyOrganization = gql`
  query getMyOrganization($language: LanguageEnumType) {
    myOrganization(language: $language) {
      backgroundColor
      bookingRange
      id
      iconColor
      logo
      name
      workingDaysStart
      workingDaysEnd
      workingHoursStart
      workingHoursEnd
      meetingRoomHoursStart
      meetingRoomHoursEnd
      enabledFeatures
      midDayHour
      cateringPreparationTime
      equipmentPreparationTime
      translations(language: $language) {
        id
        key
        value
      }
      hrSystemConfiguration {
        hrSystem
      }
    }
  }
`;

export const ICON_COLORS: IconColorsEnum = {
  DARK: 'dark',
  LIGHT: 'light',
};

const DEFAULT_CONTEXT_STATE = {
  workingDaysStart: 'monday',
  workingDaysEnd: 'friday',
  workingHoursStart: '00:00',
  workingHoursEnd: '00:00',
  meetingRoomHoursStart: '00:00',
  meetingRoomHoursEnd: '00:00',
  enabledFeatures: [],
  translations: {},
  backgroundColor: white,
  bookingRange: DEFAULT_BOOKING_RANGE,
  midDayHour: '00:00',
  preparationTime: {
    catering: 'PREP_0',
    equipment: 'PREP_0',
  },
  id: '',
  iconColor: ICON_COLORS.DARK,
  logo: undefined,
  name: '',
  hrSystem: 'LTX',
  error: {},
  loading: false,
  loaded: false,
  isWhiteBackground: false,
  contrastColor: white,
};

export const MyOrganizationContext = React.createContext<MyOrganizationContext>(
  DEFAULT_CONTEXT_STATE,
);

export const useMyOrganization = () => useContext(MyOrganizationContext);

export function MyOrganizationProvider({ children }: MyOrganizationProps) {
  const loggedIn = useRecoilValue(loggedInAtom);

  const [id, setId] = useState(DEFAULT_CONTEXT_STATE.id);
  const [logo, setLogo] = useState(DEFAULT_CONTEXT_STATE.logo);
  const [name, setName] = useState(DEFAULT_CONTEXT_STATE.name);
  const [enabledFeatures, setEnabledFeatures] = useState<Feature[]>(
    DEFAULT_CONTEXT_STATE.enabledFeatures,
  );
  const [translations, setTranslations] = useState<any>(
    DEFAULT_CONTEXT_STATE.translations,
  );
  const [backgroundColor, setBackgroundColor] = useState(
    DEFAULT_CONTEXT_STATE.backgroundColor,
  );
  const [iconColor, setIconColor] = useState(DEFAULT_CONTEXT_STATE.iconColor);
  const [bookingRange, setBookingRange] = useState(
    DEFAULT_CONTEXT_STATE.bookingRange,
  );
  const [workingDaysStart, setWorkingDaysStart] = useState(
    DEFAULT_CONTEXT_STATE.workingDaysStart,
  );
  const [workingDaysEnd, setWorkingDaysEnd] = useState(
    DEFAULT_CONTEXT_STATE.workingDaysEnd,
  );
  const [workingHoursStart, setWorkingHoursStart] = useState(
    DEFAULT_CONTEXT_STATE.workingHoursStart,
  );
  const [workingHoursEnd, setWorkingHoursEnd] = useState(
    DEFAULT_CONTEXT_STATE.workingHoursEnd,
  );
  const [meetingRoomHoursStart, setMeetingRoomHoursStart] = useState(
    DEFAULT_CONTEXT_STATE.meetingRoomHoursStart,
  );
  const [meetingRoomHoursEnd, setMeetingRoomHoursEnd] = useState(
    DEFAULT_CONTEXT_STATE.meetingRoomHoursEnd,
  );
  const [midDayHour, setMidDayHour] = useState(
    DEFAULT_CONTEXT_STATE.midDayHour,
  );
  const [preparationTime, setPreparationTime] = useState(
    DEFAULT_CONTEXT_STATE.preparationTime,
  );
  const [hrSystem, setHrSystem] = useState(DEFAULT_CONTEXT_STATE.hrSystem);

  const {
    loaded,
    loading,
    language,
    showActionsPage,
    bookingRange: bookingRangeUser,
  } = useMyUser();

  const { data, refetch, error, loading: loadingOrganization } = useErrorQuery<
    Data
  >(getMyOrganization, {
    variables: {
      language,
    },
    skip: !loggedIn || !loaded || loading || showActionsPage,
    finderError: {
      type: 'fatal',
      message: 'TopBar.fetchError',
    },
  });

  useEffect(() => {
    if (data) {
      const id = pathOr('', ['myOrganization', 'id'], data);
      const logo = pathOr(undefined, ['myOrganization', 'logo'], data);
      const name = pathOr('', ['myOrganization', 'name'], data); //TODO: can be an organization without name??
      const myBookingRange = pathOr(
        DEFAULT_CONTEXT_STATE.bookingRange,
        ['myOrganization', 'bookingRange'],
        data,
      );
      const myTranslations = pathOr(
        [],
        ['myOrganization', 'translations'],
        data,
      );
      const customTranslations = myTranslations.reduce((acc, val) => {
        const { key, value } = val;

        return { ...acc, [key]: value };
      }, {});

      const myWorkingDaysStart = pathOr(
        'monday',
        ['myOrganization', 'workingDaysStart'],
        data,
      );

      const myWorkingDaysEnd = pathOr(
        'friday',
        ['myOrganization', 'workingDaysEnd'],
        data,
      );

      const myWorkingHoursStart = pathOr(
        '',
        ['myOrganization', 'workingHoursStart'],
        data,
      );

      const myWorkingHoursEnd = pathOr(
        '',
        ['myOrganization', 'workingHoursEnd'],
        data,
      );

      const myIconColor = pathOr(
        ICON_COLORS.DARK,
        ['myOrganization', 'iconColor'],
        data,
      );

      let myBackgroundColor = pathOr(
        white,
        ['myOrganization', 'backgroundColor'],
        data,
      );

      if (!myBackgroundColor.length) {
        myBackgroundColor = white;
      }

      if (!myBackgroundColor?.startsWith('#')) {
        myBackgroundColor = `#${myBackgroundColor}`;
      }

      const myMeetingRoomHoursStart = pathOr(
        '',
        ['myOrganization', 'meetingRoomHoursStart'],
        data,
      );
      const myMeetingRoomHoursEnd = pathOr(
        '',
        ['myOrganization', 'meetingRoomHoursEnd'],
        data,
      );

      const myEnabledFeatures = pathOr(
        [],
        ['myOrganization', 'enabledFeatures'],
        data,
      );

      const myMidDayHour = pathOr(0, ['myOrganization', 'midDayHour'], data);

      const myCateringPreparationTime = pathOr(
        DEFAULT_CONTEXT_STATE.preparationTime.catering,
        ['myOrganization', 'cateringPreparationTime'],
        data,
      );

      const myEquipmentPreparationTime = pathOr(
        DEFAULT_CONTEXT_STATE.preparationTime.equipment,
        ['myOrganization', 'equipmentPreparationTime'],
        data,
      );

      const myHrSystem = pathOr(
        DEFAULT_CONTEXT_STATE.hrSystem,
        ['myOrganization', 'hrSystemConfiguration', 'hrSystem'],
        data,
      );

      setId(id);
      setLogo(logo);
      setIconColor(myIconColor);
      setName(name);
      setTranslations(customTranslations);
      setBackgroundColor(myBackgroundColor);
      setBookingRange(
        myBookingRange === 0
          ? DEFAULT_CONTEXT_STATE.bookingRange
          : myBookingRange,
      );
      setMeetingRoomHoursStart(myMeetingRoomHoursStart);
      setMeetingRoomHoursEnd(myMeetingRoomHoursEnd);
      setEnabledFeatures(myEnabledFeatures);
      setWorkingHoursStart(myWorkingHoursStart);
      setWorkingHoursEnd(myWorkingHoursEnd);
      setWorkingDaysStart(myWorkingDaysStart);
      setWorkingDaysEnd(myWorkingDaysEnd);
      setMidDayHour(timeFromHours(myMidDayHour));
      setPreparationTime({
        catering: myCateringPreparationTime,
        equipment: myEquipmentPreparationTime,
      });
      setHrSystem(myHrSystem);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [refetch, language]);

  return (
    <MyOrganizationContext.Provider
      value={{
        id,
        logo,
        name,
        backgroundColor,
        iconColor,
        enabledFeatures,
        bookingRange: bookingRangeUser > -1 ? bookingRangeUser : bookingRange,
        translations,
        meetingRoomHoursStart,
        meetingRoomHoursEnd,
        workingHoursStart,
        workingHoursEnd,
        workingDaysStart,
        workingDaysEnd,
        midDayHour,
        preparationTime,
        error,
        loading: loadingOrganization,
        loaded: !isEmpty(id),
        contrastColor: iconColor === ICON_COLORS.DARK ? black : white,
        isWhiteBackground: backgroundColor.toLowerCase() === '#ffffff',
        hrSystem,
      }}
    >
      {children}
    </MyOrganizationContext.Provider>
  );
}
