import React from 'react';
import { ScrollView } from 'react-native';

import { useMyUser } from '@providers/User';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { globalStyles } from '@views/shared/styles';

import { UserData } from './UserData';
import { Logout } from './Logout';
import Language from './Language';
import CalendarAuth from './CalendarAuth';
import Toggles from './Toggles';
import { PrivacyPolicy } from './PrivacyPolicy';

export default function Main() {
  const {
    permissions: { canSeeProfileCalendar },
  } = useMyUser();
  const isCalendarIntegrationEnabled = useHasFeature('calendar_integration');

  return (
    <ScrollView style={globalStyles.container}>
      <UserData />
      <Language />
      <Toggles />
      {isCalendarIntegrationEnabled && canSeeProfileCalendar ? (
        <CalendarAuth />
      ) : null}
      <Logout />
      <PrivacyPolicy />
    </ScrollView>
  );
}
