import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { getBookingIcon } from '@views/shared/consts';

import Item from '@views/Book/Filter/ressources/item';

import { styles } from '@views/Book/Filter/styles';
import { ParkingTypesProps } from '@views/Book/Filter/interfaces';

export default function Parkings({
  onSelect,
  items,
  selected,
}: ParkingTypesProps) {
  const { t } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.headline}>
        <View>
          <Text style={styles.title}>{t('Home.Structure.Parkings.Title')}</Text>
          {selected.length ? <View style={styles.badge} /> : null}
        </View>
      </View>
      <View style={styles.itemsContainer}>
        {items.map((item, idx) => {
          // @ts-ignore
          const icon = getBookingIcon(item);

          return (
            <Item
              icon={icon}
              isActive={selected.includes(item)}
              key={idx}
              onPress={onSelect}
              text={t(`Home.Structure.Floor.Area.Parkings.${item}`)}
              value={item}
            />
          );
        })}
      </View>
    </View>
  );
}
