import React from 'react';
import { Text, View } from 'react-native';
import { Avatar } from 'react-native-elements';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { avatarWithBadgeStyle, colors, spacings } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';
import { CustomIcon } from '@views/shared/CustomIcon';
import { styles } from '@views/Colleagues/Profile/Overview/PersonalInfoBox';

import { useContent } from '@views/Home/Profile/Overview/UserData/hooks';
import { globalStyles } from '@views/shared/styles';
import { useMyOrganization } from '@providers/Organization';

const { darkBlue } = colors;

export function UserData() {
  const { me, loading, edit } = useContent();
  const { backgroundColor, isWhiteBackground } = useMyOrganization();

  if (loading) {
    return <Loader />;
  }

  if (!me) {
    return <UnexpectedNoDataOrError />;
  }

  const {
    email,
    initials,
    name,
    userprofile: {
      avatar,
      department: { name: departmentName },
      myBuilding: { name: myBuildingName },
      phoneNumber,
      licensePlate,
    },
  } = me;

  return (
    <View style={[globalStyles.shadow, { marginBottom: spacings.medium }]}>
      <View style={styles.card}>
        <View>
          <Avatar
            rounded
            size={100}
            source={
              avatar && avatar.trim().length ? { uri: avatar } : undefined
            }
            title={initials}
            titleStyle={[avatarWithBadgeStyle.title]}
            placeholderStyle={[avatarWithBadgeStyle.placeholder]}
            overlayContainerStyle={[
              avatarWithBadgeStyle.avatarOverlayContainer,
            ]}
            containerStyle={[
              avatarWithBadgeStyle.availableAvatarContainer,
              { borderColor: backgroundColor },
              isWhiteBackground && avatarWithBadgeStyle.spaceBorder,
            ]}
          />
        </View>

        <View style={styles.userDataContainer}>
          <View style={styles.top}>
            <View style={styles.longtext}>
              <Text style={globalStyles.title}>{name}</Text>
              {departmentName ? (
                <Text style={[globalStyles.description, { marginTop: 0 }]}>
                  {departmentName}
                </Text>
              ) : null}
            </View>
          </View>

          <View style={styles.info}>
            <CustomIcon
              name="mail"
              size={20}
              color={darkBlue}
              style={styles.icon}
            />
            <View style={styles.longtext}>
              <Text style={[globalStyles.description, { marginTop: 0 }]}>
                {email}
              </Text>
            </View>
          </View>
          <View style={styles.info}>
            <CustomIcon
              name="phone"
              size={20}
              color={darkBlue}
              style={styles.icon}
            />
            <Text style={[globalStyles.description, { marginTop: 0 }]}>
              {phoneNumber}
            </Text>
          </View>
          {licensePlate && licensePlate.length ? (
            <View style={styles.info}>
              <CustomIcon
                name="car"
                size={24}
                color={darkBlue}
                style={styles.icon}
              />
              <Text style={[globalStyles.description, { marginTop: 0 }]}>
                {licensePlate}
              </Text>
            </View>
          ) : null}
          {myBuildingName ? (
            <View style={styles.info}>
              <CustomIcon
                name="building"
                size={18}
                color={darkBlue}
                style={styles.icon}
              />
              <Text style={[globalStyles.description, { marginTop: 0 }]}>
                {myBuildingName}
              </Text>
            </View>
          ) : null}
        </View>
        <View>
          <CustomIcon name="edit" size={25} onPress={edit} />
        </View>
      </View>
    </View>
  );
}
