/************* USE THIS FOR RUNNING TESTS ***************/
// import * as dayjs from 'dayjs';
// import * as utc from 'dayjs/plugin/utc';
// import * as localeData from 'dayjs/plugin/localeData';
/**************************-*****************************/
import dayjs, { UnitType } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import localeData from 'dayjs/plugin/localeData';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';

import { compose, partial, partialRight, range, toLower, until } from 'ramda';

dayjs.extend(utc);
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export interface TimeRange {
  start: dayjs.Dayjs;
  end: dayjs.Dayjs;
}

export type EnabledWorkingDays = number[];

type Time = 'inherit' | 'startOfDay' | 'endOfDay';

export type WeekDayType =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | string;

export type MixDate = string | Date | dayjs.Dayjs;

interface TimeTransform {
  INHERIT: Time;
  START_OF_DAY: Time;
  END_OF_DAY: Time;
}

interface GetNextDaysProps {
  start: Date;
  enabledWorkingDays: EnabledWorkingDays;
  nextDays?: number;
  includeStartDate?: boolean;
}

export const TIME_TRANSFORM: TimeTransform = {
  INHERIT: 'inherit',
  START_OF_DAY: 'startOfDay',
  END_OF_DAY: 'endOfDay',
};

export const dateFormat = 'YYYY-MM-DDTHH:mm';
export const dateFormatAPI = 'YYYY-MM-DDTHH:mm:ss+00:00';
export const dateFormatShort = 'YYYY-MM-DD';

// To work in the scope of this file without acces the main object every time
const { INHERIT, START_OF_DAY, END_OF_DAY } = TIME_TRANSFORM;

const setTime = (
  date: dayjs.Dayjs,
  hours = 0,
  minutes = 0,
  seconds = 0,
): dayjs.Dayjs =>
  date
    .set('hours', hours)
    .set('minutes', minutes)
    .set('seconds', seconds);

const setWeekDay = (date: dayjs.Dayjs, days = 1): dayjs.Dayjs => date.day(days);

const setTimeByTransformationType = (date: dayjs.Dayjs, time: Time) => {
  const timeTransformations = {
    [INHERIT]: (date: dayjs.Dayjs): dayjs.Dayjs => date,
    [START_OF_DAY]: (date: dayjs.Dayjs): dayjs.Dayjs => setTime(date),
    [END_OF_DAY]: (date: dayjs.Dayjs): dayjs.Dayjs => setTime(date, 23, 59, 59),
  };

  return timeTransformations[time](date);
};

const weekDays = (dayjs.weekdays() || []).map(toLower);
const getToday = () => dayjs.utc();

// This function get the DayJs instance of the specified date. If no date is specified today will be returned.
export const transformOr = (date?: MixDate): dayjs.Dayjs => {
  // avoid to reparse and do the UTC parsing if the date is a valid dayjs instance
  if (dayjs.isDayjs(date)) {
    return date;
  }

  // Check for the calendar date format YYYY-MM-DD and parse it with it
  if (
    typeof date === 'string' &&
    /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/gim.test(date)
  ) {
    return dayjs.utc(date, 'YYYY-MM-DD');
  }

  // All other string formats and JS Date will be parsed with UTC
  return dayjs.utc(date);
};

export const toLocalTime = (date?: MixDate): dayjs.Dayjs => {
  return dayjs(date).tz();
};

const toDate = (date: dayjs.Dayjs): Date => date.toDate();

export const getEventsOverZoneFormat = (date, format) => {
  return dayjs(date, 'YYYY-MM-DDTHH:mm').format(format);
};

// Extracts day as number from the GraphQL API string format
export const getNumberFromHours = (hours: string): number => {
  const regex = /([0-9])?([0-9]):[0-9]+:[0-9]+/gi;
  const groups = regex.exec(hours);

  if (groups) {
    const firstDigit = Number(groups[1]);
    const secondDigit = Number(groups[2]);

    return secondDigit > 0
      ? firstDigit === 0
        ? secondDigit
        : Number(`${firstDigit}${secondDigit}`)
      : Number(`${firstDigit}${secondDigit}`);
  }

  return -1;
};

export const getAllDay = (date: MixDate): TimeRange => {
  const localDate = transformOr(date);

  return {
    start: setTimeByTransformationType(localDate, START_OF_DAY),
    end: setTimeByTransformationType(localDate, END_OF_DAY),
  };
};

export const getBookableWorkingTime = (
  date: MixDate,
  start: string | number,
  end: string | number,
): TimeRange => {
  const localDate = transformOr(date);
  const startValue =
    typeof start === 'number' ? start : getNumberFromHours(start);
  const endValue = typeof end === 'number' ? end : getNumberFromHours(end);

  return {
    start: setTime(localDate, startValue, 0, 0),
    end: setTime(localDate, endValue, 0, 0),
  };
};

export const setTimeToDate = (
  time: { hours: number; minutes: number },
  date?: string,
) => setTime(dayjs(date), time.hours, time.minutes, 0);

export const getTimeSlot = (
  date: MixDate,
  time: number,
  slotSize = 1, // by default 1 hour
): TimeRange => {
  const localDate = transformOr(date);

  return {
    start: setTime(localDate, time, 0, 0),
    end: setTime(localDate, time + slotSize, 0, 0),
  };
};

export const universalDateFormatter = ({
  date,
  format,
}: {
  date?: MixDate;
  format?: string;
}): string => transformOr(date).format(format || dateFormatAPI);

export const sortByDate = (a: MixDate, b: MixDate) =>
  transformOr(a).unix() - transformOr(b).unix();

export const sortByDateAdapter = ({ start: a }, { start: b }) =>
  sortByDate(a, b);

export const getHour = (date?: MixDate): number => transformOr(date).hour();
export const getDay = (date?: MixDate): number => transformOr(date).day();
export const isSameDay = (firstDate: MixDate, secondDate: MixDate): boolean =>
  transformOr(firstDate).isSame(transformOr(secondDate), 'day');
// This function returns true if firstDate is after secondDate
export const isAfter = (firstDate: MixDate, secondDate: MixDate): boolean =>
  transformOr(firstDate).isAfter(transformOr(secondDate));
// This function returns true if firstDate is before secondDate
export const isBefore = (firstDate: MixDate, secondDate: MixDate): boolean =>
  transformOr(firstDate).isBefore(transformOr(secondDate));

export const isToday = (date: MixDate): boolean =>
  getToday().isSame(transformOr(date), 'day');

export const isDayExpired = (date: MixDate): boolean =>
  isToday(date) ? false : getToday().isAfter(transformOr(date));

export const getTodayAsDate = (): Date => toDate(getToday());
export const getTodayAsDayJs = (): dayjs.Dayjs => getToday();

export const getStringAsDate = (date: string) => toDate(dayjs.utc(date));

// Transforms the specified date into the Date object format
export const transformToDate = (date: MixDate, time = INHERIT): Date => {
  const transformByTime = partialRight(setTimeByTransformationType, [time]);

  return compose(toDate, transformByTime, date => transformOr(date))(date);
};

export const getNextDayFromThis = (date: MixDate, daysToAdd = 1) =>
  transformOr(date).add(daysToAdd, 'days');

export const getPreviousDayFromThis = (date: MixDate, daysToSubtract = 1) =>
  transformOr(date).subtract(daysToSubtract, 'days');

export const getPreviousWeekFromThis = (date: MixDate, weeks = 1) =>
  transformOr(date).subtract(weeks, 'days');

export const getPreviousDayFromToday = (
  date: dayjs.Dayjs,
  daysToSubtract = 1,
) => date.subtract(daysToSubtract, 'days');

export const getWorkingDaysOfWeek = (
  workingDaysStart,
  workingDaysEnd,
): EnabledWorkingDays => {
  const days = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  // TODO enable the sunday as start of the week
  // check in return array the index of sunday and set as 0
  // return [0, 1, 2, 3, 4, 5, 6];

  // range generate list of number where from is inclusive and to is exclusive so our week range is 1-8
  return range(days[workingDaysStart], days[workingDaysEnd] + 1);
};

// TODO enable the sunday as start of the week
export const setFirstWeekDay = partialRight(setWeekDay, [1]);
export const setLastWeekDay = partialRight(setWeekDay, [7]);

export const getStartOfTheDay = partialRight(setTimeByTransformationType, [
  START_OF_DAY,
]);

export const getEndOfTheDay = partialRight(setTimeByTransformationType, [
  END_OF_DAY,
]);

export function getWeekStart(currentDate: MixDate, weekStart?: MixDate): MixDate {
  if (weekStart) {
    return weekStart;
  }

  return compose(toDate, getStartOfTheDay, setFirstWeekDay, date =>
    transformOr(date),
  )(currentDate);
}

export function getWeekEnd(weekStart: MixDate): Date {
  return compose(toDate, getEndOfTheDay, setLastWeekDay, date =>
    transformOr(date),
  )(weekStart);
}

// Returns the list of working week days based on the provided date
export const getWeekDays = (
  dayOfTheWeek: Date,
  enabledWorkingDays: EnabledWorkingDays,
): Date[] =>
  enabledWorkingDays.map((day: number) =>
    toDate(setWeekDay(transformOr(dayOfTheWeek), day)),
  );

export const getPreviousWeekDays = (enabledWorkingDays: EnabledWorkingDays) => {
  const currentDay = dayjs().day();
  return enabledWorkingDays
    .map((day: number) =>
      day < currentDay
        ? toDate(setWeekDay(dayjs(), day))
        : toDate(setWeekDay(dayjs(), day).subtract(7, 'day')),
    )
    .sort(function(a, b) {
      return b - a;
    });
};

export function* testRange(start, end, tick = 1) {
  for (let i = 1; i <= end; i += tick) {
    yield i;
  }
}

export const isWorkingDay = (
  enabledWorkingDays: EnabledWorkingDays,
  date: MixDate,
) => {
  const day = getDay(date);
  return enabledWorkingDays.includes(day === 0 ? 7 : day);
};

// Returns the list of next working week days based on the provided date and the number of desired days
export function getNextDays({
  start,
  enabledWorkingDays,
  nextDays = 5,
  includeStartDate = false,
}: GetNextDaysProps): Date[] {
  let current;
  const isEnabledWorkingDays = partial(isWorkingDay, [enabledWorkingDays]);
  const startValue = includeStartDate ? [start] : [];

  return until(
    (val: Date[]) => val.length === nextDays,
    (val: Date[]) => {
      current = getNextDayFromThis(current || start);

      if (isEnabledWorkingDays(current)) {
        return [...val, toDate(current)];
      }

      return val;
    },
  )(startValue);
}

// Returns the list of previous working week days based on the provided date and the number of desired days
export function getPreviousDays({
  start,
  enabledWorkingDays,
  nextDays = 5,
  includeStartDate = false,
}: GetNextDaysProps): Date[] {
  let current;
  const isEnabledWorkingDays = partial(isWorkingDay, [enabledWorkingDays]);
  const startValue = includeStartDate ? [start] : [];

  return until(
    (val: Date[]) => val.length === nextDays,
    (val: Date[]) => {
      current = getPreviousDayFromThis(current || start);

      if (isEnabledWorkingDays(current)) {
        return [...val, toDate(current)];
      }

      return val;
    },
  )(startValue);
}

// Returns the list of next working week days based on the provided date and the number of desired days
export function getWorkingDays({
  start,
  selectedDate,
  workingDaysStart,
  workingDaysEnd,
  maxDays,
}: {
  start: MixDate;
  selectedDate: MixDate;
  workingDaysStart: WeekDayType;
  workingDaysEnd: WeekDayType;
  maxDays: number;
}): { date: MixDate; isActive: boolean }[] {
  const startDate = {
    date: start,
    isActive: isSameDay(start, selectedDate),
  };

  const dates = [startDate];
  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );
  const isEnabledWorkingDays = partial(isWorkingDay, [enabledWorkingDays]);
  let current;
  let i = 0;

  while (i < maxDays) {
    current = getNextDayFromThis(current || start);

    if (isEnabledWorkingDays(current)) {
      dates.push({
        date: current,
        isActive: isSameDay(current, selectedDate),
      });
    }
    i++;
  }

  return dates;
}

// returns time with provided hours
export const timeFromHours = (hours: number) =>
  hours < 10 ? `0${hours}:00:00` : `${hours}:00:00`;

export const toHoursAndMinutes = minutes => ({
  hours: Math.floor(minutes / 60),
  minutes: Math.floor(minutes % 60),
});

export const calculateFullHours = (start: MixDate, end: MixDate) => {
  const startHours = dayjs(start)
    /*.utc()*/
    .startOf('hour')
    .hour();

  const minutes = dayjs(end).minute();

  const endHours = dayjs(end)
    /*.utc()*/
    .endOf('hour')
    .add(minutes !== 0 ? 1 : 0, 'seconds')
    .hour();

  return {
    start: setTime(dayjs(start), startHours).format(dateFormatAPI),
    end: setTime(dayjs(end), endHours).format(dateFormatAPI),
  };
};

export const getDiffFromNow = (date: MixDate, unit: UnitType) =>
  dayjs(date.substring(0, 19).replace('T', ' ')).diff(dayjs(), unit);
