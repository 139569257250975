import React from 'react';
import { useRoute } from '@react-navigation/native';

import { BookingConfirmationNavigationParams } from '@views/shared/interfaces/booking';

import {
  BookingConfirmation,
  ConfirmBookedTimes,
} from '@views/shared/BookingConfirmation';
import NextDaysBooking from '@views/Book/Book/NextDaysBooking';
import AdditionalOrder from '@views/Book/Book/MeetingRoom/AdditionalOrder';

export function Confirm() {
  const route = useRoute<any>();
  const {
    areaId,
    date,
    dateRange,
    deskId,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    location,
    parkingId,
    time,
    type,
  } = route.params as BookingConfirmationNavigationParams;

  return (
    <BookingConfirmation
      location={location}
      date={dateRange?.range || date}
      time={ConfirmBookedTimes(time)}
      type={type}
    >
      {hasNextDaysBooking ? (
        <NextDaysBooking
          currentBookedType={type}
          enabledHalfDayBooking={enabledHalfDayBooking}
          enabledHourlyBooking={enabledHourlyBooking}
          params={{ areaId, deskId, parkingId }}
        />
      ) : null}

      <AdditionalOrder {...route.params} />
    </BookingConfirmation>
  );
}
