import React from 'react';
import { View } from 'react-native';

import {
  AreaStructureForDesks,
  AreaStructureForFreeSeats,
  AreaStructureForParkingSpots,
  AreaStructureForMeetingRooms,
  AreaStructureForTelefonBox,
  AreaType,
} from '@views/shared/interfaces/buildingStructure';
import {
  AREA_TYPE_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_PARKING,
  AREA_TYPE_TELEFON_BOX,
} from '@views/shared/consts';

import {
  useDesksArea,
  useMeetingRoomArea,
  useSeatsArea,
  useTelefonBoxArea,
} from './hooks';
import { FreeSpaceRoom } from './FreeSpaceRoom';
import { MeetingRoom } from './MeetingRoom';
import { Desks } from './Desks';
import ParkingSpots from './ParkingSpots';
import { TelefonBox } from './TelefonBox';

interface Props {
  area: AreaStructureForDesks &
    AreaStructureForFreeSeats &
    AreaStructureForParkingSpots &
    AreaStructureForMeetingRooms &
    AreaStructureForTelefonBox;
  type: AreaType;
}

function FreeSpaceRoomArea({
  area,
  type,
}: {
  area: AreaStructureForFreeSeats;
  type: AreaType;
}) {
  const { notAvailable, worklight } = useSeatsArea(area, type);
  return (
    <FreeSpaceRoom
      area={area}
      notAvailable={notAvailable}
      worklight={worklight}
    />
  );
}

function MeetingRoomArea({ area }: { area: AreaStructureForMeetingRooms }) {
  const { notAvailable, worklight } = useMeetingRoomArea(area);

  return (
    <MeetingRoom
      area={area}
      notAvailable={notAvailable}
      worklight={worklight}
    />
  );
}

function TelefonBoxArea({ area }: { area: AreaStructureForTelefonBox }) {
  const { notAvailable, worklight } = useTelefonBoxArea(area);

  return (
    <TelefonBox area={area} notAvailable={notAvailable} worklight={worklight} />
  );
}

function ParkingSpotsArea({ area }: { area: AreaStructureForParkingSpots }) {
  return <ParkingSpots area={area} />;
}

function DesksArea({
  area,
  type,
}: {
  area: AreaStructureForDesks;
  type: AreaType;
}) {
  const { worklight, bookAnyDeskEnabled } = useDesksArea(area);

  return bookAnyDeskEnabled ? (
    <FreeSpaceRoomArea area={area} type={type} />
  ) : (
    <Desks area={area} worklight={worklight} />
  );
}

function AreaByType({ area, type }: Props) {
  const areaTypes = {
    [AREA_TYPE_ROOM]: DesksArea,
    [AREA_TYPE_MEETING_ROOM]: MeetingRoomArea,
    [AREA_TYPE_FREE_SPACES_ROOM]: FreeSpaceRoomArea,
    [AREA_TYPE_PARKING]: ParkingSpotsArea,
    [AREA_TYPE_TELEFON_BOX]: TelefonBoxArea,
  };

  return areaTypes[type]({ area, type });
}

export function Area({ area, type }: Props) {
  return (
    <View>
      <AreaByType area={area} type={type} />
    </View>
  );
}
