import { defineMessages } from 'react-intl';

const dictionary = defineMessages({
  'Floorplan.fetchError': {
    id: 'Floorplan.fetchError',
    defaultMessage: 'Could not fetch floor plans',
  },
  'Floorplan.no.active.floor': {
    id: 'Floorplan.no.active.floor',
    defaultMessage:
      'Oops, it seems you do not have any floor configured for your Organization',
  },
  'Floorplan.no.data.for.floor': {
    id: 'Floorplan.no.data.for.floor',
    defaultMessage: 'Oops, it seems there is no data available for this floor',
  },
  'Floorplan.ZoomImage.Points.fetch': {
    id: 'Floorplan.ZoomImage.Points.fetch',
    defaultMessage: 'Could not fetch desks',
  },
  'Floorplan.ZoomImage.Areas.fetch': {
    id: 'Floorplan.ZoomImage.Areas.fetch',
    defaultMessage: 'Could not fetch areas',
  },
  'Floorplan.ZoomImage.Loading': {
    id: 'Floorplan.ZoomImage.Loading',
    defaultMessage: 'The data will be prepared...',
  },
});

export default {
  ...dictionary,
};
