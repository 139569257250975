import React from 'react';
import { View } from 'react-native';
import { isNil } from 'ramda';

import { useIntl } from '@utils/intl';
import { Loader } from '@views/shared/Loader';
import BasicBook from '@views/Book/Book/BasicBook';

import Booking from './Booking';
import useValues from './hooks';

export default function BookParkingSpot() {
  const { t, formats } = useIntl();
  const errorMessage = t('Home.ActiveBooking.Verify.FetchingFailed');

  const {
    areaId,
    currentDate,
    data,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    error,
    loading,
    hasNextDaysBooking,
    parkingId,
    time,
    type,
  } = useValues(formats, errorMessage);

  if (loading) {
    return <Loader />;
  }

  if (error || isNil(data)) {
    return <View>{errorMessage}</View>;
  }

  function ParkingSpot({ showAvailability }: { showAvailability? }) {
    return (
      <Booking
        type={type}
        areaId={areaId}
        parkingId={parkingId}
        date={currentDate}
        enabledHalfDayBooking={enabledHalfDayBooking}
        enabledHourlyBooking={enabledHourlyBooking}
        hasNextDaysBooking={hasNextDaysBooking}
        showAvailability={showAvailability}
      />
    );
  }

  return (
    <BasicBook
      booking={data}
      date={date}
      time={time}
      type={type}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
    >
      <ParkingSpot />
    </BasicBook>
  );
}
