import React from 'react';
import {
  ImageBackground,
  StyleSheet,
  Text,
  TouchableOpacity,
} from 'react-native';

import { useIntl } from '@utils/intl';
import {
  getTodayAsDayJs,
  getBookableWorkingTime,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { colors, fonts, spacings } from '@views/shared/styles';

import { SELECTION_OPTIONS } from '@views/shared/TimeSlots/helper';
import { SelectionProps } from '@views/shared/TimeSlots/interfaces';

const selectionMap = {
  [SELECTION_OPTIONS.BEFORE_LUNCH]: {
    icon: require('@assets/images/beforeMidDay.png'),
    title: 'Time.Slots.selection.button.beforeLunch',
  },
  [SELECTION_OPTIONS.AFTER_LUNCH]: {
    icon: require('@assets/images/afterMidDay.png'),
    title: 'Time.Slots.selection.button.AfterLunch',
  },
  [SELECTION_OPTIONS.FULL_DAY]: {
    icon: require('@assets/images/fullDay.png'),
    title: 'Time.Slots.selection.button.fullDay',
  },
};

const { aquaMarine, aquaMarineLight, grey5, whiteOverlay } = colors;
const { extraSmall, smaller, verySmall } = spacings;

const styles = StyleSheet.create({
  selectionContainer: {
    backgroundColor: grey5,
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderColor: grey5,
    borderWidth: 1,
    paddingTop: smaller,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  selectionTitle: {
    fontFamily: 'mrt-semi-bold',
  },
  selectionText: {
    fontFamily: 'mrt-regular',
    textAlign: 'center',
    paddingTop: smaller,
    paddingHorizontal: verySmall,
  },
  selectionImage: {
    height: 130,
    marginTop: -20,
    width: '100%',
  },
  selected: {
    borderColor: aquaMarine,
    borderWidth: 1,
    backgroundColor: aquaMarineLight,
  },
  disabled: {
    backgroundColor: whiteOverlay,
    borderColor: whiteOverlay,
  },
  overlay: {
    paddingBottom: extraSmall,
    borderRadius: extraSmall,
    flex: 1,
    marginHorizontal: extraSmall,
  },
  smallText: { fontSize: fonts.sizeSmallPlus },
});

const getSelectionTime = (from: string, to: string, format: string) => {
  const date = getTodayAsDayJs();
  const { start, end } = getBookableWorkingTime(date, from, to);

  const startOfSelection = universalDateFormatter({
    date: start,
    format,
  });

  const endOfSelection = universalDateFormatter({
    date: end,
    format,
  });

  return `${startOfSelection} - ${endOfSelection}`;
};

export default function Selection({
  from,
  isDisabled,
  isSelected,
  onPress,
  selection,
  showInOverlay,
  to,
  width,
}: SelectionProps) {
  const {
    t,
    formats: { timeFormatShort },
  } = useIntl();
  const selectionTime = getSelectionTime(from, to, timeFormatShort);
  const { icon, title } = selectionMap[selection];
  const opacity = isDisabled ? 0.2 : 1.0;

  return (
    <TouchableOpacity
      activeOpacity={1}
      disabled={isDisabled}
      onPress={onPress}
      style={[
        styles.selectionContainer,
        isSelected && styles.selected,
        isDisabled && styles.disabled,
        { width },
        showInOverlay && styles.overlay,
      ]}
    >
      <Text
        style={[
          styles.selectionText,
          styles.selectionTitle,
          { opacity },
          showInOverlay && styles.smallText,
        ]}
      >
        {t(title)}
      </Text>
      <Text
        style={[
          styles.selectionText,
          { opacity },
          showInOverlay && styles.smallText,
        ]}
      >
        {selectionTime}
      </Text>

      {!showInOverlay ? (
        <ImageBackground
          source={icon}
          style={[styles.selectionImage, { opacity }]}
        />
      ) : null}
    </TouchableOpacity>
  );
}
