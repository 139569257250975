import React from 'react';
import { isEmpty, startsWith } from 'ramda';
import { StyleSheet, Text, View, Platform, ViewStyle } from 'react-native';
import { isNil } from 'ramda';

import { MixDate, universalDateFormatter } from '@utils/DateAndTime';
import { Loader } from '@views/shared/Loader';
import { useIntl } from '@utils/intl';
import { getBookingIcon } from '@views/shared/consts';
import { STATUS } from '@views/shared/TimeSlots/helper';
import { globalStyles, spacings, colors, fonts } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';
import { BookingIcon } from '@views/shared/BookingIcon';
import TitleByType from '@views/shared/TitleByType';
import { Booking, BookingType } from '@views/shared/interfaces/booking';
import { Status, Colleague } from '@views/shared/TimeSlots/interfaces';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

const { blackAlpha, darkBlue, lightBlue, warmPink, white } = colors;
const { sizeDefault, sizeTitle } = fonts;
const {
  extraSmall,
  medium,
  small,
  smaller,
  oversize,
  verySmall,
  big,
} = spacings;

const isWeb = Platform.OS === 'web';

const styles = StyleSheet.create({
  view: {
    bottom: small,
    backgroundColor: white,
    borderRadius: extraSmall,
    padding: medium,
    paddingBottom: oversize,
    position: 'absolute',
    zIndex: 20,
    marginHorizontal: small,
    width: isWeb ? '97%' : '95%',
    left: 5,
  },
  viewDesktop: {
    bottom: small,
    backgroundColor: white,
    borderRadius: extraSmall,
    padding: medium,
    paddingBottom: oversize,
    position: 'absolute',
    zIndex: 20,
    marginHorizontal: small,
    width: '50%',
    left: '25%',
  },
  container: {
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: big,
  },
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
  card: {
    flexDirection: 'row',
    position: 'relative',
    marginTop: verySmall,
    alignItems: 'center',
  },
  header: {
    textAlign: 'left',
    fontSize: sizeTitle,
    fontFamily: 'mrt-regular',
    marginTop: small,
  },
  icon: {
    paddingRight: big,
  },
  right: {
    justifyContent: 'space-between',
    flex: 1,
  },
  date: {
    fontSize: sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
  checkmark: {
    position: 'absolute',
    right: -15,
    bottom: -35,
  },
  book: {
    paddingVertical: oversize,
    textAlign: 'center',
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: oversize,
  },
  notice: {
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
  licensePlate: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
});

function MyBookingInfo({
  findActiveBooking,
  format,
}: {
  findActiveBooking: () => Booking | undefined;
  format: string;
}) {
  const booking = findActiveBooking();
  if (!booking) {
    return null;
  }

  const { end, start } = booking;

  return (
    <Text>
      {universalDateFormatter({ date: start, format })} -{' '}
      {universalDateFormatter({ date: end, format })}
    </Text>
  );
}

const statusIconColors = {
  [STATUS.BLOCKED]: warmPink,
  [STATUS.BOOKED_BY_COLLEAGUE]: lightBlue,
  [STATUS.BOOKED]: darkBlue,
};

const statusMessage = {
  [STATUS.BLOCKED]: 'AvailabilityInfos.Status.Blocked',
  [STATUS.BOOKED]: 'AvailabilityInfos.Status.Booked',
  [STATUS.BOOKED_SLOT]: 'AvailabilityInfos.Status.BookedTime',
  [STATUS.BOOKED_BY_COLLEAGUE]: 'AvailabilityInfos.Status.BookedByColleague',
  [STATUS.DISABLED]: 'AvailabilityInfos.Status.Disabled',
  [STATUS.EXPIRED]: 'AvailabilityInfos.Status.Expired',
  [STATUS.OCCUPIED]: 'AvailabilityInfos.Status.OccupiedByColleague',
};

interface TimeWithColleague {
  end: MixDate;
  start: MixDate;
  colleagues: Colleague[];
}

export default function AvailabilityInfos({
  error,
  findActiveBooking,
  fullCaption,
  loading,
  status,
  unavailableTimeWithColleague,
  date,
  dismiss,
  type,
  containerStyle,
  time,
  isFloorplan,
}: {
  date: MixDate;
  loading: boolean;
  error: any;
  fullCaption: string;
  status: Status;
  findActiveBooking?: () => Booking | undefined;
  dismiss: (info?) => any;
  unavailableTimeWithColleague: TimeWithColleague[];
  type: BookingType;
  containerStyle?: ViewStyle;
  time?: { start: MixDate; end: MixDate };
  isFloorplan?: boolean;
}) {
  const {
    t,
    formats: { dayFormat, timeFormatShort },
  } = useIntl();

  const formatTime = date =>
    universalDateFormatter({
      date,
      format: timeFormatShort,
    });

  const isDesktop = useIsDesktop();

  const bookingStatus =
    status === STATUS.BOOKED_BY_COLLEAGUE &&
    isEmpty(unavailableTimeWithColleague)
      ? STATUS.OCCUPIED
      : status === STATUS.BOOKED && !isFloorplan
      ? STATUS.BOOKED_SLOT
      : status;

  const message = error ? `[Error]: ${error}` : t(statusMessage[bookingStatus]);

  const iconColor = statusIconColors[status];
  const isParking = startsWith('VirtualParking', type);
  const isBookedByColleague = status === STATUS.BOOKED_BY_COLLEAGUE;

  return (
    <View
      style={[
        isDesktop ? styles.viewDesktop : styles.view,
        globalStyles.shadow,
        containerStyle,
      ]}
    >
      <CustomIcon
        name="close"
        onPress={() => dismiss()}
        size={20}
        style={styles.close}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Text style={styles.header}>{message}</Text>
          <View style={[styles.card, styles.container]}>
            <View style={styles.icon}>
              <BookingIcon
                name={getBookingIcon(type)}
                style={{ fontSize: 75 }}
              />
            </View>
            <View style={styles.right}>
              <View>
                <TitleByType type={type} />
                <Text style={globalStyles.description}>{fullCaption}</Text>
              </View>
              <View>
                <Text style={[styles.date, { paddingTop: spacings.small }]}>
                  {universalDateFormatter({ date, format: dayFormat })}
                </Text>
              </View>
              {isBookedByColleague &&
                unavailableTimeWithColleague.map(
                  ({ colleagues, start, end }, idx) => {
                    const startTime = formatTime(start);
                    const endTime = formatTime(end);

                    return (
                      <View key={idx}>
                        <Text>
                          {startTime} - {endTime}
                        </Text>
                        {!isEmpty(colleagues)
                          ? colleagues.map(({ name, licensePlate }, key) =>
                              isParking &&
                              licensePlate &&
                              licensePlate.length ? (
                                <View
                                  key={key}
                                  style={{ marginBottom: smaller }}
                                >
                                  <Text>{name}</Text>
                                  <View style={styles.licensePlate}>
                                    <CustomIcon
                                      name="car"
                                      size={20}
                                      style={{
                                        paddingRight: extraSmall,
                                      }}
                                    />
                                    <Text>{licensePlate}</Text>
                                  </View>
                                </View>
                              ) : (
                                <View
                                  key={idx}
                                  style={{ marginBottom: smaller }}
                                >
                                  <Text>{name}</Text>
                                </View>
                              ),
                            )
                          : null}
                      </View>
                    );
                  },
                )}
              {!isNil(time) && !isBookedByColleague ? (
                <View>
                  <Text>
                    {formatTime(time.start)} - {formatTime(time.end)}
                  </Text>
                </View>
              ) : null}
              {status === STATUS.BOOKED && findActiveBooking && (
                <MyBookingInfo
                  findActiveBooking={findActiveBooking}
                  format={timeFormatShort}
                />
              )}
            </View>
            <CustomIcon
              color={iconColor}
              name={status === STATUS.BOOKED ? 'warning' : 'close'}
              size={status === STATUS.BOOKED ? 75 : 95}
              style={[
                styles.checkmark,
                { right: status === STATUS.BOOKED ? -10 : -25 },
                { bottom: status === STATUS.BOOKED ? -17 : -35 },
              ]}
            />
          </View>
        </>
      )}
    </View>
  );
}
