import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

import { Overlay } from '@views/shared/Overlay';
import { MainButton } from '@views/shared/Button';
import { useIntl } from '@utils/intl';
import { spacings } from '@views/shared/styles';
import { styles as overlayStyles } from '@views/Home/ActiveBookings/Booking/Problem';

import { Input } from '@views/Home/Profile/Edit';
import { TimeSelector } from '@views/Home/Attendance/PopUp';

import { useUpdateAttendance } from './hooks';
import { globalStyles } from '@views/shared/styles';

const { smaller, huge, small } = spacings;

const styles = StyleSheet.create({
  selectorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: spacings.small,
  },
  breakText: {
    marginBottom: small,
  },
  timeSelectorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  center: {
    alignItems: 'center',
  },
  spacer: {
    marginTop: huge,
  },
});

export default function PopUp(props) {
  const { t } = useIntl();

  const { onEdit } = props;

  const {
    isLoading,
    isError,
    workingHours,
    onUpdate,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    onStartTimeChange,
    onEndTimeChange,
    onStartBreakTimeChange,
    onEndBreakTimeChange,
    invalidTime,
    updateComment,
  } = useUpdateAttendance(props);

  const invalidTimeText = invalidTime ? (
    <Text style={[overlayStyles.success, overlayStyles.failed]}>
      {t('Home.Attendance.Edit.InvalidTimeError')}
    </Text>
  ) : null;

  return (
    <Overlay onClose={onEdit} title={t('Home.Attendance.Edit.Title')}>
      {isError ? (
        <Text style={[overlayStyles.success, overlayStyles.failed]}>
          {t('Home.Attendance.Layer.Error')}
        </Text>
      ) : null}
      {invalidTimeText}
      <View style={styles.timeSelectorContainer}>
        <View style={styles.center}>
          <Text style={globalStyles.label}>
            {t('Home.Attendance.StartTime')}
          </Text>
          <TimeSelector
            onChange={onStartTimeChange}
            time={startTime}
            workingHours={workingHours}
          />
        </View>
        <View style={[styles.center, { marginLeft: huge }]}>
          <Text style={globalStyles.label}>{t('Home.Attendance.EndTime')}</Text>
          <TimeSelector
            onChange={onEndTimeChange}
            time={endTime}
            workingHours={workingHours}
          />
        </View>
      </View>
      <View style={styles.spacer}>
        <Text style={[globalStyles.description, styles.breakText]}>
          {t('Home.Attendance.Edit.BreakEdit.Title')}
        </Text>

        <View style={styles.timeSelectorContainer}>
          <TimeSelector
            onChange={onStartBreakTimeChange}
            time={breakStartTime}
            workingHours={workingHours}
          />
          <View style={{ marginLeft: huge }}>
            <TimeSelector
              onChange={onEndBreakTimeChange}
              time={breakEndTime}
              workingHours={workingHours}
            />
          </View>
        </View>
      </View>

      <View style={styles.spacer}>
        <Input
          description="Home.Attendance.Edit.Comment.Description"
          onChange={updateComment}
          maxLength={200}
          style={{ marginBottom: 0 }}
        />
      </View>
      <View style={[overlayStyles.footer, { marginTop: smaller }]}>
        <MainButton
          disabled={isError || isLoading || invalidTime}
          onPress={onUpdate}
          buttonStyle={overlayStyles.buttonSend}
          title={t('Home.Attendance.Button.Save')}
        />
      </View>
    </Overlay>
  );
}
