import { useCallback } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';

import { useHasFeature } from '@views/shared/hooks/hasFeature';

export function useProfile() {
  const route = useRoute<any>();
  const navigation = useNavigation<any>();
  const findMyColleagueEnabled = useHasFeature('find_my_colleague');
  const showColleagueBookings = !useHasFeature('disable_colleague_bookings');
  const { colleague } = route.params || {};

  const goToList = useCallback(() => {
    navigation.navigate('colleagues', {
      screen: 'list',
      initial: false,
    });
  }, [navigation]);

  return {
    id: colleague ? colleague.id : null,
    colleague,
    goToList,
    findMyColleagueEnabled,
    showColleagueBookings,
  };
}
