import { Platform, Dimensions, StyleSheet } from 'react-native';

import { colors, fonts, spacings } from '@views/shared/styles';

const {
  extraSmall,
  medium,
  oversize,
  small,
  smaller,
  smallMedium,
  tiny,
} = spacings;
const { aquaMarine, aquaMarineLight, grey1, grey5, grey6 } = colors;
const { sizeDefault, sizeSmallPlus, sizeTitle } = fonts;

const isWeb = Platform.OS === 'web';
const gap = smallMedium;

export const styles = StyleSheet.create({
  showMore: {
    display: 'flex',
    flexDirection: 'row',
  },
  showMoreText: {
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
  showMoreIcon: {
    paddingTop: tiny,
    paddingLeft: smaller,
  },
  container: {
    borderWidth: 1,
    borderColor: grey1,
    borderRadius: extraSmall,
    marginBottom: small,
  },
  itemsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginHorizontal: gap / -2,
    paddingVertical: extraSmall,
    paddingHorizontal: small,
  },
  itemsTimeContainer: {
    /* marginHorizontal: extraSmall,*/
    justifyContent: 'space-between',
    paddingHorizontal: 0,
  },
  title: {
    fontFamily: 'mrt-semi-bold',
  },
  item: {
    fontFamily: 'mrt-regular',
    borderColor: grey1,
    borderWidth: 1,
    borderRadius: smaller,
    padding: extraSmall,
    margin: gap / 2,
    fontSize: sizeSmallPlus,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemText: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSmallPlus,
  },
  activeItem: {
    borderColor: aquaMarine,
    backgroundColor: aquaMarineLight,
  },
  hiddenItem: {
    display: 'none',
  },
  timeItem: {
    width: '50%',
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: isWeb ? '100%' : Dimensions.get('screen').width,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: small,
    paddingVertical: smallMedium,
  },
  optionsContainer: {
    paddingBottom: 50,
  },
  close: {
    position: 'absolute',
    left: 8,
    zIndex: 20,
  },
  closeOverlay: {
    position: 'absolute',
    right: 7,
    top: 8,
  },
  headline: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: grey1,
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingVertical: small,
    paddingHorizontal: medium,
  },
  scrollArea: {
    marginTop: oversize,
  },
  icon: {
    paddingRight: extraSmall,
  },
  overlay: {
    height: 'auto',
    justifyContent: 'center',
    padding: 0,
  },
  overlayHeadline: {
    backgroundColor: grey1,
    borderTopLeftRadius: extraSmall,
    borderTopRightRadius: extraSmall,
    padding: small,
    flexDirection: 'row',
  },
  overlayTitle: {
    fontFamily: 'pts-regular',
    fontSize: sizeTitle,
    textAlign: 'center',
    flex: 1,
  },
  overlayButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: small,
  },
  header: {
    backgroundColor: grey1,
    flexDirection: 'row',
    borderRadius: extraSmall,
    paddingHorizontal: medium,
    paddingVertical: small,
    alignItems: 'center',
    justifyContent: 'center',
  },
  titleWrapper: {
    alignItems: 'center',
  },
  filterIcon: {
    position: 'absolute',
    top: 18,
    right: 12,
    width: 28,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectionContainer: {
    margin: small,
  },
  selection: {
    padding: medium,
    backgroundColor: grey1,
    borderRadius: extraSmall,
    alignItems: 'center',
  },
  selectionText: {
    marginTop: extraSmall,
    fontFamily: 'mrt-semi-bold',
  },
  disabledItem: {
    backgroundColor: grey5,
    color: grey6,
  },
  badge: {
    backgroundColor: aquaMarine,
    position: 'absolute',
    right: -8,
    width: 8,
    height: 8,
    borderRadius: 5,
  },
});
