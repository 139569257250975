import { useCallback, useState } from 'react';
import { isNil } from 'ramda';

import { Formats } from '@utils/intl';
import { useBaseValues } from '@views/Book/Book/BasicBook/hooks';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { BOOKING_TYPE_VACATION } from '@views/shared/consts';
import { universalDateFormatter, TimeRange } from '@utils/DateAndTime';

const formatDate = (date, format) =>
  universalDateFormatter({
    date,
    format,
  });

const getDateOrDateRange = (
  { currentDate },
  { start, end }: TimeRange,
  { dayFullFormat }: Formats,
) => {
  if (!isNil(start) && !isNil(end)) {
    const startValue = formatDate(start, dayFullFormat);
    const endValue = formatDate(end, dayFullFormat);
    return `${startValue} - ${endValue}`;
  } else if (!isNil(start)) {
    return formatDate(start, dayFullFormat);
  } else {
    return formatDate(currentDate, dayFullFormat);
  }
};

export function useValues(formats: Formats) {
  const result = useBaseValues(formats);
  const enabledHalfDayBooking = useHasFeature('halfday_booking');
  const enabledHourlyBooking = useHasFeature('hourly_booking');

  const [dateRange, setDateRange] = useState<TimeRange>({
    start: null,
    end: null,
  });

  const onDayChange = useCallback(
    ({ timestamp }) => {
      const { start, end } = dateRange;

      const startIsEmpty = isNil(start);

      // if there is no start day, add start.
      // if start and end date are there, restart
      if (startIsEmpty || (!startIsEmpty && !isNil(end))) {
        setDateRange({ start: timestamp, end: null });
      } else {
        // switch dates if end is older than start
        if (start > timestamp) {
          setDateRange({ start: timestamp, end: start });
        } else if (start === timestamp) {
          setDateRange({ start, end: null });
        } else {
          setDateRange({ start, end: timestamp });
        }
      }
    },
    [dateRange, setDateRange],
  );

  const date = getDateOrDateRange(result, dateRange, formats);

  return {
    ...result,
    onDayChange,
    dateRange: { ...dateRange, range: date },
    date,
    booking: { [BOOKING_TYPE_VACATION]: true },
    type: BOOKING_TYPE_VACATION,
    hasNextDaysBooking: false,
    enabledHalfDayBooking: enabledHalfDayBooking,
    enabledHourlyBooking: enabledHourlyBooking,
  };
}
