import React from 'react';
import { ScrollView } from 'react-native';

import AttendanceWeek from '@views/Bookings/MyAttendances/AttendanceWeek';

import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { globalStyles } from '@views/shared/styles';
import { Buildings } from '@views/Book/Buildings';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import Attendance from './Attendance';
import Profile from './Profile';
import ActiveBookings from './ActiveBookings';
import BookForDesktop from './BookForDesktop';

export default function Main() {
  const isDesktop = useIsDesktop();
  const isTimeTrackingEnabled = useHasFeature('time_tracking');

  return (
    <ScrollView style={globalStyles.container}>
      {!isDesktop && <Profile />}
      {isDesktop && <Buildings />}
      {isTimeTrackingEnabled && !isDesktop ? <Attendance /> : null}
      <ActiveBookings />
      {isTimeTrackingEnabled && !isDesktop ? <AttendanceWeek /> : null}
      {isDesktop && <BookForDesktop />}
    </ScrollView>
  );
}
