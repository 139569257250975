import { defineMessages } from 'react-intl';

import { messages as bookingMessages } from './Booking';
import { messages as structureMessages } from './Structure';

const dictionary = defineMessages({
  'Home.Booking.TimeSlots.hooks.error': {
    id: 'Home.Booking.TimeSlots.hooks.error',
    defaultMessage:
      'Could not fetch unavailable Time Periods for the selected resource',
  },
  'Home.MeetingRoom.book': {
    id: 'Home.MeetingRoom.book',
    defaultMessage: 'Book this room',
  },
  'Home.Attendance.Layer.Description': {
    id: 'Home.Attendance.Layer.Description',
    defaultMessage: 'Clocked in for {value} today',
  },
  'Home.Attendance.Layer.Break.Description': {
    id: 'Home.Attendance.Layer.Break.Description',
    defaultMessage: 'Break taken for {value} today',
  },
  'Home.Attendance.Layer.Hours': {
    id: 'Home.Attendance.Layer.Hours',
    defaultMessage: 'hours',
  },
  'Home.Attendance.Layer.Hour': {
    id: 'Home.Attendance.Layer.Hour',
    defaultMessage: 'hour',
  },
  'Home.Attendance.Layer.Minutes': {
    id: 'Home.Attendance.Layer.Minutes',
    defaultMessage: 'minutes',
  },
  'Home.Attendance.Layer.Minute': {
    id: 'Home.Attendance.Layer.Minute',
    defaultMessage: 'minute',
  },
  'Home.Attendance.Button.Start': {
    id: 'Home.Attendance.Button.Start',
    defaultMessage: 'Clock in now',
  },
  'Home.Attendance.Button.Stop': {
    id: 'Home.Attendance.Button.Stop',
    defaultMessage: 'Clock out',
  },
  'Home.Attendance.Button.Break': {
    id: 'Home.Attendance.Button.Break',
    defaultMessage: 'Take a break',
  },
  'Home.Attendance.Button.StopBreak': {
    id: 'Home.Attendance.Button.StopBreak',
    defaultMessage: 'Stop the break',
  },
  'Home.Attendance.Button.ContinueTracking': {
    id: 'Home.Attendance.Button.ContinueTracking',
    defaultMessage: 'Clock in again',
  },
  'Home.Attendance.Button.Save': {
    id: 'Home.Attendance.Button.Save',
    defaultMessage: 'Apply changes',
  },
  'Home.Attendance.Title': {
    id: 'Home.Attendance.Title',
    defaultMessage: 'Start time tracking',
  },
  'Home.Attendance.Timer': {
    id: 'Home.Attendance.Timer',
    defaultMessage: 'Timer',
  },
  'Home.Attendance.Layer.Start.Title': {
    id: 'Home.Attendance.Layer.Start.Title',
    defaultMessage: 'When did you start?',
  },
  'Home.Attendance.Layer.Break.Title': {
    id: 'Home.Attendance.Layer.Break.Title',
    defaultMessage: 'Do you want to start your break?',
  },
  'Home.Attendance.Layer.BreakEnd.Title': {
    id: 'Home.Attendance.Layer.BreakEnd.Title',
    defaultMessage: 'Do you want to finish your break?',
  },
  'Home.Attendance.Layer.BreakStart.Title': {
    id: 'Home.Attendance.Layer.BreakStart.Title',
    defaultMessage: 'When did you start your break?',
  },
  'Home.Attendance.Layer.EndBreak.Title': {
    id: 'Home.Attendance.Layer.EndBreak.Title',
    defaultMessage: 'When did you finish your break?',
  },
  'Home.Attendance.Layer.Stop.Title': {
    id: 'Home.Attendance.Layer.Stop.Title',
    defaultMessage: 'When did you finish?',
  },
  'Home.Attendance.Layer.Description.Break': {
    id: 'Home.Attendance.Layer.Description.Break',
    defaultMessage: 'You are at break',
  },
  'Home.Attendance.Layer.Error': {
    id: 'Home.Attendance.Layer.Error',
    defaultMessage: 'An error occurred due saving',
  },
  'Home.Attendance.Layer.ErrorTimeInvalid': {
    id: 'Home.Attendance.Layer.ErrorTimeInvalid',
    defaultMessage: 'Selected time cannot be in the future',
  },
  'Home.Attendance.Layer.Confirm.break': {
    id: 'Home.Attendance.Layer.Confirm.break',
    defaultMessage: 'Your break will start at {value}',
  },
  'Home.Attendance.Layer.Confirm.breakEnd': {
    id: 'Home.Attendance.Layer.Confirm.breakEnd',
    defaultMessage: 'Your break will end at {value}',
  },
  'Home.Attendance.Layer.ErrorTimeOverlapping': {
    id: 'Home.Attendance.Layer.ErrorTimeOverlapping',
    defaultMessage:
      'Please check your selected time. There is an overlap with existing records.',
  },
  'Home.Attendance.Layer.Hour.Short': {
    id: 'Home.Attendance.Layer.Hour.Short',
    defaultMessage: 'hr',
  },
  'Home.Attendance.Layer.Hours.Short': {
    id: 'Home.Attendance.Layer.Hours.Short',
    defaultMessage: 'hrs',
  },
  'Home.Attendance.Layer.Minutes.Short': {
    id: 'Home.Attendance.Layer.Minutes.Short',
    defaultMessage: 'min',
  },
  'Home.Attendance.HistoricView.Title': {
    id: 'Home.Attendance.HistoricView.Title',
    defaultMessage: 'Time tracking for the last 5 days',
  },
  'Home.Attendance.HistoricView.NoRecord': {
    id: 'Home.Attendance.HistoricView.NoRecord',
    defaultMessage: 'No time clocked for the day',
  },
  'Home.Attendance.HistoricView.Break': {
    id: 'Home.Attendance.HistoricView.Break',
    defaultMessage: 'Break',
  },
  'Home.Attendance.HistoricView.Work': {
    id: 'Home.Attendance.HistoricView.Work',
    defaultMessage: 'Work',
  },
  'Home.Attendance.HistoricView.Meeting': {
    id: 'Home.Attendance.HistoricView.Meeting',
    defaultMessage: 'Meeting',
  },
  'Home.Attendance.HistoricView.Total': {
    id: 'Home.Attendance.HistoricView.Total',
    defaultMessage: 'Total',
  },
  'Home.Attendance.Edit.Title': {
    id: 'Home.Attendance.Edit.Title',
    defaultMessage: 'Select the new time for the attendance',
  },
  'Home.Attendance.StartTime': {
    id: 'Home.Attendance.StartTime',
    defaultMessage: 'Start time',
  },
  'Home.Attendance.EndTime': {
    id: 'Home.Attendance.EndTime',
    defaultMessage: 'End time',
  },
  'Home.Attendance.Edit.BreakEdit.Title': {
    id: 'Home.Attendance.Edit.BreakEdit.Title',
    defaultMessage: 'Modify your break time for the attendance below if needed',
  },
  'Home.Attendance.Edit.InvalidTimeError': {
    id: 'Home.Attendance.Edit.InvalidTimeError',
    defaultMessage: 'Start should be before end',
  },
  'Home.Attendance.Edit.InvalidStartTimeError': {
    id: 'Home.Attendance.Edit.InvalidStartTimeError',
    defaultMessage: 'Start cannot be after your break starts',
  },
  'Home.Attendance.Edit.InvalidEndTimeError': {
    id: 'Home.Attendance.Edit.InvalidEndTimeError',
    defaultMessage: 'End cannot be before your break ends',
  },
  'Home.Attendance.Edit.Comment.Description': {
    id: 'Home.Attendance.Edit.Comment.Description',
    defaultMessage: 'Add the optional comment for attendance update',
  },
  'Home.Attendance.HistoricView.Progress': {
    id: 'Home.Attendance.HistoricView.Progress',
    defaultMessage: 'in progress',
  },
  'Home.Attendance.GotoDetails': {
    id: 'Home.Attendance.GotoDetails',
    defaultMessage: 'More tracked times...',
  },
  'Home.Attendance.HistoricView.SystemEnd': {
    id: 'Home.Attendance.HistoricView.SystemEnd',
    defaultMessage: 'Attendance automatically ended at end of day',
  },
  'Home.Attendance.Approved': {
    id: 'Home.Attendance.Approved',
    defaultMessage: 'Approved',
  },
  'Home.Attendance.Rejected': {
    id: 'Home.Attendance.Rejected',
    defaultMessage: 'Rejected',
  },
});

export default {
  ...dictionary,
  ...bookingMessages,
  ...structureMessages,
};
