import React, { useContext, useEffect, useState } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { isEmpty, pathOr, mapObjIndexed, compose, replace } from 'ramda';
import * as Localization from 'expo-localization';
import { useRecoilValue } from 'recoil';

import { useErrorQuery } from '@providers/Errors';
import {
  Department,
  MyBuilding,
  Organization,
} from '@views/Home/Profile/Edit/interfaces';
import { loggedInAtom } from '@views/Login';
import { defaultLang } from '@i18n/context';
import { supportedLanguages } from '@i18n/translations/prod';

interface MyUserProps {
  children: React.ReactElement;
}

export interface Permissions {
  [key: string]: boolean;
}
interface MyUserContext {
  hasGoogleAccount: boolean;
  hasMicrosoftAccount: boolean;
  loading: boolean;
  loaded: boolean;
  department: Department;
  favouriteDesks: string[];
  favouriteSeats: string[];
  favouriteMeetingRooms: string[];
  favouriteColleagues: string[];
  refetchFavourites: () => any;
  error: any;
  language: 'de' | 'en' | 'fr' | 'it' | string;
  id: string;
  avatar?: string;
  name: string;
  initials: string;
  email: string;
  phoneNumber: string;
  licensePlate: string;
  getGoogleAuthState: () => any;
  getMicrosoftAuthState: () => any;
  refetch: () => any;
  myBuilding: MyBuilding;
  organizations: Organization[] | [];
  showActionsPage: boolean;
  isMultiBooker: boolean;
  permissions: Permissions;
  bookingRange: number;
}

interface FavData {
  me: {
    userprofile: {
      favouriteDesks: string[];
      favouriteSeats: string[];
      favouriteMeetingRooms: string[];
      favouriteColleagues: string[];
    };
  };
}

interface Data {
  me: {
    hasGoogleAccount: boolean;
    hasMicrosoftAccount: boolean;
    defaultBuilding: MyBuilding;
    department: Department;
    favouriteDesks: string[];
    favouriteSeats: string[];
    favouriteMeetingRooms: string[];
    favouriteColleagues: string[];
    language: 'de' | 'en' | 'fr' | 'it' | string;
    id: string;
    avatar?: string;
    name: string;
    initials: string;
    email: string;
    phoneNumber: string;
    licensePlate: string;
    rolePermissions: [string?];
  };
}

export const DEFAULT_BOOKING_RANGE = 90;

const googleGetAuthState = gql`
  query getGoogleAuthState {
    me {
      hasGoogleAccount
    }
  }
`;

const microsoftGetAuthState = gql`
  query getMicrosoftAuthState {
    me {
      hasMicrosoftAccount
    }
  }
`;

const getFavs = gql`
  query getFavs {
    me {
      id
      userprofile {
        favouriteDesks
        favouriteSeats
        favouriteMeetingRooms
        favouriteColleagues
      }
    }
  }
`;

const getMeQuery = gql`
  query getMe {
    me {
      id
      name
      email
      initials
      hasMicrosoftAccount
      hasGoogleAccount
      userprofile {
        language
        avatar
        phoneNumber
        favouriteDesks
        favouriteSeats
        favouriteMeetingRooms
        favouriteColleagues
        defaultBuilding {
          id
          name
        }
        department {
          id
          name
          bookingRange
        }
        organization {
          id
          name
        }
        licensePlate
      }
      organizationChoices {
        id
        name
      }
      isMultiBooker
      rolePermissions
    }
  }
`;

const DEFAULT_CONTEXT_STATE = {
  id: '',
  avatar: undefined,
  name: '',
  initials: '',
  email: '',
  phoneNumber: '',
  licensePlate: '',
  department: {
    id: 'none',
    name: '',
  },
  language: defaultLang,
  hasGoogleAccount: false,
  hasMicrosoftAccount: false,
  favouriteDesks: [],
  favouriteSeats: [],
  favouriteMeetingRooms: [],
  favouriteColleagues: [],
  refetch: () => null,
  refetchFavourites: () => null,
  getGoogleAuthState: () => null,
  getMicrosoftAuthState: () => null,
  loading: false,
  loaded: false,
  error: {},
  myBuilding: {
    id: null,
    name: '',
  },
  organizations: [],
  showActionsPage: false,
  isMultiBooker: false,
  permissions: {},
  bookingRange: -1,
};

export const MyUserContext = React.createContext<MyUserContext>(
  DEFAULT_CONTEXT_STATE,
);

export const useMyUser = () => useContext(MyUserContext);

const mapRolePermissions = {
  canUseBooker: 'core.view_booker',
  canBookDesk: 'core.finder_booker_resources_desk',
  canBookParkingSpot: 'core.finder_booker_resources_parkingspot',
  canBookFreeSpace: 'core.finder_booker_resources_freespace',
  canBookMeetingRoom: 'core.finder_booker_resources_meetingroom',
  canBookCatering: 'core.finder_booker_resources_meetingroom_catering',
  canBookEquipment: 'core.finder_booker_resources_meetingroom_equipment',
  canBookPhoneBox: 'core.finder_booker_resources_phonebox',
  canBookHomeOffice: 'core.finder_booker_resources_homeoffice',
  canBookTravelDay: 'core.finder_booker_resources_travelday',
  canBookVacation: 'core.finder_booker_resources_vacation',
  canBookSickDay: 'core.finder_booker_resources_sickday',
  canBookLocker: 'core.finder_booker_resources_locker',
  canSeeColleagues: 'core.finder_booker_screen_colleagues',
  canSeeColleaguesWhoisin: 'core.finder_booker_screen_colleagues_whoisin',
  canSeeProfileCalendar: 'core.finder_booker_screen_profile_calendar',
};

const getFinderPermissions = ({
  me: { rolePermissions = [] },
}: Data): Permissions =>
  mapObjIndexed(v => rolePermissions.includes(v), mapRolePermissions);

export function MyUserProvider({ children }: MyUserProps) {
  const loggedIn = useRecoilValue(loggedInAtom);

  const [id, setId] = useState(DEFAULT_CONTEXT_STATE.id);
  const [avatar, setAvatar] = useState(DEFAULT_CONTEXT_STATE.avatar);
  const [name, setName] = useState(DEFAULT_CONTEXT_STATE.name);
  const [initials, setInitials] = useState(DEFAULT_CONTEXT_STATE.initials);
  const [email, setEmail] = useState(DEFAULT_CONTEXT_STATE.email);
  const [language, setLanguage] = useState(DEFAULT_CONTEXT_STATE.language);

  const [phoneNumber, setPhoneNumber] = useState(
    DEFAULT_CONTEXT_STATE.phoneNumber,
  );
  const [licensePlate, setLicensePlate] = useState(
    DEFAULT_CONTEXT_STATE.licensePlate,
  );
  const [department, setDepartment] = useState<Department>(
    DEFAULT_CONTEXT_STATE.department,
  );

  const [hasGoogleAccount, setHasGoogleAccount] = useState<boolean>(
    DEFAULT_CONTEXT_STATE.hasGoogleAccount,
  );
  const [hasMicrosoftAccount, setHasMicrosoftAccount] = useState<boolean>(
    DEFAULT_CONTEXT_STATE.hasMicrosoftAccount,
  );
  const [favouriteDesks, setFavouriteDesks] = useState(
    DEFAULT_CONTEXT_STATE.favouriteDesks,
  );
  const [favouriteSeats, setFavouriteSeats] = useState(
    DEFAULT_CONTEXT_STATE.favouriteSeats,
  );
  const [favouriteMeetingRooms, setFavouriteMeetingRooms] = useState(
    DEFAULT_CONTEXT_STATE.favouriteMeetingRooms,
  );
  const [favouriteColleagues, setFavouriteColleagues] = useState(
    DEFAULT_CONTEXT_STATE.favouriteColleagues,
  );

  const [myBuilding, setMyBuilding] = useState(
    DEFAULT_CONTEXT_STATE.myBuilding,
  );

  const [organizations, setOrganizations] = useState(
    DEFAULT_CONTEXT_STATE.organizations,
  );

  const [showActionsPage, setShowActionsPage] = useState(
    DEFAULT_CONTEXT_STATE.showActionsPage,
  );
  const [isMultiBooker, setIsMultiBooker] = useState(
    DEFAULT_CONTEXT_STATE.isMultiBooker,
  );
  const [permissions, setPermissions] = useState<Permissions>(
    DEFAULT_CONTEXT_STATE.permissions,
  );
  const [bookingRange, setBookingRange] = useState<number>(
    DEFAULT_CONTEXT_STATE.bookingRange,
  );

  const { data, loading, error, refetch } = useErrorQuery<Data>(getMeQuery, {
    skip: !loggedIn,
    finderError: {
      type: 'fatal',
      message: 'Common.Errors.Fetch.User',
    },
  });

  const [getFavourites, { data: dataFavourites }] = useLazyQuery<FavData>(
    getFavs,
    { fetchPolicy: 'no-cache' },
  );

  const [
    getGoogleAuthState,
    { data: dataGoogleAuthState },
  ] = useLazyQuery(googleGetAuthState, { fetchPolicy: 'no-cache' });

  const [
    getMicrosoftAuthState,
    { data: dataMicrosoftAuthState },
  ] = useLazyQuery(microsoftGetAuthState, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (dataFavourites) {
      const myFavouriteDesks = pathOr(
        [],
        ['me', 'userprofile', 'favouriteDesks'],
        dataFavourites,
      );
      const myFavouriteSeats = pathOr(
        [],
        ['me', 'userprofile', 'favouriteSeats'],
        dataFavourites,
      );
      const myFavouriteMeetingRooms = pathOr(
        [],
        ['me', 'userprofile', 'favouriteMeetingRooms'],
        dataFavourites,
      );

      const myFavouriteColleagues = pathOr(
        [],
        ['me', 'userprofile', 'favouriteColleagues'],
        dataFavourites,
      );

      setFavouriteDesks(myFavouriteDesks);
      setFavouriteSeats(myFavouriteSeats);
      setFavouriteMeetingRooms(myFavouriteMeetingRooms);
      setFavouriteColleagues(myFavouriteColleagues);
    }
  }, [dataFavourites]);

  useEffect(() => {
    if (dataGoogleAuthState) {
      const myHasGoogleAccount = pathOr(
        false,
        ['me', 'hasGoogleAccount'],
        dataGoogleAuthState,
      );
      setHasGoogleAccount(myHasGoogleAccount);
    }
  }, [dataGoogleAuthState]);

  useEffect(() => {
    if (dataMicrosoftAuthState) {
      const myHasMicrosoftAccount = pathOr(
        false,
        ['me', 'hasMicrosoftAccount'],
        dataMicrosoftAuthState,
      );

      setHasMicrosoftAccount(myHasMicrosoftAccount);
    }
  }, [dataMicrosoftAuthState]);

  useEffect(() => {
    if (data) {
      const deviceLanguage = Localization.locale.split('-')[0];
      const defaultLanguage = supportedLanguages.includes(deviceLanguage)
        ? deviceLanguage
        : defaultLang;

      const myId = pathOr('', ['me', 'id'], data);
      const myLanguage = pathOr(
        defaultLanguage,
        ['me', 'userprofile', 'language'],
        data,
      );

      const myAvatar = pathOr(undefined, ['me', 'userprofile', 'avatar'], data);
      const myPhoneNumber = pathOr(
        '',
        ['me', 'userprofile', 'phoneNumber'],
        data,
      );
      const myLicensePlate = pathOr(
        '',
        ['me', 'userprofile', 'licensePlate'],
        data,
      );
      const myName = pathOr('', ['me', 'name'], data);
      const myInitials = pathOr('', ['me', 'initials'], data);
      const myEmail = pathOr('', ['me', 'email'], data);

      const myHasGoogleAccount = pathOr(
        false,
        ['me', 'hasGoogleAccount'],
        data,
      );
      const myHasMicrosoftAccount = pathOr(
        false,
        ['me', 'hasMicrosoftAccount'],
        data,
      );
      const myDepartment = pathOr(
        DEFAULT_CONTEXT_STATE.department,
        ['me', 'userprofile', 'department'],
        data,
      );
      const myFavouriteDesks = pathOr(
        [],
        ['me', 'userprofile', 'favouriteDesks'],
        data,
      );
      const myFavouriteSeats = pathOr(
        [],
        ['me', 'userprofile', 'favouriteSeats'],
        data,
      );
      const myFavouriteMeetingRooms = pathOr(
        [],
        ['me', 'userprofile', 'favouriteMeetingRooms'],
        data,
      );

      const myFavouriteColleagues = pathOr(
        [],
        ['me', 'userprofile', 'favouriteColleagues'],
        data,
      );

      const defaultBuilding = pathOr(
        DEFAULT_CONTEXT_STATE.myBuilding,
        ['me', 'userprofile', 'defaultBuilding'],
        data,
      );

      const organizations = pathOr(
        DEFAULT_CONTEXT_STATE.organizations,
        ['me', 'organizationChoices'],
        data,
      );

      const hasMultiBookerPermission = pathOr(
        DEFAULT_CONTEXT_STATE.isMultiBooker,
        ['me', 'isMultiBooker'],
        data,
      );

      const myPermissions = getFinderPermissions(data);
      const showActionsPage = !isEmpty(organizations);

      const myBookingRange = +compose(
        replace('RANGE_', ''),
        pathOr('RANGE_-1', ['me', 'userprofile', 'department', 'bookingRange']),
      )(data);

      setId(myId);
      setAvatar(myAvatar);
      setLanguage(myLanguage);
      setName(myName);
      setEmail(myEmail);
      setPhoneNumber(myPhoneNumber);
      setLicensePlate(myLicensePlate);
      setInitials(myInitials);
      setHasGoogleAccount(myHasGoogleAccount);
      setHasMicrosoftAccount(myHasMicrosoftAccount);
      setDepartment(myDepartment);
      setFavouriteDesks(myFavouriteDesks);
      setFavouriteSeats(myFavouriteSeats);
      setFavouriteMeetingRooms(myFavouriteMeetingRooms);
      setFavouriteColleagues(myFavouriteColleagues);
      setMyBuilding(defaultBuilding);
      setOrganizations(organizations);
      setShowActionsPage(showActionsPage);
      setIsMultiBooker(hasMultiBookerPermission);

      setPermissions(myPermissions);
      setBookingRange(
        myBookingRange === 0 ? DEFAULT_BOOKING_RANGE : myBookingRange,
      );
    }
  }, [data]);

  return (
    <MyUserContext.Provider
      value={{
        id,
        avatar,
        name,
        initials,
        email,
        phoneNumber,
        licensePlate,
        department,
        language,
        hasGoogleAccount,
        hasMicrosoftAccount,
        favouriteDesks,
        favouriteSeats,
        favouriteMeetingRooms,
        favouriteColleagues,
        loading,
        loaded: !isEmpty(id),
        error,
        getGoogleAuthState,
        getMicrosoftAuthState,
        refetchFavourites: getFavourites,
        refetch,
        myBuilding,
        organizations,
        showActionsPage,
        isMultiBooker,
        permissions,
        bookingRange,
      }}
    >
      {children}
    </MyUserContext.Provider>
  );
}
