import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { SecondaryButton } from '@views/shared/Button';
import { CustomIcon } from '@views/shared/CustomIcon';

import { globalStyles, globalStylesDesktop } from '@views/shared/styles';
import { styles } from '@views/Book/Book/MeetingRoom/AdditionalOrder/styles';

import {
  ItemIconProps,
  ItemTitleProps,
  OrderProps,
} from '@views/Book/Book/MeetingRoom/AdditionalOrder/types';

import useCancelOrder from './hooks';

const translationMap = {
  catering: {
    error: 'Home.Book.Catering.Order.Cancel.Error',
    notice: 'Home.Book.Catering.Order.Notice',
    success: 'Home.Book.Catering.Order.Cancel.Successful',
    title: 'Home.Book.Catering.Order.Title',
  },
  equipping: {
    error: 'Home.Book.Equipping.Order.Cancel.Error',
    notice: 'Home.Book.Equipping.Order.Notice',
    success: 'Home.Book.Equipping.Order.Cancel.Successful',
    title: 'Home.Book.Equipping.Order.Title',
  },
};
const iconMap = {
  catering: {
    size: 21,
    name: 'coffee',
    style: {},
  },
  equipping: {
    name: 'equipment',
    size: 23,
    style: { marginTop: -2 },
  },
};

export const ItemIcon = ({ onPress, style, type }: ItemIconProps) => (
  <CustomIcon
    style={[styles.titleIcon, style, iconMap[type].style]}
    name={iconMap[type].name}
    onPress={onPress}
    size={iconMap[type].size}
  />
);

export const ItemTitle = ({ style, type }: ItemTitleProps) => {
  const { t } = useIntl();

  return (
    <View style={[styles.titleContainer, style]}>
      <ItemIcon type={type} style={styles.titleIconSmall} />
      <Text style={[globalStyles.title]}>{t(translationMap[type].title)}</Text>
    </View>
  );
};

export const Ordered = ({
  data,
  isExpired = false,
  type,
  isBookingsOverview = false,
  isDesktop = false,
}: OrderProps) => (
  <View
    style={[
      styles.orderedItems,
      isBookingsOverview && styles.orderedItemsBookingsOverview,
    ]}
  >
    <Order
      data={data}
      type={type}
      isExpired={isExpired}
      isBookingsOverview={isBookingsOverview}
      isDesktop={isDesktop}
    />
  </View>
);

export default function Order({
  data,
  isExpired = false,
  showNotice = false,
  type,
  isBookingsOverview = false,
  isDesktop = false,
}: OrderProps) {
  const { t } = useIntl();
  const { id, items } = data;
  const { cancelOrder, isCanceled, isError, loading } = useCancelOrder(type);

  return (
    <View style={isBookingsOverview && styles.bookingsOverview}>
      {showNotice ? (
        <Text style={styles.description}>{t(translationMap[type].notice)}</Text>
      ) : (
        <ItemTitle style={styles.titleOrderContainer} type={type} />
      )}
      <View
        style={
          isDesktop
            ? { flexDirection: 'column', alignItems: 'flex-start' }
            : styles.orderContainer
        }
      >
        <View style={styles.orderItems}>
          {items.map(({ item, quantity }, index) => (
            <Text key={index} style={styles.orderItem}>
              {quantity} x {item.title}
            </Text>
          ))}
        </View>
        {!isExpired ? (
          <SecondaryButton
            buttonStyle={[
              globalStyles.cancelButton,
              isDesktop && globalStylesDesktop.cancelButton,
            ]}
            disabled={loading || isError || isCanceled}
            titleStyle={globalStyles.cancelButtonText}
            title={t('Home.ActiveBookings.Booking.cancel')}
            onPress={() => cancelOrder(id)}
          />
        ) : null}
      </View>
      {isError ? (
        <Text style={[styles.error, styles.errorOrder]}>
          {t(translationMap[type].error)}
        </Text>
      ) : null}
      {isCanceled ? (
        <Text style={[styles.error, styles.errorOrder, styles.success]}>
          {t(translationMap[type].success)}
        </Text>
      ) : null}
    </View>
  );
}
