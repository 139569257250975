import { useCallback } from 'react';
import { useIntl as useIntlHook } from 'react-intl';

import calendarMessages from './Calendar';
import colleaguesMessages from './Colleagues';
import commonMessages from './common';
import homeMessages from './Home';
import favouritesMessages from './Favourites';
import floorplanMessages from './Floorplan';
import profileMessages from './Profile';
import routesMessages from './Routes';
import timeSlotsMessages from './TimeSlots';
import availabilityInfosMessages from './AvailabilityInfos';
import filterMessages from './Filter';

const messages = {
  ...calendarMessages,
  ...colleaguesMessages,
  ...commonMessages,
  ...homeMessages,
  ...favouritesMessages,
  ...floorplanMessages,
  ...profileMessages,
  ...routesMessages,
  ...timeSlotsMessages,
  ...availabilityInfosMessages,
  ...filterMessages,
};

export interface Formats {
  dateFormat: string;
  dayFormat: string;
  dayShortFormat: string;
  dayFullFormat: string;
  timeFormat: string;
  timeFormatShort: string;
}

export type Translator = (key: string, vars?: any) => any;

export interface Intl {
  locale: string;
  formats: Formats;
  t: Translator;
}

export function useIntl() {
  const { formatMessage, formats, locale } = useIntlHook();

  const t = useCallback(
    (key, vars = {}) => {
      const message = messages[key];
      return message ? formatMessage(message, vars) : key;
    },
    [formatMessage],
  );

  return <Intl>{
    /**
     * Function returns the translation of the specified key based on the current locale settings
     * @param {string} key key that represents the id of the translation
     * @param {object} vars template variables for translations vars
     * @returns {string} string with the translated key
     */
    t,
    /**
     * Object contains the custom formats defined into the i18n provider ( CustomFormat and Formats are not compatible for ts )
     */
    // @ts-ignore
    formats,
    locale,
  };
}
