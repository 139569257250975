import React from 'react';
import { View, Text } from 'react-native';
import { compose, keys, pickBy } from 'ramda';

import { useIntl } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';
import { BookingIcon } from '@views/shared/BookingIcon';
import { getBookingIcon } from '@views/shared/consts';
import { colors } from '@views/shared/styles';
import { styles } from '@views/Book/Book/NextDaysBooking/FullDayBooking/WeekDays/Slot';

const getAvailableTypes = types =>
  compose(
    keys,
    pickBy(val => val),
  )(types);

export default function DaySlot({ date, disabledToBook, goToPage, types }) {
  const {
    formats: { dayFullFormat },
  } = useIntl() as any;
  return (
    <View style={styles.container}>
      <Text style={styles.date}>
        {universalDateFormatter({ date, format: dayFullFormat })}
      </Text>
      <View style={styles.icons}>
        {getAvailableTypes(types).map((type, index) => (
          <View key={index} style={index > 0 && styles.gap}>
            <BookingIcon
              name={getBookingIcon(type)}
              onPress={disabledToBook ? undefined : () => goToPage(date, type)}
              style={disabledToBook ? { color: colors.blackAlpha2 } : {}}
            />
          </View>
        ))}
      </View>
    </View>
  );
}
