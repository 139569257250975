import React, { useCallback } from 'react';
import { isNil, isEmpty } from 'ramda';
import { Dimensions, View, Text } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { topBarNavigatorStyles } from '@views/shared/styles';
import { Loader } from '@views/shared/Loader';
import { withLizTitle } from '@views/shared/utils';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';

import { useFilterSelect } from '@views/Book/Filter/select';
import { useFilterIcon } from '@views/Book/Filter/icon';

import NoFloors from './NoFloors';
import ZoomImage from './ZoomImage';
import BuildingSwitcher from './buildings';
import FloorSwitcher from './floors';
import { useFloorPlan } from './hooks';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
  tabStyle: topBarNavigatorStyles.tabStyle,
  scrollEnabled: true,
};

export default function FloorPlan() {
  const {
    error,
    loading,
    floors,
    currentFloor,
    building,
    date,
  } = useFloorPlan();
  const isDesktop = useIsDesktop();

  const { showFilterSelect } = useFilterSelect();
  const { showFilterIcon } = useFilterIcon();

  useFocusEffect(
    useCallback(() => {
      showFilterIcon(false);
      showFilterSelect();

      return () => showFilterSelect(false);
    }, [showFilterIcon, showFilterSelect]),
  );

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  if (loading) {
    return <Loader />;
  }

  if (isNil(floors) || isEmpty(floors)) {
    return <NoFloors />;
  }

  return (
    <View style={topBarNavigatorStyles.container}>
      <BuildingSwitcher />
      <Tab.Navigator
        initialRouteName={currentFloor}
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        swipeEnabled={false}
        initialLayout={{ width: Dimensions.get('window').width }}
        // @ts-ignore
        screenOptions={{ unmountOnBlur: true }}
      >
        {floors.map(floor => {
          const { id, label } = floor;
          return (
            <Tab.Screen
              key={id}
              name={id}
              options={() => {
                return {
                  // eslint-disable-next-line
                  tabBarLabel: () => (
                    <Text
                      style={[
                        topBarNavigatorStyles.tabLabel,
                        currentFloor === id &&
                          topBarNavigatorStyles.tabLabelActive,
                      ]}
                    >
                      {label}
                    </Text>
                  ),
                  title: withLizTitle(label),
                };
              }}
            >
              {({ navigation, route: { name } }) =>
                navigation.isFocused() ? (
                  <ZoomImage
                    key={name}
                    floor={floor}
                    building={building}
                    date={date}
                  />
                ) : null
              }
            </Tab.Screen>
          );
        })}
      </Tab.Navigator>
      {isDesktop ? <FloorSwitcher floors={floors} current={currentFloor} /> : null}
    </View>
  );
}
