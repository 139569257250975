import React from 'react';
import { View } from 'react-native';
import { isNil } from 'ramda';

import { useIntl } from '@utils/intl';

import { Loader } from '@views/shared/Loader';
import { Equipment } from '@views/shared/Equipment';

import BasicBook from '@views/Book/Book/BasicBook';

import Booking from './Booking';
import { useValues } from './hooks';

export default function BookDesk() {
  const { t, formats } = useIntl();

  const errorMessage = t('Home.ActiveBooking.Verify.FetchingFailed');

  const {
    areaId,
    currentDate,
    data,
    deskId,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    error,
    loading,
    time,
    type,
  } = useValues(formats, errorMessage);

  if (loading) {
    return <Loader />;
  }

  if (error || isNil(data)) {
    return <View>{errorMessage}</View>;
  }

  const { desk } = data;

  function DeskPanel({ showAvailability }: { showAvailability? }) {
    return (
      <View>
        <Booking
          type={type}
          areaId={areaId}
          deskId={deskId}
          date={currentDate}
          enabledHalfDayBooking={enabledHalfDayBooking}
          enabledHourlyBooking={enabledHourlyBooking}
          hasNextDaysBooking={hasNextDaysBooking}
          showAvailability={showAvailability}
        />
        {desk?.equipment?.length ? (
          <Equipment equipment={desk.equipment} />
        ) : null}
      </View>
    );
  }

  return (
    <BasicBook
      booking={data}
      date={date}
      time={time}
      type={type}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
    >
      <DeskPanel />
    </BasicBook>
  );
}
