import React, { CSSProperties, useMemo } from 'react';
import { TextInput, TextInputProps } from 'react-native';
import { FieldProps } from 'formik';
import { Input as BaseInput } from 'react-native-elements';
import { globalStyles } from '@views/shared/styles';

interface SinglelineProps extends FieldProps {
  label: string;
  password?: boolean;
  multiline?: false;
  errorStyle?: CSSProperties;
}

interface MultilineProps extends FieldProps {
  label: string;
  password?: boolean;
  multiline: true;
  numberOfLines: number;
  errorStyle?: CSSProperties;
}

type Props = SinglelineProps | MultilineProps;

function createMultilineInput(numberOfLines: number) {
  return function MultilineInput(props: TextInputProps) {
    return (
      <TextInput
        {...props}
        multiline
        numberOfLines={numberOfLines}
        blurOnSubmit={false}
      />
    );
  };
}

export function Input({ field, label, form, password, ...rest }: Props) {
  const name = field.name;
  const MultilineInput = useMemo(
    () => createMultilineInput((rest.multiline && rest.numberOfLines) || 4),
    [rest.multiline && rest.numberOfLines],
  );
  const InputComponent = rest.multiline ? MultilineInput : TextInput;

  return (
    <BaseInput
      containerStyle={{ paddingHorizontal: 0 }}
      label={label}
      labelStyle={[globalStyles.label]}
      value={field.value}
      onChangeText={form.handleChange(name)}
      onBlur={form.handleBlur(name)}
      style={[
        globalStyles.input,
        { marginBottom: 0, maxHeight: 100 },
        rest.errorStyle,
      ]}
      secureTextEntry={password}
      InputComponent={InputComponent}
      inputContainerStyle={{ borderBottomWidth: 0 }}
    />
  );
}
