import { useCallback } from 'react';
import { Linking, Platform } from 'react-native';
import { gql, useApolloClient } from '@apollo/client';

import { useIntl } from '@utils/intl';
import { nativeToggle } from '@views/Home/Profile/Overview/CalendarAuth';
import { useMyUser } from '@providers/User';

const getAuthUrl = gql`
  query {
    microsoftAuthorizationUrl
  }
`;

const revokeMutation = gql`
  mutation {
    revokeMicrosoftAuthorization
  }
`;

export function useMicrosoft() {
  const { t } = useIntl();
  const { hasMicrosoftAccount, getMicrosoftAuthState } = useMyUser();
  const client = useApolloClient();
  const checkedIn = hasMicrosoftAccount;
  const toggle = useCallback(async () => {
    if (checkedIn) {
      await client.mutate({ mutation: revokeMutation });

      return getMicrosoftAuthState();
    }

    const { data } = await client.query<{ microsoftAuthorizationUrl: string }>({
      query: getAuthUrl,
    });

    if (!data) {
      return;
    }

    await Linking.openURL(data.microsoftAuthorizationUrl);
  }, [checkedIn, client, getMicrosoftAuthState]);

  const handleNative = () => nativeToggle(t);

  return {
    toggle: Platform.OS === 'web' ? toggle : handleNative,
    checkedIn,
  };
}
