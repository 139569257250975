import { useCallback } from 'react';

import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';
import {
  getNextDayFromThis,
  getTodayAsDayJs,
  isAfter,
} from '@utils/DateAndTime';

import { useSetCurrentDate } from '@views/Calendar/hooks';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { useGoTo } from '@views/shared/utils/useGoTo';
import { BOOK_CATEGORY_ROOM } from '@views/shared/consts';

export function useBookable(date: Date) {
  const {
    permissions: { canBookHomeOffice, canBookTravelDay, canBookDesk },
  } = useMyUser();

  const homeOfficeEnabled = useHasFeature('home_office');
  const travelDayEnabled = useHasFeature('travel_day');

  const setCurrentDate = useSetCurrentDate();

  const { goToBookingPage, goToStructurePage } = useGoTo();

  const { bookingRange } = useMyOrganization();
  const maxAvailableDate = getNextDayFromThis(getTodayAsDayJs(), bookingRange);
  const isDisabledToBook = bookingRange > 0 && isAfter(date, maxAvailableDate);

  const goToPage = useCallback(
    type => {
      setCurrentDate(date);
      type === BOOK_CATEGORY_ROOM
        ? goToStructurePage(type)
        : goToBookingPage({ type });
    },
    [date, goToBookingPage, goToStructurePage, setCurrentDate],
  );
  const isHomeOfficeEnabled = homeOfficeEnabled && canBookHomeOffice;
  const isTravelDayEnabled = travelDayEnabled && canBookTravelDay;

  return {
    goToPage,
    isHomeOfficeEnabled,
    isDisabledToBook,
    isTravelDayEnabled,
    isDeskEnabled: canBookDesk,
    noBookable: !isHomeOfficeEnabled && !isTravelDayEnabled && !canBookDesk,
  };
}
