import { useCallback, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';

import { useMyOrganization } from '@providers/Organization';
import {
  getWorkingDaysOfWeek,
  universalDateFormatter,
  dateFormatShort,
  getPreviousDays,
} from '@utils/DateAndTime';
import { assignAttendanceToDays } from '@views/Bookings/MyBookings/core.hooks';
import { useCurrentDate } from '@views/Calendar/hooks';

const getUserWeeklyAttendances = gql`
  query userWeeklyAttendances($startDate: Date, $endDate: Date) {
    userAttendances(startDate: $startDate, endDate: $endDate) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
      totalWorkMinutes
      presenceStatus
      attendanceBreaks {
        id
        attendanceId
        status
        startTime
        endTime
        totalTimeMinutes
        date
      }
      comment
    }
    userAttendanceBreaks(startDate: $startDate, endDate: $endDate) {
      id
      attendanceId
      status
      startTime
      endTime
      totalTimeMinutes
      date
    }
  }
`;

export function useWeeklyAttendance(currentWeekDays) {
  const navigation = useNavigation<any>();

  const [isOpen, setIsOpen] = useState(false);
  const [attendanceItem, setAttendanceItem] = useState({});
  const [mode, setMode] = useState({});
  const { workingDaysStart, workingDaysEnd } = useMyOrganization();
  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );
  const currentDate = useCurrentDate();

  const weekDays = currentWeekDays
    ? currentWeekDays
    : getPreviousDays({
        start: currentDate,
        enabledWorkingDays,
      });

  const end = currentWeekDays ? weekDays[weekDays.length - 1] : weekDays[0];
  const start = currentWeekDays ? weekDays[0] : weekDays[weekDays.length - 1];

  const { data, loading, error, refetch } = useQuery(getUserWeeklyAttendances, {
    variables: {
      startDate: universalDateFormatter({
        date: start,
        format: dateFormatShort,
      }),
      endDate: universalDateFormatter({ date: end, format: dateFormatShort }),
    },
    fetchPolicy: 'no-cache',
  });

  const onEdit = useCallback(
    (item, mode) => {
      setIsOpen(prevState => !prevState);
      setAttendanceItem(item);
      setMode(mode);
    },
    [setIsOpen, setAttendanceItem],
  );

  const gotoWeeklyAttendance = useCallback(
    () =>
      navigation.navigate('bookings', {
        screen: 'attendances',
      }),
    [navigation],
  );

  return {
    weekDays,
    userAttendance: data?.userAttendances || [],
    userAttendanceBreaks: data?.userAttendanceBreaks || [],
    mappedUserAttendances: assignAttendanceToDays(data?.userAttendances || []),
    loading,
    error,
    isOpen,
    attendanceItem,
    mode,
    onEdit,
    refetch,
    gotoWeeklyAttendance,
  };
}
