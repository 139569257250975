import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';

import { useMyUser } from '@providers/User';

const updateUserMutation = gql`
  mutation updateUser($organizationId: ID) {
    updateMe(
      appName: FINDER
      user: { userprofile: { organizationId: $organizationId } }
    ) {
      name
      userprofile {
        organization {
          id
          name
        }
      }
    }
  }
`;

export function useValues() {
  const { organizations, refetch } = useMyUser();

  const [selected, setSelected] = useState<string>();
  const [isError, setIsError] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);

  const onSelect = useCallback((id) => setSelected(id), [setSelected]);

  const [updateUser, { loading: isLoading }] = useMutation(updateUserMutation, {
    onCompleted: () => {
      setIsSaved(true);
      setTimeout(refetch, 2000);
    },
    onError: () => setIsError(true),
  });

  const onSubmit = useCallback(
    (organizationId) => updateUser({ variables: { organizationId } }),
    [updateUser],
  );

  return {
    isError,
    isLoading,
    isSaved,
    onSelect,
    onSubmit,
    organizations,
    selected,
  };
}
