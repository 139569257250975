import React from 'react';
import { View, Text } from 'react-native';

import { spacings, globalStyles } from '@views/shared/styles';

import { useIntl } from '@utils/intl';
import { Google } from '@views/Home/Profile/Overview/CalendarAuth/Google';
import { Microsoft } from '@views/Home/Profile/Overview/CalendarAuth/Microsoft';
export { nativeToggle } from '@views/Home/Profile/Overview/CalendarAuth/utils';

import { Accordion } from '@views/shared/Accordion';

import { styles } from '@views/Home/Profile/Overview/Toggles';

const { small } = spacings;

export default function CalendarAuth() {
  const { t } = useIntl();

  const title = (
    <Text style={globalStyles.title}>
      {t('Profile.Content.Calendar.Integrations')}
    </Text>
  );

  return (
    <View style={[globalStyles.shadow, styles.wrapper]}>
      <Accordion open={true} headerStyle={styles.row} title={title}>
        <View style={[styles.toggleWrapper, styles.container]}>
          <View style={[styles.content, { marginRight: small }]}>
            <Google />
          </View>
          <View style={[styles.content, { marginLeft: small }]}>
            <Microsoft />
          </View>
        </View>
      </Accordion>
    </View>
  );
}
