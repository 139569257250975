import { useEffect, useState } from 'react';
import { Platform } from 'react-native';

export function useIsDesktop() {
  const isWeb = Platform.OS === 'web';
  const [width, setWidth] = useState(window.innerWidth);
  const isDesktop = isWeb && width > 1023;

  useEffect(() => {
    const listener = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  return isDesktop;
}
