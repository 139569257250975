import React, { useCallback, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { isEmpty, isNil } from 'ramda';

import { useMyOrganization } from '@providers/Organization';
import { useIntl } from '@utils/intl';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { SecondaryButton } from '@views/shared/Button';
import { BookingIcon } from '@views/shared/BookingIcon';
import Location from '@views/shared/hooks/getLocation';
import { getBookingIcon } from '@views/shared/consts';
import {
  Ordered as AdditionalOrder,
  ItemIcon as AdditionalOrderIcon,
} from '@views/Book/Book/MeetingRoom/AdditionalOrder/Order';

import { spacings, globalStyles } from '@views/shared/styles';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { styles as bStyles } from '@views/Bookings/MyBookings/Day/Ticket/Booking';
import { useBooking } from '@views/Bookings/MyBookings/Day/Ticket/Booking/hooks';

interface Props {
  booking: BookingType;
  onCancel: any;
}

const styles = StyleSheet.create({
  actionButtonContainer: {
    flexDirection: 'row',
    marginTop: spacings.verySmall,
    marginBottom: spacings.verySmall,
    justifyContent: 'flex-end',
    marginRight: spacings.verySmall,
  },
});

export default function BookingForDesktop({ booking, onCancel }: Props) {
  const [showCatering, setShowCatering] = useState(false);
  const [showEquipping, setShowEquipping] = useState(false);

  const toggleCatering = useCallback(
    () => setShowCatering(prevState => !prevState),
    [setShowCatering],
  );

  const toggleEquipping = useCallback(
    () => setShowEquipping(prevState => !prevState),
    [setShowEquipping],
  );

  const {
    t,
    formats: { timeFormatShort },
  } = useIntl() as any;

  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  const { cancel, end, isCancelable, isExpired, start, type } = useBooking(
    booking,
    onCancel,
  );

  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });

  const { catering, equipping, comment } = booking;

  const hasCatering = !isNil(catering) && !isEmpty(catering);
  const hasEquipping = !isNil(equipping) && !isEmpty(equipping);

  const showActionContainer =
    hasCatering || hasEquipping || (isCancelable && !isExpired);

  return (
    <View style={[bStyles.bookingItemDesktop, globalStyles.shadow]}>
      <View style={[bStyles.row, { borderLeftWidth: 0 }]}>
        <View style={bStyles.left}>
          <BookingIcon name={getBookingIcon(type)} />
        </View>
        <View style={[bStyles.right, { paddingLeft: 0 }]}>
          <Location booking={booking} showDeskTranslation />
          <Text style={globalStyles.description}>{time}</Text>
          {comment && comment.length ? (
            <Text style={globalStyles.description}>{comment}</Text>
          ) : null}
        </View>
      </View>
      {showActionContainer ? (
        <View style={styles.actionButtonContainer}>
          {hasCatering ? (
            <AdditionalOrderIcon onPress={toggleCatering} type="catering" />
          ) : null}
          {hasEquipping ? (
            <AdditionalOrderIcon onPress={toggleEquipping} type="equipping" />
          ) : null}
          {isCancelable && !isExpired ? (
            <View style={[bStyles.row, bStyles.buttonContainer]}>
              <SecondaryButton
                buttonStyle={globalStyles.cancelButton}
                titleStyle={globalStyles.cancelButtonText}
                title={t('Home.ActiveBookings.Booking.cancel')}
                onPress={cancel}
              />
            </View>
          ) : null}
        </View>
      ) : null}

      {showCatering && hasCatering
        ? catering.map((data, index) => (
            <AdditionalOrder
              data={data}
              key={index}
              type="catering"
              isExpired={isExpired}
              isBookingsOverview
              isDesktop
            />
          ))
        : null}
      {showEquipping && hasEquipping
        ? equipping.map((data, index) => (
            <AdditionalOrder
              data={data}
              key={index}
              type="equipping"
              isExpired={isExpired}
              isBookingsOverview
              isDesktop
            />
          ))
        : null}
    </View>
  );
}
