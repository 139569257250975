import React, { useEffect } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import { StatusBar } from 'expo-status-bar';

import { TopBar } from '@views/TopBar';
import { Routes } from '@views/Routes';
import WebRoutes from '@views/Routes/WebRoutes';
import { Calendar } from '@views/Calendar';
import { useOpenCalendar } from '@views/Calendar/hooks';
import Errors from '@views/Errors';
import { useLoggedIn } from '@views/Login/hooks';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';

import {
  useRegisterPushToken,
  useCheckAndRegisterPushToken,
} from '@pushNotification/hooks';
import { colors } from '@views/shared/styles';

/*import { useMaxWidth } from './utils';*/

const styles = StyleSheet.create({
  container: {
    height: '100%',
    margin: 'auto',
    width: '100%',
  },
});

export function Layout({ navigationRef }) {
  const isWeb = Platform.OS === 'web';
  /*const width = useMaxWidth();*/
  const { backgroundColor } = useMyOrganization();
  const { isOpen } = useOpenCalendar();
  const loggedIn = useLoggedIn();
  const { loaded } = useMyUser();
  const registerPushToken = useRegisterPushToken();
  const isDesktop = useIsDesktop();

  const { checkAndRegisterPushToken } = useCheckAndRegisterPushToken();

  useEffect(() => {
    if (loaded && !isWeb) {
      checkAndRegisterPushToken(registerPushToken);
    }
  }, [loaded, isWeb, checkAndRegisterPushToken]);

  return (
    <View
      style={[
        styles.container,
        /*{ width, backgroundColor },*/
        { backgroundColor },
        isDesktop && {
          paddingHorizontal: 50,
          backgroundColor: colors.white,
        },
      ]}
    >
      <StatusBar style="auto" />
      {loggedIn && !isDesktop ? <TopBar navigationRef={navigationRef} /> : null}
      {isOpen ? <Calendar navigationRef={navigationRef} /> : null}
      <Errors navigationRef={navigationRef} />
      {loggedIn && isDesktop ? (
        <WebRoutes navigation={navigationRef.current} />
      ) : (
        <Routes />
      )}
    </View>
  );
}
