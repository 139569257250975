import React from 'react';
import { ScrollView, View } from 'react-native';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { Loader } from '@views/shared/Loader';
import { ColleagueListCard } from '@views/Colleagues/List/ColleagueListCard';
import { ListItem } from '@views/Colleagues/List/Department';

import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import { globalStyles, globalStylesDesktop } from '@views/shared/styles';

import { ListRenderProps } from '@views/Colleagues/List/interfaces';

import {
  useOrganizationUsersInitialLetters,
  useOrganizationUsersByLetter,
} from './hooks';

export function AlphabeticalView() {
  const { data, loading, error } = useOrganizationUsersInitialLetters();
  const isDesktop = useIsDesktop();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ScrollView
        style={[globalStyles.container, isDesktop && { paddingTop: 40 }]}
      >
        {data &&
          data.organizationUsersInitialLetters.map((item, index) => {
            return (
              <ListItem
                item={item}
                key={index}
                title={item.letter}
                ItemRenderer={ColleaguesListRender}
              />
            );
          })}
      </ScrollView>
    </>
  );
}

function ColleaguesListRender({ item, page }: ListRenderProps) {
  const { data, loading, error } = useOrganizationUsersByLetter(item, page);
  const isDesktop = useIsDesktop();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  return (
    <View style={isDesktop && [globalStylesDesktop.bookingsContainer]}>
      {loading ? (
        <Loader />
      ) : data.length ? (
        data.map((colleague, index) => (
          <ColleagueListCard
            key={`${item.letter}-${index}`}
            colleague={colleague}
          />
        ))
      ) : null}
    </View>
  );
}
