import { useCallback } from 'react';

import { useGoTo } from '@views/shared/utils/useGoTo';
import { AreaStructureForParkingSpots } from '@views/shared/interfaces/buildingStructure';

export function useBookable(area: AreaStructureForParkingSpots) {
  const { goToBookingPage } = useGoTo();

  const { id: areaId, areaType: type } = area;
  const book = useCallback(() => goToBookingPage({ areaId, type }), [
    areaId,
    type,
    goToBookingPage,
  ]);

  return {
    book,
  };
}
