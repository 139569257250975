import { gql } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';
import { atom, useRecoilState } from 'recoil';

import {
  getWeekEnd,
  getWeekStart,
  universalDateFormatter,
  dateFormatShort,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useCoreBookings } from '@views/Bookings/MyBookings/core.hooks';
import { useMyUser } from '@providers/User';

const featureKey = 'bookings';

const weekAtom = atom<Date | undefined>({
  key: `${featureKey}/week`,
  default: undefined,
});

const getBookings = gql`
  query getBookings(
    $start: DateTime
    $end: DateTime
    $language: LanguageEnumType
  ) {
    bookings(start: $start, end: $end, language: $language) {
      id
      status
      checkedIn
      checkedOut
      homeOffice
      start
      travelDay
      vacation
      sickDay
      end
      desk {
        id
        type
        label
        numberOnFloor
      }
      area {
        id
        name
        areaType
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
            address
          }
        }
      }
      parking {
        type: combinedType
        label
        numberOnFloor
      }
      catering {
        id
        items {
          quantity
          item {
            title
          }
        }
      }
      equipping {
        id
        items {
          quantity
          item {
            title
          }
        }
      }
      comment
    }
  }
`;

export function useBookings() {
  const { t } = useIntl();
  const navigation = useNavigation<any>();
  const currentDate = useCurrentDate();
  const { language } = useMyUser();
  const [_weekStart, setWeekStart] = useRecoilState(weekAtom);
  const weekStart = getWeekStart(currentDate, _weekStart);

  const {
    weekDays,
    mapping,
    loading,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    onSwipe,
    refreshBookings,
  } = useCoreBookings({
    query: getBookings,
    options: {
      fetchPolicy: 'no-cache',
      variables: {
        language,
        start: universalDateFormatter({ date: weekStart }),
        end: universalDateFormatter({ date: getWeekEnd(weekStart) }),
        startDate: universalDateFormatter({
          date: weekStart,
          format: dateFormatShort,
        }),
        endDate: universalDateFormatter({
          date: getWeekEnd(weekStart),
          format: dateFormatShort,
        }),
      },
      finderError: {
        type: 'fatal',
        message: t('Bookings.hooks.getBookingsError'),
      },
    },
    weekStart,
    setWeekStart,
  });

  //setWeekStart(weekStart);

  useEffect(() => {
    navigation.addListener('focus', () => {
      refreshBookings();
    });
  }, [navigation, refreshBookings]);

  return {
    weekDays,
    mapping,
    loading,
    onSwipe,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    refreshBookings,
  };
}
