import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { styles } from '@views/Book/Book/NextDaysBooking/FullDayBooking';

import { BookingProps } from '@views/Book/Book/NextDaysBooking/FullDayBooking/interfaces';

import DaySlot from './DaySlot';
import useValues from './hooks';

export default function HourlyBooking({
  currentBookedType,
  params,
}: BookingProps) {
  const { t } = useIntl() as any;

  const { goToPage, bookableDays, types, isDisabledToBook } = useValues({
    currentBookedType,
    params,
  });

  return (
    <View>
      <Text style={styles.book}>{t('Home.Book.Again')}</Text>
      {bookableDays.map((date, index) => (
        <DaySlot
          date={date}
          disabledToBook={isDisabledToBook(date)}
          goToPage={goToPage}
          types={types}
          key={index}
        />
      ))}
    </View>
  );
}
