import { useCallback } from 'react';

import { useGoTo } from '@views/shared/utils/useGoTo';
import { AreaStructureForTelefonBox } from '@views/shared/interfaces/buildingStructure';

export function useBookable(area: AreaStructureForTelefonBox) {
  const { goToBookingPage } = useGoTo();

  const { id: areaId, areaType } = area;
  const onBook = useCallback(
    () => goToBookingPage({ areaId, type: areaType }),
    [areaId, areaType, goToBookingPage],
  );

  return {
    onBook,
  };
}
