import React from 'react';
import { Text, View, Image, TouchableHighlight } from 'react-native';

import { useIntl } from '@utils/intl';

import { Loader } from '@views/shared/Loader';
import { BookingIcon } from '@views/shared/BookingIcon';
import { globalStyles } from '@views/shared/styles';
import { Accordion } from '@views/shared/Accordion';
import { CustomIcon } from '@views/shared/CustomIcon';

import { styles } from '@views/shared/Structure';

import { getBookingIcon, getBookingTypeByAreaType } from '@views/shared/consts';

import {
  WorklightStatus,
  DeskStructure,
  AreaStructureForDesks,
} from '@views/shared/interfaces/buildingStructure';

import { useDesks } from './hooks';
import { Desk } from './Desk';

interface ContentProps {
  loading: boolean;
  error: any;
  desks?: DeskStructure[];
  book: (deskId: string) => void;
  isMore: boolean;
  toggleMore: () => void;
  showAll: boolean;
}

// TODO needs refactoring. see ParkingSpots component
function DesksContent({
  loading,
  error,
  desks,
  book,
  isMore,
  toggleMore,
  showAll,
}: ContentProps) {
  const { t } = useIntl();
  const textShowMore = showAll
    ? 'Home.Structure.Floor.Area.Desks.ShowLess'
    : 'Home.Structure.Floor.Area.Desks.ShowMore';

  if (loading) {
    return <Loader />;
  }

  if (error || !desks) {
    return <Text>GraphQL Error: Desks</Text>;
  }

  return (
    <View>
      {!desks.length ? (
        <Text style={styles.noDesks}>
          {t('Home.Structure.Floor.Area.Desks.NoFreeDesks')}
        </Text>
      ) : (
        desks.map((desk, index) => (
          <Desk
            key={desk.id}
            desk={desk}
            onBook={book}
            last={index === desks.length - 1}
          />
        ))
      )}
      {isMore ? (
        <TouchableHighlight underlayColor="transparent" onPress={toggleMore}>
          <View style={styles.more}>
            <Text style={styles.moreText}>{t(textShowMore)}</Text>
            <View style={styles.toggle}>
              <CustomIcon
                name={showAll ? 'arrow-up' : 'arrow-down'}
                size={15}
              />
            </View>
          </View>
        </TouchableHighlight>
      ) : null}
    </View>
  );
}

interface DesksProps {
  area: AreaStructureForDesks;
  worklight: WorklightStatus;
}

export function Desks({ area, worklight }: DesksProps) {
  const {
    book,
    desks,
    error,
    isFilterUsed,
    isMore,
    open,
    loading,
    showAll,
    toggleMore,
  } = useDesks(area);

  if (isFilterUsed && loading) {
    return <Loader />;
  }
  const { areaType } = area;

  const iconName = getBookingIcon(getBookingTypeByAreaType(areaType));
  const titleElm = (
    <>
      <View style={styles.left}>
        <BookingIcon name={iconName} worklight={worklight} />
      </View>
      <View style={styles.middle}>
        <Text style={globalStyles.title}>{area.name}</Text>
      </View>
    </>
  );

  const imageAvailable = area.image && area.image.trim().length > 0;

  return (
    <Accordion
      open={open}
      openStyle={imageAvailable ? {} : styles.open}
      title={titleElm}
      headerStyle={[styles.row, styles.accordionChild]}
    >
      {imageAvailable ? (
        <Image
          style={styles.image}
          resizeMode="contain"
          source={{ uri: area.image }}
        />
      ) : null}
      <DesksContent
        desks={desks}
        error={error}
        loading={loading}
        book={book}
        isMore={isMore}
        toggleMore={toggleMore}
        showAll={showAll}
      />
    </Accordion>
  );
}
