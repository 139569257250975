import { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useNavigation, useRoute } from '@react-navigation/native';
import { pathOr, pluck } from 'ramda';

import {
  getBookableWorkingTime,
  dateFormatShort,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { useErrorQuery } from '@providers/Errors';
import { useMyBuildings } from '@providers/Buildings';
import { useCurrentDate } from '@views/Calendar/hooks';
import { getDeskSlots } from '@views/Book/Bookings/Desks/hooks';
import {
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TELEFON_BOX,
} from '@views/shared/consts';

import { BookCategory } from '@views/shared/interfaces/booking';

import { ErrorQueryHookOptions } from '@providers/Errors/interfaces';
import {
  sortAreaByName,
  sortFloorsByTypeAndLabel,
  sortFloorsByTypeAndNumber,
} from '@views/shared/Booking';

import { useFilter } from '@views/Book/Filter/hooks';
import { getFloorName, getFloorInfo } from '@utils/Building';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { Translator } from '@utils/intl';

import {
  DataForDesks,
  DataForFreeSeats,
  DataForMeetingRooms,
  DataForParkingSpots,
  ProcessFloorsProps,
  DataForTelefonBox,
  QueryProps,
} from './interfaces';

const getStructureForDesks = gql`
  query getBuildingStructureForDesks($buildingId: ID!, $date: Date!) {
    building: buildingStructureForDesks(buildingId: $buildingId, date: $date) {
      id
      numberOfAvailableDesksHourlyByAreaType {
        areaType
        desksAvailable
        freeSlots
        totalSlots
      }
      floors {
        id
        floorLabel
        floorNumber
        floorType
        numberOfAvailableDesksHourlyByAreaType {
          areaType
          desksAvailable
          freeSlots
          totalSlots
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          numberOfAvailableDesksHourlyByAreaType {
            areaType
            desksAvailable
            freeSlots
            totalSlots
          }
        }
      }
    }
  }
`;

const getStructureForMeetingRooms = gql`
  query getBuildingStructureForMeetingRooms(
    $buildingId: ID!
    $date: Date!
    $start: DateTime
    $end: DateTime
  ) {
    building: buildingStructureForMeetingRooms(
      buildingId: $buildingId
      date: $date
      start: $start
      end: $end
    ) {
      id
      numberOfMeetingRooms
      floors {
        id
        floorLabel
        floorNumber
        floorType
        meetingRoomTimeStats {
          booked
          total
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          isMeetingRoomFree
          meetingRoomTimeStats {
            booked
            total
          }
          equipment {
            category {
              id
              name
            }
            id
            name
          }
          isForbidden
        }
      }
    }
  }
`;

const getStructureForFreeSeats = gql`
  query getBuildingStructureForFreeSeats($buildingId: ID!, $date: Date!) {
    building: buildingStructureForFreeSeats(
      buildingId: $buildingId
      date: $date
    ) {
      id
      numberOfFreeSpacesRooms
      floors {
        id
        floorLabel
        floorNumber
        floorType
        numberOfAvailableDesksHourlyByAreaType {
          areaType
          desksAvailable
          freeSlots
          totalSlots
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          numberOfAvailableDesksHourlyByAreaType {
            areaType
            desksAvailable
            freeSlots
            totalSlots
          }
        }
      }
    }
  }
`;

const getStructureForParking = gql`
  query getBuildingStructureForParking($buildingId: ID!, $date: Date!) {
    building: buildingStructureForParkingSpots(
      buildingId: $buildingId
      date: $date
    ) {
      id
      floors {
        id
        floorLabel
        floorNumber
        floorType
        areas {
          areaType
          id
          image
          name
          isBookable
        }
      }
      numberOfAvailableParkings
      numberOfTotalParkings
    }
  }
`;

const getStructureForTelefonBox = gql`
  query getBuildingStructureForTelefonBox(
    $buildingId: ID!
    $date: Date!
    $start: DateTime
    $end: DateTime
  ) {
    building: buildingStructureForTelefonBox(
      buildingId: $buildingId
      date: $date
      start: $start
      end: $end
    ) {
      id
      numberOfTelefonBoxes
      floors {
        id
        floorLabel
        floorNumber
        floorType
        telefonBoxTimeStats {
          booked
          total
        }
        areas {
          areaType
          id
          image
          name
          isBookable
          isTelefonBoxFree
          telefonBoxTimeStats {
            booked
            total
          }
          equipment {
            category {
              id
              name
            }
            id
            name
          }
          isForbidden
        }
      }
    }
  }
`;

function processFloors({
  equipmentIds,
  floorIds,
  floors,
  isFilterUsed,
  sortByNumber,
  t,
  timeRange,
}: ProcessFloorsProps) {
  if (!floors) {
    return floors;
  }

  const filteredFloors = floors
    .map(floor => {
      const label = getFloorName({
        t,
        ...getFloorInfo(floor),
      });
      const filteredAreas = floor.areas.filter(
        ({
          areaType,
          equipment = [],
          isBookable,
          isMeetingRoomFree,
          isTelefonBoxFree,
        }) => {
          // filtering for meeting rooms
          if (isFilterUsed && areaType === BOOK_CATEGORY_MEETING_ROOM) {
            let hasEquipment = true;

            if (equipmentIds.length) {
              const equipmentList = pluck('id', equipment);
              hasEquipment = equipmentIds.some(id =>
                equipmentList.includes(id),
              );
            }

            // check isMeetingRoomFree only if timeRange is selected
            return (
              isBookable &&
              hasEquipment &&
              (timeRange.start ? isMeetingRoomFree : true)
            );
          }

          if (isFilterUsed && areaType === BOOK_CATEGORY_TELEFON_BOX) {
            let hasEquipment = true;

            if (equipmentIds.length) {
              const equipmentList = pluck('id', equipment);
              hasEquipment = equipmentIds.some(id =>
                equipmentList.includes(id),
              );
            }

            // check isMeetingRoomFree only if timeRange is selected
            return (
              isBookable &&
              hasEquipment &&
              (timeRange.start ? isTelefonBoxFree : true)
            );
          }

          return isBookable;
        },
      );

      return { ...floor, areas: sortAreaByName(filteredAreas), label };
    })
    .filter(({ areas, id }) => {
      const hasAreas = areas.length > 0;
      const isFilteredFloorOr = !floorIds.length ? true : floorIds.includes(id);

      return hasAreas && isFilteredFloorOr;
    });

  return sortByNumber
    ? sortFloorsByTypeAndNumber(filteredFloors)
    : sortFloorsByTypeAndLabel(filteredFloors);
}

function useQueryOptions({
  buildingId,
  start,
  end,
}: QueryProps): ErrorQueryHookOptions {
  const { t } = useIntl();
  const date = useCurrentDate();

  const { start: queryStart, end: queryEnd } = start
    ? getBookableWorkingTime(date, start, end)
    : { start, end };

  return {
    fetchPolicy: 'no-cache',
    variables: {
      buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
      start: queryStart && universalDateFormatter({ date: queryStart }),
      end: queryEnd && universalDateFormatter({ date: queryEnd }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: t('Home.Structure.hooks.error'),
    },
  };
}

function useStructureDataForDesks(queryResult) {
  const { data, ...rest } = queryResult;
  const count = getDeskSlots(data?.building, 'desksAvailable', 0);

  return {
    data,
    ...rest,
    count,
  };
}

function useStructureDataForMeetingRooms(queryResult) {
  const { data, ...rest } = queryResult;
  const count = pathOr(0, ['building', 'numberOfMeetingRooms'], data);

  return {
    data,
    ...rest,
    count,
  };
}

function useStructureDataForFreeSeats(queryResult) {
  const { data, ...rest } = queryResult;
  const count = pathOr(0, ['building', 'numberOfFreeSpacesRooms'], data);

  return {
    data,
    ...rest,
    count,
  };
}

function useStructureDataForParkingSpots(queryResult) {
  const { data, ...rest } = queryResult;
  const count = pathOr(0, ['building', 'numberOfAvailableParkings'], data);

  return {
    data,
    ...rest,
    count,
  };
}

function useStructureDataForTelefonBox(queryResult) {
  const { data, ...rest } = queryResult;
  const count = pathOr(0, ['building', 'numberOfTelefonBoxes'], data);

  return {
    data,
    ...rest,
    count,
  };
}

function useStructureForDesks(buildingId) {
  const options = useQueryOptions({ buildingId });
  const result = useErrorQuery<DataForDesks>(getStructureForDesks, options);

  return useStructureDataForDesks(result);
}

function useStructureForMeetingRooms(buildingId, { start, end }) {
  const options = useQueryOptions({ buildingId, start, end });
  const result = useErrorQuery<DataForMeetingRooms>(
    getStructureForMeetingRooms,
    options,
  );

  return useStructureDataForMeetingRooms(result);
}

function useStructureForFreeSeats(buildingId) {
  const options = useQueryOptions({ buildingId });
  const result = useErrorQuery<DataForFreeSeats>(
    getStructureForFreeSeats,
    options,
  );

  return useStructureDataForFreeSeats(result);
}

function useStructureForParking(buildingId) {
  const options = useQueryOptions({ buildingId });
  const result = useErrorQuery<DataForParkingSpots>(
    getStructureForParking,
    options,
  );

  return useStructureDataForParkingSpots(result);
}

function useStructureForTelefonBox(buildingId, { start, end }) {
  const options = useQueryOptions({ buildingId, start, end });
  const result = useErrorQuery<DataForTelefonBox>(
    getStructureForTelefonBox,
    options,
  );

  return useStructureDataForTelefonBox(result);
}

const useStructureByAreaType = {
  [BOOK_CATEGORY_ROOM]: useStructureForDesks,
  [BOOK_CATEGORY_MEETING_ROOM]: useStructureForMeetingRooms,
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: useStructureForFreeSeats,
  [BOOK_CATEGORY_PARKING]: useStructureForParking,
  [BOOK_CATEGORY_TELEFON_BOX]: useStructureForTelefonBox,
};

export function useStructure(t: Translator) {
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const type: BookCategory = route.params?.type ?? BOOK_CATEGORY_ROOM;
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const sortByNumber = useHasFeature('floor_plan_sorting');

  const filterData = useFilter();

  const { floorIds = [], isFilterUsed = false, equipmentIds = [], timeRange } =
    filterData[type] || {};

  const { count, data, error, loading } = useStructureByAreaType[type](
    buildingId,
    timeRange,
  );

  const floors = processFloors({
    floors: data?.building?.floors || [],
    floorIds,
    equipmentIds,
    isFilterUsed,
    sortByNumber,
    timeRange,
    t,
  });

  useEffect(() => {
    if (!buildingId) {
      navigation.navigate('index');
    }
  }, [navigation, buildingId]);

  const isFilterEnabledByType = [
    BOOK_CATEGORY_ROOM,
    BOOK_CATEGORY_MEETING_ROOM,
    BOOK_CATEGORY_PARKING,
    BOOK_CATEGORY_TELEFON_BOX,
  ].includes(type);

  return {
    count,
    error,
    floors,
    isFilterUsed,
    isFilterEnabledByType,
    loading,
    type,
  };
}
