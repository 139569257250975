import React from 'react';
import { View, StyleSheet } from 'react-native';

import { useMyBuildings } from '@providers/Buildings';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Select } from '@views/shared/Select';

import { ItemComponentProps } from '@views/shared/Select/interfaces';

import { globalStyles, colors, fonts, spacings } from '@views/shared/styles';

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    top: 5,
    right: spacings.small,
    backgroundColor: colors.white,
    width: 38,
    height: 38,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
  },
});

export default function BuildingSwitcher() {
  const {
    selectBuildingById,
    buildings,
    selectedBuildingId,
  } = useMyBuildings();

  if (buildings.length < 2) {
    return null;
  }

  const items = buildings.map(({ name: label }, value) => ({ value, label }));
  const selected = buildings.findIndex(({ id }) => id === selectedBuildingId);

  return (
    <Select
      value={selected}
      items={items}
      onValueChange={selectBuildingById}
      selectedComponent={SelectedItem}
    />
  );
}

function SelectedItem({ show }: ItemComponentProps) {
  return (
    <View style={[styles.icon, globalStyles.shadow]}>
      <CustomIcon name="building" onPress={show} size={fonts.sizeBuilding} />
    </View>
  );
}
