import React from 'react';

import { useIntl } from '@utils/intl';
import BaseBooking from '@views/Book/Book/BasicBook/Booking';
import { SharedBookWithAreaProps } from '@views/shared/interfaces/booking';

export default function BookingWithArea({
  areaId,
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  type,
  useBook,
  showAvailability,
  showShortCutSlotsSelection = false,
}: SharedBookWithAreaProps) {
  const { t, formats } = useIntl();
  const {
    bookablePeriod,
    blockedTimeSlots,
    book,
    error,
    errorBooking,
    loading,
    loadingBooking,
    selectedSlots,
    setSlots,
    midDayHour,
    updateComment,
  } = useBook({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    formats,
    params: {
      areaId,
    },
    t,
    type,
  });

  return (
    <BaseBooking
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
      bookablePeriod={bookablePeriod}
      blockedTimeSlots={blockedTimeSlots}
      book={book}
      selectedSlots={selectedSlots}
      error={error}
      errorBooking={errorBooking}
      loading={loading}
      loadingBooking={loadingBooking}
      setSlots={setSlots}
      midDayHour={midDayHour}
      showAvailability={showAvailability}
      showShortCutSlotsSelection={showShortCutSlotsSelection}
      updateComment={updateComment}
    />
  );
}
