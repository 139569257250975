import React from 'react';
import { View, StyleSheet, Linking } from 'react-native';

import { colors, spacings } from '@views/shared/styles';
import { OrganizationUserApiResponse } from '@views/Colleagues/List/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';

const { blackAlpha, white, grey6Transparent, aquaMarine } = colors;
const { small } = spacings;

const styles = StyleSheet.create({
  container: {
    borderTopWidth: 1,
    borderColor: blackAlpha,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: small,
  },
  disabled: {
    backgroundColor: grey6Transparent,
    borderColor: grey6Transparent,
  },
  viewSpacing: {
    marginHorizontal: small,
    backgroundColor: aquaMarine,
    borderColor: aquaMarine,
    borderWidth: 1,
    borderRadius: 50,
    padding: small,
  },
});

interface Props {
  id: string;
  colleague: OrganizationUserApiResponse;
}

export function Contacts({
  colleague: {
    userprofile: { phoneNumber },
    email,
  },
}: Props) {
  const isPhoneEnabled = phoneNumber.length > 0;

  return (
    <View style={styles.container}>
      <View style={[styles.viewSpacing, !isPhoneEnabled && styles.disabled]}>
        <CustomIcon
          name="phone-inv"
          size={25}
          color={white}
          onPress={
            isPhoneEnabled ? () => Linking.openURL('tel:' + phoneNumber) : null
          }
        />
      </View>
      <View style={styles.viewSpacing}>
        <CustomIcon
          name="mail-inv"
          size={25}
          color={white}
          onPress={() => Linking.openURL('mailto:' + email)}
        />
      </View>
    </View>
  );
}
