import { useState, useCallback, useEffect } from 'react';
import { useMyOrganization } from '@providers/Organization';
import {
  getWorkingDaysOfWeek,
  getWeekDays,
  getWeekStart,
  transformToDate,
  getNextDayFromThis,
  getPreviousWeekFromThis,
  getWeekEnd,
  isAfter,
  isSameDay,
} from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';

export function useWeeklyDates() {
  const currentDate = useCurrentDate();
  const [_weekStart, setWeekStart] = useState(currentDate);
  const [disableNav, setDisableNav] = useState(true);
  const { workingDaysStart, workingDaysEnd } = useMyOrganization();
  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );

  const weekStart = getWeekStart(currentDate, _weekStart);
  const weekEnd = getWeekEnd(_weekStart);
  const weekDays = getWeekDays(weekStart, enabledWorkingDays);

  const moveToNextWeek = useCallback(() => {
    const nextWeek = transformToDate(getNextDayFromThis(weekStart, 7));
    setWeekStart(nextWeek);
  }, [setWeekStart, weekStart]);

  const moveToPreviousWeek = useCallback(() => {
    const previousWeek = transformToDate(getPreviousWeekFromThis(weekStart, 7));
    setWeekStart(previousWeek);
  }, [setWeekStart, weekStart]);

  useEffect(() => {
    if (isAfter(weekEnd, currentDate) || isSameDay(weekEnd, currentDate)) {
      setDisableNav(true);
    } else {
      setDisableNav(false);
    }
  }, [_weekStart]);

  return {
    weekDays,
    disableNav,
    moveToNextWeek,
    moveToPreviousWeek,
  };
}
