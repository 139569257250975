import {
  any,
  ascend,
  dropLast,
  isEmpty,
  last,
  pathOr,
  pick,
  range,
  sort,
} from 'ramda';
import dayjs from 'dayjs';

/************* USE THIS FOR RUNNING TESTS ***************/
// import * as dayjs from 'dayjs';
//
// import {
//  getNumberFromHours,
//  getTimeSlot,
//  getHour,
//  isToday,
//  universalDateFormatter,
// } from '../../../utils/DateAndTime';
// import { Booking } from "../interfaces/booking";
// import { getBookingType } from '../utils';
// import { BOOKING_TYPE_VIRTUAL_DESK } from '../consts';
/**************************-*****************************/

import {
  getNumberFromHours,
  getTimeSlot,
  getHour,
  isToday,
  universalDateFormatter,
  MixDate,
  getBookableWorkingTime,
  getTodayAsDayJs,
  TimeRange,
  getEndOfTheDay,
} from '@utils/DateAndTime';
import {
  BOOKING_TYPE_VIRTUAL_DESK,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_FAST_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_TELEFON_BOX,
  BOOKING_TYPE_VACATION,
  BOOKING_TYPE_SICKDAY,
} from '@views/shared/consts';
import { Booking } from '@views/shared/interfaces/booking';
import { getBookingType } from '@views/shared/utils';

import {
  BlockedTimeSlot,
  CalculateTimeSlotsProps,
  GenerateTimeSlotsProps,
  SlotStatus,
  Status,
  TimeSlot,
  TimeSlotData,
  TimeSlotsProps,
  UIStatusForSlots,
  UpdateTimeSlotsProps,
  SelectionTypeOptions,
  UnavailableTimeSlot,
} from './interfaces';

export const STATUS: SlotStatus = {
  BLOCKED: 'blocked',
  BOOKED: 'booked',
  BOOKED_BY_COLLEAGUE: 'bookedByColleague',
  OCCUPIED_BY_COLLEAGUE: 'occupiedByColleague',
  DISABLED: 'disabled',
  EXPIRED: 'expired',
  FREE: 'free',
  OCCUPIED: 'occupied',
  OUTSIDE_BUILDING: 'outsideBuilding',
};

export const SELECTION_OPTIONS: SelectionTypeOptions = {
  NONE: 'none',
  FULL_DAY: 'full-day',
  BEFORE_LUNCH: 'before-lunch',
  AFTER_LUNCH: 'after-lunch',
  ALL: 'all-available',
};

export const SLOT_STATUS: UIStatusForSlots = {
  BLOCKED: 'blocked',
  BOOKED: 'booked',
  BOOKED_BY_COLLEAGUE: 'bookedByColleague',
  DISABLED: 'disabled',
  EXPIRED: 'expired',
  FREE: 'free',
  OCCUPIED: 'occupied',
  SELECTED: 'selected',
};

const sortSlotsByStartIndex = sort(ascend((slot: TimeSlot) => slot.startIndex));
const isMyBooking = ({ bookedByColleague }: BlockedTimeSlot) =>
  !bookedByColleague;

const updateSlotsStatus = ({
  slots,
  reserved,
  isToday,
}: CalculateTimeSlotsProps): TimeSlot[] =>
  slots.map(slot => ({
    ...slot,
    ...updateStatus({ slot, reserved, isToday }),
  }));

const generateStatus = (status, userId) => ({ status, userId });

function getReservedSlotStatus(
  slot: BlockedTimeSlot,
): { status: Status; userId: string[] } {
  if (slot.blocked) {
    return generateStatus(STATUS.BLOCKED, []);
  }

  if (slot.bookedByColleague) {
    return generateStatus(STATUS.BOOKED_BY_COLLEAGUE, slot.userId);
  }

  if ([BOOKING_TYPE_HOME, BOOKING_TYPE_TRAVEL_DAY].includes(slot.type)) {
    return generateStatus(STATUS.OUTSIDE_BUILDING, []);
  }

  return generateStatus(STATUS.BOOKED, []);
}

function updateStatus({
  slot,
  reserved,
  isToday,
}: UpdateTimeSlotsProps): { status: Status; userId: string[] } {
  const { startIndex, endIndex, status: currStatus } = slot;

  // check if the slot has an overlap with one of the reserved slots
  const bookedSlots = reserved.filter(
    ({ start, end }) =>
      getHour(start) <= startIndex && getHour(end) >= endIndex,
  );

  // get the reserved slot for the colleague bookings
  const colleagueBookings = bookedSlots.find(
    ({ bookedByColleague }) => bookedByColleague,
  );

  if (colleagueBookings) {
    return getReservedSlotStatus(colleagueBookings);
  }

  // get the reserved slot for the own bookings
  const ownBookings = bookedSlots.find((slot, index, slots) =>
    any(isMyBooking, slots),
  );

  if (ownBookings) {
    return getReservedSlotStatus(ownBookings);
  }

  //check the expiration of the hour based on the local time of the device
  if (isToday && getHour(getTodayAsDayJs().local()) > startIndex) {
    return { status: STATUS.EXPIRED, userId: [] };
  }

  return { status: currStatus, userId: [] };
}

// If the end time index of previous slot is equal to the start time index of current slot,
// merge this slots together: start is from the previous slot and the end is from current slot
const reduceSlots = (acc: TimeSlot[], cur: TimeSlot): TimeSlot[] => {
  if (acc && !isEmpty(acc)) {
    const prevSlot = last(acc) as TimeSlot;
    const { startIndex: currentIdx, end, endIndex, endValue } = cur;
    const { endIndex: prevIdx } = prevSlot || {};

    if (currentIdx - prevIdx > 0) {
      return [...acc, cur];
    }

    return [...dropLast(1, acc), { ...prevSlot, end, endIndex, endValue }];
  }

  return [cur];
};

const getTimePeriods = (booking: Booking): TimeSlotData =>
  pick(['start', 'end'])(booking);

// This function generates the basic structure for the time slots
// based on the defined time range anda date
// Note: This is exported only to be used inside the tests file
export function generateDefaultSlots({
  bookablePeriod,
  date,
}: GenerateTimeSlotsProps): TimeSlot[] {
  const { start, end } = bookablePeriod;
  const startValue = getNumberFromHours(start);
  const endValue = getNumberFromHours(end);
  const timeRange = range(startValue, endValue);

  return timeRange.map(time => {
    const { start, end } = getTimeSlot(date, time);

    return {
      status: STATUS.FREE,
      startIndex: time,
      endIndex: time + 1,
      start,
      end,
      startValue: universalDateFormatter({ date: start }),
      endValue: universalDateFormatter({ date: end }),
      userId: [],
    };
  });
}

// The main function that generates the time slots based on the input from GraphQL
export function calculateTimeSlots({
  bookablePeriod,
  date,
  blockedTimeSlots,
}: TimeSlotsProps): TimeSlot[] {
  const slots = generateDefaultSlots({ bookablePeriod, date });

  return updateSlotsStatus({
    slots,
    reserved: blockedTimeSlots,
    isToday: isToday(date),
  });
}

// This function merges the selected slots that are contiguous
export function mergeTimeSlots(slots: TimeSlot[]) {
  return sortSlotsByStartIndex(slots).reduce(reduceSlots, []);
}

// This function transform the time slots formats from GraphQL to the format that fits BlockedTimeSlot,
// where is also exposed the type of booking
export function fromTimeSlotsDataToBlockedTimeSlots(
  userId: string,
  slots: UnavailableTimeSlot[],
  type = BOOKING_TYPE_VIRTUAL_DESK,
): BlockedTimeSlot[] {
  const slotMapper = {
    [BOOKING_TYPE_HOME]: (slot: UnavailableTimeSlot): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end'])(slot),
      blocked: false,
      bookedByColleague: false,
      type: BOOKING_TYPE_HOME,
    }),
    [BOOKING_TYPE_ROOM]: (slot: UnavailableTimeSlot): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'deskId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_ROOM,
    }),
    [BOOKING_TYPE_MEETING_ROOM]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'areaId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_MEETING_ROOM,
    }),
    [BOOKING_TYPE_TELEFON_BOX]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'areaId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_TELEFON_BOX,
    }),
    [BOOKING_TYPE_FREE_SPACES_ROOM]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end'])(slot),
      blocked: false,
      bookedByColleague: true,
      type: BOOKING_TYPE_FREE_SPACES_ROOM,
    }),
    [BOOKING_TYPE_TRAVEL_DAY]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end'])(slot),
      blocked: false,
      bookedByColleague: false,
      type: BOOKING_TYPE_TRAVEL_DAY,
    }),
    [BOOKING_TYPE_PARKING_CHARGING]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'parkingId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_PARKING_CHARGING,
    }),
    [BOOKING_TYPE_PARKING_FAST_CHARGING]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'parkingId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_PARKING_FAST_CHARGING,
    }),
    [BOOKING_TYPE_PARKING_GENERAL]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'parkingId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_PARKING_GENERAL,
    }),
    [BOOKING_TYPE_PARKING_HANDICAPPED]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'parkingId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_PARKING_HANDICAPPED,
    }),
    [BOOKING_TYPE_VIRTUAL_DESK]: (
      slot: UnavailableTimeSlot,
    ): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end', 'deskId'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_VIRTUAL_DESK,
    }),
    [BOOKING_TYPE_VACATION]: (slot: UnavailableTimeSlot): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_VACATION,
    }),
    [BOOKING_TYPE_SICKDAY]: (slot: UnavailableTimeSlot): BlockedTimeSlot => ({
      ...pick(['userId', 'start', 'end'])(slot),
      blocked: slot.unavailableType === 'lock',
      bookedByColleague: !slot.userId.includes(userId),
      type: BOOKING_TYPE_SICKDAY,
    }),
  };

  return (
    slots
      // Filter out current user booking because they will be added by the ActiveBookings provider
      .filter(
        (slot: UnavailableTimeSlot) =>
          pathOr(null, ['user', 'id'], slot) !== userId,
      )
      // Map all the other external reservations from other colleagues or due to status blocked
      .map(slotMapper[type])
  );
}

// This function is used to decorate the current user bookings so `blocked` and `bookedByColleague` are always false.
export function createBlockedTimeSlot(booking: Booking): BlockedTimeSlot {
  return {
    ...getTimePeriods(booking),
    blocked: false,
    bookedByColleague: false,
    userId: [],
    type: getBookingType(booking),
  };
}

export const getHalfDaySlots = ({
  selectedDate,
  workingHoursStart,
  workingHoursEnd,
  midDayHour,
  selectionType,
}: {
  selectedDate: MixDate;
  workingHoursStart: string;
  workingHoursEnd: string;
  midDayHour: string;
  selectionType: string;
}): TimeSlot => {
  const startTime =
    selectionType === 'after-lunch' ? midDayHour : workingHoursStart;
  const endTime =
    selectionType === 'before-lunch' ? midDayHour : workingHoursEnd;

  const { start, end } = getBookableWorkingTime(
    selectedDate,
    startTime,
    endTime,
  );

  return {
    status: STATUS.BOOKED,
    start,
    end,
    startIndex: getHour(start),
    endIndex: getHour(end),
    startValue: universalDateFormatter({ date: start }),
    endValue: universalDateFormatter({ date: end }),
    userId: [],
  };
};

// TODO for halfday bookings: add same date check for start and end
// to prevent the overwrite of end value
export const getDateRangeSlots = ({ start, end }: TimeRange): TimeSlot => {
  const endRange = getEndOfTheDay(dayjs(end || start));
  return {
    status: STATUS.BOOKED,
    start: dayjs(start),
    end: endRange,
    startIndex: getHour(start),
    endIndex: getHour(endRange),
    startValue: universalDateFormatter({ date: start }),
    endValue: universalDateFormatter({ date: endRange }),
    userId: [],
  };
};
