import React, { ReactNode } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { ascend, pathOr, sort, path, type } from 'ramda';

import { useIntl } from '@utils/intl';
import {
  getHour,
  universalDateFormatter,
  getTodayAsDayJs,
} from '@utils/DateAndTime';
import {
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_VACATION,
  BOOKING_TYPE_SICKDAY,
  getBookingIcon,
} from '@views/shared/consts';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import CloseConfirmBooking from '@views/shared/Booking/CloseConfirm';
import { CustomIcon } from '@views/shared/CustomIcon';
import TitleByType from '@views/shared/TitleByType';
import { STATUS } from '@views/shared/TimeSlots/helper';

import {
  Booking,
  BookingType,
  CreateBookingGeneric,
} from '@views/shared/interfaces/booking';
import { LightTimeSlot, TimeSlot } from '@views/shared/TimeSlots/interfaces';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

interface Props {
  location: string;
  children?: ReactNode;
  date: string;
  Icon?: React.FC;
  time?: string | ReactNode;
  title?: string | ReactNode;
  type: BookingType;
}

const { extraSmall, big } = spacings;
const { sizeBig, sizeDefault, sizeTitle } = fonts;

export const styles = StyleSheet.create({
  container: {
    borderColor: colors.blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: big,
    marginBottom: 50,
  },
  containerDesktop: {
    maxWidth: 924,
    alignSelf: 'center',
    paddingHorizontal: 0,
  },
  card: {
    flexDirection: 'row',
    position: 'relative',
    marginTop: big,
    alignItems: 'center',
  },
  header: {
    textAlign: 'center',
    fontSize: sizeBig,
    fontFamily: 'mrt-regular',
  },
  icon: {
    paddingRight: big,
  },
  right: {
    justifyContent: 'space-between',
    flex: 1,
  },
  date: {
    fontSize: sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
  checkmark: {
    position: 'absolute',
    right: -15,
    bottom: -30,
  },
  book: {
    paddingVertical: big,
    textAlign: 'center',
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: big,
  },
  notice: {
    fontFamily: 'mrt-regular',
    fontSize: sizeDefault,
  },
});

function getSlots(bookings: Booking[], timeFormat: string): TimeSlot[] {
  const currentDate = getTodayAsDayJs();
  return bookings.map(
    (booking: Booking): TimeSlot => {
      const start = pathOr(
        currentDate,
        ['data', 'createBooking', 'start'],
        booking,
      );
      const end = pathOr(
        currentDate,
        ['data', 'createBooking', 'end'],
        booking,
      );

      return {
        status: STATUS.BOOKED,
        start,
        end,
        startIndex: getHour(start),
        endIndex: getHour(end),
        startValue: universalDateFormatter({ date: start, format: timeFormat }),
        endValue: universalDateFormatter({ date: end, format: timeFormat }),
        userId: [],
      };
    },
  );
}

export function getLightSlots(
  bookings: CreateBookingGeneric[],
  timeFormat: string,
): LightTimeSlot[] {
  const currentDate = getTodayAsDayJs();

  return bookings.map(
    (booking: CreateBookingGeneric): LightTimeSlot => {
      let data = path(['data', 'createBooking'], booking);

      if (type(data) === 'Array') {
        data = data[0];
      }

      const { start = currentDate, end = currentDate } = data;

      return {
        startIndex: getHour(start),
        startValue: universalDateFormatter({ date: start, format: timeFormat }),
        endValue: universalDateFormatter({ date: end, format: timeFormat }),
      };
    },
  );
}

export function BookedTimes(bookings: Booking[], timeFormat: string) {
  const slots = getSlots(bookings, timeFormat);

  const sortedSlots = sort(
    ascend(({ startIndex }) => startIndex),
    slots,
  );

  return sortedSlots.map(({ startValue, endValue, startIndex }: TimeSlot) => {
    return (
      <View key={startIndex}>
        <Text
          style={globalStyles.description}
        >{`${startValue} - ${endValue}`}</Text>
      </View>
    );
  });
}

export function ConfirmBookedTimes(timeSlots: LightTimeSlot[]) {
  const sortedSlots = sort(
    ascend(({ startIndex }) => startIndex),
    timeSlots,
  );

  return sortedSlots.map(
    ({ startValue, endValue, startIndex }: LightTimeSlot) => {
      return (
        <View key={startIndex}>
          <Text
            style={globalStyles.description}
          >{`${startValue} - ${endValue}`}</Text>
        </View>
      );
    },
  );
}

export function BookingConfirmation({
  type,
  location,
  date,
  children,
  Icon,
  time,
  title,
}: Props) {
  const { t } = useIntl() as any;
  const headerTitle = title || t('Home.ActiveBooking.Booking.title');
  const showLocation = ![
    BOOKING_TYPE_TRAVEL_DAY,
    BOOKING_TYPE_VACATION,
    BOOKING_TYPE_SICKDAY,
  ].includes(type);

  const isDesktop = useIsDesktop();

  return (
    <ScrollView
      style={[globalStyles.container, isDesktop && styles.containerDesktop]}
    >
      <View style={styles.container}>
        <CloseConfirmBooking />
        <Text style={styles.header}>{headerTitle}</Text>
        <View style={[styles.card, styles.container]}>
          <View style={styles.icon}>
            <CustomIcon name={getBookingIcon(type)} size={75} />
          </View>
          <View style={styles.right}>
            <View>
              <TitleByType type={type} />
              {showLocation && (
                <Text style={globalStyles.description}>{location}</Text>
              )}
            </View>
            <View>
              <Text style={[styles.date, { paddingTop: spacings.small }]}>
                {date}
              </Text>
              {typeof time === 'string' ? (
                <Text style={globalStyles.description}>{time}</Text>
              ) : (
                time
              )}
            </View>
          </View>
          {Icon ? (
            <Icon />
          ) : (
            <CustomIcon
              color={colors.aquaMarine}
              name="checkmark"
              size={80}
              style={styles.checkmark}
            />
          )}
        </View>
        <View>{children}</View>
      </View>
    </ScrollView>
  );
}
