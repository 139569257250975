import React from 'react';
import { Text, View } from 'react-native';

import { Overlay } from '@views/shared/Overlay';
import { EventsList } from '@views/shared/Events';
import { globalStyles } from '@views/shared/styles';
import { useIntl } from '@utils/intl';

import { ColleagueEventsProps } from './interfaces';
import { useOpenColleagueEvents, useColleagueEvents } from './hooks';

export function ColleagueEvents({ id }: ColleagueEventsProps) {
  const { t } = useIntl();

  const { events, loading, error } = useColleagueEvents({
    id,
  });
  const { isOpen, close } = useOpenColleagueEvents();

  if (error || loading) {
    return null;
  }

  return isOpen ? (
    <Overlay onClose={close} title={t('Colleagues.Events.Title')}>
      <View>
        {events.length === 0 ? (
          <Text style={[globalStyles.description, { textAlign: 'center' }]}>
            {t('Colleagues.Events.no_events_today')}
          </Text>
        ) : (
          <EventsList events={events} />
        )}
      </View>
    </Overlay>
  ) : null;
}
