import React from 'react';
import SafeAreaView from 'react-native-safe-area-view';
import { Text, View, StyleSheet, ScrollView } from 'react-native';

import { useIntl } from '@utils/intl';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Loader } from '@views/shared/Loader';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { globalStyles, fonts, spacings, colors } from '@views/shared/styles';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import Day from './Day';
import { useBookings } from './hooks';

const { smallMedium, verySmall } = spacings;

export const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
  header: {
    flexDirection: 'row',
    paddingVertical: smallMedium,
    paddingHorizontal: verySmall,
    justifyContent: 'space-between',
  },
});

export default function MyBookings() {
  const { t } = useIntl();
  const enabledHourlyBooking = useHasFeature('hourly_booking');
  const isDesktop = useIsDesktop();

  const {
    mapping,
    weekDays,
    loading,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    refreshBookings,
  } = useBookings();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SafeAreaView style={{ backgroundColor: colors.white }}>
        <View style={styles.header}>
          <CustomIcon name="arrow-left" onPress={moveToPreviousWeek} />
          <Text style={styles.title}>{t('views.Bookings.title')}</Text>
          <CustomIcon name="arrow-right" onPress={moveToNextWeek} />
        </View>
      </SafeAreaView>
      <ScrollView
        style={[
          globalStyles.container,
          { paddingTop: 0 },
          isDesktop && { paddingHorizontal: 0 },
        ]}
      >
        <View style={isDesktop ? { flexDirection: 'row' } : {}}>
          {weekDays.map((date, idx) => {
            const key = universalDateFormatter({
              date,
              format: dateFormatShort,
            });
            const bookings = mapping[key] ?? [];
            return (
              <Day
                bookings={bookings}
                date={date}
                enabledHourlyBooking={enabledHourlyBooking}
                key={key}
                refreshBookings={refreshBookings}
                last={idx === weekDays.length - 1}
                numberOfDays={weekDays.length}
              />
            );
          })}
        </View>
      </ScrollView>
    </>
  );
}
