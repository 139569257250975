import React from 'react';
import { Text } from 'react-native';

import { useIntl } from '@utils/intl';
import { globalStyles } from '@views/shared/styles';
import {
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_FAST_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_VIRTUAL_DESK,
  BOOKING_TYPE_VACATION,
  BOOKING_TYPE_SICKDAY,
  BOOKING_TYPE_TELEFON_BOX,
} from '@views/shared/consts';
import { BookingType } from '@views/shared/interfaces/booking';

export default function TitleByType({ type }: { type: BookingType }) {
  const { t } = useIntl();
  const defaultTitle = 'Home.ActiveBookings.Booking.title.free_spaces_room';

  const titles = {
    [BOOKING_TYPE_HOME]: 'Home.ActiveBookings.Booking.title.home',
    [BOOKING_TYPE_MEETING_ROOM]:
      'Home.ActiveBookings.Booking.title.meeting_room',
    [BOOKING_TYPE_TRAVEL_DAY]: 'Home.ActiveBookings.Booking.title.travelDay',
    [BOOKING_TYPE_PARKING_CHARGING]:
      'Home.ActiveBookings.Booking.title.parkingSpot',
    [BOOKING_TYPE_PARKING_FAST_CHARGING]:
      'Home.ActiveBookings.Booking.title.parkingSpot',
    [BOOKING_TYPE_PARKING_GENERAL]:
      'Home.Structure.Floor.Area.Parkings.Spot.empty_label',
    [BOOKING_TYPE_PARKING_HANDICAPPED]:
      'Home.ActiveBookings.Booking.title.parkingSpot',
    [BOOKING_TYPE_ROOM]: 'Home.Structure.Floor.Area.Desks.Desk.empty_label',
    [BOOKING_TYPE_VIRTUAL_DESK]: 'Home.ActiveBookings.Booking.title.room',
    [BOOKING_TYPE_VACATION]: 'Home.ActiveBookings.Booking.title.vacation',
    [BOOKING_TYPE_SICKDAY]: 'Home.ActiveBookings.Booking.title.sickday',
    [BOOKING_TYPE_TELEFON_BOX]: 'Home.ActiveBookings.Booking.title.telefonBox',
  };

  return (
    <Text style={globalStyles.title}>{t(titles[type] || defaultTitle)}</Text>
  );
}
