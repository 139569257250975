import React from 'react';
import { ScrollView, Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton, SecondaryButton } from '@views/shared/Button';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Loader } from '@views/shared/Loader';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { globalStyles } from '@views/shared/styles';

import Equipment from './ressources/equipment';
import Floors from './ressources/floors';
import Time from './ressources/time';
import Parkings from './ressources/parkings';

import { useOptions } from './hooks';

import { styles } from './styles';

export default function Filter() {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();

  const {
    applyFilter,
    equipment,
    floors,
    goBack,
    isFilterUsed,
    isFloorplan,
    isMeetingRoom,
    loading,
    onEquipmentSelect,
    onFloorSelect,
    onParkingTypeSelect,
    onTimeSelect,
    parkingTypes,
    resetFilter,
    selectedEquipment,
    selectedFloors,
    selectedParkingTypes,
    selectedTime,
    timeSlots,
    type,
  } = useOptions();

  const FilterComponent = (
    <>
      <ScrollView style={[styles.scrollArea, isDesktop && { flexGrow: 0 }]}>
        {floors.length ? (
          <Floors
            isFloorplan={isFloorplan}
            items={floors}
            onSelect={onFloorSelect}
            selected={selectedFloors}
          />
        ) : null}
        {timeSlots.length ? (
          <Time
            isMeetingRoom={isMeetingRoom}
            items={timeSlots}
            onSelect={onTimeSelect}
            selected={selectedTime}
          />
        ) : null}
        {equipment.length ? (
          <Equipment
            items={equipment}
            onSelect={onEquipmentSelect}
            selected={selectedEquipment}
          />
        ) : null}
        {parkingTypes.length ? (
          <Parkings
            items={parkingTypes}
            onSelect={onParkingTypeSelect}
            selected={selectedParkingTypes}
          />
        ) : null}
      </ScrollView>

      <View
        style={[styles.buttonContainer, isDesktop && { position: 'relative' }]}
      >
        <SecondaryButton
          disabled={!isFilterUsed}
          onPress={resetFilter}
          title={t('Filter.Button.Cancel')}
        />

        <MainButton
          disabled={!isFilterUsed}
          onPress={applyFilter}
          title={t('Filter.Button.Apply')}
        />
      </View>
    </>
  );

  return (
    <View
      style={[
        globalStyles.container,
        styles.optionsContainer,
        isDesktop && { maxWidth: 924, marginHorizontal: 'auto' },
      ]}
    >
      <View style={styles.header}>
        <CustomIcon
          name="arrow-left"
          onPress={goBack}
          size={30}
          style={styles.close}
        />

        <View style={styles.titleWrapper}>
          <Text style={globalStyles.title}>{t('Filter.Headline.Title')}</Text>
          <Text style={globalStyles.description}>
            {t(`Home.Structure.Title.${type}`)}
          </Text>
        </View>
      </View>

      {loading ? <Loader /> : FilterComponent}
    </View>
  );
}
