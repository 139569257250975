import React, { useCallback, useState } from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import { pluck } from 'ramda';

import { transformEquipment } from '@views/shared/Equipment';
import { CustomIcon } from '@views/shared/CustomIcon';
import { useIntl } from '@utils/intl';

import Item from '@views/Book/Filter/ressources/item';
import { styles } from '@views/Book/Filter/styles';
import {
  EquipmentProps,
  EquipmentStuffProps,
  ToggleData,
} from '@views/Book/Filter/interfaces';

export default function Equipment({
  items,
  onSelect,
  selected,
}: EquipmentProps) {
  const data = transformEquipment(items);

  return (
    <View>
      {data.map(({ category, stuff }, idx) => (
        <Stuff
          category={category}
          key={idx}
          onSelect={onSelect}
          selected={selected}
          stuff={stuff}
        />
      ))}
    </View>
  );
}

function Stuff({ category, onSelect, selected, stuff }: EquipmentStuffProps) {
  const stuffIds = pluck('id', stuff);
  const isSomeItemSelected = selected.some((id) => stuffIds.includes(id));

  const { itemsCount, More, showAll, Toggle } = useToggle(isSomeItemSelected);
  const showToggle = stuff.length > itemsCount;

  return (
    <View style={styles.container}>
      <View style={styles.headline}>
        <View>
          <Text style={styles.title}>{category}</Text>
          {isSomeItemSelected ? <View style={styles.badge} /> : null}
        </View>
        {showToggle ? Toggle : null}
      </View>
      <View style={styles.itemsContainer}>
        {stuff.map(({ name, id }, idx) => (
          <Item
            isActive={selected.includes(id)}
            isHidden={idx + 1 > itemsCount && !showAll}
            key={idx}
            onPress={onSelect}
            text={name}
            value={id}
          />
        ))}
        {showToggle && !showAll ? More : null}
      </View>
    </View>
  );
}

export function useToggle(isOpen): ToggleData {
  const { t } = useIntl();
  const [showAll, setShowAll] = useState<boolean>(isOpen);
  const toggleMore = useCallback(() => setShowAll((prevState) => !prevState), [
    setShowAll,
  ]);

  const Toggle = (
    <TouchableHighlight underlayColor="transparent" onPress={toggleMore}>
      <View style={styles.showMore}>
        <Text style={styles.showMoreText}>
          {t(
            showAll
              ? 'Home.Structure.Floor.Area.Desks.ShowLess'
              : 'Home.Structure.Floor.Area.Desks.ShowMore',
          )}
        </Text>
        <CustomIcon
          name={showAll ? 'arrow-up' : 'arrow-down'}
          size={15}
          style={styles.showMoreIcon}
        />
      </View>
    </TouchableHighlight>
  );

  const More = (
    <Text style={styles.item} onPress={toggleMore}>
      . . .
    </Text>
  );

  return {
    itemsCount: 4, // how many items are visible on the begin
    More,
    showAll,
    Toggle,
  };
}
