import React from 'react';
import { Text, View, StyleSheet, Platform } from 'react-native';

import { Accordion } from '@views/shared/Accordion';
import { Switch } from '@views/shared/Switch';
import { Loader } from '@views/shared/Loader';

import { useIntl } from '@utils/intl';
import { spacings, colors, globalStyles } from '@views/shared/styles';

import { useToggles } from '@views/Home/Profile/Overview/Toggles/hooks';

const { white, blackAlpha } = colors;
const { extraSmall, smallMedium, medium } = spacings;

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    padding: smallMedium,
    borderRadius: extraSmall,
    backgroundColor: white,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toggleWrapper: {
    borderColor: blackAlpha,
    borderTopWidth: 1,
    borderWidth: 0,
    padding: smallMedium,
    alignItems: 'center',
  },
  wrapper: {
    marginBottom: medium,
    backgroundColor: white,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  content: {
    flex: 1,
  },
});

export default function Toggles() {
  const { t } = useIntl();
  const {
    colleagues,
    toggleColleagues,
    calendar,
    toggleCalendar,
    homeOffice,
    toggleHomeOffice,
    privateMode,
    togglePrivateMode,
    loading,
    error,
    enablePushNotifications,
    togglePushNotifications,
  } = useToggles();

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  const title = (
    <Text style={globalStyles.title}>{t('Profile.Content.Toggles.title')}</Text>
  );

  return (
    <View style={[globalStyles.shadow, styles.wrapper]}>
      <Accordion open={true} headerStyle={styles.row} title={title}>
        {Platform.OS !== 'web' ? (
          <View style={styles.toggleWrapper}>
            <Switch
              labelStyle={globalStyles.title}
              label={t('Profile.Content.Toggles.PushNotifications')}
              secondLabelStyle={globalStyles.description}
              secondLabel={t(
                'Profile.Content.Toggles.PushNotifications.description',
              )}
              isActive={enablePushNotifications}
              onToggle={togglePushNotifications}
            />
          </View>
        ) : null}

        <View style={styles.toggleWrapper}>
          <Switch
            labelStyle={globalStyles.title}
            label={t('Profile.Content.Toggles.colleague')}
            secondLabelStyle={globalStyles.description}
            secondLabel={t('Profile.Content.Toggles.colleague.description')}
            isActive={colleagues}
            onToggle={toggleColleagues}
          />
        </View>

        <View style={styles.toggleWrapper}>
          <Switch
            labelStyle={globalStyles.title}
            label={t('Profile.Content.Toggles.calendar')}
            secondLabelStyle={globalStyles.description}
            secondLabel={t('Profile.Content.Toggles.calendar.description')}
            isActive={calendar}
            onToggle={toggleCalendar}
          />
        </View>

        {calendar ? (
          <View style={styles.toggleWrapper}>
            <Switch
              labelStyle={globalStyles.title}
              label={t('Profile.Content.Toggles.privateMode')}
              secondLabelStyle={globalStyles.description}
              secondLabel={t('Profile.Content.Toggles.privateMode.description')}
              isActive={!privateMode}
              onToggle={togglePrivateMode}
            />
          </View>
        ) : null}

        <View style={styles.toggleWrapper}>
          <Switch
            labelStyle={globalStyles.title}
            label={t('Profile.Content.Toggles.homeoffice')}
            secondLabelStyle={globalStyles.description}
            secondLabel={t('Profile.Content.Toggles.homeoffice.description')}
            isActive={homeOffice}
            onToggle={toggleHomeOffice}
          />
        </View>
      </Accordion>
    </View>
  );
}
