import React from 'react';
import { ScrollView } from 'react-native';
import { Calendar } from 'react-native-calendars';
import { isNil } from 'ramda';

import { Overlay } from '@views/shared/Overlay';
import { CustomIcon } from '@views/shared/CustomIcon';
import { MainButton } from '@views/shared/Button';

import { getTodayAsDate } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';

import { LoadCalenderConfig, styles, theme, getPeriod } from './helper';

export default function CalendarPickerRange({ close, onDayChange, dateRange }) {
  const { t } = useIntl();

  LoadCalenderConfig(); //localize calendar names for months and days

  return (
    <Overlay
      onClose={close}
      contentStyle={styles.overlay}
      title={t('Calendar.Select.DateRange')}
    >
      <ScrollView>
        <Calendar
          firstDay={1}
          onDayPress={onDayChange}
          markedDates={getPeriod(dateRange)}
          markingType="period"
          minDate={getTodayAsDate()}
          renderArrow={direction => (
            <CustomIcon name={`arrow-${direction}`} size={18} />
          )}
          theme={theme}
        />

        <MainButton
          disabled={isNil(dateRange.start)}
          onPress={close}
          title={t('Filter.BookingTime.Overlay.Button.Apply')}
          buttonStyle={styles.apply}
        />
      </ScrollView>
    </Overlay>
  );
}
