import { useRoute } from '@react-navigation/native';
import { isNil } from 'ramda';

import { useIntl } from '@utils/intl';
import { BookingIcon } from '@views/shared/interfaces/booking';
import { WorklightStatus } from '@views/shared/interfaces/buildingStructure';
import {
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_PARKING,
  AREA_TYPE_FREE_SPACES_ROOM,
  getBookingIcon,
  AREA_TYPE_ROOM,
  getBookCategoryByAreaType,
  AREA_TYPE_TELEFON_BOX,
} from '@views/shared/consts';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';

import { getDeskSlots } from '@views/Book/Bookings/Desks/hooks';
import { getFloorInfo, getFloorName } from '@utils/Building';

import {
  AreaType,
  FloorStructureForDesks,
  FloorStructureForMeetingRooms,
  FloorStructureForFreeSeats,
  FloorStructureForParkingSpots,
  FloorStructureForTelefonBox,
} from '@views/shared/interfaces/buildingStructure';

const useDesksFloor = (floor: FloorStructureForDesks): WorklightStatus => {
  const free = getDeskSlots(floor, 'freeSlots', 0);
  const total = getDeskSlots(floor, 'totalSlots', 1);

  return getWorklightStatus(free, total);
};

const useMeetingFloor = (
  floor: FloorStructureForMeetingRooms,
): WorklightStatus => {
  const free =
    (floor.meetingRoomTimeStats?.total ?? 0) -
    (floor.meetingRoomTimeStats?.booked ?? 0);
  const total = floor.meetingRoomTimeStats?.total ?? 0;
  return getWorklightStatus(free, total);
};

const useTelefonBox = (floor: FloorStructureForTelefonBox): WorklightStatus => {
  const free =
    (floor.telefonBoxTimeStats?.total ?? 0) -
    (floor.telefonBoxTimeStats?.booked ?? 0);
  const total = floor.telefonBoxTimeStats?.total ?? 0;
  return getWorklightStatus(free, total);
};

const useSeatsFloor = (floor: FloorStructureForFreeSeats): WorklightStatus => {
  const free = getDeskSlots(floor, 'freeSlots', 0, AREA_TYPE_FREE_SPACES_ROOM);
  const total = getDeskSlots(
    floor,
    'totalSlots',
    1,
    AREA_TYPE_FREE_SPACES_ROOM,
  );

  return getWorklightStatus(free, total);
};

const useParkingFloor = (): WorklightStatus => 'none';

const useFloorByType = {
  [AREA_TYPE_ROOM]: useDesksFloor,
  [AREA_TYPE_MEETING_ROOM]: useMeetingFloor,
  [AREA_TYPE_FREE_SPACES_ROOM]: useSeatsFloor,
  [AREA_TYPE_PARKING]: useParkingFloor,
  [AREA_TYPE_TELEFON_BOX]: useTelefonBox,
};

export function useFloorWithDesks(floor: FloorStructureForDesks) {
  return useFloor(floor, AREA_TYPE_ROOM);
}

export function useFloorWithMeetingRooms(floor: FloorStructureForMeetingRooms) {
  return useFloor(floor, AREA_TYPE_MEETING_ROOM);
}

export function useFloorWithTelefonBox(floor: FloorStructureForTelefonBox) {
  return useFloor(floor, AREA_TYPE_TELEFON_BOX);
}

export function useFloorWithFreeSeats(floor: FloorStructureForFreeSeats) {
  return useFloor(floor, AREA_TYPE_FREE_SPACES_ROOM);
}

export function useFloorWithParkingSpots(floor: FloorStructureForParkingSpots) {
  return useFloor(floor, AREA_TYPE_PARKING);
}

type Props = {
  bookingIcon: BookingIcon;
  open: boolean;
  worklight: WorklightStatus;
  title: string;
  type: AreaType;
  floor: any;
};

export function useFloor(floor, type: AreaType): Props {
  const route = useRoute<any>();
  const { t } = useIntl();

  const title = getFloorName({
    t,
    ...getFloorInfo(floor),
  });
  const areaId = route.params?.areaId;
  const bookingIcon = getBookingIcon(getBookCategoryByAreaType(type));
  const worklight = useFloorByType[type](floor);

  const selectedArea = floor.areas.find(({ id }) => id === areaId);

  return {
    bookingIcon,
    open: !isNil(selectedArea) || floor.isOpen,
    worklight,
    title,
    type,
    floor,
  };
}
