import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import { MAP_PRESENCE_STATUS_ICON } from '@views/shared/consts';
import { Colleague, ColleagueLight } from '@views/Colleagues/List/interfaces';

export enum STATUS {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

export const DEFAULT_ICON = 'warning';

export const badgeStatus = {
  UNKNOWN: STATUS.WARNING,
  AVAILABLE: STATUS.SUCCESS,
  OCCUPIED: STATUS.ERROR,
};

export const getInitials = name => {
  const arrInitials = name.replace(/[^a-zA-Z- ]/g, '').match(/\b\w/g) || [];
  return arrInitials.join('').toUpperCase();
};

export function useLightColleague(colleague: Colleague): ColleagueLight {
  const {
    name,
    id,
    email,
    userprofile: {
      avatar,
      phoneNumber,
      licensePlate,
      department,
      visibleHomeoffice,
      visibleForColleagues,
      visibleCalendar,
    },
    availabilityStatus: { presenceStatus, presenceTypes: status },
  } = colleague;

  const initials = getInitials(name);

  const icon =
    status.length > 0 ? status.map(item => MAP_PRESENCE_STATUS_ICON[item]) : [];
  const badgeColor = badgeStatus[presenceStatus];

  const navigation = useNavigation<any>();
  const goToProfile = useCallback(() => {
    navigation.navigate('colleagues', {
      screen: 'profile',
      params: {
        id: colleague.id,
        colleague,
      },
      initial: false,
    });
  }, [navigation, colleague]);

  return {
    id,
    name,
    avatar,
    department,
    email,
    phoneNumber,
    licensePlate,
    visibleHomeoffice,
    visibleForColleagues,
    visibleCalendar,
    presenceStatus,
    goToProfile,
    icon,
    initials,
    status,
    badgeColor,
  };
}
