import React from 'react';
import { Text, TouchableHighlight, View } from 'react-native';

import { useIntl } from '@utils/intl';
import BasicBook from '@views/Book/Book/BasicBook';

import CalendarPickerRange from '@views/shared/CalendarPicker/range';
import { useCalendarPicker } from '@views/shared/CalendarPicker/hooks';
import { CustomIcon } from '@views/shared/CustomIcon';

import { colors } from '@views/shared/styles';
import { styles } from '@views/Book/Book/Vacation';

import useValues from './hooks';
import Booking from './Booking';

export default function BookSickDay() {
  const { t, formats } = useIntl();

  const {
    booking,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    currentDate,
    type,
    onDayChange,
    dateRange,
  } = useValues(formats);

  const { open: openCalendar, isOpen, close } = useCalendarPicker();

  const DateSelector = (
    <View style={styles.container}>
      <Text style={styles.description}>
        {t('Home.Book.Sickday.Period.Change')}
      </Text>

      <TouchableHighlight
        onPress={openCalendar}
        underlayColor={colors.aquaMarineLight}
        style={styles.touchable}
      >
        <View style={styles.touchableContainer}>
          <CustomIcon
            name="calendar-no"
            size={26}
            style={styles.touchableIcon}
          />
          <Text style={styles.touchableText}>{t('Calendar.Open')}</Text>
        </View>
      </TouchableHighlight>

      {isOpen ? (
        <CalendarPickerRange
          close={close}
          dateRange={dateRange}
          onDayChange={onDayChange}
        />
      ) : null}
    </View>
  );

  return (
    <BasicBook
      booking={booking}
      date={date}
      type={type}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
      dateRange={dateRange}
    >
      <>
        {DateSelector}
        <Booking
          type={type}
          date={currentDate}
          enabledHalfDayBooking={enabledHalfDayBooking}
          enabledHourlyBooking={enabledHourlyBooking}
          hasNextDaysBooking={hasNextDaysBooking}
          onDayChange={onDayChange}
          dateRange={dateRange}
        />
      </>
    </BasicBook>
  );
}
