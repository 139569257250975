import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Avatar } from 'react-native-elements';

import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';
import { avatarWithBadgeStyle } from '@views/shared/styles';
import { fonts, spacings } from '@views/shared/styles';

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  title: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
    marginTop: spacings.smallMedium,
  },
});
// just a wrapper for desktop view
export default function MyProfile() {
  const { avatar, initials } = useMyUser();
  const { backgroundColor, isWhiteBackground } = useMyOrganization();

  return (
    <View style={styles.container}>
      <Avatar
        rounded
        size={70}
        source={avatar && avatar.trim().length ? { uri: avatar } : undefined}
        title={initials}
        titleStyle={[avatarWithBadgeStyle.title]}
        placeholderStyle={[avatarWithBadgeStyle.placeholder]}
        overlayContainerStyle={[avatarWithBadgeStyle.avatarOverlayContainer]}
        containerStyle={[
          avatarWithBadgeStyle.availableAvatarContainer,
          { borderColor: backgroundColor },
          isWhiteBackground && avatarWithBadgeStyle.spaceBorder,
        ]}
      />
    </View>
  );
}
