import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';

import { globalStyles } from '@views/shared/styles';
import { styles } from '@views/Bookings/MyBookings/Day/Ticket/Booking';
import { DateDisplayForBookingsTab } from '@views/Bookings/MyAttendances/AttendanceWeek/DailyAttendance';

export function Empty({ date, isToday, isDesktop }) {
  const { t } = useIntl();

  return (
    <View style={isDesktop ? { alignItems: 'center' } : styles.row}>
      <DateDisplayForBookingsTab
        isDesktop={isDesktop}
        date={date}
        isToday={isToday}
      />
      <View
        style={
          isDesktop
            ? [styles.noBookingContainer, globalStyles.shadow, { width: '92%' }]
            : styles.right
        }
      >
        <Text style={globalStyles.title}>{t('Colleagues.Day.no.book')}</Text>
      </View>
    </View>
  );
}
