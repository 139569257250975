import React, { useState, useCallback } from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';

import { AreaHeader, styles } from '@views/shared/Structure';
import { Equipment, Touchable } from '@views/shared/Equipment';

import { useBookable } from './hooks';
import {
  AreaStructureForMeetingRooms,
  WorklightStatus,
} from '@views/shared/interfaces/buildingStructure';

interface Props {
  area: AreaStructureForMeetingRooms;
  notAvailable?: boolean;
  worklight: WorklightStatus;
}

export function MeetingRoom({ area, notAvailable, worklight }: Props) {
  const { t } = useIntl();
  const { onBook } = useBookable(area);
  const [showEquipment, setShowEquipment] = useState(false);
  const toggleEquipment = useCallback(
    () => setShowEquipment(prevState => !prevState),
    [setShowEquipment],
  );

  const { equipment, isForbidden } = area;

  return (
    <View style={styles.container}>
      <AreaHeader area={area} worklight={worklight} />
      <View style={styles.headerArea}>
        <View style={styles.header}>
          <View style={styles.buttonContainer}>
            {notAvailable || isForbidden ? (
              <Text style={styles.noFree}>
                {t('Home.Structure.MeetingRoom.NotAvailable')}
              </Text>
            ) : (
              <MainButton
                onPress={() => onBook()}
                title={t('Home.Structure.book')}
              />
            )}
          </View>
          <Touchable
            hasEquipment={!!equipment.length}
            onPress={toggleEquipment}
            show={showEquipment}
          />
          <View />
        </View>
        {showEquipment ? <Equipment equipment={equipment} /> : null}
      </View>
    </View>
  );
}
