import React from 'react';
import { TouchableHighlight, Text, View } from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';

import { styles } from '@views/Book/Filter/styles';
import { ItemProps } from '@views/Book/Filter/interfaces';

export default function Item({
  icon,
  isActive,
  isDisabled,
  isHidden,
  onPress,
  style,
  text,
  value,
}: ItemProps) {
  return (
    <TouchableHighlight
      disabled={isDisabled}
      onPress={() => onPress(value, isActive)}
      underlayColor="transparent"
      style={style}
    >
      <View
        style={[
          styles.item,
          isActive && styles.activeItem,
          isDisabled && styles.disabledItem,
          isHidden && styles.hiddenItem,
        ]}
      >
        {icon ? <CustomIcon name={icon} size={20} style={styles.icon} /> : null}
        <Text
          numberOfLines={1}
          style={[styles.itemText, isDisabled && styles.disabledItem]}
        >
          {text}
        </Text>
      </View>
    </TouchableHighlight>
  );
}
