import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { isNil } from 'ramda';

import { useIntl } from '@utils/intl';
import { spacings, fonts } from '@views/shared/styles';
import Selection from '@views/shared/TimeSlots/Selection';
import { SELECTION_OPTIONS } from '@views/shared/TimeSlots/helper';
import { SelectionType } from '@views/shared/TimeSlots/interfaces';

const DEFAULT_CONTAINER_WIDTH = 350;
const DEFAULT_SELECTION_WIDTH = 110;
const DEFAULT_SELECTION_SPACE = 20;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: spacings.medium,
  },
  shortCutSelectionContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingTop: 35,
    alignSelf: 'center',
  },
  description: {
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeSemiSmall,
    marginTop: spacings.huge,
  },
});

const isAfterLunchSelected = (state: SelectionType) =>
  state === SELECTION_OPTIONS.AFTER_LUNCH;
const isBeforeLunchSelected = (state: SelectionType) =>
  state === SELECTION_OPTIONS.BEFORE_LUNCH;
const isFullDaySelected = (state: SelectionType) =>
  state === SELECTION_OPTIONS.FULL_DAY;

export default function HalfDaySelection({
  bookablePeriod,
  onSelect,
  midDayHour,
  dateRange,
}) {
  const { t } = useIntl();

  const [selectedShortCut, setSelectedShortCut] = useState(
    SELECTION_OPTIONS.FULL_DAY,
  );

  const [halfDayDisabled, setHalfDayDisabled] = useState(false);
  const [endDate, setEndDate] = useState(null);

  const [containerWidth, setContainerWidth] = useState(DEFAULT_CONTAINER_WIDTH);
  const [selectionWidth, setSelectionWidth] = useState(DEFAULT_SELECTION_WIDTH);

  const setSelection = useCallback(type => setSelectedShortCut(type), [
    setSelectedShortCut,
  ]);

  const onPageLayout = useCallback(
    event => {
      const { width } = event.nativeEvent.layout;

      const newWidth =
        width > DEFAULT_CONTAINER_WIDTH ? DEFAULT_CONTAINER_WIDTH : width;
      const itemWidth = Math.round((newWidth - DEFAULT_SELECTION_SPACE) / 3);
      setContainerWidth(Math.round(newWidth));
      setSelectionWidth(Math.round(itemWidth));
    },
    [setContainerWidth, setSelectionWidth],
  );

  useEffect(() => {
    if (dateRange && !isNil(dateRange.end)) {
      setHalfDayDisabled(true);
      setEndDate(dateRange.end);
      setSelection(SELECTION_OPTIONS.FULL_DAY);
    } else if (
      dateRange &&
      isNil(dateRange.end) &&
      dateRange.end !== endDate
    ) {
      setHalfDayDisabled(false);
      setSelection(SELECTION_OPTIONS.FULL_DAY);
    }
  }, [dateRange, endDate, setSelection]);

  useEffect(() => onSelect(selectedShortCut), [onSelect, selectedShortCut]);

  return (
    <View onLayout={onPageLayout}>
      <View
        style={[styles.shortCutSelectionContainer, { width: containerWidth }]}
      >
        <Selection
          from={bookablePeriod.start}
          isDisabled={halfDayDisabled}
          isSelected={isBeforeLunchSelected(selectedShortCut)}
          onPress={() => setSelection(SELECTION_OPTIONS.BEFORE_LUNCH)}
          selection={SELECTION_OPTIONS.BEFORE_LUNCH}
          to={midDayHour}
          width={selectionWidth}
        />
        <Selection
          from={bookablePeriod.start}
          isDisabled={false}
          isSelected={isFullDaySelected(selectedShortCut)}
          onPress={() => setSelection(SELECTION_OPTIONS.FULL_DAY)}
          selection={SELECTION_OPTIONS.FULL_DAY}
          to={bookablePeriod.end}
          width={selectionWidth}
        />
        <Selection
          from={midDayHour}
          isDisabled={halfDayDisabled}
          isSelected={isAfterLunchSelected(selectedShortCut)}
          onPress={() => setSelection(SELECTION_OPTIONS.AFTER_LUNCH)}
          selection={SELECTION_OPTIONS.AFTER_LUNCH}
          to={bookablePeriod.end}
          width={selectionWidth}
        />
      </View>
      {halfDayDisabled && (
        <View>
          <Text style={styles.description}>
            {t('Home.Book.Sickday.HalfDayDisabled')}
          </Text>
        </View>
      )}
    </View>
  );
}
