import { defineMessages } from 'react-intl';

const dictionary = defineMessages({
  'Colleagues.Content.Find.text': {
    id: 'Colleagues.Content.Find.text',
    defaultMessage: 'Find my colleague',
  },
  'Colleagues.Content.Find.status.free': {
    id: 'Colleagues.Content.Find.status.free',
    defaultMessage: 'Free from {start} to {end}',
  },
  'Colleagues.Content.Find.status.free.all': {
    id: 'Colleagues.Content.Find.status.freeall',
    defaultMessage: 'Free all the day',
  },
  'Colleagues.Content.Find.status.busy': {
    id: 'Colleagues.Content.Find.status.busy',
    defaultMessage: 'Busy from {start} to {end}',
  },
  'Colleagues.Content.Find.status.travel': {
    id: 'Colleagues.Content.Find.status.travel',
    defaultMessage: 'On a Travel/Holiday',
  },
  'Colleagues.Content.Find.status.absent': {
    id: 'Colleagues.Content.Find.status.absent',
    defaultMessage: 'Away / Not booked',
  },
  'Colleagues.Content.Book.text': {
    id: 'Colleagues.Content.Book.text',
    defaultMessage: 'Book same room',
  },
  'Colleagues.error.no-data': {
    id: 'Colleagues.error.no-data',
    defaultMessage: 'No data available',
  },
  'Colleagues.Content.Find.home_office': {
    id: 'Colleagues.Content.Find.home_office',
    defaultMessage: 'Home Office',
  },
  'Colleagues.Content.Find.building': {
    id: 'Colleagues.Content.Find.building',
    defaultMessage: 'Building',
  },
  'Colleagues.Content.Find.address': {
    id: 'Colleagues.Content.Find.address',
    defaultMessage: 'Address',
  },
  'Colleagues.Content.Find.floor': {
    id: 'Colleagues.Content.Find.floor',
    defaultMessage: 'Floor',
  },
  'Colleagues.Content.Find.area': {
    id: 'Colleagues.Content.Find.area',
    defaultMessage: 'Area',
  },
  'Colleagues.Content.Find.desk': {
    id: 'Colleagues.Content.Find.desk',
    defaultMessage: 'Desk',
  },
  'Colleagues.Content.Find.available_desks': {
    id: 'Colleagues.Content.Find.available_desk',
    defaultMessage: 'Available desks',
  },
  'Colleagues.List.Screen.departments': {
    id: 'Colleagues.List.Screen.departments',
    defaultMessage: 'Departments',
  },
  'Colleagues.List.Screen.whosin': {
    id: 'Colleagues.List.Screen.whosin',
    defaultMessage: 'Who\'s in',
  },
  'Colleagues.List.Screen.alphabetical': {
    id: 'Colleagues.List.Screen.alphabetical',
    defaultMessage: 'Alphabetical',
  },
  'Colleagues.List.Screen.Favourites': {
    id: 'Colleagues.List.Screen.Favourites',
    defaultMessage: 'Favourites',
  },
  'Colleagues.List.no_department': {
    id: 'Colleagues.List.no_department',
    defaultMessage: 'No Department assigned',
  },
  'Colleagues.List.Screen.status.free': {
    id: 'Colleagues.List.Screen.status.free',
    defaultMessage: 'Free',
  },
  'Colleagues.List.Screen.status.busy': {
    id: 'Colleagues.List.Screen.status.busy',
    defaultMessage: 'Busy',
  },
  'Colleagues.Favourites.EmptyList': {
    id: 'Colleagues.Favourites.EmptyList',
    defaultMessage:
      'You don’t have any favourites yet. Add some coworkers as favourites to see their weekly schedule and book a desk nearby',
  },
  'Colleagues.reload_failed': {
    id: 'Colleagues.reload_failed',
    defaultMessage: 'Go back to list of colleagues',
  },
  'Colleague.Screen.overview': {
    id: 'Colleague.Screen.overview',
    defaultMessage: 'Overview',
  },
  'Colleague.Screen.bookings': {
    id: 'Colleague.Screen.bookings',
    defaultMessage: 'Bookings',
  },
  'Colleagues.Events.no_events_today': {
    id: 'Colleagues.Events.no_events_today',
    defaultMessage: 'No events today',
  },
  'views.Colleagues.Bookings.title': {
    id: 'views.Colleagues.Bookings.title',
    defaultMessage: 'My Colleagues Bookings',
  },
  'Colleagues.Events.Title': {
    id: 'Colleagues.Events.Title',
    defaultMessage: 'My Colleagues events',
  },
  'Colleagues.Day.no.book': {
    id: 'Colleagues.Day.no.book',
    defaultMessage: 'No Booking yet',
  },
  'Colleagues.List.Colleagues': {
    id: 'Colleagues.List.Colleagues',
    defaultMessage: 'Colleagues',
  },
  'Colleagues.List.Screen.Office': {
    id: 'Colleagues.List.Screen.Office',
    defaultMessage: 'Office',
  },
  'Colleagues.List.Screen.Home_office': {
    id: 'Colleagues.List.Screen.Home_office',
    defaultMessage: 'Home Office',
  },
  'Colleagues.List.Screen.Travel_day': {
    id: 'Colleagues.List.Screen.Travel_day',
    defaultMessage: 'Travel Day',
  },
  'Colleagues.WhosIn.EmptyList.inOffice': {
    id: 'Colleagues.WhosIn.EmptyList.inOffice',
    defaultMessage:
      'There are currenlty no colleagues who have booked Office',
  },
  'Colleagues.WhosIn.EmptyList.homeOffice': {
    id: 'Colleagues.WhosIn.EmptyList.homeOffice',
    defaultMessage:
      'There are currenlty no colleagues who have booked Home Office',
  },
  'Colleagues.WhosIn.EmptyList.travelDay': {
    id: 'Colleagues.WhosIn.EmptyList.travelDay',
    defaultMessage:
      'There are currenlty no colleagues who have booked Travel Day',
  },
  'Colleagues.Hooks.QueryError': {
    id: 'Colleagues.Hooks.QueryError',
    defaultMessage: 'Failed to fetch list',
  },
});

export default {
  ...dictionary,
};
