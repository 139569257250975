import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Routes.tabs.home': {
    id: 'Routes.tabs.home',
    defaultMessage: 'Home',
  },
  'Routes.tabs.bookings': {
    id: 'Routes.tabs.bookings',
    defaultMessage: 'Bookings',
  },
  'Routes.tabs.floorplan': {
    id: 'Routes.tabs.floorplan',
    defaultMessage: 'Floorplan',
  },
  'Routes.tabs.colleagues': {
    id: 'Routes.tabs.colleagues',
    defaultMessage: 'Colleagues',
  },
  'Routes.tabs.login': {
    id: 'Routes.tabs.login',
    defaultMessage: 'Login',
  },
  'Routes.tabs.profile': {
    id: 'Routes.tabs.profile',
    defaultMessage: 'Profile',
  },
  'Routes.Home.Structure': {
    id: 'Routes.Home.Structure',
    defaultMessage: 'Browse...',
  },
  'Routes.Home.Book.BookDesk': {
    id: 'Routes.Home.Book.BookDesk',
    defaultMessage: 'Book a desk',
  },
  'Routes.Home.Book.FreeSpace': {
    id: 'Routes.Home.Book.FreeSpace',
    defaultMessage: 'Book a free space',
  },
  'Routes.Home.Book.MeetingRoom': {
    id: 'Routes.Home.Book.MeetingRoom',
    defaultMessage: 'Book a meeting room',
  },
  'Routes.Home.Book.TravelDay': {
    id: 'Routes.Home.Book.TravelDay',
    defaultMessage: 'Book a travel day',
  },
  'Routes.Home.Book.HomeOffice': {
    id: 'Routes.Home.Book.HomeOffice',
    defaultMessage: 'Book a home office',
  },
  'Routes.Home.Book.ParkingSpot': {
    id: 'Routes.Home.Book.ParkingSpot',
    defaultMessage: 'Book a parking spot',
  },
  'Routes.Home.Book.Confirmation': {
    id: 'Routes.Home.Book.Confirmation',
    defaultMessage: 'Booking confirmation',
  },
  'Routes.Home.HowWasYourDay': {
    id: 'Routes.Home.HowWasYourDay',
    defaultMessage: 'Vote how was your day',
  },
  'Routes.Home.Favourites': {
    id: 'Routes.Home.Favourites',
    defaultMessage: 'My favourites',
  },
  'Routes.Home.Filter': {
    id: 'Routes.Home.Filter',
    defaultMessage: 'My filter',
  },
  'Routes.Home.Book.Vacation': {
    id: 'Routes.Home.Book.Vacation',
    defaultMessage: 'Vacation',
  },
  'Routes.Home.Book.Sickday': {
    id: 'Routes.Home.Book.Sickday',
    defaultMessage: 'Sickday',
  },
  'Routes.Home.Book.TelefonBox': {
    id: 'Routes.Home.Book.TelefonBox',
    defaultMessage: 'Book a telefon box',
  },
  'Routes.tabs.book': {
    id: 'Routes.tabs.book',
    defaultMessage: 'Book',
  },
});

export default {
  ...dictionary,
};
