import React from 'react';
import { View } from 'react-native';

import { isToday } from '@utils/DateAndTime';
import { Booking } from '@views/shared/interfaces/booking';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import { globalStylesDesktop } from '@views/shared/styles';

import { Booking as BookingComponent } from './Booking';
import { Empty } from './Booking/empty';
import { styles } from '@views/Bookings/MyBookings/Day/Ticket/Booking';

import { DateDisplayForBookingsTab } from '@views/Bookings/MyAttendances/AttendanceWeek/DailyAttendance';

interface Props {
  bookings: Booking[];
  date: Date;
  last: boolean;
  numberOfDays: number;
}

export function Day({ date, bookings, last, numberOfDays }: Props) {
  const isTodayDate = isToday(date);
  const isDesktop = useIsDesktop();
  const widthForDesktop = `${100 / numberOfDays}%`;

  return (
    <View
      style={[
        styles.item,
        !isDesktop && last && styles.itemLast,
        isDesktop && [
          { width: widthForDesktop, borderTopWidth: 0 },
          globalStylesDesktop.desktopConWithBorder,
          last && { borderRightWidth: 0 },
        ],
      ]}
    >
      {bookings.length === 0 && !isTodayDate ? (
        <Empty date={date} isToday={isTodayDate} isDesktop={isDesktop} />
      ) : (
        <View style={isDesktop ? { alignItems: 'center' } : styles.row}>
          <DateDisplayForBookingsTab
            isDesktop={isDesktop}
            date={date}
            isToday={isTodayDate}
          />
          <View
            style={[
              styles.right,
              { paddingLeft: 0 },
              isDesktop && { width: '92%' },
            ]}
          >
            {bookings.map(booking => (
              <BookingComponent
                key={booking.id}
                booking={booking}
                date={date}
                isToday={isTodayDate}
              />
            ))}
          </View>
        </View>
      )}
    </View>
  );
}
