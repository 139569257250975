import React from 'react';
import { StyleSheet } from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';
import { colors } from '@views/shared/styles';

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    right: -35,
    bottom: -25,
  },
});

export default function QuestionIcon() {
  return (
    <CustomIcon
      color={colors.grey2}
      name="question"
      size={80}
      style={styles.icon}
    />
  );
}
