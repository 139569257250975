import React, { useContext, useEffect, useState, useCallback } from 'react';
import { gql } from '@apollo/client';
import { compose, isEmpty, isNil, map, prop, sortBy, toLower } from 'ramda';

import { useErrorQuery } from '@providers/Errors';
import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';

import { MyBuilding } from '@views/Home/Profile/Edit/interfaces';

interface MyActiveBookingsProviderProps {
  children: React.ReactElement;
}

export interface Building {
  id: string;
  name: string;
  image: string;
  address: string;
  cateringPreparationTime: number;
  equipmentPreparationTime: number;
}

export interface MyBookingsData {
  buildingsInfo: Building[];
}

interface MyBuildingsContext {
  buildings: Building[];
  error: any;
  loading: boolean;
  reorderBuildings: (id: string) => any;
  selectedBuilding: Building | undefined;
  selectedBuildingId: string | undefined;
  selectBuildingById: (id: string) => any;
}

const getBuildings = gql`
  query getBuildings {
    buildingsInfo {
      id
      name
      image
      address
      cateringPreparationTime
      equipmentPreparationTime
    }
  }
`;

const DEFAULT_CONTEXT_STATE = {
  buildings: [],
  error: undefined,
  loading: false,
  reorderBuildings: () => null,
  selectedBuildingId: undefined,
  selectedBuilding: undefined,
  selectBuildingById: () => null,
};

export const MyBuildingsContext = React.createContext<MyBuildingsContext>(
  DEFAULT_CONTEXT_STATE,
);

export const useMyBuildings = () => useContext(MyBuildingsContext);

export const getPreparationTime = (v: string): number =>
  +v.replace('PREP_', '').replace('A_', '');
/**
 * This function sort the list of buildings by name
 */
const getSortedBuildings = (
  data: Building[],
  myBuilding: MyBuilding,
  preparationTime: { catering: string; equipment: string },
) => {
  const { id: myBuildingId } = myBuilding;
  const sortedData = compose(
    sortBy(compose(toLower, prop('name'))),
    map((building: Building) => {
      const { cateringPreparationTime, equipmentPreparationTime } = building;

      const prepTimeCatering = isNil(cateringPreparationTime)
        ? preparationTime.catering
        : cateringPreparationTime;

      const prepTimeEquipment = isNil(equipmentPreparationTime)
        ? preparationTime.equipment
        : equipmentPreparationTime;

      return {
        ...building,
        cateringPreparationTime: getPreparationTime(prepTimeCatering),
        equipmentPreparationTime: getPreparationTime(prepTimeEquipment),
      };
    }),
  )(data);

  return isNil(myBuildingId)
    ? sortedData
    : getReorderedBuildings(sortedData, myBuildingId);
};

/**
 * This function puts the building that matches buildingId to the top of the list
 */
const getReorderedBuildings = (data: Building[], buildingId: string) =>
  data.reduce((acc: Building[], curr: Building) => {
    if (curr.id === buildingId) {
      return [curr, ...acc];
    }
    return [...acc, curr];
  }, []);

export const MyBuildingsProvider = ({
  children,
}: MyActiveBookingsProviderProps) => {
  const [buildings, setBuildings] = useState<Building[]>([]);
  const [selectedBuilding, setSelectedBuilding] = useState<Building>();
  const { myBuilding, id: userId, showActionsPage } = useMyUser();
  const { preparationTime } = useMyOrganization();

  const { data, error, loading } = useErrorQuery<MyBookingsData>(getBuildings, {
    skip:
      isEmpty(userId) ||
      !isEmpty(buildings) ||
      !isNil(selectedBuilding) ||
      showActionsPage,
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: 'Common.Buildings.fetchFail',
    },
  });

  const selectBuildingById = useCallback(
    (id: string) => {
      const building = buildings[id];
      setSelectedBuilding(building);
    },
    [buildings, setSelectedBuilding],
  );

  useEffect(() => {
    if (data) {
      const { buildingsInfo = [] } = data;

      const sortedBuildings = getSortedBuildings(
        buildingsInfo,
        myBuilding,
        preparationTime,
      );
      setBuildings(sortedBuildings);

      if (!selectedBuilding) {
        setSelectedBuilding(sortedBuildings[0]);
      }
    }
  }, [data, myBuilding, selectedBuilding, preparationTime]);

  const reorderBuildings = useCallback(
    (buildingId: string) => {
      const reorderedBuildings = getReorderedBuildings(buildings, buildingId);

      setBuildings(reorderedBuildings);
      setSelectedBuilding(reorderedBuildings[0]);
    },
    [buildings, setSelectedBuilding],
  );

  return (
    <MyBuildingsContext.Provider
      value={{
        error,
        loading,
        buildings,
        reorderBuildings,
        selectBuildingById,
        selectedBuilding,
        selectedBuildingId: selectedBuilding?.id,
      }}
    >
      {children}
    </MyBuildingsContext.Provider>
  );
};
