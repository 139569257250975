import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Calendar.Events.error': {
    id: 'Calendar.Events.error',
    defaultMessage: 'Could not fetch events',
  },
  'Calendar.Open': {
    id: 'Calendar.Open',
    defaultMessage: 'Open calender',
  },
  'Calendar.Select.Date': {
    id: 'Calendar.Select.Date',
    defaultMessage: 'Please select the date',
  },
  'Calendar.Select.DateRange': {
    id: 'Calendar.Select.DateRange',
    defaultMessage: 'Please select a date or a period',
  },
});

export default {
  ...dictionary,
};
