import React, { useState, useCallback } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import { useIntl } from '@utils/intl';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { Loader } from '@views/shared/Loader';
import { GallerySlider } from '@views/shared/GallerySlider';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { colors, fonts, spacings } from '@views/shared/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import Attendance from '@views/Home/Attendance';
import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';

import { useBuildings } from './hooks';

const { grey3Alpha, white, blackAlpha, black } = colors;
const { sizeBuilding, sizeTitle } = fonts;
const { extraSmall, huge, medium, small, smaller } = spacings;

const styles = StyleSheet.create({
  card: {
    marginBottom: medium,
    height: 200,
    position: 'relative',
  },
  cardDesktop: {
    marginBottom: medium,
    height: 270,
    width: '70%',
  },
  building: {
    zIndex: 20,
    backgroundColor: grey3Alpha,
    position: 'absolute',
    left: 0,
    bottom: huge,
    right: 0,
    paddingVertical: small,
    borderBottomLeftRadius: extraSmall,
    borderBottomRightRadius: extraSmall,
  },
  buildingDesktop: {
    width: '100%',
    height: '55%',
    borderTopLeftRadius: extraSmall,
    justifyContent: 'center',
    paddingHorizontal: medium,
  },
  buildingDesktopCon: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    justifyContent: 'center',
    height: 250,
    borderColor: blackAlpha,
    borderTopLeftRadius: extraSmall,
    borderBottomLeftRadius: extraSmall,
    borderWidth: 1,
    borderRightWidth: 0,
  },
  label: {
    fontSize: sizeBuilding,
    fontWeight: 'bold',
    color: white,
    textAlign: 'center',
  },
  userLabel: {
    fontSize: sizeBuilding,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: small,
    color: white,
  },
  adressLabel: {
    fontSize: sizeTitle,
  },
  slider: {
    borderRadius: extraSmall,
  },
  sliderDesktop: {
    borderTopRightRadius: extraSmall,
    borderBottomRightRadius: extraSmall,
  },
  timeTrackerContainer: {
    width: '100%',
    height: '45%',
    borderBottomLeftRadius: extraSmall,
    padding: 1,
    borderTopWidth: 1,
    borderColor: blackAlpha,
  },
});

export function Buildings() {
  const [isVisible, setIsVisible] = useState(false);

  useFocusEffect(
    useCallback(() => {
      setIsVisible(true);

      return () => {
        setIsVisible(false);
      };
    }, []),
  );

  const {
    items,
    selectedBuilding,
    selectBuildingById,
    error,
    loading,
  } = useBuildings();

  const isDesktop = useIsDesktop();

  // fetching and preparing of buildings takes some time.
  // the best practice is to show the loader instead of error
  if (loading || !items || !selectedBuilding) {
    return <Loader />;
  }

  if (error) {
    return <UnexpectedNoDataOrError />;
  }
  const { address, name } = selectedBuilding;
  const hideGalleryDots = items.length < 2;

  return isVisible ? (
    <View style={isDesktop && { display: 'flex', flexDirection: 'row' }}>
      {isDesktop && <BuildingsDataForDesktop />}
      <View
        style={[
          isDesktop ? styles.cardDesktop : styles.card,
          hideGalleryDots && { marginBottom: smaller },
        ]}
      >
        {!isDesktop && (
          <View style={styles.building}>
            <Text style={styles.label}>{name}</Text>
            <Text style={styles.label}>{address}</Text>
          </View>
        )}
        <GallerySlider
          hideDots={hideGalleryDots}
          items={items}
          onChange={selectBuildingById}
          style={isDesktop ? styles.sliderDesktop : styles.slider}
          position={items.findIndex(
            ({ value }) => value === selectedBuilding.id,
          )}
        />
      </View>
    </View>
  ) : null;
}

export function BuildingsDataForDesktop() {
  const { t } = useIntl();
  const { name } = useMyUser();

  const { selectedBuilding } = useBuildings();
  const { address, name: buildingName } = selectedBuilding || {};

  const { backgroundColor, isWhiteBackground } = useMyOrganization();
  const isTimeTrackingEnabled = useHasFeature('time_tracking');

  return (
    <View style={styles.buildingDesktopCon}>
      <View
        style={[
          styles.buildingDesktop,
          { backgroundColor: backgroundColor },
          !isTimeTrackingEnabled && { height: '100%' },
        ]}
      >
        <Text
          style={[
            styles.userLabel,
            { marginBottom: 0 },
            isWhiteBackground && { color: black },
          ]}
        >
          {t('Profile.Content.Welcome')}
        </Text>
        <Text
          style={[styles.userLabel, isWhiteBackground && { color: black }]}
          numberOfLines={2}
        >
          {name}
        </Text>
        <Text style={[styles.label, isWhiteBackground && { color: black }]}>
          {buildingName}
        </Text>
        <Text
          style={[
            styles.label,
            styles.adressLabel,
            isWhiteBackground && { color: black },
          ]}
        >
          {address}
        </Text>
      </View>
      {isTimeTrackingEnabled ? (
        <View style={styles.timeTrackerContainer}>
          <Attendance />
        </View>
      ) : null}
    </View>
  );
}
