import { gql } from '@apollo/client';
import { useIntl, defineMessages } from 'react-intl';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';

import { Data } from './interfaces';

const i18nMessages = defineMessages({
  'Colleagues.Content.Department.Collegue.Profile.Overview.FindMyColleague.error': {
    id:
      'Colleagues.Content.Department.Collegue.Profile.Overview.FindMyColleague.error',
    defaultMessage: 'Could not find my collegue',
  },
});

const getFindMyColleague = gql`
  query findMyColleague($userID: ID!, $date: Date) {
    findMyColleague(userId: $userID, date: $date) {
      createdAt
      id
      user {
        name
      }
      area {
        id
        name
        floor {
          floorNumber
          floorLabel
          floorType
          building {
            name
            address
          }
        }
        numberOfAvailableDesks
        areaType
      }
      desk {
        label
        description
        id
        numberOnFloor
      }
      status
      homeOffice
      travelDay
      sickDay
      vacation
      checkedIn
      checkedOut
    }
    colleagues(userId: $userID, forDate: $date) {
      presenceStatus
      status
      email
      currentEvent {
        subject
        description
        start
        end
      }
      previousEvent {
        subject
        description
        start
        end
      }
    }
  }
`;

interface Props {
  id: string;
}
// TODO needs to be refactored to kill the middleware. use organizationUsers query instead of colleagues
export function useFindMyColleague({ id }: Props) {
  const intl = useIntl();
  const date = useCurrentDate();
  const { data, loading, error } = useErrorQuery<Data>(getFindMyColleague, {
    variables: {
      userID: id,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: intl.formatMessage(
        i18nMessages[
          'Colleagues.Content.Department.Collegue.Profile.Overview.FindMyColleague.error'
        ],
      ),
    },
  });

  return {
    data,
    loading,
    error,
  };
}
