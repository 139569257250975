import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import {
  BOOKING_TYPE_ROOM,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  BOOK_CATEGORY_HOME,
  BOOK_CATEGORY_TRAVEL,
  BOOK_CATEGORY_PARKING,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_FAST_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_VACATION,
  BOOKING_TYPE_SICKDAY,
  BOOK_CATEGORY_TELEFON_BOX,
} from '@views/shared/consts';
import { BookCategory } from '@views/shared/interfaces/booking';

const BOOKING_PAGE_DESK = 'book-desk';
const BOOKING_PAGE_MEETING = 'book-meeting-room';
const BOOKING_PAGE_HOMEOFFICE = 'book-home';
const BOOKING_PAGE_TRAVELDAY = 'book-travel';
const BOOKING_PAGE_PARKING = 'book-parking';
const BOOKING_PAGE_FREE_SPACE = 'book-free-space';
const BOOKING_PAGE_VACATION = 'book-vacation';
const BOOKING_PAGE_SICKDAY = 'book-sickday';
const BOOKING_PAGE_TELEFON_BOX = 'book-telefon-box';

const bookingScreenMap = {
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: BOOKING_PAGE_FREE_SPACE,
  [BOOK_CATEGORY_HOME]: BOOKING_PAGE_HOMEOFFICE,
  [BOOK_CATEGORY_MEETING_ROOM]: BOOKING_PAGE_MEETING,
  [BOOKING_TYPE_ROOM]: BOOKING_PAGE_DESK,
  [BOOK_CATEGORY_PARKING]: BOOKING_PAGE_PARKING,
  [BOOK_CATEGORY_TRAVEL]: BOOKING_PAGE_TRAVELDAY,
  [BOOKING_TYPE_PARKING_CHARGING]: BOOKING_PAGE_PARKING,
  [BOOKING_TYPE_PARKING_FAST_CHARGING]: BOOKING_PAGE_PARKING,
  [BOOKING_TYPE_PARKING_GENERAL]: BOOKING_PAGE_PARKING,
  [BOOKING_TYPE_PARKING_HANDICAPPED]: BOOKING_PAGE_PARKING,
  [BOOKING_TYPE_VACATION]: BOOKING_PAGE_VACATION,
  [BOOKING_TYPE_SICKDAY]: BOOKING_PAGE_SICKDAY,
  [BOOK_CATEGORY_TELEFON_BOX]: BOOKING_PAGE_TELEFON_BOX,
};

interface Props {
  areaId?: string;
  deskId?: string;
  parkingId?: string;
  type?: BookCategory;
}

export function useGoTo() {
  const navigation = useNavigation<any>();

  const goToBookingPage = useCallback(
    ({ areaId, deskId, parkingId, type = BOOK_CATEGORY_ROOM }: Props) => {
      const screen = bookingScreenMap[type];

      navigation.navigate('book', {
        screen,
        params: {
          areaId,
          deskId,
          parkingId,
          type,
        },
      });
    },
    [navigation],
  );

  const goToFloorplan = useCallback(() => navigation.navigate('floorplan'), [
    navigation,
  ]);

  const goToStructurePage = useCallback(
    (type?: BookCategory) => {
      navigation.navigate('book', {
        screen: type ? 'structure' : 'index',
        params: {
          type,
        },
      });
    },
    [navigation],
  );

  return { goToBookingPage, goToFloorplan, goToStructurePage };
}
