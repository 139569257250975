import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'AvailabilityInfos.Status.Blocked': {
    id: 'AvailabilityInfos.Status.Blocked',
    defaultMessage: 'This place is not available for booking',
  },
  'AvailabilityInfos.Status.Booked': {
    id: 'AvailabilityInfos.Status.Booked',
    defaultMessage: 'This place is booked by you',
  },
  'AvailabilityInfos.Status.BookedTime': {
    id: 'AvailabilityInfos.Status.BookedTime',
    defaultMessage: 'This time is booked by you',
  },
  'AvailabilityInfos.Status.BookedByColleague': {
    id: 'AvailabilityInfos.Status.BookedByColleague',
    defaultMessage: 'This place is already booked by your colleagues',
  },
  'AvailabilityInfos.Status.OccupiedByColleague': {
    id: 'AvailabilityInfos.Status.OccupiedByColleague',
    defaultMessage:
      'This place it seems not booked but anyway already used by your colleagues',
  },
  'AvailabilityInfos.Status.Disabled': {
    id: 'AvailabilityInfos.Status.Disabled',
    defaultMessage: "This place doesn't match the filter settings",
  },
  'AvailabilityInfos.Status.Expired': {
    id: 'AvailabilityInfos.Status.Expired',
    defaultMessage: 'This time is expired',
  },
});

export default {
  ...dictionary,
};
