import { useEffect, useCallback, useRef } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useWindowDimensions } from 'react-native';

import { ScaleEvent } from '@views/shared/PinchArea/interfaces';

import { MOBILE_BROWSERS } from './utils';
import {
  scaleSelector,
  imageWidthSelector,
  imageHeightSelector,
  viewWidthSelector,
  viewHeightSelector,
  areaWidthSelector,
  areaHeightSelector,
  correctionXSelector,
  correctionYSelector,
  usePan,
  useImageDim,
  useReset,
} from './state';

// Responsible for initialization of view. Reads view dimensions and saves them for later use
function useView(isMountedVal: any) {
  const scale = useRecoilValue(scaleSelector);
  const [viewWidth, setViewWidth] = useRecoilState(viewWidthSelector);
  const [viewHeight, setViewHeight] = useRecoilState(viewHeightSelector);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    if (isMountedVal.current) {
      setViewWidth(width);
      setViewHeight(height);
    }
  }, [width, height, isMountedVal, scale]);

  return {
    viewWidth,
    viewHeight,
  };
}

// All the logic related to intializing, using and changing scale
function useScale(isMountedVal: any) {
  const [scale, setScale] = useRecoilState(scaleSelector);
  const setAreaWidth = useSetRecoilState(areaWidthSelector);
  const setAreaHeight = useSetRecoilState(areaHeightSelector);
  let isMobile = false;

  if (MOBILE_BROWSERS.test(navigator.userAgent)) {
    isMobile = true;
  }

  const ZOOM_STEP = 0.05;

  const onScale = useCallback(
    ({ scale, areaHeight, areaWidth }: ScaleEvent) => {
      if (isMountedVal.current) {
        setScale(isMobile ? scale : scale - 0.05);
        setAreaHeight(areaHeight);
        setAreaWidth(areaWidth);
      }
    },
    [setScale, setAreaHeight, setAreaWidth],
  );

  const plus = useCallback(() => {
    setScale(scale + ZOOM_STEP);
  }, [setScale, scale]);

  const minus = useCallback(() => {
    setScale(scale - ZOOM_STEP);
  }, [setScale, scale]);

  return {
    scale,
    onScale,
    plus,
    minus,
  };
}

function useCenterCorrection(isMountedVal: any) {
  const viewWidth = useRecoilValue(viewWidthSelector) ?? 1;
  const viewHeight = useRecoilValue(viewHeightSelector) ?? 1;
  const imageWidth = useRecoilValue(imageWidthSelector) ?? 1;
  const imageHeight = useRecoilValue(imageHeightSelector) ?? 1;
  const setCorrectionX = useSetRecoilState(correctionXSelector);
  const setCorrectionY = useSetRecoilState(correctionYSelector);
  let isMobile = false;

  if (MOBILE_BROWSERS.test(navigator.userAgent)) {
    isMobile = true;
  }

  const correctionX = -imageWidth / 2 + viewWidth / (isMobile ? 2 : 6);
  const correctionY = -imageHeight / 2 + viewHeight / (isMobile ? 2.5 : 2.3);

  useEffect(() => {
    if (isMountedVal.current) {
      setCorrectionX(correctionX);
      setCorrectionY(correctionY);
    }
  }, [correctionX, correctionY, setCorrectionX, setCorrectionY, isMountedVal]);

  return {
    correctionX,
    correctionY,
  };
}

export function useZoomImage(imageUrl: string, reset: boolean | undefined) {
  const isMountedVal = useRef(0);
  const imageProps = useImageDim(imageUrl);
  const viewProps = useView(isMountedVal);
  const scaleProps = useScale(isMountedVal);
  const panProps = usePan();
  const correctionProps = useCenterCorrection(isMountedVal);
  const resetPositionAndZoom = useReset();

  useEffect(() => {
    isMountedVal.current = 1;
    // ZoomImage loading...
    if (reset) {
      resetPositionAndZoom();
      // ZoomImage loading and on change floor reset state;
    }

    return () => {
      // ZoomImage Unmounting no reset state changes...
      isMountedVal.current = 0;
    };
  }, [isMountedVal]);

  return {
    ...imageProps,
    ...viewProps,
    ...scaleProps,
    ...panProps,
    ...correctionProps,
  };
}
