import React, { useState, useEffect } from 'react';
import { Text, View } from 'react-native';
import { isNil, isEmpty } from 'ramda';

import { useIntl } from '@utils/intl';
import { toHoursAndMinutes } from '@utils/DateAndTime';

import { CustomIcon } from '@views/shared/CustomIcon';
import { MainButton, SecondaryButton } from '@views/shared/Button';
import { useMyOrganization } from '@providers/Organization';

import {
  spacings,
  fonts,
  globalStyles,
  globalStylesDesktop,
} from '@views/shared/styles';
import { styles } from '@views/Home/ActiveBookings/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import PopUp from './PopUp';
import { STATUS, useAttendance } from './hooks';

export default function Attendance() {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();

  const {
    backgroundColor,
    contrastColor,
    isWhiteBackground,
  } = useMyOrganization();

  const {
    onToggle,
    isOpen,
    minutes,
    totalBreak,
    breakMinutes,
    attendanceId,
    attendanceStatus,
    onComplete,
    mode,
    lastBlockedTime,
    loading,
    error,
  } = useAttendance();

  return (
    <View
      style={[
        globalStyles.cardContainer,
        isDesktop && {
          marginBottom: 0,
          height: '100%',
        },
      ]}
    >
      {!isDesktop && (
        <Text style={globalStyles.cardTitle}>{t('Home.Attendance.Title')}</Text>
      )}
      <View
        style={[
          isDesktop
            ? {
                marginHorizontal: 0,
                borderBottomRightRadius: 0,
                height: '100%',
              }
            : globalStyles.shadow,
        ]}
      >
        <View style={[styles.row, isDesktop && { height: '100%' }]}>
          {!isDesktop && (
            <View
              style={[
                styles.left,
                { backgroundColor },
                isWhiteBackground && styles.spaceBorder,
              ]}
            >
              <CustomIcon
                name="timer"
                onPress={onToggle}
                size={25}
                color={contrastColor}
              />
            </View>
          )}
          <View
            style={[styles.right, isDesktop && { justifyContent: 'center' }]}
          >
            <View>
              <Text
                style={[
                  globalStyles.title,
                  isDesktop && [
                    globalStylesDesktop.centeredTitle,
                    { fontSize: fonts.sizeTitle },
                  ],
                ]}
              >
                {t('Home.Attendance.Timer')}
              </Text>
              {isEmpty(attendanceStatus) || isNil(attendanceStatus) ? (
                <Text
                  style={[
                    globalStyles.description,
                    isDesktop && globalStylesDesktop.centeredTitle,
                  ]}
                >
                  {t('Home.Attendance.Layer.Description', {
                    value: `0 ${t('Home.Attendance.Layer.Minutes')}`,
                  })}
                </Text>
              ) : null}
              {[STATUS.PROGRESS, STATUS.OPEN, STATUS.APPROVED].includes(
                attendanceStatus,
              ) ? (
                <CurrentTimer
                  time={minutes * 60}
                  t={t}
                  attendanceStatus={attendanceStatus}
                  isDesktop={isDesktop}
                />
              ) : null}
              {[STATUS.OPEN, STATUS.APPROVED].includes(attendanceStatus) &&
              totalBreak > 0 ? (
                <BreakTime time={totalBreak} t={t} isDesktop={isDesktop} />
              ) : null}
              {attendanceStatus === STATUS.BREAK ? (
                <CurrentBreakTimer
                  time={breakMinutes * 60}
                  t={t}
                  isDesktop={isDesktop}
                />
              ) : null}
            </View>
            <View
              style={[
                styles.row,
                styles.buttons,
                isDesktop && {
                  justifyContent: 'center',
                  marginTop: spacings.medium,
                },
              ]}
            >
              {isNil(attendanceId) ||
              [STATUS.OPEN, STATUS.APPROVED].includes(attendanceStatus) ? (
                <MainButton
                  disabled={loading || error}
                  onPress={() => onToggle('start')}
                  buttonStyle={styles.button}
                  titleStyle={styles.buttonTitle}
                  title={t('Home.Attendance.Button.Start')}
                />
              ) : (
                <>
                  {attendanceStatus === STATUS.BREAK ? (
                    <MainButton
                      onPress={() => onToggle('breakEnd')}
                      buttonStyle={styles.button}
                      titleStyle={styles.buttonTitle}
                      title={t('Home.Attendance.Button.StopBreak')}
                    />
                  ) : null}
                  {attendanceStatus === STATUS.PROGRESS ? (
                    <MainButton
                      onPress={() => onToggle('break')}
                      buttonStyle={styles.button}
                      titleStyle={styles.buttonTitle}
                      title={t('Home.Attendance.Button.Break')}
                    />
                  ) : null}
                  <SecondaryButton
                    onPress={() => onToggle('stop')}
                    buttonStyle={[styles.button, globalStyles.cancelButton]}
                    titleStyle={globalStyles.cancelButtonText}
                    title={t('Home.Attendance.Button.Stop')}
                  />
                </>
              )}
            </View>
          </View>
        </View>
        {isOpen ? (
          <PopUp
            onToggle={onToggle}
            onComplete={onComplete}
            attendanceId={attendanceId}
            mode={mode}
            attendanceStatus={attendanceStatus}
            lastBlockedTime={lastBlockedTime}
          />
        ) : null}
      </View>
    </View>
  );
}

function CurrentTimer({ attendanceStatus, time, t, isDesktop }) {
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    setCounter(time);
  }, [time]);

  useEffect(() => {
    const timer =
      attendanceStatus === STATUS.PROGRESS &&
      counter > 0 &&
      setInterval(() => {
        setCounter(time => time + 60);
      }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter, attendanceStatus]);

  const { hours, minutes } = toHoursAndMinutes(counter / 60);

  const trsHours =
    hours > 0
      ? `${hours} ${t(
          hours === 1
            ? 'Home.Attendance.Layer.Hour'
            : 'Home.Attendance.Layer.Hours',
        )} `
      : '';

  const trsMinutes = `${minutes} ${t(
    minutes === 1
      ? 'Home.Attendance.Layer.Minute'
      : 'Home.Attendance.Layer.Minutes',
  )}`;

  return (
    <Text
      style={[
        globalStyles.description,
        isDesktop && globalStylesDesktop.centeredTitle,
      ]}
    >
      {t('Home.Attendance.Layer.Description', {
        value: `${trsHours}${trsMinutes}`,
      })}
    </Text>
  );
}

function CurrentBreakTimer({ time, t, isDesktop }) {
  const [counter, setCounter] = useState(null);

  useEffect(() => {
    setCounter(time);
  }, [time]);

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(time => time + 60);
      }, 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [counter]);

  const { hours, minutes } = toHoursAndMinutes(counter / 60);

  const trsHours =
    hours > 0
      ? `${hours} ${t(
          hours === 1
            ? 'Home.Attendance.Layer.Hour'
            : 'Home.Attendance.Layer.Hours',
        )} `
      : '';

  const trsMinutes = `${minutes} ${t(
    minutes === 1
      ? 'Home.Attendance.Layer.Minute'
      : 'Home.Attendance.Layer.Minutes',
  )}`;

  return (
    <Text
      style={[
        globalStyles.description,
        isDesktop && globalStylesDesktop.centeredTitle,
      ]}
    >
      {t('Home.Attendance.Layer.Break.Description', {
        value: `${trsHours}${trsMinutes}`,
      })}
    </Text>
  );
}

function BreakTime({ time, t, isDesktop }) {
  const { hours, minutes } = toHoursAndMinutes(time);

  const trsHours =
    hours > 0
      ? `${hours} ${t(
          hours === 1
            ? 'Home.Attendance.Layer.Hour'
            : 'Home.Attendance.Layer.Hours',
        )} `
      : '';

  const trsMinutes = `${minutes} ${t(
    minutes === 1
      ? 'Home.Attendance.Layer.Minute'
      : 'Home.Attendance.Layer.Minutes',
  )}`;

  return (
    <Text
      style={[
        globalStyles.description,
        isDesktop && globalStylesDesktop.centeredTitle,
      ]}
    >
      {t('Home.Attendance.Layer.Break.Description', {
        value: `${trsHours}${trsMinutes}`,
      })}
    </Text>
  );
}
