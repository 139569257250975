import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { isNil } from 'ramda';

import { useMyActiveBookings } from '@providers/ActiveBookings';
import { OptionType } from '@views/Book/Book/MeetingRoom/AdditionalOrder/types';

const cancelCateringMutation = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder: cancelCatering(cateringId: $id) {
      status
    }
  }
`;
const cancelEquippingMutation = gql`
  mutation cancelOrder($id: ID!) {
    cancelOrder: cancelEquipping(equippingId: $id) {
      status
    }
  }
`;

const mapMutation = {
  catering: cancelCateringMutation,
  equipping: cancelEquippingMutation,
};

export default function useCancelOrder(type: OptionType) {
  const { refreshActiveBookings } = useMyActiveBookings();
  const [cancel, { data, error, loading }] = useMutation(mapMutation[type]);

  const cancelOrder = useCallback(
    async (id) => {
      await cancel({
        variables: {
          id,
        },
      });
      setTimeout(() => refreshActiveBookings(), 2000);
    },
    [cancel, refreshActiveBookings],
  );

  return {
    cancelOrder,
    isCanceled: data?.cancelOrder.status === 'CANCELED_BY_USER',
    isError: !isNil(error),
    loading,
  };
}
