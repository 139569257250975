import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';

import { useIntl } from '@utils/intl';
import { Select } from '@views/shared/Select';
import { CustomIcon } from '@views/shared/CustomIcon';

import { ItemComponentProps } from '@views/shared/Select/interfaces';

import { globalStyles, colors, spacings } from '@views/shared/styles';

import { FloorPlan } from '@views/shared/interfaces/floorplan';

interface Props {
  floors: FloorPlan[];
  current: string;
}

const { extraSmall, small } = spacings;
const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: extraSmall,
    left: small,
    backgroundColor: colors.white,
    height: 38,
    zIndex: 999,
    paddingHorizontal: small,
  },
  touchable: {
    height: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'mrt-regular',
    paddingRight: extraSmall,
  },
});

export default function FloorSwitcher({ floors, current }: Props) {
  const navigation = useNavigation<any>();

  const selectFloorById = useCallback(
    (id: string) => {
      navigation.navigate(id);
    },
    [navigation],
  );

  if (floors.length < 2) {
    return null;
  }

  const items = floors.map(({ label, id: value }) => ({ label, value }));
  const selectedItem = floors.find(({ id }) => id === current);
  const selected = selectedItem ? selectedItem.id : current;

  return (
    <Select
      value={selected}
      items={items}
      onValueChange={selectFloorById}
      selectedComponent={SelectedItem}
    />
  );
}

function SelectedItem({ show }: ItemComponentProps) {
  const { t } = useIntl();

  return (
    <View style={[styles.container, globalStyles.shadow]}>
      <TouchableOpacity style={styles.touchable} onPress={show}>
        <Text style={styles.text}>{t('Filter.Floors.Title')}</Text>
        <CustomIcon name="arrow-down" size={14} />
      </TouchableOpacity>
    </View>
  );
}
