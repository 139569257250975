import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { atom, useSetRecoilState, useResetRecoilState } from 'recoil';
import { gql, useMutation } from '@apollo/client';
import * as Notifications from 'expo-notifications';
import * as Linking from 'expo-linking';
import * as Application from 'expo-application';
import { isNil } from 'ramda';

import { dateAtom } from '@views/Calendar/hooks';
import { getStringAsDate } from '@utils/DateAndTime';

export const pushNotificationNavAtom = atom({
  key: 'push-notif-nav',
  default: {
    nav: '',
    areaId: '',
    deskId: '',
    timestamp: '',
  },
});

export const pushTokenAtom = atom({
  key: 'push-token',
  default: null,
});

const addTokenMutation = gql`
  mutation addNotificationToken($token: ID!) {
    addNotificationToken(token: $token)
  }
`;

const removeNotificationDataInStorage = async () => {
  try {
    await AsyncStorage.removeItem('notification');
  } catch {
    e => console.log(e);
  }
};

const getInstallationTime = async () => {
  const installedTime = await Application.getInstallationTimeAsync();
  return installedTime.toString();
};

const getDataFromAsyncStore = async key => await AsyncStorage.getItem(key);

async function RegisterForPushNotificationsAsync(state = '') {
  const { status: existingStatus } = await Notifications.getPermissionsAsync();

  let finalStatus = existingStatus;

  if (state === 'initial' && existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }

  if (finalStatus !== 'granted') {
    return null;
  }

  return (await Notifications.getExpoPushTokenAsync()).data;
}

export const setNotificationDataInStorage = async data => {
  try {
    return AsyncStorage.setItem('notification', JSON.stringify(data));
  } catch {
    e => console.log(e);
  }
};

export const getNotificationDataFromStorage = async callback => {
  try {
    const dataRef = await AsyncStorage.getItem('notification');
    if (!isNil(dataRef)) {
      return callback(JSON.parse(dataRef));
    }
  } catch {
    e => console.log(e);
  }
};

export function useRegisterPushToken() {
  const [addToken] = useMutation(addTokenMutation);

  return useCallback((token: string) => addToken({ variables: { token } }), [
    addToken,
  ]);
}

export function useHandleNotificationTap() {
  const setPushNotificationNav = useSetRecoilState(pushNotificationNavAtom);
  const setDate = useSetRecoilState(dateAtom);

  const handleNotificationTap = useCallback(
    data => {
      removeNotificationDataInStorage().then(() => {
        switch (data.category) {
          case 'index':
            setPushNotificationNav({ nav: 'book', timestamp: new Date() });
            setDate(getStringAsDate(data.date));
            break;
          case 'guided_booking_room':
          case 'guided_booking_desk':
            setPushNotificationNav({
              nav: data.category,
              timestamp: new Date(),
              areaId: data.areaId || '',
              deskId: data.deskId || '',
            });
            setDate(getStringAsDate(data.date));
            break;
          case 'version_up':
            if (Platform.OS == 'ios') {
              Linking.openURL(
                'https://apps.apple.com/in/app/liz-booker/id1549996098',
              ).catch(err => console.error('An error occurred', err));
            } else {
              Linking.openURL(
                'https://play.google.com/store/apps/details?id=com.onltx.finder',
              ).catch(err => console.error('An error occurred', err));
            }
            break;
          default:
            break;
        }
      });
    },
    [setPushNotificationNav, setDate],
  );

  return {
    handleNotificationTap,
  };
}

export function useHandleNotificationNav() {
  const navigation = useNavigation<any>();
  const resetPushNotificationNav = useResetRecoilState(pushNotificationNavAtom);

  const handleNotificationNav = useCallback(
    pushNotificationNav => {
      if (pushNotificationNav.nav.length) {
        if (pushNotificationNav.nav === 'book') {
          navigation.navigate(pushNotificationNav.nav);
        } else {
          const screen =
            pushNotificationNav.nav === 'guided_booking_room'
              ? 'book-meeting-room'
              : 'book-desk';
          const type =
            pushNotificationNav.nav === 'guided_booking_room'
              ? 'meeting_room'
              : 'room';
          navigation.navigate('book', {
            screen: screen,
            params: {
              areaId: pushNotificationNav.areaId,
              deskId: pushNotificationNav.deskId || '',
              type: type,
            },
          });
        }
        resetPushNotificationNav();
      }
    },
    [navigation, resetPushNotificationNav],
  );

  return {
    handleNotificationNav,
  };
}

export function useCheckAndRegisterPushToken() {
  const setPushToken = useSetRecoilState(pushTokenAtom);
  let appInstalled = null,
    installedTime = null,
    token = null;

  (async () => {
    appInstalled = await getDataFromAsyncStore('appInstalled');
    installedTime = await getInstallationTime();
    token = await getDataFromAsyncStore('token');
  })();

  const checkAndRegisterPushToken = useCallback(
    callback => {
      if (isNil(appInstalled) || appInstalled !== installedTime) {
        RegisterForPushNotificationsAsync('initial').then(token => {
          if (!isNil(token)) {
            callback(token);
            setPushToken(token);
            return AsyncStorage.setItem('token', token);
          }
        });
        return AsyncStorage.setItem('appInstalled', installedTime);
      } else {
        if (isNil(token)) {
          RegisterForPushNotificationsAsync().then(token => {
            if (!isNil(token)) {
              callback(token);
              setPushToken(token);
              return AsyncStorage.setItem('token', token);
            }
          });
        }
      }
    },
    [appInstalled, installedTime, setPushToken, token],
  );

  return {
    checkAndRegisterPushToken,
  };
}
