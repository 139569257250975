import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { useIntl } from '@utils/intl';
import { WithDateSwitcher } from '@views/shared/DateSwitcher';
import { withLizTitle } from '@views/shared/utils';

import BookDesk from './Book/Desk';
import BookFreeSpace from './Book/FreeSpace';
import BookTravelDay from './Book/Travel';
import BookParkingSpot from './Book/ParkingSpot';
import BookMeetingRoom from './Book/MeetingRoom';
import BookHomeOffice from './Book/HomeOffice';
import Structure from './Structure';
import Filter from './Filter';
import Favourites from './Favourites';
import HowWasYourDay from './HowWasYourDay';
import Main from './Main';
import BookVacation from './Book/Vacation';
import BookSickDay from './Book/SickDay';
import TelefonBox from './Book/TelefonBox';
import { Confirm as BookConfirm } from './Book/BasicBook/Confirm';
import { Confirm as ConfirmNextDaysBooking } from './Book/NextDaysBooking/FullDayBooking/Confirm';

const Stack = createStackNavigator();

export default function Book() {
  const { t } = useIntl();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="index"
        options={{ title: withLizTitle(t('Routes.tabs.book')) }}
      >
        {() => (
          <WithDateSwitcher>
            <Main />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="structure"
        options={{ title: withLizTitle(t('Routes.Home.Structure')) }}
      >
        {() => (
          <WithDateSwitcher>
            <Structure />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-desk"
        options={{ title: withLizTitle(t('Routes.Home.Book.BookDesk')) }}
      >
        {() => (
          <WithDateSwitcher>
            <BookDesk />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-free-space"
        options={{ title: withLizTitle(t('Routes.Home.Book.FreeSpace')) }}
      >
        {() => (
          <WithDateSwitcher>
            <BookFreeSpace />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-meeting-room"
        options={{ title: withLizTitle(t('Routes.Home.Book.MeetingRoom')) }}
      >
        {() => (
          <WithDateSwitcher>
            <BookMeetingRoom />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-travel"
        options={{ title: withLizTitle(t('Routes.Home.Book.TravelDay')) }}
      >
        {() => (
          <WithDateSwitcher>
            <BookTravelDay />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-home"
        options={{ title: withLizTitle(t('Routes.Home.Book.HomeOffice')) }}
      >
        {() => (
          <WithDateSwitcher>
            <BookHomeOffice />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-parking"
        options={{ title: withLizTitle(t('Routes.Home.Book.ParkingSpot')) }}
      >
        {() => (
          <WithDateSwitcher>
            <BookParkingSpot />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="book-confirm"
        component={BookConfirm}
        options={{ title: withLizTitle(t('Routes.Home.Book.Confirmation')) }}
      />
      <Stack.Screen
        name="book-next-confirm"
        component={ConfirmNextDaysBooking}
        options={{ title: withLizTitle(t('Routes.Home.Book.Confirmation')) }}
      />
      <Stack.Screen
        name="favourites"
        options={{ title: withLizTitle(t('Routes.Home.Favourites')) }}
      >
        {() => (
          <WithDateSwitcher>
            <Favourites />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
      <Stack.Screen
        name="howwasyourday"
        component={HowWasYourDay}
        options={{ title: withLizTitle(t('Routes.Home.HowWasYourDay')) }}
      />
      <Stack.Screen
        name="filter"
        component={Filter}
        options={{ title: withLizTitle(t('Routes.Home.Filter')) }}
      />
      <Stack.Screen
        name="book-vacation"
        component={BookVacation}
        options={{ title: withLizTitle(t('Routes.Home.Book.Vacation')) }}
      />
      <Stack.Screen
        name="book-sickday"
        component={BookSickDay}
        options={{
          headerShown: false,
          title: withLizTitle(t('Routes.Home.Book.Sickday')),
        }}
      />
      <Stack.Screen
        name="book-telefon-box"
        options={{ title: withLizTitle(t('Routes.Home.Book.TelefonBox')) }}
      >
        {() => (
          <WithDateSwitcher>
            <TelefonBox />
          </WithDateSwitcher>
        )}
      </Stack.Screen>
    </Stack.Navigator>
  );
}
