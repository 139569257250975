import { StyleSheet } from 'react-native';

import { colors, fonts, spacings } from '@views/shared/styles';

const { medium, verySmall, extraSmall } = spacings;
const { aquaMarine, lightBlue2, blackAlpha, white, blackAlpha3 } = colors;

export const styles = StyleSheet.create({
  cardSpacer: {
    marginBottom: medium,
  },
  location: {
    fontSize: fonts.sizeSmall,
    fontFamily: 'mrt-regular',
  },
  row: {
    flexDirection: 'row',
  },
  date: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
    textAlign: 'left',
  },
  time: {
    fontSize: fonts.sizeSmall,
    fontFamily: 'mrt-regular',
  },
  right: {
    padding: medium,
    flex: 1,
    backgroundColor: white,
    borderBottomRightRadius: extraSmall,
    borderTopRightRadius: extraSmall,
  },
  between: {
    justifyContent: 'space-between',
  },
  itemsCenter: {
    alignItems: 'center',
  },
  left: {
    width: 50,
    borderColor: blackAlpha,
    borderBottomLeftRadius: extraSmall,
    borderTopLeftRadius: extraSmall,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    marginBottom: medium,
  },
  dateTime: {
    zIndex: 2,
    flex: 1,
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: verySmall,
    borderColor: lightBlue2,
    backgroundColor: 'transparent',
  },
  buttons: {
    marginTop: verySmall,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  spaceBorder: {
    borderRightWidth: 1,
    borderColor: blackAlpha3,
  },
  buttonTitle: {
    color: lightBlue2,
  },
  greenButtonTitle: {
    color: aquaMarine,
  },
  greenButton: {
    borderColor: aquaMarine,
  },
});
