import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableHighlight,
  Platform,
} from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';
import {
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_FAST_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  FLOOR_TYPE_PARKING,
} from '@views/shared/consts';

import { STATUS, SLOT_STATUS } from '@views/shared/TimeSlots/helper';
//import { colors, fonts } from '@views/shared/styles';
import { colors } from '@views/shared/styles';
import { SlotUIStatus, Status } from '@views/shared/TimeSlots/interfaces';
import { QuarterStateType } from '@views/shared/interfaces/buildingStructure';

import { usePoints, ComponentProps } from './hooks';

/**
 * This Function map the Status of a Point into the UI status type
 **/
function mapPointStatusToUI(status: Status): SlotUIStatus {
  const mapStatus = {
    [STATUS.BLOCKED]: SLOT_STATUS.BLOCKED,
    [STATUS.BOOKED]: SLOT_STATUS.BOOKED,
    [STATUS.BOOKED_BY_COLLEAGUE]: SLOT_STATUS.BOOKED_BY_COLLEAGUE,
    [STATUS.FREE]: SLOT_STATUS.FREE,
    [STATUS.DISABLED]: SLOT_STATUS.DISABLED,
    // EXPIRED At the moment is not used
    // OUTSIDE_BUILDING is not a Status that is not showed via Floor Plan
  };

  return mapStatus[status];
}

const {
  aquaMarine,
  /*black,*/
  darkBlue,
  lightBlue,
  grey6,
  warmPink,
  white,
} = colors;
/*const { sizeDefault, sizeSmaller } = fonts;*/

const styles = StyleSheet.create({
  point: {
    zIndex: 50,
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
  },
  caption: {
    color: white,
    textAlign: 'center',
    justifyContent: 'center',
    /*    fontSize: sizeDefault,*/
    zIndex: 9,
  },
  quarter: {
    position: 'absolute',
    height: '50%',
    width: '50%',
    borderStartWidth: 1,
    borderEndWidth: 1,
  },
  quarterTopRight: {
    borderTopRightRadius: 999,
    top: 0,
    left: '50%',
  },
  quarterBottomRight: {
    borderBottomRightRadius: 999,
    top: '50%',
    left: '50%',
  },
  quarterBottomLeft: {
    borderBottomLeftRadius: 999,
    top: '50%',
    left: 0,
  },
  quarterTopLeft: {
    borderTopLeftRadius: 999,
    top: 0,
    left: 0,
  },
  pointIcon: {
    color: white,
    zIndex: 10,
    position: 'relative',
  },
});

const mapBackgroundColor = {
  blocked: warmPink,
  booked: darkBlue,
  self_booked: darkBlue,
  bookedByColleague: lightBlue,
  disabled: grey6,
  free: aquaMarine,
};

/*const mapTextColor = {
  blocked: white,
  booked: white,
  bookedByColleague: black,
  disabled: white,
  free: black,
};*/

const getColorByState = state => {
  return {
    borderColor:
      mapBackgroundColor[state === 'booked' ? 'bookedByColleague' : state],
    backgroundColor:
      mapBackgroundColor[state === 'booked' ? 'bookedByColleague' : state],
  };
};

const iconMap = {
  [BOOKING_TYPE_PARKING_FAST_CHARGING]: {
    name: 'power-pro',
    size: 1.6,
    style: { top: 1 },
  },
  [BOOKING_TYPE_PARKING_CHARGING]: {
    name: 'power',
    size: 1.3,
    style: { top: 1 },
  },
  [BOOKING_TYPE_PARKING_HANDICAPPED]: {
    name: 'parking-handicapped',
    size: 1.5,
  },
  [BOOKING_TYPE_PARKING_GENERAL]: {
    name: 'parking',
    size: 2,
  },
};

export function Points({
  ids,
  floorType,
  showAvailability,
  onLoading,
}: ComponentProps) {
  const { book, points, scale, showNumber } = usePoints({
    ids,
    floorType,
    onLoading,
  });

  //const isAndroid = Platform.OS === 'android';
  const pointSize = 25 / scale;
  const fontSize = 17 / scale;
  const iconSize = 13 / scale;
  const pointBaseStyle = {
    width: pointSize,
    height: pointSize,
    borderRadius: 999,
  };
  const isParkingFloor = floorType === FLOOR_TYPE_PARKING;

  return (
    <>
      {points.map((point, index) => {
        const {
          deskCaption,
          id,
          isAvailableToBook,
          status,
          x,
          y,
          quarterState,
          type,
        } = point;

        const myStatus: SlotUIStatus = mapPointStatusToUI(status);
        const showQuarter =
          quarterState && !['disabled', 'blocked'].includes(myStatus);

        // const textColor = mapTextColor[myStatus];
        const pointStyle = {
          ...pointBaseStyle,
          backgroundColor: mapBackgroundColor[myStatus],
        };

        return (
          <TouchableHighlight
            onPress={() =>
              isAvailableToBook
                ? book(point)
                : showAvailability({ type: 'point', element: point })
            }
            key={index}
            style={[
              styles.point,
              pointStyle,
              {
                transform: [
                  {
                    translateX: x - 10 / scale,
                  },
                  {
                    translateY: y - 10 / scale,
                  },
                ],
              },
            ]}
          >
            <>
              <View style={[styles.point, pointStyle]}>
                {// desk/ parking id for debugging
                Platform.OS === 'web' && (
                  <Text style={{ display: 'none' }}>{`PointID: ${id}`}</Text>
                )}

                {showQuarter ? <QuarterSegments data={quarterState} /> : null}
                {showNumber && !isParkingFloor ? (
                  <Text
                    style={[styles.caption, { fontSize /*color: textColor*/ }]}
                  >
                    {deskCaption}
                  </Text>
                ) : null}
                {isParkingFloor ? (
                  <CustomIcon
                    name={iconMap[type].name}
                    size={iconSize * iconMap[type].size}
                    style={[styles.pointIcon, iconMap[type].style]}
                  />
                ) : null}
              </View>
            </>
          </TouchableHighlight>
        );
      })}
    </>
  );
}

export function QuarterSegments({ data }: QuarterStateType) {
  const stylesMap = [
    styles.quarterTopRight,
    styles.quarterBottomRight,
    styles.quarterBottomLeft,
    styles.quarterTopLeft,
  ];

  return data.map((state, idx) => (
    <View
      key={idx}
      style={[
        styles.quarter,
        stylesMap[idx],
        getColorByState(state.toLowerCase()),
      ]}
    />
  ));
}
