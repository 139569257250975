import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet } from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

const styles = StyleSheet.create({
  close: {
    position: 'absolute',
    right: 10,
    top: 10,
  },
});

export default function CloseConfirmBooking() {
  const navigation = useNavigation<any>();
  const isDesktop = useIsDesktop();

  const parentNav = isDesktop ? 'home' : 'index';
  const goHome = useCallback(() => {
    navigation.navigate(parentNav);
  }, [navigation]);

  return (
    <CustomIcon name="close" onPress={goHome} size={23} style={styles.close} />
  );
}
