import { curry, pathOr } from 'ramda';

import { Booking, BookingType } from '@views/shared/interfaces/booking';
import { AreaType } from '@views/shared/interfaces/buildingStructure';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_PARKING,
  AREA_TYPE_ROOM,
  AREA_TYPE_TELEFON_BOX,
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_FAST_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_VIRTUAL_DESK,
  BOOKING_TYPE_VACATION,
  BOOKING_TYPE_SICKDAY,
  BOOKING_TYPE_TELEFON_BOX,
} from '@views/shared/consts';
import { BlockedTimeSlot } from '@views/shared/TimeSlots/interfaces';
import { createBlockedTimeSlot } from '@views/shared/TimeSlots/helper';
/**********************************************************************
 *            FULL DAY BOOKING TYPES COMBINATIONS CHECKS              *
 **********************************************************************/

const checkHome = ({ homeOffice }: Booking) => homeOffice;
const checkTravel = ({ travelDay }: Booking) => travelDay;
const checkVacation = ({ vacation }: Booking) => vacation;
const checkSickDay = ({ sickDay }: Booking) => sickDay;
const checkByAreaType = (type: AreaType, { area }: Booking) =>
  type === area?.areaType;

//forbid double away, home, vacation or sick day.
const forbidDoubleAwayOrHome = (booking: Booking) =>
  checkHome(booking) ||
  checkTravel(booking) ||
  checkVacation(booking) ||
  checkSickDay(booking);

const forbidOutsideBuilding = (booking: Booking) =>
  checkByAreaType(AREA_TYPE_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_MEETING_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_FREE_SPACES_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_PARKING, booking) ||
  checkByAreaType(AREA_TYPE_TELEFON_BOX, booking) ||
  forbidDoubleAwayOrHome(booking);

const forbidDoubleDesk = (booking: Booking) =>
  checkByAreaType(AREA_TYPE_ROOM, booking) ||
  checkByAreaType(AREA_TYPE_FREE_SPACES_ROOM, booking) ||
  forbidDoubleAwayOrHome(booking);

const forbidMeetingOutsideBuilding = (booking: Booking) =>
  forbidDoubleAwayOrHome(booking);

const forbidTelefonBoxBookingOutsideBuilding = (booking: Booking) =>
  forbidDoubleAwayOrHome(booking);

const forbidDoubleParking = (booking: Booking) =>
  checkByAreaType(AREA_TYPE_PARKING, booking) ||
  forbidDoubleAwayOrHome(booking);

// This function is to curried so is possible pass one argument per time ( map, reduce, filter )
export const checkFullDayActiveBookings = curry(
  (type: BookingType, isMultiBooker: boolean, booking: Booking) => {
    const fullDayOverlap = {
      [BOOKING_TYPE_ROOM]: () => !isMultiBooker && forbidDoubleDesk(booking),
      [BOOKING_TYPE_VIRTUAL_DESK]: () =>
        isMultiBooker && forbidDoubleDesk(booking),
      [BOOKING_TYPE_FREE_SPACES_ROOM]: () => forbidDoubleDesk(booking),
      [BOOKING_TYPE_MEETING_ROOM]: () =>
        !isMultiBooker && forbidMeetingOutsideBuilding(booking),
      [BOOKING_TYPE_TELEFON_BOX]: () =>
        !isMultiBooker && forbidTelefonBoxBookingOutsideBuilding(booking),
      [BOOKING_TYPE_PARKING_CHARGING]: () =>
        !isMultiBooker && forbidDoubleParking(booking),
      [BOOKING_TYPE_PARKING_FAST_CHARGING]: () =>
        !isMultiBooker && forbidDoubleParking(booking),
      [BOOKING_TYPE_PARKING_GENERAL]: () =>
        !isMultiBooker && forbidDoubleParking(booking),
      [BOOKING_TYPE_PARKING_HANDICAPPED]: () =>
        !isMultiBooker && forbidDoubleParking(booking),

      [BOOKING_TYPE_HOME]: () => forbidOutsideBuilding(booking),
      [BOOKING_TYPE_TRAVEL_DAY]: () => forbidOutsideBuilding(booking),
      [BOOKING_TYPE_VACATION]: () => forbidOutsideBuilding(booking),
      [BOOKING_TYPE_SICKDAY]: () => forbidOutsideBuilding(booking),
    };

    return fullDayOverlap[type]();
  },
);

/**********************************************************************
 *              HOURLY BOOKING TYPES COMBINATIONS CHECKS              *
 **********************************************************************/

const whitelistOr = (
  whitelist: AreaType[],
  areaType: AreaType | null,
  booking: Booking,
) =>
  areaType && whitelist.includes(areaType) && !forbidDoubleAwayOrHome(booking)
    ? null
    : booking;

const excludeComboForMeetingRooms = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [AREA_TYPE_PARKING, AREA_TYPE_ROOM, AREA_TYPE_FREE_SPACES_ROOM],
    areaType,
    booking,
  );
};

const excludeComboForTelefonBox = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [AREA_TYPE_PARKING, AREA_TYPE_ROOM, AREA_TYPE_FREE_SPACES_ROOM],
    areaType,
    booking,
  );
};

const excludeComboForDesks = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [AREA_TYPE_MEETING_ROOM, AREA_TYPE_PARKING, AREA_TYPE_TELEFON_BOX],
    areaType,
    booking,
  );
};

const excludeComboForParkingSpot = (booking: Booking) => {
  const areaType = pathOr(null, ['area', 'areaType'], booking);

  return whitelistOr(
    [
      AREA_TYPE_MEETING_ROOM,
      AREA_TYPE_ROOM,
      AREA_TYPE_FREE_SPACES_ROOM,
      AREA_TYPE_TELEFON_BOX,
    ],
    areaType,
    booking,
  );
};

const forbidBookingTypesCombo = (
  unavailableTimePeriods: BlockedTimeSlot[],
  booking: Booking,
): BlockedTimeSlot[] => {
  return [...unavailableTimePeriods, createBlockedTimeSlot(booking)];
};

const forbidOutsideBuildingSlot = curry(forbidBookingTypesCombo);

const forbidBookingTypesComboOrWhitelist = curry(
  (
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking | null,
  ): BlockedTimeSlot[] =>
    booking
      ? forbidBookingTypesCombo(unavailableTimePeriods, booking)
      : unavailableTimePeriods,
);

// TODO simplify next three functions
const checkBookingDesk = curry(
  (
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking | null,
  ): BlockedTimeSlot[] => {
    const bookedId = pathOr(null, ['desk', 'id'], booking);
    const { deskId: id } = unavailableTimePeriods[0] || {};

    return bookedId === id
      ? forbidBookingTypesCombo(unavailableTimePeriods, booking)
      : unavailableTimePeriods;
  },
);

const checkBookingParkingSpot = curry(
  (
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking | null,
  ): BlockedTimeSlot[] => {
    const bookedId = pathOr(null, ['parking', 'id'], booking);
    const { parkingId: id } = unavailableTimePeriods[0] || {};

    return bookedId === id
      ? forbidBookingTypesCombo(unavailableTimePeriods, booking)
      : unavailableTimePeriods;
  },
);

const checkBookingArea = curry(
  (
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking | null,
  ): BlockedTimeSlot[] => {
    const bookedId = pathOr(null, ['area', 'id'], booking);
    const { areaId: id } = unavailableTimePeriods[0] || {};

    return bookedId === id
      ? forbidBookingTypesCombo(unavailableTimePeriods, booking)
      : unavailableTimePeriods;
  },
);

const checkType = (type: BookingType) => {
  const isDesk = [BOOKING_TYPE_ROOM, BOOKING_TYPE_VIRTUAL_DESK].includes(type);
  const isParkingSpot = [
    BOOKING_TYPE_PARKING_GENERAL,
    BOOKING_TYPE_PARKING_CHARGING,
    BOOKING_TYPE_PARKING_FAST_CHARGING,
    BOOKING_TYPE_PARKING_HANDICAPPED,
  ].includes(type);
  const isArea = [BOOKING_TYPE_MEETING_ROOM, BOOKING_TYPE_TELEFON_BOX];

  return {
    isParkingSpot,
    isDesk,
    isArea,
  };
};

export const getActiveBookingsTimePeriods = curry(
  (
    type: BookingType,
    isMultiBooker: boolean,
    unavailableTimePeriods: BlockedTimeSlot[],
    booking: Booking,
  ): BlockedTimeSlot[] => {
    const { isParkingSpot, isDesk, isArea } = checkType(type);

    let getActiveBookingTimeSlots = forbidBookingTypesComboOrWhitelist(
      unavailableTimePeriods,
    );

    if (isMultiBooker && isDesk) {
      getActiveBookingTimeSlots = checkBookingDesk(unavailableTimePeriods);
    } else if (isMultiBooker && isParkingSpot) {
      getActiveBookingTimeSlots = checkBookingParkingSpot(
        unavailableTimePeriods,
      );
    } else if (isMultiBooker && isArea) {
      getActiveBookingTimeSlots = checkBookingArea(unavailableTimePeriods);
    }

    const getOutsideBuildingActiveBookingTimeSlots = forbidOutsideBuildingSlot(
      unavailableTimePeriods,
    );

    const fullDayOverlap = {
      [BOOKING_TYPE_ROOM]: () =>
        getActiveBookingTimeSlots(excludeComboForDesks(booking)),
      [BOOKING_TYPE_VIRTUAL_DESK]: () =>
        getActiveBookingTimeSlots(excludeComboForDesks(booking)),
      [BOOKING_TYPE_FREE_SPACES_ROOM]: () =>
        getActiveBookingTimeSlots(excludeComboForDesks(booking)),
      [BOOKING_TYPE_MEETING_ROOM]: () =>
        getActiveBookingTimeSlots(excludeComboForMeetingRooms(booking)),
      [BOOKING_TYPE_TELEFON_BOX]: () =>
        getActiveBookingTimeSlots(excludeComboForTelefonBox(booking)),
      [BOOKING_TYPE_PARKING_CHARGING]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_PARKING_FAST_CHARGING]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_PARKING_GENERAL]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_PARKING_HANDICAPPED]: () =>
        getActiveBookingTimeSlots(excludeComboForParkingSpot(booking)),
      [BOOKING_TYPE_HOME]: () =>
        getOutsideBuildingActiveBookingTimeSlots(booking),
      [BOOKING_TYPE_TRAVEL_DAY]: () =>
        getOutsideBuildingActiveBookingTimeSlots(booking),
      [BOOKING_TYPE_VACATION]: () =>
        getOutsideBuildingActiveBookingTimeSlots(booking),
      [BOOKING_TYPE_SICKDAY]: () =>
        getOutsideBuildingActiveBookingTimeSlots(booking),
    };

    return fullDayOverlap[type]();
  },
);
