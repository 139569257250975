import React from 'react';
import { Dimensions, View, Text } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { useIntl } from '@utils/intl';
import { withLizTitle } from '@views/shared/utils';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { topBarNavigatorStyles } from '@views/shared/styles';

import MyBookings from './MyBookings';
import MyAttendances from './MyAttendances';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
  tabStyle: topBarNavigatorStyles.tabStyle,
  contentContainerStyle: topBarNavigatorStyles.contentContainerStyle,
};

export default function Bookings() {
  const { t } = useIntl();
  const isTimeTrackingEnabled = useHasFeature('time_tracking');

  return isTimeTrackingEnabled ? (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        lazy
        initialLayout={{ width: Dimensions.get('window').width }}
        screenOptions={{ unmountOnBlur: true }}
        swipeEnabled={false}
      >
        <Tab.Screen
          name="bookings"
          component={MyBookings}
          options={() => ({
            tabBarLabel: ({ focused }) => (
              <Text
                style={[
                  topBarNavigatorStyles.tabLabel,
                  focused && topBarNavigatorStyles.tabLabelActive,
                ]}
              >
                {t('views.Bookings.title')}
              </Text>
            ),
            title: withLizTitle(t('views.Bookings.title')),
          })}
        />
        <Tab.Screen
          name="attendances"
          component={MyAttendances}
          options={() => ({
            tabBarLabel: ({ focused }) => (
              <Text
                style={[
                  topBarNavigatorStyles.tabLabel,
                  focused && topBarNavigatorStyles.tabLabelActive,
                ]}
              >
                {t('Profile.Attendances.Title')}
              </Text>
            ),
            title: withLizTitle(t('Profile.Attendances.Title')),
          })}
        />
      </Tab.Navigator>
    </View>
  ) : (
    <MyBookings />
  );
}
