import { pathOr } from 'ramda';

import { BookCategory } from '@views/shared/interfaces/booking';
import {
  AreaStructureForDesks,
  AreaStructureForFreeSeats,
  AreaStructureForMeetingRooms,
  AreaStructureForTelefonBox,
} from '@views/shared/interfaces/buildingStructure';

import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { getDeskSlots } from '@views/Book/Bookings/Desks/hooks';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { getAreaTypeByBookCategory } from '@views/shared/consts';

export function useDesksArea(area: AreaStructureForDesks) {
  const bookAnyDeskEnabled = useHasFeature('book_any_desk_for_room_type_area');
  const free = getDeskSlots(area, 'freeSlots', 0);
  const total = getDeskSlots(area, 'totalSlots', 1);

  return {
    worklight: getWorklightStatus(free, total),
    bookAnyDeskEnabled,
  };
}

export function useMeetingRoomArea(area: AreaStructureForMeetingRooms) {
  const { meetingRoomTimeStats } = area;
  const bookedTime = pathOr(0, ['booked'], meetingRoomTimeStats);
  const totalTime = pathOr(0, ['total'], meetingRoomTimeStats);

  const free = totalTime - bookedTime;

  return {
    notAvailable: free === 0,
    worklight: getWorklightStatus(free, totalTime),
  };
}

export function useTelefonBoxArea(area: AreaStructureForTelefonBox) {
  const { telefonBoxTimeStats } = area;
  const bookedTime = pathOr(0, ['booked'], telefonBoxTimeStats);
  const totalTime = pathOr(0, ['total'], telefonBoxTimeStats);

  const free = totalTime - bookedTime;

  return {
    notAvailable: free === 0,
    worklight: getWorklightStatus(free, totalTime),
  };
}

export function useSeatsArea(
  area: AreaStructureForFreeSeats,
  type: BookCategory,
) {
  const areaType = getAreaTypeByBookCategory(type);
  const free = getDeskSlots(area, 'freeSlots', 0, areaType);
  const total = getDeskSlots(area, 'totalSlots', 1, areaType);

  return {
    notAvailable: free === 0,
    worklight: getWorklightStatus(free, total),
  };
}
