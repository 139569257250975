import { useCallback } from 'react';

import { useCurrentDate, useSetCurrentDate } from '@views/Calendar/hooks';

import { useMyOrganization } from '@providers/Organization';

import { getTodayAsDayJs, getWorkingDays } from '@utils/DateAndTime';

export function useValues() {
  const date = getTodayAsDayJs();

  const currentDate = useCurrentDate();

  const setCurrentDate = useSetCurrentDate();
  const {
    workingDaysStart,
    workingDaysEnd,
    bookingRange,
  } = useMyOrganization();

  const onDateChange = useCallback(
    date => {
      setCurrentDate(date);
    },
    [setCurrentDate],
  );

  const workingDays = getWorkingDays({
    start: date,
    selectedDate: currentDate,
    workingDaysStart,
    workingDaysEnd,
    maxDays: bookingRange,
  });

  return { workingDays, onDateChange };
}
