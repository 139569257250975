import React, { useCallback } from 'react';
import { Text, ScrollView, View } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { useFocusEffect } from '@react-navigation/native';

import { useIntl } from '@utils/intl';
import { Loader } from '@views/shared/Loader';
import { BookingIcon } from '@views/shared/BookingIcon';
import { useFilterIcon } from '@views/Book/Filter/icon';
import { useFilterSelect } from '@views/Book/Filter/select';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { globalStyles, spacings } from '@views/shared/styles';

import {
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_ROOM,
  getBookingIcon,
  BOOK_CATEGORY_TELEFON_BOX,
} from '@views/shared/consts';
import { useFavourites } from '@views/Favourites/hooks';
import { FavouriteCount } from '@views/Book/Bookings/FavouriteCount';

import { styles } from '@views/Book/Bookings/Panel';
import { BookCategory } from '@views/shared/interfaces/booking';

import { useStructure } from './hooks';
import Floor, {
  withDesks,
  withFreeSeats,
  withMeetingRooms,
  withParkingSpots,
  withTelefonBox,
} from './Floor';

// TODO incr2
// enable favoritize of parking spots

const getCountDesc = (type: string, count: number) => {
  const translation = {
    [BOOK_CATEGORY_MEETING_ROOM]: 'views.Home.Bookings.MeetingRooms.total',
    [BOOK_CATEGORY_ROOM]: 'views.Home.Bookings.Desks.total',
    [BOOK_CATEGORY_FREE_SPACES_ROOM]: 'views.Home.Bookings.Areas.total',
    [BOOK_CATEGORY_PARKING]: 'Home.Structure.Parkings.Total',
    [BOOK_CATEGORY_TELEFON_BOX]: 'views.Home.Bookings.TelefonBox.total',
  };

  const templateVars =
    type === BOOK_CATEGORY_ROOM ? { totalDesks: count } : { total: count };

  return {
    translation: translation[type],
    templateVars,
  };
};

function getFloorByType(type: BookCategory) {
  const floorTypes = {
    [BOOK_CATEGORY_FREE_SPACES_ROOM]: withFreeSeats()(Floor),
    [BOOK_CATEGORY_MEETING_ROOM]: withMeetingRooms()(Floor),
    [BOOK_CATEGORY_PARKING]: withParkingSpots()(Floor),
    [BOOK_CATEGORY_ROOM]: withDesks()(Floor),
    [BOOK_CATEGORY_TELEFON_BOX]: withTelefonBox()(Floor),
  };

  return floorTypes[type];
}

export default function Structure() {
  const { t } = useIntl();
  const { showFilterIcon } = useFilterIcon();
  const { showFilterSelect } = useFilterSelect();
  const isDesktop = useIsDesktop();

  const {
    count,
    error,
    floors,
    isFilterUsed,
    isFilterEnabledByType,
    loading,
    type,
  } = useStructure(t);

  useFocusEffect(
    useCallback(() => {
      if (isFilterEnabledByType) {
        showFilterSelect(false);
        showFilterIcon();
      }
      return () => showFilterIcon(false);
    }, [isFilterEnabledByType, showFilterIcon, showFilterSelect]),
  );

  const { favCount, goToFavourites } = useFavourites(type);

  const { translation, templateVars } = getCountDesc(type, count);

  if (loading) {
    return <Loader />;
  }

  if (error || !floors) {
    return <Text>GraphQL Error: Structure</Text>;
  }

  const isOpen = isFilterEnabledByType && isFilterUsed;

  const FloorForBookCategory = getFloorByType(type);

  return (
    <ScrollView>
      <View style={isDesktop && { minWidth: 924, marginHorizontal: 'auto' }}>
        <SafeAreaView>
          <View
            style={[
              {
                margin: spacings.smallMedium,
                marginTop: 0,
              },
              isDesktop && { marginHorizontal: 0 },
            ]}
          >
            <View style={globalStyles.shadow}>
              <View style={styles.row}>
                <View style={styles.left}>
                  <BookingIcon name={getBookingIcon(type)} />
                </View>

                <View style={styles.middle}>
                  <Text style={globalStyles.title}>
                    {t(`Home.Structure.Title.${type}`)}
                  </Text>
                  {translation && (
                    <Text style={globalStyles.description}>
                      {t(translation, templateVars)}
                    </Text>
                  )}
                </View>
                {![BOOK_CATEGORY_PARKING, BOOK_CATEGORY_TELEFON_BOX].includes(
                  type,
                ) && ( //incr2
                  <FavouriteCount
                    count={favCount}
                    onPress={goToFavourites}
                    style={styles.favIcon}
                  />
                )}
              </View>
            </View>
          </View>
        </SafeAreaView>
        <ScrollView
          style={[
            globalStyles.container,
            isDesktop && { paddingHorizontal: 0 },
          ]}
        >
          {floors.map(floor => (
            <FloorForBookCategory key={floor.id} floor={{ ...floor, isOpen }} />
          ))}
        </ScrollView>
      </View>
    </ScrollView>
  );
}
