import React from 'react';
import { Dimensions, View, Text } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { useMyUser } from '@providers/User';
import { useIntl } from '@utils/intl';
import { withLizTitle } from '@views/shared/utils';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import { topBarNavigatorStyles } from '@views/shared/styles';

import { DepartmentsView } from './Department';
import { AlphabeticalView } from './Alphabetical';
import { WhosInList } from './WhosIn';
import FavouritesView from './Favourites';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
  tabStyle: topBarNavigatorStyles.tabStyleAutoWidth,
  contentContainerStyle: topBarNavigatorStyles.contentContainerStyle,
  scrollEnabled: true,
};

export function List() {
  const { t } = useIntl();
  const {
    permissions: { canSeeColleaguesWhoisin },
  } = useMyUser();

  const tabs = [
    {
      name: 'favourites',
      label: 'Colleagues.List.Screen.Favourites',
      item: FavouritesView,
    },
    {
      name: 'departments',
      label: 'Colleagues.List.Screen.departments',
      item: DepartmentsView,
    },
    {
      name: 'whosin',
      label: 'Colleagues.List.Screen.whosin',
      item: WhosInList,
      disabled:
        useHasFeature('disable_show_whoisin') || !canSeeColleaguesWhoisin,
    },
    {
      name: 'alphabetical',
      label: 'Colleagues.List.Screen.alphabetical',
      item: AlphabeticalView,
    },
  ];

  return (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        lazy
        initialLayout={{ width: Dimensions.get('window').width }}
        screenOptions={{ unmountOnBlur: true }}
        swipeEnabled={false}
      >
        {tabs.map(({ label, item, name, disabled = false }, idx) =>
          disabled ? null : (
            <Tab.Screen
              key={idx}
              options={() => ({
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={[
                      topBarNavigatorStyles.tabLabel,
                      focused && topBarNavigatorStyles.tabLabelActive,
                    ]}
                  >
                    {t(label)}
                  </Text>
                ),
                title: withLizTitle(t(label)),
              })}
              name={name}
            >
              {({ navigation }) =>
                navigation.isFocused() ? React.createElement(item, {}) : null
              }
            </Tab.Screen>
          ),
        )}
      </Tab.Navigator>
    </View>
  );
}
