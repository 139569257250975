import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useMyUser } from '@providers/User';

export function useContent() {
  const navigation = useNavigation<any>();
  const {
    avatar,
    department,
    email,
    error,
    initials,
    loading,
    myBuilding,
    name,
    phoneNumber,
    licensePlate,
  } = useMyUser();
  const edit = useCallback(() => {
    navigation.navigate('edit');
  }, [navigation]);

  return {
    me: {
      email,
      name,
      initials,
      userprofile: {
        avatar,
        department,
        myBuilding,
        phoneNumber,
        licensePlate,
      },
    },
    loading,
    edit,
    error,
  };
}
