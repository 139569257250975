import { useCallback } from 'react';

import { useMyUser } from '@providers/User';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useMyOrganization } from '@providers/Organization';
import {
  getNextDays,
  getNextDayFromThis,
  getTodayAsDayJs,
  getWorkingDaysOfWeek,
  isAfter,
} from '@utils/DateAndTime';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_HOME,
  BOOKING_TYPE_ROOM,
  BOOKING_TYPE_TRAVEL_DAY,
  BOOKING_TYPE_PARKING_CHARGING,
  BOOKING_TYPE_PARKING_FAST_CHARGING,
  BOOKING_TYPE_PARKING_GENERAL,
  BOOKING_TYPE_PARKING_HANDICAPPED,
} from '@views/shared/consts';
import { useGoTo } from '@views/shared/utils/useGoTo';

import { useSetCurrentDate } from '@views/Calendar/hooks';
import { BookingProps } from '@views/Book/Book/NextDaysBooking/FullDayBooking/interfaces';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

export default function useValues({ currentBookedType, params }: BookingProps) {
  const homeOfficeEnabled = useHasFeature('home_office');
  const travelDayEnabled = useHasFeature('travel_day');

  const {
    permissions: {
      canBookDesk,
      canBookFreeSpace,
      canBookHomeOffice,
      canBookTravelDay,
      canBookParkingSpot,
    },
  } = useMyUser();

  const date = useCurrentDate();
  const setCurrentDate = useSetCurrentDate();
  const {
    workingDaysStart,
    workingDaysEnd,
    bookingRange,
  } = useMyOrganization();
  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );

  const bookableDays = getNextDays({
    start: date,
    enabledWorkingDays,
    includeStartDate: true,
  });
  const { goToBookingPage, goToStructurePage } = useGoTo();

  const goToPage = useCallback(
    (date, type) => {
      setCurrentDate(date);

      if (
        [
          BOOKING_TYPE_HOME,
          BOOKING_TYPE_TRAVEL_DAY,
          currentBookedType,
        ].includes(type)
      ) {
        goToBookingPage({
          type,
          ...params,
        });
      } else {
        goToStructurePage(type);
      }
    },
    [
      goToBookingPage,
      goToStructurePage,
      setCurrentDate,
      params,
      currentBookedType,
    ],
  );

  const maxAvailableDate = getNextDayFromThis(getTodayAsDayJs(), bookingRange);
  const isDisabledToBook = useCallback(
    date => bookingRange > 0 && isAfter(date, maxAvailableDate),
    [bookingRange, maxAvailableDate],
  );

  const homeAndTravelTypes = {
    [BOOKING_TYPE_HOME]: canBookHomeOffice && homeOfficeEnabled,
    [BOOKING_TYPE_TRAVEL_DAY]: canBookTravelDay && travelDayEnabled,
  };

  const bookableTypes = {
    [BOOKING_TYPE_ROOM]: {
      [BOOKING_TYPE_ROOM]: canBookDesk,
      ...homeAndTravelTypes,
    },
    [BOOKING_TYPE_FREE_SPACES_ROOM]: {
      [BOOKING_TYPE_FREE_SPACES_ROOM]: canBookFreeSpace,
    },
    [BOOKING_TYPE_HOME]: homeAndTravelTypes,
    [BOOKING_TYPE_TRAVEL_DAY]: homeAndTravelTypes,
    [BOOKING_TYPE_PARKING_CHARGING]: {
      [BOOKING_TYPE_ROOM]: canBookDesk,
      [BOOKING_TYPE_PARKING_CHARGING]: canBookParkingSpot,
    },
    [BOOKING_TYPE_PARKING_FAST_CHARGING]: {
      [BOOKING_TYPE_ROOM]: canBookDesk,
      [BOOKING_TYPE_PARKING_FAST_CHARGING]: canBookParkingSpot,
    },
    [BOOKING_TYPE_PARKING_GENERAL]: {
      [BOOKING_TYPE_ROOM]: canBookDesk,
      [BOOKING_TYPE_PARKING_GENERAL]: canBookParkingSpot,
    },
    [BOOKING_TYPE_PARKING_HANDICAPPED]: {
      [BOOKING_TYPE_ROOM]: canBookDesk,
      [BOOKING_TYPE_PARKING_HANDICAPPED]: canBookParkingSpot,
    },
  };

  return {
    goToPage,
    bookableDays,
    isDisabledToBook,
    types: bookableTypes[currentBookedType],
  };
}
