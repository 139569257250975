import React, { useState } from 'react';
import { RecoilRoot } from 'recoil';
import AppLoading from 'expo-app-loading';

import AppWrapper from '@views/AppWrapper';

import { loadAssets } from './assets';
import * as firebase from './firebase';

export default function App() {
  return (
    <RecoilRoot>
      <AppRoot />
    </RecoilRoot>
  );
}

function AppRoot() {
  const [assetsLoaded, setAssetsLoaded] = useState(false);

  //We will show AppLoading till all resources will be loaded
  if (!assetsLoaded) {
    return (
      <AppLoading
        // @ts-ignore
        startAsync={loadAssets}
        onFinish={() => {
          setAssetsLoaded(true);
        }}
        onError={console.error}
      />
    );
  }

  return <AppWrapper />;
}

firebase.initialize();
