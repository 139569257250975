import { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/native';
import { gql } from '@apollo/client';
import { isNil, pathOr } from 'ramda';

import { Formats } from '@utils/intl';
import { useErrorQuery } from '@providers/Errors';
import { universalDateFormatter } from '@utils/DateAndTime';
import {
  useBaseValues,
  AREAS_BY_IDS_QUERY,
} from '@views/Book/Book/BasicBook/hooks';
import { BOOKING_TYPE_ROOM } from '@views/shared/consts';
import { QueryDeskData } from '@views/Book/Book/BasicBook/interfaces';
import { Area, Desk } from '@views/shared/interfaces/buildingStructure';

const getDeskQuery = gql`
  ${AREAS_BY_IDS_QUERY}
  query getDesk($areaId: ID!, $deskId: ID, $date: DateTime!) {
    desksByIds(desksIds: [$deskId], date: $date) {
      equipment {
        category {
          name
        }
        name
      }
      label
      numberOnFloor
      type
    }
    areasByIds(areasIds: [$areaId]) {
      ...BookingArea
    }
  }
`;

interface State {
  area?: Area;
  desk?: Desk;
}

export function useValues(formats: Formats, message: string) {
  const {
    params: { areaId, deskId },
  } = useRoute<any>();

  const { currentDate, ...rest } = useBaseValues(formats);
  const [deskData, setDeskData] = useState<State>({});

  const { data, error, loading } = useErrorQuery<QueryDeskData>(getDeskQuery, {
    fetchPolicy: 'no-cache',
    variables: {
      areaId,
      deskId,
      date: universalDateFormatter({ date: currentDate }),
    },
    finderError: {
      type: 'fatal',
      message,
    },
  });

  useEffect(() => {
    if (!isNil(data)) {
      const desk = pathOr(null, ['desksByIds', 0], data);
      const area = pathOr(null, ['areasByIds', 0], data);
      const newState = {
        ...(area ? { area } : {}),
        ...(desk ? { desk } : {}),
      };

      setDeskData(newState);
    }
  }, [data]);

  return {
    ...rest,
    areaId,
    currentDate,
    data: deskData,
    deskId,
    error,
    loading,
    type: BOOKING_TYPE_ROOM,
    hasNextDaysBooking: true,
  };
}
