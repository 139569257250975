import React from 'react';
import { StyleSheet, TouchableHighlight, View, Text } from 'react-native';
import { useIntl } from '@utils/intl';
import { CustomIcon } from '@views/shared/CustomIcon';

import { colors, globalStyles } from '@views/shared/styles';
import { styles } from '@views/Home/Profile/Overview/CalendarAuth/Google';

import { useMicrosoft } from './hooks';

const { white, msBlue } = colors;

const msColors = StyleSheet.create({
  button: {
    backgroundColor: white,
    borderColor: msBlue,
  },
  icon: {
    color: msBlue,
    marginTop: -1,
    marginLeft: 0,
  },
});

export function Microsoft() {
  const { t } = useIntl();
  const { toggle, checkedIn } = useMicrosoft();

  return (
    <TouchableHighlight underlayColor="transparent" onPress={toggle}>
      <View style={[globalStyles.shadow, styles.container]}>
        <View style={styles.imageContainer}>
          <CustomIcon
            name="microsoft-office"
            style={[styles.icon, msColors.icon]}
          />
        </View>
        <Text style={styles.title}>
          {t(
            checkedIn
              ? 'Profile.Content.Microsoft.out'
              : 'Profile.Content.Microsoft.button',
          )}
        </Text>
      </View>
    </TouchableHighlight>
  );
}
