import React, { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';
import { isNil } from 'ramda';

import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { CustomIcon } from '@views/shared/CustomIcon';

import { useFilter } from './hooks';
import { styles } from './styles';
import { FilterIconProps } from './interfaces';

export default function FilterIcon(props: FilterIconProps) {
  const { navigation } = props;
  const hasBookingFilterFeature = useHasFeature('booking_filter');
  const { isFilterIconVisible } = useFilterIcon();

  const { name = null, params = null } = isNil(navigation)
    ? {}
    : navigation.getCurrentRoute();

  const showFilterSelect = name === 'filter' || isFilterIconVisible;

  return showFilterSelect && hasBookingFilterFeature ? (
    <IconComponent {...props} type={params?.type} />
  ) : null;
}

function IconComponent({ navigation, color, type }: FilterIconProps) {
  const filterData = useFilter();
  const { isFilterUsed = false } = filterData[type] || {};

  const goToFilterPage = useCallback(
    () =>
      navigation.navigate('book', {
        screen: 'filter',
        params: { name: 'book', screen: 'structure', type },
      }),
    [navigation, type],
  );

  return (
    <CustomIcon
      name={`filter${isFilterUsed ? '-inv' : ''}`}
      onPress={goToFilterPage}
      size={28}
      color={color}
      style={styles.filterIcon}
    />
  );
}

const isShowFilter = atom({
  key: 'filterIcon/show',
  default: false,
});

export function useFilterIcon() {
  const [isFilterIconVisible, setFilterIconVisible] = useRecoilState(
    isShowFilter,
  );

  const showFilterIcon = useCallback(
    (show = true) => setFilterIconVisible(show),
    [setFilterIconVisible],
  );

  return {
    isFilterIconVisible,
    showFilterIcon,
  };
}
