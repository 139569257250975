import React, { useCallback } from 'react';

import { AreaWithStatus } from 'views/shared/interfaces/floorplan';
import { useGoTo } from '@views/shared/utils/useGoTo';
import { AvailabilityInfo } from 'views/shared/interfaces/floorplan';

import { Area } from './Area';
import { useArea } from './hooks';

interface ComponentProps {
  ids: string[];
  showAvailability: (info?: AvailabilityInfo) => any;
  onLoading: (type: string, loading: boolean) => any;
}

export default function Areas({
  ids,
  showAvailability,
  onLoading,
}: ComponentProps) {
  const { goToBookingPage } = useGoTo();
  const { areas, scale } = useArea({ ids, onLoading });

  const bookArea = useCallback(
    (area: AreaWithStatus) => {
      const { id: areaId, areaType: type } = area;

      goToBookingPage({ areaId, type });
    },
    [goToBookingPage],
  );

  return (
    <>
      {areas.map((area, index) => (
        <Area
          area={area}
          key={index}
          bookArea={bookArea}
          scale={scale}
          showAvailability={showAvailability}
        />
      ))}
    </>
  );
}
