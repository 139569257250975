import React, { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import { Avatar } from 'react-native-elements';

import { useMyUser } from '@providers/User';
import { useMyOrganization } from '@providers/Organization';
import { avatarWithBadgeStyle } from '@views/shared/styles';
import { fonts, spacings } from '@views/shared/styles';
import { useIntl } from '@utils/intl';

const { large, smallMedium } = spacings;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: large,
  },
  title: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
    marginTop: smallMedium,
  },
});

export default function Profile() {
  const { t } = useIntl();
  const navigation = useNavigation<any>();
  const gotoOverview = useCallback(() => {
    navigation.navigate('home', {
      screen: 'overview',
    });
  }, [navigation]);

  const { avatar, initials, name } = useMyUser();
  const { backgroundColor, isWhiteBackground } = useMyOrganization();

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={gotoOverview}>
        <Avatar
          rounded
          size={50}
          source={avatar && avatar.trim().length ? { uri: avatar } : undefined}
          title={initials}
          titleStyle={[avatarWithBadgeStyle.title]}
          placeholderStyle={[avatarWithBadgeStyle.placeholder]}
          overlayContainerStyle={[avatarWithBadgeStyle.avatarOverlayContainer]}
          containerStyle={[
            avatarWithBadgeStyle.availableAvatarContainer,
            { borderColor: backgroundColor },
            isWhiteBackground && avatarWithBadgeStyle.spaceBorder,
          ]}
        />
      </TouchableOpacity>

      <Text style={styles.title}>{t('Profile.Content.Hello', { name })}</Text>
    </View>
  );
}
