import React from 'react';
//import { Event } from '@views/shared/Events/interfaces';

export interface InitialLettersApiResponse {
  organizationUsersInitialLetters: organizationUsersInitialLettersType[];
}

interface organizationUsersInitialLettersType {
  letter: string;
  count: number;
}

export interface DepartmentsApiResponse {
  organizationDepartments: OrganizationDepartmentType[];
}

interface OrganizationDepartmentType {
  id: string;
  name: string;
  count: number;
}

export interface Colleague {
  id: string;
  email: string;
  name: string;
  userprofile: UserProfile;
  availabilityStatus?: AvailabilityType;
  // calendarEvents: Event;
  department: DepartmentType;
}

export interface OrganizationUserApiResponse {
  organizationUsers: {
    users: Colleague[];
    count: number;
  };
}

interface UserProfile {
  avatar: string;
  phoneNumber: string;
  department: DepartmentType;
  visibleForColleagues: boolean;
  visibleCalendar: boolean;
  visibleHomeoffice: boolean;
  licensePlate: string;
}

interface DepartmentType {
  id: string;
  name: string;
}

interface AvailabilityType {
  presenceStatus: UserAvailabilityStatus;
  presenceTypes: PresenceStatus[];
}

export type UserAvailabilityStatus = 'UNKNOWN' | 'AVAILABLE' | 'OCCUPIED';

type PresenceStatus =
  | 'office'
  | 'meeting'
  | 'homeoffice'
  | 'travelday'
  | 'vacation'
  | 'absent'
  | 'disallowed';

export interface ColleagueLight {
  id: string;
  name: string;
  avatar: string;
  department: DepartmentType;
  email: string;
  phoneNumber: string;
  licensePlate: string;
  visibleHomeoffice: boolean;
  visibleForColleagues: boolean;
  visibleCalendar: boolean;
  presenceStatus: UserAvailabilityStatus;
  goToProfile: () => void;
  icon: string[];
  initials: string;
  status: PresenceStatus[];
  badgeColor: badgeStatus;
}

type badgeStatus = 'warning' | 'success' | 'error';

export enum STATUS {
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Item = {
  id?: string;
  name?: string;
  letter?: string;
  count: number;
};

export interface ListItemProps {
  item: Item;
  title: string;
  ItemRenderer: React.ReactElement;
}

export interface ListRenderProps {
  item: Item;
  limit: number;
}
