import React, { useCallback } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ImageBackground,
  TouchableOpacity,
} from 'react-native';
import { useRecoilState } from 'recoil';

import { useIntl } from '@utils/intl';
import MyProfile from '@views/Home/Profile/desktop';
import { FilterComponent } from '@views/TopBar';
import { useMyOrganization, ICON_COLORS } from '@providers/Organization';
import { colors, fonts, spacings } from '@views/shared/styles';
import { Logo } from '@views/TopBar/Logo';
import { getNaviIcon } from '@views/Routes';
import { activeWebTabAtom } from '@views/Routes/WebRoutes';

const { smallMedium, extraOversize } = spacings;
const { black, blackAlpha, white, aquaMarine, grey2 } = colors;
const { sizeDefault } = fonts;

const styles = StyleSheet.create({
  header: {
    padding: smallMedium,
    borderBottomColor: blackAlpha,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
  },
  name: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeDefault,
    textAlign: 'left',
    paddingTop: smallMedium,
    paddingLeft: smallMedium,
  },
  imageContainer: {
    width: 150,
    height: 40,
    alignSelf: 'center',
    position: 'absolute',
    left: '49%',
  },
  logo: {
    height: 40,
    width: '100%',
  },
  selected: {
    color: aquaMarine,
  },
  tabContainer: {
    marginRight: extraOversize,
    alignItems: 'center',
  },
  firstContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

const NavigationTab = ({ tab, screen, goTo }) => {
  const { t } = useIntl();
  const focused = (tab === 'index' ? 'home' : tab) === screen;

  return (
    <TouchableOpacity onPress={() => goTo(screen)}>
      <View style={styles.tabContainer}>
        {getNaviIcon({
          color: focused ? aquaMarine : grey2,
          focused,
          name: screen,
          size: 30,
        })}
        <Text style={focused && styles.selected}>
          {t(`Routes.tabs.${screen}`)}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default function Sidebar({ navigation, showActionsPage, canUseBooker }) {
  const { name, logo, iconColor } = useMyOrganization();
  const contrastColor = iconColor === ICON_COLORS.DARK ? black : white;
  const [activeWebTab] = useRecoilState(activeWebTabAtom);

  const goTo = useCallback(
    screen => {
      navigation.navigate(screen);
    },
    [navigation],
  );

  return (
    <View style={styles.header}>
      <View style={styles.firstContainer}>
        <Logo color={contrastColor} />
        {canUseBooker && !showActionsPage && (
          <View style={{ flexDirection: 'row', marginLeft: '4vw' }}>
            <NavigationTab tab={activeWebTab} screen="home" goTo={goTo} />
            <NavigationTab tab={activeWebTab} screen="bookings" goTo={goTo} />
            <NavigationTab tab={activeWebTab} screen="floorplan" goTo={goTo} />
            <NavigationTab tab={activeWebTab} screen="colleagues" goTo={goTo} />
          </View>
        )}
      </View>
      <View style={styles.imageContainer}>
        {logo ? (
          <ImageBackground
            source={{ uri: logo }}
            style={styles.logo}
            resizeMode="contain"
          />
        ) : (
          <Text style={[styles.name, { color: contrastColor }]}>{name}</Text>
        )}
      </View>
      {!showActionsPage && (
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <View>
            <FilterComponent navigation={navigation} color={contrastColor} />
          </View>
          <TouchableOpacity onPress={() => goTo('myProfile')}>
            <MyProfile />
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
}
