import { useEffect, useState } from 'react';

import { gql, useQuery } from '@apollo/client';

import { useMyUser } from '@providers/User';

import {
  Item,
  QueryAdditionalOrderData,
} from '@views/Book/Book/MeetingRoom/AdditionalOrder/types';

const itemsQuery = gql`
  query additionalOrder($language: LanguageEnumType) {
    cateringItems(language: $language) {
      itemId: id
      title
      description
    }
    equippingItems(language: $language) {
      itemId: id
      title
      description
    }
  }
`;

export default function useItems() {
  const { language } = useMyUser();
  const [cateringItems, setCateringItems] = useState<Item[]>([]);
  const [equippingItems, setEquippingItems] = useState<Item[]>([]);

  const { data } = useQuery<QueryAdditionalOrderData>(itemsQuery, {
    variables: {
      language,
    },
  });

  useEffect(() => {
    if (data?.cateringItems || data?.equippingItems) {
      setCateringItems(data.cateringItems || []);
      setEquippingItems(data.equippingItems || []);
    }
  }, [data]);

  return {
    cateringItems,
    equippingItems,
  };
}
