import { useCallback, useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { compose, head, sortWith, descend, prop, isNil } from 'ramda';
import dayjs from 'dayjs';

import { universalDateFormatter, dateFormatShort } from '@utils/DateAndTime';

const getUserAttendances = gql`
  query userAttendances($startDate: Date, $endDate: Date) {
    userAttendances(startDate: $startDate, endDate: $endDate) {
      id
      status
      startTime
      endTime
      date
      totalTimeMinutes
      attendanceBreaks {
        id
        attendanceId
        status
        startTime
        endTime
        totalTimeMinutes
        date
      }
    }
    userAttendanceBreaks(startDate: $startDate, endDate: $endDate) {
      id
      attendanceId
      startTime
      endTime
      status
      totalTimeMinutes
      date
    }
  }
`;

export const STATUS = {
  PROGRESS: 'IN_PROGRESS',
  BREAK: 'ON_BREAK',
  OPEN: 'OPEN',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
};

export function useAttendance() {
  const [isOpen, setIsOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const [attendanceId, setAttendanceId] = useState();
  const [minutes, setMinutes] = useState(0);
  const [breakMinutes, setBreakMinutes] = useState(0);
  const [totalBreak, setTotalBreak] = useState(0);
  const [mode, setMode] = useState('');
  const [attendanceStatus, setAttendanceStatus] = useState();
  const [lastBlockedTime, setLastBlockedTime] = useState(null);

  const date = new Date();

  const { data, loading, error, refetch: onComplete } = useQuery(
    getUserAttendances,
    {
      variables: {
        startDate: universalDateFormatter({ date, format: dateFormatShort }),
        endDate: universalDateFormatter({ date, format: dateFormatShort }),
      },
      fetchPolicy: 'no-cache',
    },
  );

  const onToggle = useCallback(
    value => {
      setIsOpen(prevState => !prevState);
      setMode(value);
    },
    [setIsOpen, setMode],
  );

  const onPageLayout = useCallback(
    ({
      nativeEvent: {
        layout: { height },
      },
    }) => setHeight(height),
    [setHeight],
  );

  useEffect(() => {
    if (data?.userAttendances) {
      const totalMinutes = data.userAttendances.reduce(
        (acc, { totalTimeMinutes }) => acc + totalTimeMinutes,
        0,
      );

      const totalBreak = data.userAttendanceBreaks.reduce(
        (acc, { totalTimeMinutes }) => acc + totalTimeMinutes,
        0,
      );

      const completeAttendance = [
        ...data.userAttendances,
        ...data.userAttendanceBreaks,
      ];

      const blockedHours = completeAttendance.reduce(
        (acc, { startTime, endTime }) => {
          const start = !isNil(startTime)
            ? dayjs(startTime).valueOf()
            : startTime;
          const end = !isNil(endTime) ? dayjs(endTime).valueOf() : endTime;

          if (!isNil(start) && !acc.includes(start)) {
            acc.push(start);
          }

          if (!isNil(end) && !acc.includes(end)) {
            acc.push(end);
          }

          return acc;
        },
        [],
      );

      const lastRecord = compose(
        head,
        sortWith([descend(compose(parseInt, prop('id')))]),
      )(data.userAttendances);

      const { id, status } = lastRecord || {};

      const lastBreakRecord =
        status === STATUS.BREAK
          ? compose(
              head,
              sortWith([descend(compose(parseInt, prop('id')))]),
            )(lastRecord.attendanceBreaks)
          : null;

      const { totalTimeMinutes: currentBreakMin } = lastBreakRecord
        ? lastBreakRecord
        : 0;

      setMinutes(totalMinutes);
      setBreakMinutes(currentBreakMin);
      setTotalBreak(totalBreak);
      setAttendanceId(id);
      setAttendanceStatus(status);
      setLastBlockedTime(
        blockedHours.length ? Math.max(...blockedHours) : null,
      );
    }
  }, [data]);

  return {
    onToggle,
    isOpen,
    onPageLayout,
    height,
    minutes,
    totalBreak,
    breakMinutes,
    mode,
    attendanceId,
    attendanceStatus,
    loading,
    error,
    onComplete,
    lastBlockedTime,
  };
}
