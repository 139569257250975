import React from 'react';
import { isNil, isEmpty, pick } from 'ramda';
import { Text } from 'react-native';

import { useIntl } from '@utils/intl';
import { useHasFeature } from '@views/shared/hooks/hasFeature';

import {
  FLOOR_TYPE_PARKING,
  FLOOR_TYPE_MIXED,
  AREA_TYPE_ROOM,
  AREA_TYPE_PARKING,
} from '@views/shared/consts';
import { globalStyles } from '@views/shared/styles';

import {
  AreaType,
  FloorTypes,
  ParkingSpotType,
} from '@views/shared/interfaces/buildingStructure';
import { Translator } from '@utils/intl';

export interface BuildingMinimalInfos {
  name: string;
  address: string;
}

export interface FloorMinimalInfos {
  floorLabel?: string;
  floorNumber: number;
  floorType: FloorTypes;
}

interface PointProps {
  label: string;
  numberOnFloor: number;
  type: ParkingSpotType | string;
}

interface FloorProps extends FloorMinimalInfos {
  t: Translator;
}

interface PointLabelProps {
  areaType?: AreaType;
  point: PointProps;
  showDeskNumber?: boolean;
  t: any;
  showDeskTranslation?: boolean;
}

interface LocationProps extends FloorProps {
  areaType: AreaType;
  areaName: string;
  buildingName: string;
  buildingAddress?: string;
  point?: PointProps;
  showDeskNumber?: boolean;
  showDeskTranslation?: boolean;
}

export function getBuildingInfo(building: BuildingMinimalInfos) {
  return { buildingName: building.name, buildingAddress: building.address };
}

export function getFloorInfo(floor: FloorMinimalInfos) {
  return pick(['floorLabel', 'floorNumber', 'floorType'])(floor);
}

export function getFloorName({
  floorLabel,
  floorNumber,
  floorType,
  t,
}: FloorProps) {
  const floorTypes = {
    [FLOOR_TYPE_MIXED]: () =>
      isNil(floorLabel) || isEmpty(floorLabel)
        ? t('Home.ActiveBookings.Booking.floor_number', {
            number: floorNumber,
          })
        : floorLabel.trim(),
    [FLOOR_TYPE_PARKING]: () =>
      isNil(floorLabel) || isEmpty(floorLabel)
        ? `P - ${floorNumber}`
        : `P - ${floorLabel.trim()}`,
  };

  return floorTypes[floorType]();
}

export function getDeskOrParkingLabel({
  areaType = AREA_TYPE_ROOM,
  point,
  t,
  showDeskNumber,
  showDeskTranslation = false,
}: PointLabelProps) {
  const { label = '', numberOnFloor = null } = point;
  const translationKey =
    areaType === AREA_TYPE_PARKING
      ? 'Home.Structure.Floor.Area.Parkings.Spot.empty_label'
      : 'Home.Structure.Floor.Area.Desks.Desk.empty_label';
  const deskTranslation = t(translationKey);

  let deskLabel = label;

  // this will be used only on fav page and desk booking page
  if (showDeskTranslation && isEmpty(label)) {
    deskLabel = deskTranslation;
  }

  // with enabled feature flag the desk will be shown with the number: Desk (7)
  // if the label is available: BossDesk (1)
  if (showDeskNumber && !isNil(numberOnFloor)) {
    deskLabel = isEmpty(label) ? deskTranslation : label;
    return `${deskLabel} (${numberOnFloor})`;
  }

  return deskLabel;
}

const availableAreas = [AREA_TYPE_PARKING, AREA_TYPE_ROOM];

export function getPointFullLabel({
  areaType,
  areaName,
  buildingName,
  floorLabel,
  floorNumber,
  floorType,
  point,
  showDeskNumber = false,
  showDeskTranslation = false,
  t,
}: LocationProps) {
  let pointLabel = '';

  const floorName = getFloorName({
    floorLabel,
    floorNumber,
    floorType,
    t,
  });

  if (point && availableAreas.includes(areaType)) {
    pointLabel = `, ${getDeskOrParkingLabel({
      areaType,
      point,
      t,
      showDeskNumber,
      showDeskTranslation,
    })}`;
  }

  const floorTypes = {
    [FLOOR_TYPE_MIXED]: () =>
      `${buildingName}, ${floorName}, ${areaName}${pointLabel}`,
    [FLOOR_TYPE_PARKING]: () => `${buildingName}, ${floorName}${pointLabel}`,
  };

  return floorTypes[floorType]();
}

export default function PointFullLabel({
  areaType,
  areaName,
  buildingName,
  floorLabel,
  floorNumber,
  floorType,
  point,
  showDeskTranslation = false,
}: LocationProps) {
  const { t } = useIntl();
  const showDeskNumber = useHasFeature('desk_number_on_floor');

  let pointLabel = '';
  let areaLabel = `, ${areaName}`;

  const floorName = getFloorName({
    floorLabel,
    floorNumber,
    floorType,
    t,
  });

  if (point && availableAreas.includes(areaType)) {
    pointLabel = getDeskOrParkingLabel({
      areaType,
      point,
      t,
      showDeskNumber,
      showDeskTranslation,
    });
  }

  if (!availableAreas.includes(areaType)) {
    areaLabel = '';
    pointLabel = areaName;
  }

  return (
    <>
      <Text style={globalStyles.title}>{pointLabel}</Text>
      <Text style={globalStyles.description}>
        {buildingName}, {floorName}
        {areaLabel}
      </Text>
    </>
  );
}
