import React from 'react';
import { Dimensions, View, Text, StyleSheet } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { useIntl } from '@utils/intl';
import { withLizTitle } from '@views/shared/utils';
import {
  colors,
  spacings,
  fonts,
  topBarNavigatorStyles,
  globalStyles,
} from '@views/shared/styles';

import WhosInTabView from './WhosInTab';

const Tab = createMaterialTopTabNavigator();

const { extraSmall, smallMedium } = spacings;
const { black, aquaMarineLight, aquaMarine } = colors;

const tabBarNavigatorOptions = {
  labelStyle: topBarNavigatorStyles.tabLabel,
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
  tabStyle: {
    padding: 0,
    width: 'auto',
    flex: 1,
  },
  contentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'space-around',
    marginVertical: smallMedium,
  },
  scrollEnabled: false,
};

export const styles = StyleSheet.create({
  tabLabel: {
    color: black,
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-semi-bold',
  },
  tabContainer: {
    height: 40,
    minWidth: 110,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabContainerActive: {
    borderRadius: extraSmall,
    borderWidth: 1,
    backgroundColor: aquaMarineLight,
    borderColor: aquaMarine,
  },
});

const renderInOfficeView = () => <WhosInTabView type="inOffice" />;

const renderHomeOfficeView = () => <WhosInTabView type="homeOffice" />;

const renderTravelDayView = () => <WhosInTabView type="travelDay" />;

export function WhosInList() {
  const { t } = useIntl();

  const tabs = [
    {
      name: 'office',
      label: 'Colleagues.List.Screen.Office',
      item: renderInOfficeView,
    },
    {
      name: 'home-office',
      label: 'Colleagues.List.Screen.Home_office',
      item: renderHomeOfficeView,
    },
    {
      name: 'travel-day',
      label: 'Colleagues.List.Screen.Travel_day',
      item: renderTravelDayView,
    },
  ];

  return (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        lazy
        initialLayout={{ width: Dimensions.get('window').width }}
        screenOptions={{ unmountOnBlur: true }}
        swipeEnabled={false}
      >
        {tabs.map(({ label, item, name }, idx) => (
          <Tab.Screen
            key={idx}
            options={{
              tabBarLabel: ({ focused }) => (
                <View
                  style={[
                    globalStyles.shadow,
                    styles.tabContainer,
                    focused && styles.tabContainerActive,
                  ]}
                >
                  <Text
                    style={[styles.tabLabel, focused && { color: aquaMarine }]}
                  >
                    {t(label)}
                  </Text>
                </View>
              ),
              title: withLizTitle(
                `${t('Colleagues.List.Screen.whosin')} - ${t(label)}`,
              ),
            }}
            name={name}
          >
            {({ navigation }) =>
              navigation.isFocused() ? React.createElement(item, {}) : null
            }
          </Tab.Screen>
        ))}
      </Tab.Navigator>
    </View>
  );
}
