import React from 'react';
import {
  Platform,
  StyleSheet,
  TouchableHighlight,
  View,
  Text,
} from 'react-native';

import { colors, fonts, spacings } from '@views/shared/styles';
import {
  AreaWithStatus,
  AvailabilityInfo,
} from '@views/shared/interfaces/floorplan';
import { STATUS, SLOT_STATUS } from '@views/shared/TimeSlots/helper';
import { getBookingTypeByAreaType, getBookingIcon } from '@views/shared/consts';
import { SlotUIStatus, Status } from '@views/shared/TimeSlots/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';

const { extraSmall } = spacings;
const { sizeSmaller, sizeSmall } = fonts;
const {
  aquaMarineTransparent,
  grey6Transparent,
  darkBlueTransparent,
  warmPinkTransparent,
  lightBlueDesaturated,
  black,
} = colors;

/**
 * This Function map the Status of a Point into the UI status type
 **/
function mapPointStatusToUI(status: Status): SlotUIStatus {
  const mapStatus = {
    [STATUS.BLOCKED]: SLOT_STATUS.BLOCKED,
    [STATUS.BOOKED]: SLOT_STATUS.BOOKED,
    [STATUS.BOOKED_BY_COLLEAGUE]: SLOT_STATUS.BOOKED_BY_COLLEAGUE,
    [STATUS.FREE]: SLOT_STATUS.FREE,
    [STATUS.DISABLED]: SLOT_STATUS.DISABLED,
    [STATUS.OCCUPIED]: SLOT_STATUS.OCCUPIED,
    // EXPIRED At the moment is not used
    // OUTSIDE_BUILDING is not a Status that is not showed via Floor Plan
  };

  return mapStatus[status];
}

const mapBackgroundColor = {
  blocked: warmPinkTransparent,
  booked: darkBlueTransparent,
  bookedByColleague: lightBlueDesaturated,
  occupied: warmPinkTransparent,
  disabled: grey6Transparent,
  free: aquaMarineTransparent,
};

const styles = StyleSheet.create({
  area: {
    position: 'absolute',
    zIndex: 60,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  caption: {
    color: black,
    textAlign: 'center',
    fontFamily: 'mrt-semi-bold',
    alignSelf: 'center',
    padding: extraSmall,
  },
  icon: {
    textAlign: 'center',
    alignSelf: 'center',
  },
});

export function Area({
  area,
  bookArea,
  scale,
  showAvailability,
}: {
  area: AreaWithStatus;
  bookArea: (area: AreaWithStatus) => any;
  scale: number;
  showAvailability: (info?: AvailabilityInfo) => any;
}) {
  const {
    coordinates: { x, y, width, height },
    id,
    isAvailableToBook,
    name,
    status,
    areaType,
  } = area;

  const uiStatus = mapPointStatusToUI(status);
  const backgroundColor = mapBackgroundColor[uiStatus];
  const isAndroid = Platform.OS === 'android';
  const onlyIcon = scale <= (isAndroid ? 0.4 : 0.35);
  const fontSize = (isAndroid ? sizeSmaller : sizeSmall) / scale;
  const iconSize = width > 65 ? 45 : width - 20;

  const areaStyle = {
    width,
    height,
    backgroundColor,
  };

  return (
    <TouchableHighlight
      key={id}
      onPress={() =>
        isAvailableToBook
          ? bookArea(area)
          : showAvailability({ type: 'area', element: area })
      }
      style={[
        styles.area,
        {
          width,
          height,
          transform: [
            {
              translateX: x,
            },
            {
              translateY: y,
            },
          ],
        },
      ]}
    >
      <View style={[styles.area, areaStyle]}>
        {// areaId for debugging
        Platform.OS === 'web' && (
          <Text style={{ display: 'none' }}>{`AreaID: ${id}`}</Text>
        )}
        <CustomIcon
          name={getBookingIcon(getBookingTypeByAreaType(areaType))}
          size={iconSize}
          color={black}
          style={styles.icon}
        />
        {!onlyIcon && (
          <Text style={[styles.caption, { fontSize: fontSize }]}>{name}</Text>
        )}
      </View>
    </TouchableHighlight>
  );
}
