import React, { CSSProperties } from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';
import { Input as BaseInput } from 'react-native-elements';

import { useIntl } from '@utils/intl';

import { useMyOrganization } from '@providers/Organization';

import { Loader } from '@views/shared/Loader';
import { Select } from '@views/shared/Select';
import { MainButton, SecondaryButton } from '@views/shared/Button';
import { ImageUpload } from '@views/shared/ImageUpload';

import {
  globalStyles,
  spacings,
  avatarWithBadgeStyle,
} from '@views/shared/styles';

import { useEdit } from './hooks';
import { SelectedItem } from './SelectedItem';

const { medium, huge } = spacings;

export const styles = StyleSheet.create({
  actions: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: huge,
  },
  button: {
    paddingHorizontal: huge,
    marginHorizontal: medium,
  },
  avatarWrapper: {
    alignSelf: 'center',
  },
});

interface Props {
  label?: string;
  description?: string;
  onChange: (value: any) => void;
  value?: string;
  maxLength?: number;
  style?: CSSProperties;
}

export function Input({
  label,
  description,
  onChange,
  value,
  maxLength,
  style,
}: Props) {
  const { t } = useIntl();
  const LabelComponent =
    label || description ? (
      <View style={{ paddingBottom: spacings.small }}>
        {label ? (
          <Text style={[globalStyles.label, { paddingBottom: 0 }]}>
            {t(label)}
          </Text>
        ) : null}
        {description ? (
          <Text style={globalStyles.description}>{t(description)}</Text>
        ) : null}
      </View>
    ) : null;

  return (
    <BaseInput
      label={LabelComponent}
      onChangeText={onChange}
      value={value}
      inputStyle={[globalStyles.input, style]}
      inputContainerStyle={{ borderBottomWidth: 0 }}
      containerStyle={{ paddingHorizontal: 0 }}
      errorStyle={{ margin: 0 }}
      maxLength={maxLength}
    />
  );
}

export function Edit() {
  const { t } = useIntl();
  const { backgroundColor } = useMyOrganization();

  const {
    buildings,
    cancel,
    error,
    initials,
    loading,
    me,
    setAvatar,
    setBuildingId,
    setPhoneNumber,
    setName,
    setLicensePlate,
    update,
  } = useEdit();

  if (loading) {
    return <Loader />;
  }

  if (error || !me) {
    return <Text>GraphQL Error: edit profile</Text>;
  }

  return (
    <ScrollView style={globalStyles.container}>
      <View
        style={[
          avatarWithBadgeStyle.availableAvatarContainer,
          styles.avatarWrapper,
          { borderColor: backgroundColor, borderRadius: 999 },
        ]}
      >
        <ImageUpload
          title={initials}
          onError={() => setAvatar(undefined)}
          onFileChange={setAvatar}
          uri={me.avatar}
        />
      </View>
      <Input label={'Profile.Edit.name'} value={me.name} onChange={setName} />

      <Input
        label={'Profile.Edit.phone'}
        value={me.phoneNumber}
        onChange={setPhoneNumber}
      />
      <Input
        label={'Profile.Edit.licensePlate'}
        value={me.licensePlate}
        onChange={setLicensePlate}
        maxLength={12}
      />
      {buildings.length > 1 ? (
        <Select
          value={me.buildingId}
          items={buildings}
          onValueChange={setBuildingId}
          placeholder={t('Profile.Edit.MyBuilding.Placeholder')}
          selectedComponent={SelectedItem}
          label={t('Profile.Edit.MyBuilding')}
        />
      ) : null}
      <View style={styles.actions}>
        <SecondaryButton
          title={t('Profile.Edit.cancel')}
          titleStyle={globalStyles.cancelButtonText}
          buttonStyle={[styles.button, globalStyles.cancelButton]}
          onPress={cancel}
        />
        <MainButton
          title={t('Profile.Edit.save')}
          buttonStyle={styles.button}
          onPress={update}
        />
      </View>
    </ScrollView>
  );
}
