import React, { useCallback, useState } from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import { atom, useRecoilState } from 'recoil';
import { isNil } from 'ramda';

import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { CustomIcon } from '@views/shared/CustomIcon';
import { BookingIcon } from '@views/shared/BookingIcon';

import {
  BOOK_CATEGORY_MEETING_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_ROOM,
  BOOK_CATEGORY_TELEFON_BOX,
  getBookingIcon,
} from '@views/shared/consts';

import { useIntl } from '@utils/intl';

import { Overlay } from '@views/shared/Overlay';

import { useFilter } from './hooks';
import { styles } from './styles';
import { FilterIconProps } from './interfaces';

export default function FilterSelect({ navigation, color }: FilterIconProps) {
  const hasBookingFilterFeature = useHasFeature('booking_filter');
  const { isFilterSelectVisible } = useFilterSelect();
  const { name = null } = isNil(navigation) ? {} : navigation.getCurrentRoute();

  const showFilterSelect = name === 'filter' || isFilterSelectVisible;

  return showFilterSelect && hasBookingFilterFeature ? (
    <SelectComponent navigation={navigation} color={color} />
  ) : null;
}

const titleMap = {
  [BOOK_CATEGORY_MEETING_ROOM]: 'views.Home.Bookings.MeetingRooms.title',
  [BOOK_CATEGORY_PARKING]: 'Home.Structure.Parkings.Title',
  [BOOK_CATEGORY_ROOM]: 'views.Home.Bookings.Desks.shared',
  [BOOK_CATEGORY_TELEFON_BOX]: 'views.Home.Bookings.TelefonBox.title',
};

function SelectComponent({ navigation, color }: FilterIconProps) {
  const [showOverlay, setVisibleOverlay] = useState(false);

  const hasParkingFeature = useHasFeature('parkings_enabled');
  const { t } = useIntl();

  const toggleOverlay = useCallback(
    () => setVisibleOverlay(prevState => !prevState),
    [setVisibleOverlay],
  );

  const goToFilterPage = useCallback(
    type => {
      toggleOverlay();
      const { name: screen } = navigation.getCurrentRoute();
      navigation.navigate('book', {
        screen: 'filter',
        params: { name: 'floorplan', screen, type },
      });
    },
    [navigation, toggleOverlay],
  );
  const filterData = useFilter();
  const { isFilterUsed: isFilterUsedRoom = false } =
    filterData[BOOK_CATEGORY_ROOM] || {};
  const { isFilterUsed: isFilterUsedMeetingRoom = false } =
    filterData[BOOK_CATEGORY_MEETING_ROOM] || {};
  const { isFilterUsed: isFilterUsedTelefonBox = false } =
    filterData[BOOK_CATEGORY_TELEFON_BOX] || {};
  const { isFilterUsed: isFilterUsedParking = false } =
    filterData[BOOK_CATEGORY_PARKING] || {};

  const Touchable = ({ isFilterUsed, type }) => (
    <View style={styles.selectionContainer}>
      <TouchableHighlight
        onPress={() => goToFilterPage(type)}
        underlayColor="transparent"
      >
        <View style={styles.selection}>
          <BookingIcon
            name={getBookingIcon(type)}
            worklight={isFilterUsed ? 'low' : null}
          />
          <Text style={styles.selectionText}>{t(titleMap[type])}</Text>
        </View>
      </TouchableHighlight>
    </View>
  );

  return (
    <>
      <CustomIcon
        name={`filter${
          isFilterUsedRoom ||
          isFilterUsedMeetingRoom ||
          isFilterUsedParking ||
          isFilterUsedTelefonBox
            ? '-inv'
            : ''
        }`}
        onPress={toggleOverlay}
        size={28}
        color={color}
        style={styles.filterIcon}
      />

      {showOverlay ? (
        <Overlay onClose={toggleOverlay} title={t('Filter.Select.Type')}>
          <Touchable
            isFilterUsed={isFilterUsedRoom}
            type={BOOK_CATEGORY_ROOM}
          />
          <Touchable
            isFilterUsed={isFilterUsedMeetingRoom}
            type={BOOK_CATEGORY_MEETING_ROOM}
          />
          <Touchable
            isFilterUsed={isFilterUsedTelefonBox}
            type={BOOK_CATEGORY_TELEFON_BOX}
          />
          {hasParkingFeature ? (
            <Touchable
              isFilterUsed={isFilterUsedParking}
              type={BOOK_CATEGORY_PARKING}
            />
          ) : null}
        </Overlay>
      ) : null}
    </>
  );
}

const isShowFilter = atom({
  key: 'filterSelect/show',
  default: false,
});

export function useFilterSelect() {
  const [isFilterSelectVisible, setFilterSelectVisible] = useRecoilState(
    isShowFilter,
  );
  const showFilterSelect = useCallback(
    (show = true) => setFilterSelectVisible(show),
    [setFilterSelectVisible],
  );

  return {
    isFilterSelectVisible,
    showFilterSelect,
  };
}
