import React from 'react';
import { StyleSheet, TouchableHighlight, Text, View } from 'react-native';
import { Avatar } from 'react-native-elements';
import { isNil } from 'ramda';

import { FavouriteBadge } from '@views/Favourites/FavouriteBadge';
import { PresenceIcon } from '@views/Colleagues/Profile/Overview/StatusBox';
import {
  colors,
  spacings,
  fonts,
  avatarWithBadgeStyle,
} from '@views/shared/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import { Colleague } from '@views/Colleagues/List/interfaces';

import { useLightColleague } from './hooks';

const { sizeSmallPlus, sizeDefault } = fonts;
const { aquaMarineLight, grey9, black1, grey1, blackAlpha } = colors;
const { extraSmall, small, smallMedium, verySmall, oversize } = spacings;

const styles = StyleSheet.create({
  container: {
    padding: smallMedium,
    borderColor: blackAlpha,
    borderTopWidth: 1,
    flexDirection: 'row',
  },
  containerDesktop: {
    borderWidth: 1,
    borderRadius: extraSmall,
    marginHorizontal: oversize,
    marginBottom: small,
    backgroundColor: aquaMarineLight,
    minHeight: 100,
  },
  textContainer: {
    flex: 1,
    alignItems: 'baseline',
    justifyContent: 'center',
    paddingHorizontal: small,
    borderLeftWidth: 1,
    borderColor: grey1,
  },
  text: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeDefault,
    color: black1,
  },
  textDesktop: {
    overflowWrap: 'anywhere',
  },
  presenceIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: verySmall,
  },
  presenceIcon: {
    marginRight: smallMedium,
  },
  departmentText: {
    color: grey9,
    fontSize: sizeSmallPlus,
    marginTop: verySmall,
  },
  avatar: {
    marginRight: smallMedium,
    justifyContent: 'center',
  },
});

export function ColleagueListCard({ colleague }: { colleague: Colleague }) {
  const isDesktop = useIsDesktop();
  const {
    avatar,
    goToProfile,
    icon,
    id,
    name,
    initials,
    presenceStatus,
    status,
    department,
  } = useLightColleague(colleague);

  return (
    <TouchableHighlight
      onPress={goToProfile}
      underlayColor={aquaMarineLight}
      style={isDesktop && { width: '30%' }}
    >
      <View style={[styles.container, isDesktop && styles.containerDesktop]}>
        <View style={styles.avatar}>
          <Avatar
            rounded
            size={50}
            source={
              avatar && avatar.trim().length ? { uri: avatar } : undefined
            }
            title={initials}
            titleStyle={[avatarWithBadgeStyle.title]}
            placeholderStyle={[avatarWithBadgeStyle.placeholder]}
            overlayContainerStyle={[
              avatarWithBadgeStyle.avatarOverlayContainer,
            ]}
            containerStyle={[
              avatarWithBadgeStyle[`${presenceStatus}AvatarContainer`],
            ]}
          />
        </View>
        <View style={styles.textContainer}>
          <Text style={[styles.text, isDesktop && styles.textDesktop]}>
            {name}
          </Text>
          {!isNil(department) ? (
            <Text style={styles.departmentText}>{department.name}</Text>
          ) : null}
          {icon.length ? (
            <View style={styles.presenceIconContainer}>
              {icon.map((item, index) => (
                <View style={styles.presenceIcon} key={index}>
                  <PresenceIcon icon={item} status={status} />
                </View>
              ))}
            </View>
          ) : null}
        </View>
        <FavouriteBadge type="colleague" id={id} />
      </View>
    </TouchableHighlight>
  );
}
