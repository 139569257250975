import React from 'react';
import { ScrollView } from 'react-native';

import { globalStyles } from '@views/shared/styles';

import { Buildings } from './Buildings';
import { Bookings } from './Bookings';

export default function Main() {
  return (
    <ScrollView style={[globalStyles.container, { paddingTop: 0 }]}>
      <Buildings />
      <Bookings />
    </ScrollView>
  );
}
