import React, { useEffect, useState, useCallback } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';
import { Overlay } from '@views/shared/Overlay';
import { MainButton } from '@views/shared/Button';
import { Input } from '@views/Home/Profile/Edit';

import { useIntl } from '@utils/intl';

import { colors, spacings, fonts } from '@views/shared/styles';
import { styles as overlayStyles } from '@views/Home/ActiveBookings/Booking/Problem';

import { useAttendance } from '@views/Home/Attendance/PopUp/hooks';

const { blackAlpha, grey5 } = colors;
const { smaller, small, large, huge } = spacings;

const styles = StyleSheet.create({
  touchableContainer: {
    borderColor: blackAlpha,
    borderRadius: smaller,
    borderWidth: 1,
  },
  touchable: {
    backgroundColor: grey5,
    height: 22,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: large,
  },
  valueContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  value: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
  selectorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  divider: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 15,
  },
  spacer: {
    marginTop: huge,
  },
});

const titleByMode = {
  start: 'Home.Attendance.Layer.Start.Title',
  stop: 'Home.Attendance.Layer.Stop.Title',
  break: 'Home.Attendance.Layer.BreakStart.Title',
  breakEnd: 'Home.Attendance.Layer.EndBreak.Title',
};

export default function PopUp(props) {
  const { t } = useIntl();

  const { onToggle, mode } = props;
  const {
    isError,
    isLoading,
    onSave,
    onTimeChange,
    time,
    isTimeNotValid,
    workingHours,
    isOverlappedTime,
    updateComment,
  } = useAttendance(props);

  return (
    <Overlay onClose={onToggle} title={t(titleByMode[mode])}>
      {isError ? (
        <Text style={[overlayStyles.success, overlayStyles.failed]}>
          {t('Home.Attendance.Layer.Error')}
        </Text>
      ) : null}

      {isOverlappedTime || isTimeNotValid ? (
        <Text style={[overlayStyles.success, overlayStyles.failed]}>
          {t(
            isOverlappedTime
              ? 'Home.Attendance.Layer.ErrorTimeOverlapping'
              : 'Home.Attendance.Layer.ErrorTimeInvalid',
          )}
        </Text>
      ) : null}

      <TimeSelector
        onChange={onTimeChange}
        time={time}
        workingHours={workingHours}
      />

      {mode === 'stop' ? (
        <View style={styles.spacer}>
          <Input
            description="Home.Attendance.Edit.Comment.Description"
            onChange={updateComment}
            maxLength={200}
            style={{ marginBottom: 0 }}
          />
        </View>
      ) : null}

      <View style={[overlayStyles.footer, { marginTop: small }]}>
        <MainButton
          disabled={isError || isLoading || isTimeNotValid || isOverlappedTime}
          onPress={onSave}
          buttonStyle={overlayStyles.buttonSend}
          title={t('Home.Attendance.Button.Save')}
        />
      </View>
    </Overlay>
  );
}

export function TimeSelector({ onChange, time, workingHours }) {
  const [minutes, setMinutes] = useState(time.minutes);
  const [hoursIndex, setHoursIndex] = useState(
    workingHours.indexOf(time.hours) !== -1
      ? workingHours.indexOf(time.hours)
      : 0,
  );

  const onChangeHours = useCallback(
    (increase = true) =>
      setHoursIndex(prevState => {
        const value = prevState + (increase ? 1 : -1);

        return value === -1
          ? 0
          : workingHours[value]
          ? value
          : workingHours.length - 1;
      }),
    [setHoursIndex, workingHours],
  );
  const onChangeMinutes = useCallback(
    (increase = true) => {
      setMinutes(prevState => {
        const value = prevState + (increase ? 5 : -5);
        return value > 55 ? 55 : value < 0 ? 0 : value;
      });
    },
    [setMinutes],
  );

  useEffect(() => onChange({ hours: workingHours[hoursIndex], minutes }), [
    hoursIndex,
    minutes,
    onChange,
  ]);

  const hours = workingHours[hoursIndex];

  return (
    <View style={styles.selectorContainer}>
      <View style={styles.touchableContainer}>
        <TouchableOpacity
          onPress={() => onChangeHours()}
          style={styles.touchable}
        >
          <CustomIcon name="arrow-up" size={25} />
        </TouchableOpacity>
        <View style={styles.valueContainer}>
          <Text style={styles.value}>{hours}</Text>
        </View>
        <TouchableOpacity
          onPress={() => onChangeHours(false)}
          style={styles.touchable}
        >
          <CustomIcon name="arrow-down" size={22} />
        </TouchableOpacity>
      </View>
      <View style={styles.divider}>
        <Text style={styles.value}> : </Text>
      </View>
      <View style={styles.touchableContainer}>
        <TouchableOpacity
          onPress={() => onChangeMinutes()}
          style={styles.touchable}
        >
          <CustomIcon name="arrow-up" size={22} />
        </TouchableOpacity>
        <View style={styles.valueContainer}>
          <Text style={styles.value}>
            {minutes < 10 ? `0${minutes}` : minutes}
          </Text>
        </View>
        <TouchableOpacity
          onPress={() => onChangeMinutes(false)}
          style={styles.touchable}
        >
          <CustomIcon name="arrow-down" size={25} />
        </TouchableOpacity>
      </View>
    </View>
  );
}
