import React from 'react';
import { Text, View, StyleSheet, TouchableHighlight } from 'react-native';

import { Select } from '@views/shared/Select';
import { ItemComponentProps } from '@views/shared/Select/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';
import { spacings, fonts } from '@views/shared/styles';
import { Accordion } from '@views/shared/Accordion';

import { styles as tStyles } from '@views/Home/Profile/Overview/Toggles';
import { globalStyles } from '@views/shared/styles';

import { useIntl } from '@utils/intl';

import { useLanguage } from './hooks';

const { sizeDefault, sizeSmallPlus } = fonts;

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lang: {
    fontSize: sizeSmallPlus,
    fontFamily: 'mrt-regular',
    marginRight: spacings.extraSmall,
  },
});

function SelectedItem({ show, item, placeholder }: ItemComponentProps) {
  return (
    <TouchableHighlight onPress={show} underlayColor="transparent">
      <View style={styles.item}>
        <Text style={styles.lang}>{item?.label ?? placeholder}</Text>
        <CustomIcon name="arrow-down" size={sizeDefault} />
      </View>
    </TouchableHighlight>
  );
}

export default function Language() {
  const { t } = useIntl();

  const { languages, lang, onLanguageChange } = useLanguage(t);

  const title = (
    <Text style={globalStyles.title}>
      {t('Profile.Content.Language.Title')}
    </Text>
  );

  return (
    <View style={[globalStyles.shadow, tStyles.wrapper]}>
      <Accordion open={true} headerStyle={tStyles.row} title={title}>
        <View style={[tStyles.toggleWrapper, tStyles.container]}>
          <Select
            value={lang}
            items={languages}
            onValueChange={onLanguageChange}
            selectedComponent={SelectedItem}
          />
        </View>
      </Accordion>
    </View>
  );
}
