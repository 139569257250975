import { StyleSheet, Platform } from 'react-native';

const isWeb = Platform.OS === 'web';

export const colors = {
  grey1: '#e3e3e3',
  grey2: '#6f6f6e',
  grey3: '#707070',
  grey4: '#36333e',
  grey5: '#f3f4f5',
  grey6: '#a3a4a5',
  grey6Transparent: '#a3a4a570',
  grey7: '#86939e',
  grey8: '#f7f7f7',
  grey9: '#7c7c7c',
  greyAlpha: 'rgba(28, 28, 28, .5)',
  aquaMarine: '#5ae0b9',
  aquaMarineLight: '#eefbf8',
  aquaMarineMedium: '#5ae0b940',
  aquaMarineTransparent: '#5ae0b970',
  black: '#000000',
  blackAlpha: '#00000029',
  blackAlpha2: 'rgba(0, 0, 0, .3)',
  blackAlpha3: 'rgba(0, 0, 0, .05)',
  black1: '#23242c',
  darkBlue: '#061A52',
  lightBlue: '#049DE8',
  lightBlue2: '#0097E3',
  googleRed: '#dd4b39',
  googleBlue: '#4285F4',
  grey2Alpha: '#6f6f6ea7',
  grey3Alpha: '#70707080',
  msBlue: '#00acef',
  paleOrange: '#ffb65c',
  paleOrangeTransparent: '#ffb65c70',
  warmPink: '#ff4387',
  warmPinkTransparent: '#ff438770',
  white: '#ffffff',
  whiteOverlay: '#ffffff99',
  darkBlueHighDesaturated: '#f2f8fa',
  paleOrangeHighDesaturated: '#fefbf5',
  darkBlueMediumDesaturated: '#d9eaf2',
  darkBlueTransparent: '#061A5270',
  lightBlueHighDesaturated: 'rgba(4, 157, 232, 0.05)',
  lightBlueMediumDesaturated: 'rgba(4, 157, 232, 0.3)',
  lightBlueDesaturated: 'rgba(4, 157, 232, 0.8)',
  paleOrangeMediumDesaturated: '#fbeed1',
  warmPinkHighDesaturated: '#ffecf3',
};

export const fonts = {
  sizeTiny: 9,
  sizeSmaller: 10,
  sizeSmall: 11,
  sizeSmallPlus: 12,
  sizeSemiSmall: 13,
  sizeDefault: 14,
  sizeMedium: 15,
  sizeTitle: 16,
  sizeBig: 18,
  sizeBuilding: 20,
};

export const spacings = {
  tiniest: 2,
  tiny: 3,
  smaller: 4,
  extraSmall: 5,
  verySmall: 8,
  small: 10,
  smallMedium: 12,
  medium: 14,
  large: 16,
  big: 18,
  huge: 20,
  oversize: 24,
  extraOversize: 35,
};

export const globalStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    padding: spacings.smallMedium,
    paddingBottom: spacings.big,
    backgroundColor: colors.white,
  },
  badge: {
    position: 'absolute',
    top: -1,
    right: 0,
    width: 10,
    height: 10,
    borderRadius: 5,
  },
  label: {
    color: colors.black,
    fontFamily: 'mrt-semi-bold',
    fontWeight: 'normal',
    fontSize: fonts.sizeDefault,
    paddingBottom: spacings.small,
  },
  input: {
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-regular',
    borderColor: colors.blackAlpha,
    borderRadius: spacings.extraSmall,
    borderWidth: 1,
    paddingHorizontal: spacings.smallMedium,
    paddingVertical: spacings.verySmall,
    marginBottom: spacings.large,
  },
  shadow: {
    shadowColor: colors.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: isWeb ? spacings.extraSmall : spacings.tiny,
    borderRadius: spacings.extraSmall,
    elevation: spacings.tiny,
    backgroundColor: colors.white,
    marginHorizontal: 2,
  },
  title: {
    fontSize: fonts.sizeDefault,
    fontFamily: 'mrt-semi-bold',
  },
  cardContainer: {
    marginBottom: spacings.big,
  },
  card: {
    borderColor: colors.blackAlpha,
    borderWidth: 1,
    borderRadius: spacings.extraSmall,
  },
  cardTitle: {
    fontSize: fonts.sizeMedium,
    fontFamily: 'mrt-semi-bold',
    marginBottom: spacings.small,
  },
  description: {
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-regular',
    marginTop: spacings.extraSmall,
  },
  favCount: {
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-semi-bold',
    color: colors.white,
  },
  cancelButton: {
    borderColor: colors.warmPink,
  },
  cancelButtonText: {
    color: colors.warmPink,
  },
  shortCutSelectionButton: {
    borderColor: colors.darkBlue,
    borderRadius: spacings.extraSmall,
  },
  shortCutSelectionButtonText: {
    color: colors.darkBlue,
  },
  shortCutSelectionButtonOn: {
    backgroundColor: colors.darkBlue,
  },
  shortCutSelectionButtonOnText: {
    color: colors.white,
  },
  shortCutSelectionTitle: {
    paddingVertical: spacings.extraSmall,
    fontSize: fonts.sizeDefault,
    fontFamily: 'mrt-semi-bold',
    color: colors.darkBlue,
  },
  textCenter: {
    textAlign: 'center',
  },
  noDataText: {
    fontSize: fonts.sizeSemiSmall,
    fontFamily: 'mrt-regular',
    padding: spacings.smallMedium,
  },
});

export const topBarNavigatorStyles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
  },
  sceneContainer: {
    backgroundColor: colors.white,
  },
  navigator: {
    borderWidth: 1,
    borderColor: colors.blackAlpha,
  },
  tabLabel: {
    fontSize: fonts.sizeSemiSmall,
    fontFamily: 'mrt-regular',
    color: colors.greyAlpha,
    letterSpacing: 0.25,
    paddingHorizontal: 0,
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  tabLabelActive: {
    color: colors.aquaMarine,
    fontFamily: 'mrt-semi-bold',
    letterSpacing: 0,
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  tabStyle: {
    flex: 1,
    padding: spacings.tiny,
  },
  tabStyleAutoWidth: {
    flex: 1,
    padding: spacings.verySmall,
    width: 'auto',
  },
  contentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
});

export const avatarWithBadgeStyle = StyleSheet.create({
  title: {
    fontFamily: 'mrt-semi-bold',
    color: colors.darkBlue,
    fontSize: fonts.sizeDefault,
  },
  avatarOverlayContainer: {
    backgroundColor: colors.white,
  },
  placeholder: {
    backgroundColor: colors.white,
  },
  avatarContainer: {
    borderWidth: 1,
    borderColor: colors.darkBlue,
  },
  availableAvatarContainer: {
    borderWidth: 1,
    borderColor: colors.aquaMarine,
    padding: spacings.tiniest,
  },
  busyAvatarContainer: {
    borderWidth: 1,
    borderColor: colors.warmPink,
    padding: spacings.tiniest,
  },
  unknownAvatarContainer: {
    borderWidth: 1,
    borderColor: colors.grey1,
    padding: spacings.tiniest,
  },
  spaceBorder: {
    borderRightWidth: 1,
    borderColor: colors.blackAlpha3,
  },
});

export const globalStylesDesktop = StyleSheet.create({
  bookingsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    columnGap: '5%',
    rowGap: 20,
    alignItems: 'flex-start',
  },
  cancelButton: {
    marginTop: spacings.extraSmall,
  },
  desktopConWithBorder: {
    borderRightWidth: 1,
    borderColor: colors.blackAlpha,
    borderStyle: 'solid',
  },
  cardTitle: {
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
    marginBottom: spacings.oversize,
    marginTop: spacings.large,
  },
  centeredTitle: {
    textAlign: 'center',
  },
});
