import React from 'react';
import { View, Text } from 'react-native';

import { useMyOrganization } from '@providers/Organization';
import { universalDateFormatter } from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { getBookingType } from '@views/shared/utils';
import { BookingIcon } from '@views/shared/BookingIcon';
import { getBookingIcon } from '@views/shared/consts';
import Location from '@views/shared/hooks/getLocation';
import { styles } from '@views/Bookings/MyBookings/Day/Ticket/Booking';
import { globalStyles } from '@views/shared/styles';
import { Booking as BookingType } from '@views/shared/interfaces/booking';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

interface Props {
  booking: BookingType;
}

export function Booking({ booking }: Props) {
  const {
    formats: { timeFormatShort },
  } = useIntl() as any;
  const isDesktop = useIsDesktop();

  const type = getBookingType(booking);
  const start = universalDateFormatter({
    date: booking.start,
    format: timeFormatShort,
  });
  const end = universalDateFormatter({
    date: booking.end,
    format: timeFormatShort,
  });
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });

  return (
    <View
      style={
        isDesktop
          ? [styles.bookingItemDesktop, globalStyles.shadow]
          : styles.bookingItem
      }
    >
      <View style={[styles.row, { borderLeftWidth: 0 }]}>
        <View style={[styles.left]}>
          <BookingIcon name={getBookingIcon(type)} />
        </View>
        <View style={[styles.right, { paddingLeft: 0 }]}>
          <View>
            <Location booking={booking} showDeskTranslation />
            <Text style={globalStyles.description}>{time}</Text>
          </View>
        </View>
      </View>
    </View>
  );
}
