import React from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';
import { colors, fonts, spacings } from '@views/shared/styles';
import { useMyOrganization } from '@providers/Organization';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { getEventsOverZoneFormat } from '@utils/DateAndTime';

import { Event } from './interfaces';

const { grey5, aquaMarine } = colors;
const { extraSmall, huge, large, smallMedium } = spacings;
const { sizeSmall, sizeDefault } = fonts;

const styles = StyleSheet.create({
  event: {
    backgroundColor: grey5,
    marginHorizontal: large,
    marginVertical: extraSmall,
    borderRadius: extraSmall,
    flexDirection: 'row',
    paddingHorizontal: smallMedium,
    paddingVertical: extraSmall,
    alignItems: 'center',
  },
  day: {
    fontSize: sizeDefault,
    color: aquaMarine,
    fontFamily: 'mrt-semi-bold',
  },
  info: {
    fontSize: sizeSmall,
    fontFamily: 'mrt-regular',
  },
  metaView: {
    paddingLeft: huge,
    maxWidth: '100%',
  },
  loader: {
    height: 70,
  },
  container: {
    maxHeight: 410,
  },
});

interface Props {
  events: Event[];
}

interface EventProps {
  event: Event;
  timeFormat: string;
  timeFormatShort: string;
  workingHoursStart: Date | string;
  workingHoursEnd: Date | string;
}

// let do this dirty fix. backend will enable the half day booking and then
// we will fix the booking time
function getEventTime({
  event,
  timeFormat,
  timeFormatShort,
  workingHoursStart,
  workingHoursEnd,
}: EventProps) {
  const { end, start } = event;

  return start.toString().indexOf('00:00:00') > -1 &&
    end.toString().indexOf('23:59:59') > -1
    ? getTimeByType({
        // daily Booking (this will be obsolete soon )
        // events for full day should be shown als event for working hours
        /*end,
        start,*/
        timeFormatShort,
        workingHoursStart,
        workingHoursEnd,
      })
    : `${getEventsOverZoneFormat(
        // We just show the date that we receive for the moment we will change it when BE will fix UTC
        start,
        timeFormat,
      )} - ${getEventsOverZoneFormat(end, timeFormat)}`;
}

export function EventsList({ events }: Props) {
  const {
    formats: { timeFormat, timeFormatShort },
  } = useIntl() as any;
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  return (
    <ScrollView style={styles.container}>
      {events.map((event, index) => {
        return (
          <View key={index} style={styles.event}>
            <View>
              <Text style={[styles.day, styles.info]}>
                {universalDateFormatter({ date: event.start, format: 'ddd' })}
              </Text>
              <Text style={[styles.day]}>
                {universalDateFormatter({ date: event.start, format: 'DD' })}
              </Text>
            </View>
            <View style={styles.metaView}>
              <Text style={styles.info}>{event.subject}</Text>
              <Text style={styles.info}>
                {getEventTime({
                  event,
                  timeFormat,
                  timeFormatShort,
                  workingHoursStart,
                  workingHoursEnd,
                })}
              </Text>
            </View>
          </View>
        );
      })}
    </ScrollView>
  );
}
