import React from 'react';
import { Text, View } from 'react-native';

import { getFloorName } from '@utils/Building';
import { useIntl } from '@utils/intl';

import Item from '@views/Book/Filter/ressources/item';
import { useToggle } from '@views/Book/Filter/ressources/equipment';
import { styles } from '@views/Book/Filter/styles';
import { FloorsProps } from '@views/Book/Filter/interfaces';

export default function Floors({
  isFloorplan,
  items,
  onSelect,
  selected,
}: FloorsProps) {
  const { t } = useIntl();
  const isSomeItemSelected = selected.length > 0;
  const { itemsCount, More, showAll, Toggle } = useToggle(isSomeItemSelected);
  const showToggle = items.length > itemsCount;

  return (
    <View style={styles.container}>
      <View style={styles.headline}>
        <View>
          <Text style={styles.title}>{t('Filter.Floors.Title')}</Text>
          {selected.length ? <View style={styles.badge} /> : null}
        </View>
        {showToggle ? Toggle : null}
      </View>
      <View style={styles.itemsContainer}>
        {items.map((floor, idx) => {
          const title = getFloorName({
            t,
            ...floor,
          });

          const { id } = floor;

          return (
            <Item
              isActive={selected.includes(id)}
              isDisabled={isFloorplan}
              isHidden={idx + 1 > itemsCount && !showAll}
              key={idx}
              onPress={onSelect}
              text={title}
              value={id}
            />
          );
        })}
        {showToggle && !showAll ? More : null}
      </View>
    </View>
  );
}
