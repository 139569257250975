import React from 'react';
import { Text, View, ScrollView } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';

import { useIntl } from '@utils/intl';
import { useFavourites } from '@views/Favourites/hooks';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_ROOM,
} from '@views/shared/consts';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { globalStyles, spacings } from '@views/shared/styles';
import { styles } from '@views/Book/Bookings/Panel';

import List from './List';

const { smallMedium } = spacings;

export default function Favourites() {
  const isDesktop = useIsDesktop();
  const { favDesks, favMeetingRooms, favSeats } = useFavourites();
  const { t } = useIntl();

  if (!favDesks.length && !favMeetingRooms.length && !favSeats.length) {
    return <UnexpectedNoDataOrError message={t('Home.Favourites.no-favs')} />;
  }

  return (
    <View style={isDesktop ? { width: 948, marginHorizontal: 'auto' } : {}}>
      <SafeAreaView>
        <View style={{ margin: smallMedium }}>
          <View style={globalStyles.shadow}>
            <View style={[styles.row, { paddingLeft: smallMedium }]}>
              <Text style={globalStyles.title}>
                {t('Home.Favourites.MyFavourites')}
              </Text>
            </View>
          </View>
        </View>
      </SafeAreaView>

      <ScrollView style={globalStyles.container}>
        {favDesks.length ? <List ids={favDesks} type={AREA_TYPE_ROOM} /> : null}
        {favMeetingRooms.length ? (
          <List ids={favMeetingRooms} type={AREA_TYPE_MEETING_ROOM} />
        ) : null}
        {favSeats.length ? (
          <List ids={favSeats} type={AREA_TYPE_FREE_SPACES_ROOM} />
        ) : null}
      </ScrollView>
    </View>
  );
}
