import { useMyOrganization } from '@providers/Organization';

export type Feature =
  | 'beta_feature'
  | 'book_any_desk_for_room_type_area'
  | 'booking_catering'
  | 'booking_clean_desk'
  | 'booking_equipping'
  | 'booking_filter'
  | 'booking_find_space'
  | 'booking_report_problem'
  | 'booking_resources_contingent'
  | 'booking_sensors_data'
  | 'calendar_integration'
  | 'check_in_out_booking'
  | 'default_visible_to_colleagues'
  | 'department_booking_range'
  | 'department_forbidden_desks'
  | 'department_forbidden_lcokers'
  | 'department_forbidden_meeting_rooms'
  | 'department_forbidden_parkings'
  | 'desk_number_on_floor'
  | 'disable_colleague_bookings'
  | 'disable_colleagues'
  | 'disable_invite_mails'
  | 'disable_show_whoisin'
  | 'employee_responsibilities'
  | 'find_my_colleague'
  | 'fixed_booking'
  | 'floor_plan_rotation'
  | 'floor_plan_sorting'
  | 'halfday_booking'
  | 'home_office'
  | 'hourly_booking'
  | 'management_admin_roles_page'
  | 'management_booking_creation_page'
  | 'management_booking_page'
  | 'management_department_desks_page'
  | 'management_timerange_lock_page'
  | 'my_organization_setup'
  | 'no_show'
  | 'parkings_enabled'
  | 'qrcode_booking'
  | 'resource_groups'
  | 'shared_floorplan'
  | 'sick_day'
  | 'social_distance'
  | 'sso_departments'
  | 'time_tracking'
  | 'time_tracking_approvals'
  | 'travel_day'
  | 'vacation'
  | 'web_conference_link';

export function useHasFeature(...features: Feature[]): boolean {
  const { enabledFeatures = [] } = useMyOrganization();

  return features.every(feature => enabledFeatures.includes(feature));
}
