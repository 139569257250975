import React from 'react';
import { Text } from 'react-native';
import { pathOr, path } from 'ramda';

import { useIntl } from '@utils/intl';
import PointFullLabel, {
  getPointFullLabel,
  getBuildingInfo,
  getFloorInfo,
  FloorMinimalInfos,
  BuildingMinimalInfos,
} from '@utils/Building';

import { AREA_TYPE_PARKING, AREA_TYPE_ROOM } from '@views/shared/consts';
import { globalStyles } from '@views/shared/styles';

import { Booking } from '@views/shared/interfaces/booking';

interface LocationProps {
  booking: Booking | any;
  t: any;
  showDeskNumber?: boolean;
  showDeskTranslation?: boolean;
}

export function getLocation({
  booking,
  showDeskNumber = false,
  showDeskTranslation = false,
  t,
}: LocationProps) {
  if (booking?.homeOffice) {
    return t('Home.Bookings.HomeOffice.title');
  }

  if (booking?.travelDay) {
    return t('Home.ActiveBookings.Booking.title.travelDay');
  }

  if (booking?.vacation) {
    return t('Home.ActiveBookings.Booking.title.vacation');
  }

  if (booking?.sickDay) {
    return t('Home.ActiveBookings.Booking.title.sickday');
  }

  const areaName = pathOr('', ['area', 'name'], booking);
  const areaType = pathOr(AREA_TYPE_ROOM, ['area', 'areaType'], booking);
  const floor = path<FloorMinimalInfos>(['area', 'floor'], booking);
  const building = path<BuildingMinimalInfos>(
    ['area', 'floor', 'building'],
    booking,
  );

  const isParking = areaType === AREA_TYPE_PARKING;
  const point = isParking ? booking?.parking : booking?.desk || {};

  if (building && floor) {
    return getPointFullLabel({
      areaType,
      areaName,
      ...getBuildingInfo(building),
      ...getFloorInfo(floor),
      point,
      showDeskNumber,
      showDeskTranslation,
      t,
    });
  }

  //TODO: Should we return some error label in this case
  // or it will never happen that building and floor are undefined??
  return '';
}

export default function Location({
  booking,
  showDeskTranslation = false,
}: {
  booking: Booking | any;
  showDeskTranslation?: boolean;
}) {
  const { t } = useIntl();

  if (booking?.homeOffice) {
    return (
      <Text style={globalStyles.title}>
        {t('Home.Bookings.HomeOffice.title')}
      </Text>
    );
  }

  if (booking?.travelDay) {
    return (
      <Text style={globalStyles.title}>
        {t('Home.ActiveBookings.Booking.title.travelDay')}
      </Text>
    );
  }

  if (booking?.vacation) {
    return (
      <Text style={globalStyles.title}>
        {t('Home.ActiveBookings.Booking.title.vacation')}
      </Text>
    );
  }

  if (booking?.sickDay) {
    return (
      <Text style={globalStyles.title}>
        {t('Home.ActiveBookings.Booking.title.sickday')}
      </Text>
    );
  }

  const areaName = pathOr('', ['area', 'name'], booking);
  const areaType = pathOr(AREA_TYPE_ROOM, ['area', 'areaType'], booking);
  const floor = path<FloorMinimalInfos>(['area', 'floor'], booking);
  const building = path<BuildingMinimalInfos>(
    ['area', 'floor', 'building'],
    booking,
  );

  const isParking = areaType === AREA_TYPE_PARKING;
  const point = isParking ? booking?.parking : booking?.desk || {};

  if (building && floor) {
    return (
      <PointFullLabel
        areaType={areaType}
        areaName={areaName}
        point={point}
        showDeskTranslation={showDeskTranslation}
        {...getBuildingInfo(building)}
        {...getFloorInfo(floor)}
      />
    );
  }

  return null;
}
