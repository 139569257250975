import React from 'react';

import { useIntl } from '@utils/intl';
import { BookingIcon } from '@views/shared/BookingIcon';
import { BOOK_CATEGORY_SICKDAY, getBookingIcon } from '@views/shared/consts';
import { Panel } from '@views/Book/Bookings/Panel';
import { useGoTo } from '@views/shared/utils/useGoTo';

export default function SickDay() {
  const { t } = useIntl();

  const { goToBookingPage } = useGoTo();

  return (
    <Panel
      icon={<BookingIcon name={getBookingIcon(BOOK_CATEGORY_SICKDAY)} />}
      name={t('Home.ActiveBookings.Booking.title.sickday')}
      onAction={() => goToBookingPage({ type: BOOK_CATEGORY_SICKDAY })}
    />
  );
}
