import React, { useEffect } from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { useRecoilState } from 'recoil';

import {
  pushNotificationNavAtom,
  useHandleNotificationNav,
} from '@pushNotification/hooks';
import { useIntl } from '@utils/intl';
import { withLizTitle } from '@views/shared/utils';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import Main from './Main';
import { Overview } from './Profile/Overview';
import { Edit } from './Profile/Edit';

const Stack = createStackNavigator();

export default function Home() {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();

  const [pushNotificationNav] = useRecoilState(pushNotificationNavAtom);
  const { handleNotificationNav } = useHandleNotificationNav();

  useEffect(() => {
    if (pushNotificationNav.nav.length) {
      handleNotificationNav(pushNotificationNav);
    }
  }, [pushNotificationNav, handleNotificationNav]);

  return (
    <>
      {isDesktop ? (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen
            name="index"
            component={Main}
            options={{ title: withLizTitle(t('Routes.tabs.home')) }}
          />
        </Stack.Navigator>
      ) : (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen
            name="index"
            component={Main}
            options={{ title: withLizTitle(t('Routes.tabs.home')) }}
          />
          <Stack.Screen
            component={Overview}
            name="overview"
            options={{ title: t('Profile.title') }}
          />
          <Stack.Screen
            component={Edit}
            name="edit"
            options={{ title: t('Profile.title') }}
          />
        </Stack.Navigator>
      )}
    </>
  );
}
