import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { head } from 'ramda';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { Overlay } from '@views/shared/Overlay';
import { colors } from '@views/shared/styles';
import { UIFinderError } from '@views/Errors/interfaces';
import {
  filterFatal,
  filterWithUserIteration,
  filterDismissible,
  filterUnique,
} from '@views/Errors/common';

import { useErrorsView } from './hooks';

const { warmPink } = colors;

const styles = StyleSheet.create({
  error: {
    color: warmPink,
  },
  message: {
    marginVertical: 5,
  },
  actions: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginVertical: 10,
    marginHorizontal: 20,
  },
  buttonInline: {
    marginHorizontal: 10,
  },
});

export default function Errors({ navigationRef }) {
  const { t } = useIntl();
  const { errors, fatalPresent, visible, dismiss, logout } = useErrorsView();

  const showErrors = ({ action, description, title, index }: UIFinderError) =>
    action({
      index,
      dismiss,
      description,
      title,
      navigationRef,
      logout,
      modalStyle: styles,
      t,
    });

  if (fatalPresent && visible) {
    const fatalError = head(errors.filter(filterFatal));

    if (fatalError) {
      return showErrors(fatalError);
    }
  }

  // filtering out the errors that has a defined user interaction and pick the first one
  const interactiveError = head(errors.filter(filterWithUserIteration));
  if (interactiveError) {
    return showErrors(interactiveError);
  }

  // filtering out the dismissible errors and strip out the repetitions for the same error description
  const dismissibleErrors = errors
    .filter(filterDismissible)
    .filter(filterUnique);

  return visible ? (
    <Overlay
      onClose={dismiss}
      title={t('Errors.error_title')}
      titleStyle={styles.error}
      hideClose
    >
      {dismissibleErrors.map(({ description }, index) => (
        <View style={styles.message} key={index}>
          <Text>{description}</Text>
        </View>
      ))}
      <MainButton
        title={t('Errors.dismiss')}
        onPress={() => dismissibleErrors.forEach(error => dismiss(error.index))}
        style={{
          alignSelf: 'center',
        }}
      />
    </Overlay>
  ) : null;
}
