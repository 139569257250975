import React from 'react';
import { sortBy, compose, toLower } from 'ramda';
import { Divider } from 'react-native-elements';
import { StyleSheet, View, Text } from 'react-native';
import Icons from '@assets/fonts/icons/selection.json';

import { CustomIcon } from '@views/shared/CustomIcon';
import { colors, spacings } from '@views/shared/styles';

const { aquaMarine, aquaMarineTransparent, blackAlpha } = colors;
const { medium, extraSmall, tiny } = spacings;

const styles = StyleSheet.create({
  icons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: medium,
    justifyContent: 'space-between',
  },
  iconContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: medium,
    alignItems: 'center',
    textAlign: 'center',
    width: 120,
  },
  icon: {
    marginRight: extraSmall,
    marginBottom: extraSmall,
    width: 75,
    height: 75,
    borderColor: aquaMarineTransparent,
    borderStyle: 'solid',
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: extraSmall,
  },
});

export function IconExamples() {
  const sortByName = sortBy(compose(toLower));
  const icons = sortByName(Icons.icons.map(icon => icon.properties.name));

  return (
    <View style={{ marginTop: 15 }}>
      <Text h3>Icon Font - LIZ Solutions Native Finder 2.1</Text>
      <Text>Font-Family name: liz-native-finder-icomoon</Text>
      <Divider style={{ backgroundColor: blackAlpha, marginTop: tiny }} />
      <View style={styles.icons}>
        {icons.map((icon, idx) => (
          <View style={styles.iconContainer} key={idx}>
            <View style={styles.icon}>
              <CustomIcon color={aquaMarine} name={icon} size={40} />
            </View>
            <Text>{icon}</Text>
          </View>
        ))}
      </View>
    </View>
  );
}
