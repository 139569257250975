import { defineMessages } from 'react-intl';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Errors.error_title': {
    id: 'Errors.error_title',
    defaultMessage: 'Something went wrong',
  },
  'Errors.dismiss': {
    id: 'Errors.dismiss',
    defaultMessage: 'Dismiss errors',
  },
  'Errors.hook.defaultFatal': {
    id: 'Errors.hook.defaultFatal',
    defaultMessage: 'Could not execute query',
  },
  'Errors.description': {
    id: 'Errors.description',
    defaultMessage: "Something doesn't work as expected, please try again.",
  },
  'Errors.login.again': {
    id: 'Errors.login.again',
    defaultMessage: 'Login with different account',
  },
  'Errors.login.fresh': {
    id: 'Errors.login.fresh',
    defaultMessage: 'Login again with your new account',
  },
  'Errors.reload.page': {
    id: 'Errors.reload.page',
    defaultMessage: 'Reload',
  },
  'Errors.cancel': {
    id: 'Errors.cancel',
    defaultMessage: 'Cancel',
  },
  'Errors.desk.book.another': {
    id: 'Errors.desk.book.another',
    defaultMessage: 'Book a different desk',
  },
  'Errors.desk.title': {
    id: 'Errors.desk.title',
    defaultMessage: 'Selected desk is not available',
  },
  'Errors.desk.description': {
    id: 'Errors.desk.description',
    defaultMessage:
      'We are sorry but the desk seems not available anymore, please try to book a different one!',
  },
  'Errors.no.permission.title': {
    id: 'Errors.no.permission.title',
    defaultMessage: 'You do not have permission to see this content',
  },
  'Errors.no.permission.description': {
    id: 'Errors.no.permission.description',
    defaultMessage:
      "You don't have the permission to see this content, please login again or reload the app!",
  },
  'Errors.no.userprofile.title': {
    id: 'Errors.no.userprofile.title',
    defaultMessage: 'Your user is not ready yet',
  },
  'Errors.no.userprofile.description': {
    id: 'Errors.no.userprofile.description',
    defaultMessage: 'Please login again to start to use your account!',
  },
  'Errors.no.orgBuildingAccess.title': {
    id: 'Errors.no.orgBuildingAccess.title',
    defaultMessage:
      "User doesn't have access to building object from this organization",
  },
  'Errors.no.orgBuildingAccess.description': {
    id: 'Errors.no.orgBuildingAccess.description',
    defaultMessage:
      "You don't have the permission to see this content, please login again or reload the app!",
  },
  'Home.ActiveBookings.hooks.fetchError': {
    id: 'Home.ActiveBookings.hooks.fetchError',
    defaultMessage: 'Could not fetch my active bookings',
  },
  'Home.Buildings.hooks.fetchFail': {
    id: 'Home.Buildings.hooks.fetchFail',
    defaultMessage: 'Could not get buildings list',
  },
  'TopBar.fetchError': {
    id: 'TopBar.fetchError',
    defaultMessage: 'Could not fetch organization data',
  },
  'Common.Errors.Fetch.User': {
    id: 'Common.Errors.Fetch.User',
    defaultMessage: 'Could not fetch user data',
  },
  'Errors.Book.ErrorAreaLimit': {
    id: 'Errors.Book.ErrorAreaLimit',
    defaultMessage: "Booking isn't saved: Area has no free spaces",
  },
  'Errors.Book.ErrorAreaBlocked': {
    id: 'Errors.Book.ErrorAreaBlocked',
    defaultMessage: "Booking isn't saved: Area is blocked",
  },
  'Errors.Book.ErrorDeskNotAvailable': {
    id: 'Errors.Book.ErrorDeskNotAvailable',
    defaultMessage: "Booking isn't saved: Desk isn't available",
  },
  'Errors.Book.GeneralError': {
    id: 'Errors.Book.GeneralError',
    defaultMessage: "Booking isn't saved: Error occurred",
  },
  'Errors.Book.ErrorTravelDay': {
    id: 'Errors.Book.ErrorTravelDay',
    defaultMessage:
      "Booking isn't saved: One TravelDay is allowed at the same time",
  },
  'Errors.Book.ErrorHomeOffice': {
    id: 'Errors.Book.ErrorHomeOffice',
    defaultMessage:
      "Booking isn't saved: One HomeOffice is allowed at the same time",
  },
  'Errors.Book.ErrorMeetingRoom': {
    id: 'Errors.Book.ErrorMeetingRoom',
    defaultMessage: "Booking isn't saved: Meeting room is not bookable",
  },
  'Errors.Book.ErrorTelefonBox': {
    id: 'Errors.Book.ErrorTelefonBox',
    defaultMessage: "Booking isn't saved: Telefon Box is not bookable",
  },
  'Errors.no-data.error_title': {
    id: 'Errors.no-data.error_title',
    defaultMessage: 'No data available.',
  },
  'Errors.Book.ErrorSickDay': {
    id: 'Errors.Book.ErrorSickDay',
    defaultMessage:
      'Please check your selected date. It looks like you’ve already booked a sick day.',
  },
  'Errors.Book.Quota': {
    id: 'Errors.Book.Quota',
    defaultMessage:
      'Unfortunately you cannot make this booking. Your quota has been used up for this week.',
  },
});

export const messages = dictionary;
