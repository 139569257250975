import { Formats } from '@utils/intl';
import { BOOKING_TYPE_SICKDAY } from '@views/shared/consts';

import { useValues as useVacationValues } from '@views/Book/Book/Vacation/hooks';

export default function useValues(formats: Formats) {
  const values = useVacationValues(formats);

  return {
    ...values,
    booking: { [BOOKING_TYPE_SICKDAY]: true },
    type: BOOKING_TYPE_SICKDAY,
  };
}
