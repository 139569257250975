import React, { useRef, useEffect, CSSProperties } from 'react';
import {
  SafeAreaView,
  View,
  VirtualizedList,
  StyleSheet,
  Text,
  TouchableHighlight,
} from 'react-native';

import { useIntl } from '@utils/intl';
import { universalDateFormatter } from '@utils/DateAndTime';
import { fonts, colors, globalStyles, spacings } from '@views/shared/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { useValues } from './hooks';

type Item = {
  date: Date;
  isActive: boolean;
};
interface ItemProps {
  item: Item;
  onChange: (string) => void;
  isFirst: boolean;
}

interface Props {
  containerStyle?: CSSProperties;
}

interface WrapperProps {
  containerStyle?: CSSProperties;
  children: JSX.Element;
}

const { white, aquaMarine, aquaMarineLight } = colors;
const { smallMedium, extraSmall, small } = spacings;
const { sizeSmallPlus } = fonts;

const getItem = (data: [Item], idx: number) => data[idx];

const f = (date: Date, format: string) =>
  universalDateFormatter({ date, format });

const Item = ({ item: { date, isActive }, onChange, isFirst }: ItemProps) => {
  const {
    formats: { dayShortFormat },
  } = useIntl() as any;

  return (
    <TouchableHighlight
      onPress={() => onChange(date)}
      underlayColor="transparent"
    >
      <View
        style={[
          styles.item,
          globalStyles.shadow,
          isActive && styles.itemActive,
          isFirst && { marginLeft: 2 },
        ]}
      >
        <Text style={[styles.weekDay, isActive && styles.weekDayActive]}>
          {f(date, 'dd')}
        </Text>
        <Text style={[styles.day, isActive && styles.dayActive]}>
          {f(date, dayShortFormat)}
        </Text>
      </View>
    </TouchableHighlight>
  );
};

// TODO fix scrolling to the selected date
export default function DateSwitcher({ containerStyle }: Props) {
  const { workingDays, onDateChange } = useValues();
  const listRef = useRef(0);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (listRef.current) {
      // @ts-ignore
      listRef.current.scrollToItem({
        item: workingDays.find(({ isActive }) => isActive),
        viewPosition: 0.9,
        animated: false,
      });
    } /*    return () => {
      listRef.current = 0;
    };*/
  }, [workingDays]);

  return (
    <View
      style={[
        styles.container,
        containerStyle,
        isDesktop && styles.containerDesktop,
      ]}
    >
      <SafeAreaView style={styles.saveArea}>
        <VirtualizedList
          horizontal
          ref={listRef}
          data={workingDays}
          renderItem={({ item, index }) => (
            <Item
              item={item}
              onChange={onDateChange}
              key={index}
              isFirst={index === 0}
            />
          )}
          keyExtractor={(item, index) => index.toString()}
          getItemCount={data => data.length}
          getItem={getItem}
          onScrollToIndexFailed={() => ({})}
          showsHorizontalScrollIndicator={false}
        />
      </SafeAreaView>
    </View>
  );
}

export function WithDateSwitcher({ children, containerStyle }: WrapperProps) {
  return (
    <>
      <DateSwitcher containerStyle={containerStyle} />
      {children}
    </>
  );
}

const styles = StyleSheet.create({
  item: {
    backgroundColor: white,
    height: 40,
    width: 40,
    justifyContent: 'center',
    margin: extraSmall,
    alignItems: 'center',
  },
  itemActive: {
    backgroundColor: aquaMarineLight,
    borderWidth: 1,
    borderColor: aquaMarine,
  },
  weekDay: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeSmallPlus,
  },
  weekDayActive: {
    color: aquaMarine,
  },
  day: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSmallPlus,
  },
  dayActive: {
    color: aquaMarine,
  },
  container: {
    paddingVertical: small,
    paddingLeft: smallMedium,
    backgroundColor: white,
  },
  containerDesktop: {
    alignSelf: 'center',
    paddingLeft: 0,
    maxWidth: 924,
  },
  saveArea: {
    flexDirection: 'row',
    padding: 0,
  },
});
