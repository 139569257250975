import { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { flatten, isEmpty, isNil, pick } from 'ramda';
import {
  useRoute,
  getFocusedRouteNameFromRoute,
} from '@react-navigation/native';

import { useIntl, Translator } from '@utils/intl';
import { useMyBuildings } from '@providers/Buildings';
import { useMyUser } from '@providers/User';

import { preloadImages } from '@assets/index';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { FLOOR_TYPE_PARKING } from '@views/shared/consts';
import { useCurrentDate } from '@views/Calendar/hooks';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { getFloorInfo, getFloorName } from '@utils/Building';

import {
  sortFloorsByTypeAndLabel,
  sortFloorsByTypeAndNumber,
} from '@views/shared/Booking';

import {
  Floor,
  FloorData,
  FloorPlan,
} from '@views/shared/interfaces/floorplan';

const getFloorsQuery = gql`
  query getBuilding($buildingId: ID!) {
    floorplanFloors(buildingId: $buildingId) {
      floors {
        id
        floorLabel
        floorNumber
        floorType
        plan
        areas {
          id
          areaType
          isBookable
        }
      }
    }
  }
`;

const toFloorToFloorPlan = (t: Translator) => (floor: Floor): FloorPlan => {
  const label = getFloorName({
    t,
    ...getFloorInfo(floor),
  });

  return {
    ...floor,
    label,
  };
};

const hasPlan = ({ plan }: Floor) => !isNil(plan) && !isEmpty(plan);
const hideParking = (hasParkingFeature: boolean) => ({ floorType }: Floor) =>
  !hasParkingFeature ? floorType !== FLOOR_TYPE_PARKING : true;

const getFloorsPlanImagesWithRotationIfEnabled = (
  rotationEnabled: boolean,
) => ({ plan }: FloorPlan) =>
  rotationEnabled
    ? [plan, plan.replace('floor_plan', 'floor_plan_rotated')]
    : plan;

const emptyBuilding = { name: '', address: '' };

export function useFloorPlan() {
  const {
    permissions: { canBookParkingSpot },
  } = useMyUser();
  // Dependencies
  const { t } = useIntl();
  const date = useCurrentDate();
  const { selectedBuildingId: buildingId, selectedBuilding } = useMyBuildings();
  const rotationEnabled = useHasFeature('floor_plan_rotation');
  const sortByNumber = useHasFeature('floor_plan_sorting');
  const hasParkingFeature = useHasFeature('parkings_enabled');
  const route = useRoute<any>();

  const [floors, setFloors] = useState<FloorPlan[]>([]);
  const [initialization, setInitialization] = useState(true);

  const sortFloors = sortByNumber
    ? sortFloorsByTypeAndNumber
    : sortFloorsByTypeAndLabel;

  const { data, loading, error } = useQuery<FloorData>(getFloorsQuery, {
    variables: {
      buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
  });

  useEffect(
    () => {
      if (data?.floorplanFloors?.floors.length) {
        const floors = data.floorplanFloors.floors
          .filter(hasPlan)
          .filter(hideParking(hasParkingFeature && canBookParkingSpot))
          .map(toFloorToFloorPlan(t));

        const floorsData = sortFloors(floors);

        const floorsImages = flatten(
          floorsData.map(
            getFloorsPlanImagesWithRotationIfEnabled(rotationEnabled),
          ),
        );

        preloadImages({ resources: floorsImages }).then(() => {
          setFloors(floorsData);
          setInitialization(false);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, rotationEnabled, hasParkingFeature],
  );

  return {
    building: selectedBuilding
      ? pick(['name', 'address'])(selectedBuilding)
      : emptyBuilding,
    loading: loading || initialization,
    error,
    floors,
    currentFloor: getFocusedRouteNameFromRoute(route),
    date,
  };
}
