import React from 'react';
import {
  Text,
  StyleSheet,
  ScrollView,
  Platform,
  SafeAreaView,
} from 'react-native';
import { ListItem } from 'react-native-elements';

import { Overlay } from '@views/shared/Overlay';
import { fonts, spacings } from '@views/shared/styles';

import { useSelect } from './hooks';
import { SelectProps, ItemComponentProps } from './interfaces';

export * from './interfaces';

const styles = StyleSheet.create({
  item: {
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-regular',
  },
  activeItem: {
    fontFamily: 'mrt-semi-bold',
  },
  content: {
    width: '100%',
    height: '100%',
  },
  list: {
    padding: spacings.smallMedium,
  },
});

function DefaultItem({
  item,
  onSelected,
  last,
  selectedItem,
}: ItemComponentProps) {
  if (!item) {
    return null;
  }

  const isActive = selectedItem ? selectedItem.value === item.value : false;

  return (
    <ListItem
      onPress={() => onSelected(item.value)}
      bottomDivider={!last}
      containerStyle={[styles.list]}
    >
      <ListItem.Content>
        <ListItem.Title style={[styles.item, isActive && styles.activeItem]}>
          {item.label}
        </ListItem.Title>
      </ListItem.Content>
    </ListItem>
  );
}

function DefaultSelected({ item, show, placeholder }: ItemComponentProps) {
  return (
    <Text style={styles.item} onPress={show}>
      {item?.label ?? placeholder}
    </Text>
  );
}

export function Select({
  items,
  value,
  onValueChange,
  itemComponent,
  selectedComponent,
  placeholder,
  errorStyle,
  label,
}: SelectProps) {
  const { shown, selectedItem, ...controls } = useSelect(items, value);
  const ItemComponent = itemComponent ?? DefaultItem;
  const SelectedComponent = selectedComponent ?? DefaultSelected;
  const { show, toggleShow } = controls;
  const itemHeight = 40;
  const height =
    items.length * itemHeight < 400 ? items.length * itemHeight : '70%';

  return (
    <>
      <SelectedComponent
        errorStyle={errorStyle}
        onSelected={show}
        item={selectedItem}
        placeholder={placeholder}
        label={label}
        {...controls}
      />
      {shown ? (
        <Overlay
          onClose={toggleShow}
          noHeader
          width={Platform.OS === 'web' ? 350 : '80%'}
          height={height}
          contentStyle={{
            padding: 0,
          }}
          overlayStyle={{ borderRadius: 0 }}
        >
          <SafeAreaView>
            <ScrollView>
              {items.map((item, index) => (
                <ItemComponent
                  key={item.value}
                  item={item}
                  last={index === items.length - 1}
                  {...controls}
                  onSelected={value => {
                    toggleShow();
                    onValueChange(value);
                  }}
                  selectedItem={selectedItem}
                />
              ))}
            </ScrollView>
          </SafeAreaView>
        </Overlay>
      ) : null}
    </>
  );
}
