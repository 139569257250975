import { toHoursAndMinutes } from '@utils/DateAndTime';

export function containValue(val: number, min: number, max: number) {
  return val > max ? max : val < min ? min : val;
}

export function getTotalAttendance(data) {
  const total = data.reduce(
    (acc, { totalWorkMinutes }) => acc + totalWorkMinutes,
    0,
  );
  return toHoursAndMinutes(total);
}

export function getTotalBreak(data) {
  const total = data.reduce(
    (acc, { totalTimeMinutes }) => acc + totalTimeMinutes,
    0,
  );
  return toHoursAndMinutes(total);
}
