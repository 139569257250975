import { defineMessages } from 'react-intl';

const dictionary = defineMessages({
  'Home.Structure.Floor.Area.Desks.Desk.empty_label': {
    id: 'Home.Structure.Floor.Area.Desks.Desk.empty_label',
    defaultMessage: 'Desk',
  },
  'Home.Structure.Floor.Area.Parkings.Spot.empty_label': {
    id: 'Home.Structure.Floor.Area.Parkings.Spot.empty_label',
    defaultMessage: 'Parking spot',
  },
  'Home.Structure.Title.room': {
    id: 'Home.Structure.Title.room',
    defaultMessage: 'Shared Desks',
  },
  'Home.Structure.Title.free_spaces_room': {
    id: 'Home.Structure.Title.free_spaces_room',
    defaultMessage: 'Free Seats',
  },
  'Home.Structure.Title.meeting_room': {
    id: 'Home.Structure.Title.meeting_room',
    defaultMessage: 'Meeting Room',
  },
  'views.Home.Bookings.Desks.total': {
    id: 'views.Home.Bookings.Desks.total',
    defaultMessage: '{totalDesks} seats',
  },
  'views.Home.Bookings.MeetingRooms.total': {
    id: 'views.Home.Bookings.MeetingRooms.total',
    defaultMessage: '{total} rooms',
  },
  'views.Home.Bookings.Areas.total': {
    id: 'views.Home.Bookings.Areas.total',
    defaultMessage: '{total} areas',
  },
  'Home.Structure.Parkings.Total': {
    id: 'Home.Structure.Parkings.Total',
    defaultMessage: '{total} spots',
  },
  'Home.Structure.Title.parking': {
    id: 'Home.Structure.Title.parking',
    defaultMessage: 'Parking Spot',
  },
  'Home.Structure.hooks.error': {
    id: 'Home.Structure.hooks.error',
    defaultMessage: 'Could not fetch structure of building',
  },
  'Home.Structure.Floor.Area.Equipment': {
    id: 'Home.Structure.Floor.Area.Equipment',
    defaultMessage: 'This equipment is available:',
  },
  'Home.Structure.Floor.Area.Desks.error': {
    id: 'Home.Structure.Floor.Area.Desks.error',
    defaultMessage: 'Could not fetch available desks for booking',
  },
  'Home.Structure.Floor.Area.Desks.NoFreeDesks': {
    id: 'Home.Structure.Floor.Area.Desks.NoFreeDesks',
    defaultMessage: 'No free desks in this area',
  },
  'Home.Structure.Floor.Area.Desks.ShowMore': {
    id: 'Home.Structure.Floor.Area.Desks.ShowMore',
    defaultMessage: 'show more',
  },
  'Home.Structure.Floor.Area.Desks.ShowLess': {
    id: 'Home.Structure.Floor.Area.Desks.ShowLess',
    defaultMessage: 'show less',
  },
  'Home.Structure.MeetingRoom.SelectTime': {
    id: 'Home.Structure.MeetingRoom.SelectTime',
    defaultMessage: 'Select a time slot',
  },
  'Home.Structure.MeetingRoom.SelectTime.close': {
    id: 'Home.Structure.MeetingRoom.SelectTime.close',
    defaultMessage: 'Close time slots',
  },
  'Home.Structure.book': {
    id: 'Home.Structure.book',
    defaultMessage: 'Book',
  },
  'Home.Structure.Seats.nofree': {
    id: 'Home.Structure.Seats.nofree',
    defaultMessage: 'No free seats in this area',
  },
  'Home.Structure.Floor.Area.Parkings.error': {
    id: 'Home.Structure.Floor.Area.Parkings.error',
    defaultMessage: 'Could not fetch available parking spots for booking',
  },
  'Home.Structure.Floor.Area.Parkings.NoParkingSpots': {
    id: 'Home.Structure.Floor.Area.Parkings.NoParkingSpots',
    defaultMessage: 'No free parking spots in this area',
  },
  'Home.Structure.Parkings.Title': {
    id: 'Home.Structure.Parkings.Title',
    defaultMessage: 'Parking Spots',
  },
  'Home.Structure.Parkings.DataFailed': {
    id: 'Home.Structure.Parkings.DataFailed',
    defaultMessage: 'Could not fetch data for parking spots',
  },
  'Home.Structure.MeetingRoom.NotAvailable': {
    id: 'Home.Structure.MeetingRoom.NotAvailable',
    defaultMessage: 'This meeting room is not available',
  },
  'Home.Structure.Floor.Area.Parkings.VirtualParkingGENERAL': {
    id: 'Home.Structure.Floor.Area.Parkings.VirtualParkingGENERAL',
    defaultMessage: 'Parking spot',
  },
  'Home.Structure.Floor.Area.Parkings.VirtualParkingCHARGING': {
    id: 'Home.Structure.Floor.Area.Parkings.VirtualParkingCHARGING',
    defaultMessage: 'Charging station',
  },
  'Home.Structure.Floor.Area.Parkings.VirtualParkingHANDICAPPED': {
    id: 'Home.Structure.Floor.Area.Parkings.VirtualParkingHANDICAPPED',
    defaultMessage: 'Parking spot for handicapped',
  },
  'Home.Structure.Title.telefon_box': {
    id: 'Home.Structure.Title.telefon_box',
    defaultMessage: 'Telefon Box',
  },
  'views.Home.Bookings.TelefonBox.total': {
    id: 'views.Home.Bookings.TelefonBox.total',
    defaultMessage: '{total} telefon boxes',
  },
  'Home.Structure.TelefonBox.NotAvailable': {
    id: 'Home.Structure.TelefonBox.NotAvailable',
    defaultMessage: 'This telefon box is not available',
  },
  'Home.Structure.Floor.Area.Parkings.VirtualParkingFAST_CHARGING': {
    id: 'Home.Structure.Floor.Area.Parkings.VirtualParkingFAST_CHARGING',
    defaultMessage: 'Fast charging station',
  },
  'Home.Structure.Comment.Label': {
    id: 'Home.Structure.Comment.Label',
    defaultMessage: 'Comment',
  },
  'Home.Structure.Comment.Description': {
    id: 'Home.Structure.Comment.Description',
    defaultMessage:
      'Add the optional comment for the booking (maximum length: 200 characters)',
  },
});

export const messages = dictionary;
