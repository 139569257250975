import { useCallback, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { range } from 'ramda';

import { useMyOrganization } from '@providers/Organization';

import {
  setTimeToDate,
  universalDateFormatter,
  isBefore,
  getNumberFromHours,
} from '@utils/DateAndTime';

const startUserAttendance = gql`
  mutation startUserAttendance($startTime: DateTime!) {
    startUserAttendance(startTime: $startTime) {
      id
      status
      startTime
      endTime
      date
      totalWorkMinutes
    }
  }
`;

const startUserAttendanceBreak = gql`
  mutation startUserAttendanceBreak(
    $attendanceId: ID!
    $breakStartTime: DateTime!
  ) {
    startUserAttendanceBreak(
      attendanceId: $attendanceId
      breakStartTime: $breakStartTime
    ) {
      id
      status
      startTime
      endTime
      date
      totalWorkMinutes
    }
  }
`;

const endUserAttendanceBreak = gql`
  mutation endUserAttendanceBreak(
    $attendanceId: ID!
    $breakEndTime: DateTime!
  ) {
    endUserAttendanceBreak(
      attendanceId: $attendanceId
      breakEndTime: $breakEndTime
    ) {
      id
      status
      startTime
      endTime
      date
      totalWorkMinutes
    }
  }
`;

const endUserAttendance = gql`
  mutation endUserAttendance(
    $attendanceId: ID!
    $endTime: DateTime!
    $comment: String
  ) {
    endUserAttendance(
      attendanceId: $attendanceId
      endTime: $endTime
      comment: $comment
    ) {
      id
      status
      startTime
      endTime
      date
      totalWorkMinutes
    }
  }
`;

const mapMutationByMode = {
  start: startUserAttendance,
  break: startUserAttendanceBreak,
  breakEnd: endUserAttendanceBreak,
  stop: endUserAttendance,
};

//return date with rounded minutes
const getDate = () => {
  const coeff = 1000 * 60 * 5;
  return new Date(Math.floor(new Date().getTime() / coeff) * coeff);
};

export function useAttendance(props) {
  const { onComplete, onToggle, attendanceId, mode, lastBlockedTime } = props;
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  const currentDate = getDate();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [time, setTime] = useState({
    hours: currentDate.getHours(),
    minutes: currentDate.getMinutes(),
  });
  const [comment, setComment] = useState<string>('');

  const onTimeChange = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      setTime({ hours: parseInt(hours), minutes: parseInt(minutes) });
    },
    [setTime],
  );

  const updateComment = useCallback(value => setComment(value), [setComment]);

  const [saveAttendance] = useMutation(mapMutationByMode[mode]);
  const selectedTime = setTimeToDate(time);

  const onSave = useCallback(async () => {
    setIsLoading(true);
    const formattedDate = universalDateFormatter({
      date: selectedTime,
      format: 'YYYY-MM-DDTHH:mm:ssZ',
    });

    await saveAttendance({
      variables: {
        attendanceId,
        startTime: formattedDate,
        breakStartTime: formattedDate,
        endTime: formattedDate,
        breakEndTime: formattedDate,
        comment,
      },
    })
      .then(() => {
        setIsLoading(false);
        setTimeout(() => onComplete(), 2000);
        onToggle();
      })
      .catch(e => {
        setIsLoading(false);
        setIsError(true);
        console.info(e);
      });
  }, [saveAttendance, time, onComplete, comment]);

  const start = getNumberFromHours(workingHoursStart);
  const end = getNumberFromHours(workingHoursEnd);
  const workingHours = [...range(start, end), end];

  return {
    currentDate,
    isError,
    isLoading,
    onSave,
    onTimeChange,
    time,
    isTimeNotValid: !isBefore(selectedTime.subtract(5, 'minutes'), currentDate),
    workingHours,
    isOverlappedTime: selectedTime.valueOf() < lastBlockedTime,
    updateComment,
  };
}
