import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';

import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { FavouriteBadge } from '@views/Favourites/FavouriteBadge';
import { BookingIcon } from '@views/shared/BookingIcon';
import { getBookingIcon } from '@views/shared/consts';
import {
  WorklightStatus,
  AreaStructureForParkingSpots,
  AreaStructureForDesks,
  AreaStructureForMeetingRooms,
  AreaStructureForFreeSeats,
  AreaStructureForTelefonBox,
} from '@views/shared/interfaces/buildingStructure';

const { huge, medium, small, smaller, smallMedium } = spacings;
const { blackAlpha, white } = colors;
const { sizeSemiSmall } = fonts;

export const styles = StyleSheet.create({
  mainWrapper: {
    marginBottom: medium,
    backgroundColor: white,
  },
  container: {
    borderColor: blackAlpha,
    borderTopWidth: 1,
  },
  headerContainer: {
    padding: smallMedium,
  },
  headerContainerArea: {
    borderRadius: 0,
    marginHorizontal: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerArea: {
    padding: smallMedium,
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    paddingLeft: small,
  },
  image: {
    width: '100%',
    height: 200,
  },
  buttonContainer: {
    flex: 1,
    alignItems: 'center',
  },
  badgeWrapper: {
    justifyContent: 'center',
  },
  noFree: {
    textAlign: 'center',
    fontFamily: 'pts-regular',
  },
  central: {
    flexDirection: 'row',
    padding: small,
    alignItems: 'center',
    justifyContent: 'center',
  },
  noImage: {
    borderBottomColor: 'blue',
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  more: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 0,
    padding: smallMedium,
  },
  moreText: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSemiSmall,
    paddingRight: 3,
  },
  toggle: {
    alignSelf: 'center',
    top: 1,
    paddingLeft: smaller,
  },
  toggleEquipment: {
    marginLeft: medium,
  },
  noDesks: {
    paddingVertical: small,
    textAlign: 'center',
    fontFamily: 'mrt-regular',
  },
  open: {
    borderBottomColor: blackAlpha,
    borderBottomWidth: 1,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  equipmentContainer: {
    paddingTop: huge,
  },
  equipmentTitle: {
    fontFamily: 'mrt-semi-bold',
  },
  equipmentStuff: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSemiSmall,
  },
  equipment: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  equipmentBlock: {
    paddingTop: small,
    width: '49%',
  },
  noEquipment: {
    width: 19,
    marginLeft: medium,
  },
  buttonIcon: {
    marginLeft: 2,
    marginRight: -7,
  },
  equipmentBlockFullWidth: {
    width: 'auto',
  },
  quarter: {
    width: 30,
    height: 30,
  },
  row: {
    flexDirection: 'row',
    padding: smallMedium,
    paddingLeft: 0,
    /*borderRadius: extraSmall,*/
    backgroundColor: white,
    alignItems: 'center',
  },
  middle: {
    flex: 1,
    paddingLeft: smallMedium,
    borderLeftWidth: 1,
    borderColor: blackAlpha,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  left: {
    paddingHorizontal: smallMedium,
    width: 50,
  },
  accordionChild: {
    borderTopWidth: 1,
    borderColor: blackAlpha,
  },
});

interface Props {
  area:
    | AreaStructureForParkingSpots
    | AreaStructureForDesks
    | AreaStructureForMeetingRooms
    | AreaStructureForFreeSeats
    | AreaStructureForTelefonBox;
  worklight: WorklightStatus;
}

export function AreaHeader({ area, worklight }: Props) {
  const { areaType, name, image } = area;

  return (
    <>
      <View style={[styles.row, { paddingBottom: 0 }]}>
        <View style={styles.left}>
          <BookingIcon name={getBookingIcon(areaType)} worklight={worklight} />
        </View>
        <View style={styles.middle}>
          <Text style={globalStyles.title}>{name}</Text>
        </View>

        {areaType !== 'telefon_box' && (
          <View style={styles.badgeWrapper}>
            <FavouriteBadge type={areaType} id={area.id} />
          </View>
        )}
      </View>
      {image && image.trim().length > 0 ? (
        <Image
          style={styles.image}
          resizeMode="contain"
          source={{ uri: image }}
        />
      ) : null}
    </>
  );
}
