import React from 'react';

import AvailabilityInfos from '@views/shared/AvailabilityInfos';
import { TimeSlot } from '@views/shared/TimeSlots/interfaces';

import { useInfos } from './hooks';
import { BookingType } from '@views/shared/interfaces/booking';

export default function Infos({
  slot,
  location,
  dismiss,
  type,
}: {
  location: string;
  slot: TimeSlot;
  dismiss: (slot?: TimeSlot) => any;
  type: BookingType;
}) {
  const { error, loading, unavailableTimePeriods } = useInfos({
    slot,
  });

  const { start, end, status } = slot;

  return (
    <AvailabilityInfos
      dismiss={dismiss}
      error={error}
      loading={loading}
      fullCaption={location}
      date={start}
      status={status}
      unavailableTimeWithColleague={unavailableTimePeriods}
      type={type}
      time={{ start, end }}
    />
  );
}
