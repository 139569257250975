import React from 'react';
import { Text, View, StyleSheet, ImageBackground } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { isNil } from 'ramda';

import { colors, fonts, spacings } from '@views/shared/styles';
import { ICON_COLORS, useMyOrganization } from '@providers/Organization';

import { Logo } from './Logo';
import FilterSelect from '@views/Book/Filter/select';
import FilterIcon from '@views/Book/Filter/icon';

const { smallMedium, oversize } = spacings;
const { black, blackAlpha, white } = colors;
const { sizeDefault } = fonts;

const styles = StyleSheet.create({
  header: {
    padding: smallMedium,
    borderBottomColor: blackAlpha,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
  },
  name: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeDefault,
    textAlign: 'center',
    paddingTop: smallMedium,
  },
  imageContainer: {
    height: 40,
    flex: 1,
  },
  lizLogo: {
    position: 'absolute',
    top: oversize,
    left: smallMedium,
  },
  logo: {
    flex: 1,
  },
});

export function TopBar({ navigationRef: { current: navigation } }) {
  const { backgroundColor, name, logo, iconColor } = useMyOrganization();
  const contrastColor = iconColor === ICON_COLORS.DARK ? black : white;

  return (
    <SafeAreaView forceInset={{ top: 'always' }}>
      <View style={[styles.header, { backgroundColor }]}>
        <View style={styles.lizLogo}>
          <Logo color={contrastColor} />
        </View>
        <View style={styles.imageContainer}>
          {logo ? (
            <ImageBackground
              source={{ uri: logo }}
              style={styles.logo}
              resizeMode="contain"
            />
          ) : (
            <Text style={[styles.name, { color: contrastColor }]}>{name}</Text>
          )}
        </View>
        {isNil(navigation) ? null : (
          <FilterComponent navigation={navigation} color={contrastColor} />
        )}
      </View>
    </SafeAreaView>
  );
}

export function FilterComponent(props) {
  return (
    <>
      <FilterSelect {...props} />
      <FilterIcon {...props} />
    </>
  );
}
