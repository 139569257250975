import React, { useCallback, useState } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { isEmpty, isNil } from 'ramda';

import { useMyOrganization } from '@providers/Organization';
import { useIntl } from '@utils/intl';
import { getTimeByType } from '@views/Home/ActiveBookings/Booking';
import { SecondaryButton } from '@views/shared/Button';
import { BookingIcon } from '@views/shared/BookingIcon';
import Location from '@views/shared/hooks/getLocation';
import { getBookingIcon } from '@views/shared/consts';
import {
  Ordered as AdditionalOrder,
  ItemIcon as AdditionalOrderIcon,
} from '@views/Book/Book/MeetingRoom/AdditionalOrder/Order';

import { colors, spacings, globalStyles } from '@views/shared/styles';
import { Booking as BookingType } from '@views/shared/interfaces/booking';

import { useBooking } from './hooks';

const {
  tiny,
  medium,
  smallMedium,
  extraSmall,
  big,
  small,
  verySmall,
} = spacings;
const { aquaMarine, blackAlpha, warmPink } = colors;

export const styles = StyleSheet.create({
  item: {
    paddingVertical: smallMedium,
    borderTopWidth: 1,
    borderColor: blackAlpha,
  },
  itemLast: {
    borderBottomWidth: 1,
    borderColor: blackAlpha,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderLeftColor: aquaMarine,
    borderLeftWidth: tiny,
    padding: verySmall,
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
    paddingLeft: smallMedium,
  },
  between: {
    justifyContent: 'space-between',
  },
  left: {
    paddingLeft: 0,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    width: 50,
  },
  buttonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: medium,
    borderLeftWidth: 0,
  },
  button: {
    borderColor: warmPink,
    paddingHorizontal: 0,
    paddingVertical: 0,
    padding: 0,
  },
  buttonText: {
    color: warmPink,
  },
  empty: {
    width: 55,
  },
  bookingItem: {
    paddingVertical: extraSmall,
  },
  noBookingContainer: {
    padding: big,
    borderRadius: extraSmall,
  },
  bookingItemDesktop: {
    borderRadius: extraSmall,
    marginBottom: small,
  },
});

interface Props {
  booking: BookingType;
  onCancel: any;
}

export default function Booking({ booking, onCancel }: Props) {
  const [showCatering, setShowCatering] = useState(false);
  const [showEquipping, setShowEquipping] = useState(false);

  const toggleCatering = useCallback(
    () => setShowCatering(prevState => !prevState),
    [setShowCatering],
  );

  const toggleEquipping = useCallback(
    () => setShowEquipping(prevState => !prevState),
    [setShowEquipping],
  );

  const {
    t,
    formats: { timeFormatShort },
  } = useIntl() as any;

  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  const { cancel, end, isCancelable, isExpired, start, type } = useBooking(
    booking,
    onCancel,
  );

  const time = getTimeByType({
    end,
    start,
    timeFormatShort,
    workingHoursStart,
    workingHoursEnd,
  });

  const { catering, equipping, comment } = booking;

  const hasCatering = !isNil(catering) && !isEmpty(catering);
  const hasEquipping = !isNil(equipping) && !isEmpty(equipping);

  return (
    <View style={styles.bookingItem}>
      <View style={[styles.row, { borderLeftWidth: 0 }]}>
        <View style={styles.left}>
          <BookingIcon name={getBookingIcon(type)} />
        </View>
        <View style={[styles.right, { paddingLeft: 0 }]}>
          <Location booking={booking} showDeskTranslation />
          <Text style={globalStyles.description}>{time}</Text>
          {comment && comment.length ? (
            <Text style={globalStyles.description}>{comment}</Text>
          ) : null}
        </View>
        {hasCatering ? (
          <AdditionalOrderIcon onPress={toggleCatering} type="catering" />
        ) : null}
        {hasEquipping ? (
          <AdditionalOrderIcon onPress={toggleEquipping} type="equipping" />
        ) : null}
        {isCancelable && !isExpired ? (
          <View style={[styles.row, styles.buttonContainer]}>
            <SecondaryButton
              buttonStyle={globalStyles.cancelButton}
              titleStyle={globalStyles.cancelButtonText}
              title={t('Home.ActiveBookings.Booking.cancel')}
              onPress={cancel}
            />
          </View>
        ) : null}
      </View>
      {showCatering && hasCatering
        ? catering.map((data, index) => (
            <AdditionalOrder
              data={data}
              key={index}
              type="catering"
              isExpired={isExpired}
              isBookingsOverview
            />
          ))
        : null}
      {showEquipping && hasEquipping
        ? equipping.map((data, index) => (
            <AdditionalOrder
              data={data}
              key={index}
              type="equipping"
              isExpired={isExpired}
              isBookingsOverview
            />
          ))
        : null}
    </View>
  );
}
