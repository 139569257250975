import React from 'react';

import { useIntl } from '@utils/intl';
import { BookingIcon } from '@views/shared/BookingIcon';
import { Panel } from '@views/Book/Bookings/Panel';
import {
  BOOK_CATEGORY_FREE_SPACES_ROOM,
  getBookingIcon,
} from '@views/shared/consts';

import { useFreeSeats } from './hooks';

const getCountDesc = (count = 0) => {
  const translation = 'views.Home.Bookings.Areas.total';
  const templateVars = { total: count };

  return {
    translation,
    templateVars,
  };
};

export default function FreeSeats() {
  const { t } = useIntl();
  const errorMessage = t('views.Home.Bookings.Desks.hooks.desksDataFail');

  const {
    numberOfFreeSpacesRooms,
    loading,
    error,
    favCount,
    goToFavourites,
    goToStructure,
    worklightStatus,
  } = useFreeSeats(BOOK_CATEGORY_FREE_SPACES_ROOM, errorMessage);

  const name = t('views.Home.Bookings.Desks.free');
  const { translation, templateVars } = getCountDesc(numberOfFreeSpacesRooms);

  if (error) {
    return null;
  }

  return (
    <Panel
      loading={loading}
      text={t(translation, templateVars)}
      favs={favCount}
      icon={
        <BookingIcon
          name={getBookingIcon(BOOK_CATEGORY_FREE_SPACES_ROOM)}
          worklight={worklightStatus}
        />
      }
      name={name}
      onFavsPress={goToFavourites}
      onAction={goToStructure}
    />
  );
}
