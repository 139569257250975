import React from 'react';

import { createStackNavigator } from '@react-navigation/stack';
import { atom, useSetRecoilState, useRecoilState } from 'recoil';

import Home from '@views/Home';
import Book from '@views/Book';
import Bookings from '@views/Bookings';
import Floorplan from '@views/Floorplan';
import { Colleagues } from '@views/Colleagues';
import { Overview } from '@views/Home/Profile/Overview';
import { Login, useLoggedIn } from '@views/Login';
import { useMyUser } from '@providers/User';
import Actions from '@views/Home/Profile/Actions';
import Favourites from '@views/Book/Favourites';
import { WithDateSwitcher } from '@views/shared/DateSwitcher';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import NoAccess from '@views/shared/NoAccess';
import { withLizTitle } from '@views/shared/utils';
import { useIntl } from '@utils/intl';

import Sidebar from './Sidebar';
import { useCallback } from 'react';

const Stack = createStackNavigator();

export const activeWebTabAtom = atom({
  key: 'active-web-tab',
  default: 'index',
});

export default function WebRoutes({ navigation }) {
  const loggedIn = useLoggedIn();
  const { t } = useIntl();
  const {
    showActionsPage,
    permissions: { canSeeColleagues, canUseBooker },
    loaded,
  } = useMyUser();

  const showColleaguesScreen =
    !useHasFeature('disable_colleagues') && canSeeColleagues;

  const setActiveWebTab = useSetRecoilState(activeWebTabAtom);
  const [activeWebTab] = useRecoilState(activeWebTabAtom);

  const setActiveTabAtom = useCallback(
    (selected, tab) => (selected ? setActiveWebTab(tab) : ''),
    [setActiveWebTab],
  );

  return (
    <>
      <Sidebar
        navigation={navigation}
        showActionsPage={showActionsPage}
        canUseBooker={canUseBooker}
      />
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {loggedIn && loaded ? (
          !canUseBooker ? (
            <Stack.Screen name="home" component={NoAccess} />
          ) : showActionsPage ? (
            <Stack.Screen name="actions" component={Actions} />
          ) : (
            <>
              <Stack.Screen name="home">
                {props => {
                  setActiveTabAtom(props.navigation.isFocused(), 'index');
                  return <Home />;
                }}
              </Stack.Screen>
              <Stack.Screen name="book">
                {props => {
                  setActiveTabAtom(props.navigation.isFocused(), activeWebTab);
                  return <Book />;
                }}
              </Stack.Screen>
              <Stack.Screen name="bookings">
                {props => {
                  setActiveTabAtom(props.navigation.isFocused(), 'bookings');
                  return <Bookings />;
                }}
              </Stack.Screen>
              <Stack.Screen
                name="favourites"
                options={{ title: withLizTitle(t('Routes.Home.Favourites')) }}
              >
                {() => (
                  <WithDateSwitcher>
                    <Favourites />
                  </WithDateSwitcher>
                )}
              </Stack.Screen>
              <Stack.Screen name="floorplan">
                {props => {
                  setActiveTabAtom(props.navigation.isFocused(), 'floorplan');
                  return (
                    <WithDateSwitcher>
                      <Floorplan />
                    </WithDateSwitcher>
                  );
                }}
              </Stack.Screen>
              <Stack.Screen name="colleagues">
                {props => {
                  setActiveTabAtom(props.navigation.isFocused(), 'colleagues');
                  return showColleaguesScreen && canSeeColleagues ? (
                    <WithDateSwitcher>
                      <Colleagues />
                    </WithDateSwitcher>
                  ) : (
                    <NoAccess />
                  );
                }}
              </Stack.Screen>
              <Stack.Screen name="myProfile">
                {props => {
                  setActiveTabAtom(props.navigation.isFocused(), 'myProfile');
                  return <Overview />;
                }}
              </Stack.Screen>
            </>
          )
        ) : (
          <Stack.Screen name="login" component={Login} />
        )}
      </Stack.Navigator>
    </>
  );
}
