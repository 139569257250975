import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { GallerySlider } from '@views/shared/GallerySlider';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const placeHolderImage = require('@assets/images/placeholder-building.jpg');

const styles = StyleSheet.create({
  card: {
    marginVertical: 20,
    height: 300,
    position: 'relative',
  },
});

export function GalleryExamples() {
  const items = [
    {
      image: {
        uri:
          'https://cdn.develop.onltx.com/buildings/20180525-BoazArad-3W3A9929_30_31.jpg',
      },
    },
    {
      image: {
        uri: 'https://cdn.develop.onltx.com/areas/org_CzCgBEV.jpeg',
      },
    },
    {
      image: {
        uri: 'https://cdn.develop.onltx.com/organization_photo/most.jpg',
      },
    },
    {
      image: placeHolderImage,
    },
  ];
  return (
    <View style={styles.card}>
      <Text h3>Gallery Slider</Text>
      <GallerySlider items={items} />
    </View>
  );
}
