import { useCallback, useState } from 'react';
import { findIndex, propEq } from 'ramda';
import { gql, useMutation } from '@apollo/client';
import { isNil, project, clone } from 'ramda';

import { useMyUser } from '@providers/User';
import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useMyBuildings } from '@providers/Buildings';

import { getDiffFromNow } from '@utils/DateAndTime';

import {
  Item,
  ItemsProps,
  OrderResponse,
} from '@views/Book/Book/MeetingRoom/AdditionalOrder/types';

const orderCateringMutation = gql`
  mutation addCatering(
    $bookingId: ID!
    $items: [CateringCartItemInput]
    $language: LanguageEnumType
  ) {
    addOrder: addCatering(
      catering: { bookingId: $bookingId, items: $items }
      language: $language
    ) {
      id
      items {
        quantity
        item {
          title
        }
      }
    }
  }
`;

const orderEquippingMutation = gql`
  mutation addEquipping(
    $bookingId: ID!
    $items: [EquippingCartItemInput]
    $language: LanguageEnumType
  ) {
    addOrder: addEquipping(
      equipping: { bookingId: $bookingId, items: $items }
      language: $language
    ) {
      id
      items {
        quantity
        item {
          title
        }
      }
    }
  }
`;

const mutationMap = {
  catering: orderCateringMutation,
  equipping: orderEquippingMutation,
};

export function useItems({ bookingId, items, type, start }: ItemsProps) {
  const [quantityItems, setQuantityItems] = useState<Item[]>(items);
  const { language } = useMyUser();
  const { refreshActiveBookings } = useMyActiveBookings();
  const { selectedBuilding } = useMyBuildings();

  const {
    cateringPreparationTime,
    equipmentPreparationTime,
  } = selectedBuilding;

  const timeGapInMinutes = getDiffFromNow(start, 'minute');

  const prepTime =
    type === 'catering' ? cateringPreparationTime : equipmentPreparationTime;

  const [save, { data, error, loading: isLoading }] = useMutation<
    OrderResponse
  >(mutationMap[type]);

  const createOrder = useCallback(async () => {
    const items = quantityItems.filter((item: Item) => item.quantity);

    if (!items.length) {
      return;
    }

    await save({
      variables: {
        bookingId,
        items: project(['itemId', 'quantity'], items),
        language,
      },
    });
    refreshActiveBookings();
  }, [bookingId, quantityItems, language, save, refreshActiveBookings]);

  // update quantity for changed item
  const onItemUpdate = useCallback(
    (itemId: string, increase = true) => {
      const items = clone(quantityItems);
      const index = findIndex(propEq('itemId', itemId))(items);

      const { quantity = 0 } = items[index];

      items[index].quantity = quantity + (increase ? 1 : -1);

      setQuantityItems(items);
    },
    [quantityItems],
  );

  const isError = !isNil(error);

  return {
    createOrder,
    data: data?.addOrder || {},
    isError,
    items: quantityItems,
    isDirty:
      isLoading ||
      isError ||
      quantityItems.filter((item: Item) => item.quantity).length < 1,
    onItemUpdate,
    disableOrder: prepTime === 0 ? false : timeGapInMinutes - prepTime < 0,
    prepTime,
  };
}
