import { useEffect, useState, useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigation } from '@react-navigation/native';
import { isNil } from 'ramda';

import { getMyBookings, useMyActiveBookings } from '@providers/ActiveBookings';
import { useMyUser } from '@providers/User';

import {
  universalDateFormatter,
  getAllDay,
  sortByDateAdapter,
  getTodayAsDate,
} from '@utils/DateAndTime';

export function useCurrentBookings() {
  const navigation = useNavigation<any>();
  const { refreshActiveBookings } = useMyActiveBookings();
  const [bookings, setBookings] = useState([]);
  const date = getTodayAsDate();
  const { start, end } = getAllDay(date);
  const { language } = useMyUser();

  const { loading, data, refetch: refreshBookings } = useQuery(getMyBookings, {
    variables: {
      language,
      start: universalDateFormatter({ date: start }),
      end: universalDateFormatter({ date: end }),
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      const { actives, checkedIn } = data;
      let bookings = [];

      if (!isNil(checkedIn) && !isNil(actives)) {
        bookings = checkedIn.concat(actives).sort(sortByDateAdapter);
      }

      setBookings(bookings);
    }
  }, [data]);

  useEffect(() => {
    navigation.addListener('focus', () => {
      refreshBookings();
    });
  }, [navigation, refreshBookings]);

  const onUpdate = useCallback(() => {
    refreshActiveBookings();
    refreshBookings();
  }, [refreshActiveBookings, refreshBookings]);

  return {
    bookings,
    loading,
    onUpdate,
  };
}
