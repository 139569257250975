import React, { useCallback, useRef, useState } from 'react';
import { View, Image, StyleSheet } from 'react-native';
import { isEmpty, pluck } from 'ramda';

import { PanArea } from '@views/shared/PanArea';
import { PinchArea } from '@views/shared/PinchArea';
import { AvailabilityInfo } from '@views/shared/interfaces/floorplan';

import { getBookablePoints, getBookableAreas } from './utils';
import { useZoomImage } from './hooks.web';
import { Points } from './Points';
import Areas from './Areas';
import Zoommer from './Zoommer';
import Infos from './Infos';
import DataLoader from './loader';

import { ZoomImageProps } from './interfaces';

const MIN_PINCH_DOWN_SCALE = 0.05;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  layer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'hidden',
  },
  image: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
});

export default function ZoomImage({
  floor,
  reset,
  building,
  date,
}: ZoomImageProps) {
  const {
    imageWidth = 1,
    imageHeight = 1,
    viewWidth = 1,
    viewHeight = 1,
    scale,
    x,
    y,
    correctionX,
    correctionY,
    onPan,
    onScale,
    minus,
    plus,
    url,
  } = useZoomImage(floor.plan, reset);

  const { areas = [], floorType } = floor;

  const pinchRef = useRef<any>();
  const panRef = useRef<any>();

  const [availabilityInfos, setAvailabilityInfos] = useState<
    AvailabilityInfo
  >();

  const areaIdsForBookablePoints = pluck('id', areas.filter(getBookablePoints));
  const areaIdsForBookableAreas = pluck('id', areas.filter(getBookableAreas));

  const [loading, setLoading] = useState({ points: false, areas: false });

  const showAvailability = useCallback(
    (info?: AvailabilityInfo) => setAvailabilityInfos(info),
    [],
  );

  const updateLoadingLayer = useCallback(
    (type: string, loading: boolean) =>
      setTimeout(
        () =>
          setLoading(prevState => ({
            ...prevState,
            [type]: loading,
          })),
        500,
      ),
    [],
  );

  if (!url || reset) {
    return null;
  }

  return (
    <View style={styles.container}>
      {loading.areas || loading.points ? <DataLoader /> : null}

      {availabilityInfos ? (
        <Infos
          info={availabilityInfos}
          dismiss={showAvailability}
          building={building}
          floor={floor}
          date={date}
        />
      ) : null}
      <Zoommer onMinus={minus} onPlus={plus} />
      <PinchArea
        pinchRef={pinchRef}
        simultaneousHandlers={panRef}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        viewWidth={viewWidth}
        viewHeight={viewHeight}
        onChange={onScale}
        x={x}
        y={y}
        minScale={MIN_PINCH_DOWN_SCALE}
        isWeb
      >
        <PanArea
          ref={panRef}
          simultaneousHandlers={pinchRef}
          onChange={onPan}
          x={x}
          y={y}
          scale={scale}
        >
          <View
            style={{
              width: imageWidth,
              height: imageHeight,
              transform: [
                { translateX: correctionX },
                { translateY: correctionY },
              ],
            }}
          >
            <View
              style={[
                styles.layer,
                {
                  transform: [
                    { scale: scale },
                    { translateX: x },
                    { translateY: y },
                  ],
                },
              ]}
            >
              {!isEmpty(areaIdsForBookablePoints) && (
                <Points
                  ids={areaIdsForBookablePoints}
                  floorType={floorType}
                  showAvailability={showAvailability}
                  onLoading={updateLoadingLayer}
                />
              )}

              {!isEmpty(areaIdsForBookableAreas) && (
                <Areas
                  ids={areaIdsForBookableAreas}
                  showAvailability={showAvailability}
                  onLoading={updateLoadingLayer}
                />
              )}
              <Image source={{ uri: url ?? floor.plan }} style={styles.image} />
            </View>
          </View>
        </PanArea>
      </PinchArea>
    </View>
  );
}
