import { useCallback } from 'react';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import { useNavigation } from '@react-navigation/native';
import { isNil } from 'ramda';

import { useMyOrganization } from '@providers/Organization';
import { dateFormatShort } from '@utils/DateAndTime';
import { MAP_PRESENCE_STATUS_ICON } from '@views/shared/consts';

import { useFindMyColleague } from '@views/Colleagues/Profile/Overview/FindMyColleague/hooks';
import {
  badgeStatus,
  DEFAULT_ICON,
} from '@views/Colleagues/List/ColleagueListCard/hooks';

import {
  ColleagueLight,
  UserAvailabilityStatus,
} from '@views/Colleagues/List/interfaces';
import { Event } from '@views/shared/Events/interfaces';

interface Props {
  id: string;
  colleague: ColleagueLight;
  findMyColleagueEnabled: boolean;
}

interface TemplateProps {
  timeFormat: string;
  currentEvent?: Event;
  previousEvent?: Event;
  status: UserAvailabilityStatus;
  organizationWorkingHours?: OrganizationWorkingHours;
}

interface OrganizationWorkingHours {
  workingHoursStart: Date | string;
  workingHoursEnd: Date | string;
}

const busyPresenceLabel = {
  ABSENT: 'Colleagues.Content.Find.status.absent',
  DISALLOWED: 'Colleagues.error.no-data', // TODO: there is a specific message to show in this case?
};

const busyPresenceLabelTpl = {
  ABSENT: {},
  DISALLOWED: {},
};

const busyStatusLabel = {
  error: 'Colleagues.Content.Find.status.busy',
  success: 'Colleagues.Content.Find.status.free',
  //'success': "Colleagues.Content.Find.status.free.all",
  warning: 'Colleagues.error.no-data',
};

// TODO find a way to replays dayjs, current usage of dayjs is using the local time.
// The dayjs in utils/DateAndTime is using the utc time and shows here a wrong date
const getTplData = ({
  timeFormat,
  currentEvent,
  previousEvent,
  status,
  organizationWorkingHours: { workingHoursEnd },
}: TemplateProps) => {
  const formatDate = date => dayjs(date).format(timeFormat);
  const endOfWorkingDay = formatDate(
    `${dayjs().format(dateFormatShort)} ${workingHoursEnd}`,
  );

  if (!isNil(currentEvent) && status === 'OCCUPIED') {
    return {
      start: formatDate(currentEvent.start),
      end: formatDate(currentEvent.end),
    };
  }

  if (!isNil(currentEvent) && isNil(previousEvent)) {
    return {
      start: formatDate(currentEvent.end),
      end: endOfWorkingDay,
    };
  }

  return {
    start: isNil(previousEvent)
      ? formatDate(dayjs().startOf('hour'))
      : formatDate(previousEvent.end),
    end: isNil(currentEvent) ? endOfWorkingDay : formatDate(currentEvent.start),
  };
};

// TODO needs to be refactored to kill the middleware
export function useStatusBox({ id }: Props) {
  const intl = useIntl();
  const { formats } = intl;
  const { timeFormat } = formats as any;

  const { data, loading, error } = useFindMyColleague({ id });

  const { workingHoursStart = '', workingHoursEnd = '' } = useMyOrganization();
  const { presenceStatus = '', currentEvent, previousEvent, status = '' } =
    data?.colleagues[0] || {};

  const colleagueStatus = ['SICKDAY', 'VACATION'].includes(presenceStatus)
    ? 'OCCUPIED'
    : status;

  const icon =
    MAP_PRESENCE_STATUS_ICON[presenceStatus.toLowerCase()] || DEFAULT_ICON;
  const badgeColor = badgeStatus[colleagueStatus];

  const statusLabel =
    error || loading
      ? busyStatusLabel['DISALLOWED']
      : busyPresenceLabel[presenceStatus] || busyStatusLabel[badgeColor];

  const statusTmplValues =
    error || loading
      ? busyPresenceLabelTpl['DISALLOWED']
      : busyPresenceLabelTpl[presenceStatus] ||
        getTplData({
          timeFormat,
          currentEvent,
          status: colleagueStatus,
          previousEvent,
          organizationWorkingHours: {
            workingHoursStart,
            workingHoursEnd,
          },
        });

  const navigation = useNavigation<any>();
  const bookSameRoom = useCallback(() => {
    navigation.navigate('book', {
      screen: 'structure',
      params: {
        type: 'room',
        areaId: data?.findMyColleague.area.id,
      },
    });
  }, [navigation, data]);

  return {
    loading,
    bookSameRoom,
    icon,
    status: badgeColor,
    error,
    data,
    presenceStatus,
    statusLabel,
    statusTmplValues,
  };
}
