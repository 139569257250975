import { gql, useQuery } from '@apollo/client';

import { useCurrentDate } from '@views/Calendar/hooks';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import {
  DepartmentsApiResponse,
  OrganizationUserApiResponse,
  Item,
} from '@views/Colleagues/List/interfaces';

export const limitUsers = 5;
export const limitUsersDesktop = 6;

const getOrganizationDepartments = gql`
  query getOrganizationDepartments {
    organizationDepartments(excludeRequestUser: true) {
      id
      name
      count: countUsers
    }
  }
`;

export const getOrganizationUsersQuery = gql`
  query getOrganizationUsers(
    $userId: ID
    $favColleagues: Boolean
    $departmentId: ID
    $nameStartsWith: String
    $inOffice: Boolean
    $homeOffice: Boolean
    $travelDay: Boolean
    $limit: Int
    $offset: Int
    $date: Date!
  ) {
    organizationUsers(
      userId: $userId
      excludeRequestUser: true
      favColleagues: $favColleagues
      departmentId: $departmentId
      nameStartsWith: $nameStartsWith
      inOffice: $inOffice
      homeOffice: $homeOffice
      travelDay: $travelDay
      limit: $limit
      offset: $offset
      date: $date
    ) {
      users {
        id
        email
        name
        userprofile {
          avatar
          phoneNumber
          department {
            id
            name
          }
          visibleForColleagues
          visibleCalendar
          visibleHomeoffice
          licensePlate
        }
        availabilityStatus {
          presenceStatus
          presenceTypes
        }
      }
      count
    }
  }
`;

export function useOrganizationDepartments() {
  const { data, loading, error } = useQuery<DepartmentsApiResponse>(
    getOrganizationDepartments,
  );

  return {
    data: data?.organizationDepartments || [],
    loading,
    error,
  };
}

export function useOrganizationUsersByDepartment(item: Item, page: number) {
  const date = useCurrentDate();
  const isDesktop = useIsDesktop();
  const limit = isDesktop ? limitUsersDesktop : limitUsers;

  const { data, loading, error } = useQuery<OrganizationUserApiResponse>(
    getOrganizationUsersQuery,
    {
      variables: {
        departmentId: item.id === '0' ? null : item.id,
        limit: limit,
        offset: page * limit,
        date: universalDateFormatter({ date, format: dateFormatShort }),
      },
    },
  );

  return {
    data: data?.organizationUsers?.users || [],
    loading,
    error,
  };
}
