export const localeFormatDefault = {
  dateFormat: 'YYYY-MM-DD',
  dayFormat: 'dd, D.MMM',
  dayShortFormat: 'MM.D',
  dayFullFormat: 'dddd, MMM D',
  timeFormat: 'h:mm a',
  timeFormatShort: 'h a',
  dayWeekFormatShort: 'dd',
  dateOnlyFormat: 'DD',
  monthFormat: 'MMM',
};

export const localeFormats = {
  en: {
    dateFormat: 'YYYY-MM-DD',
    dayFormat: 'dddd, MMM.D',
    dayShortFormat: 'MM.D',
    dayFullFormat: 'dddd, MMM D',
    timeFormat: 'h:mm a',
    timeFormatShort: 'h a',
    dayWeekFormatShort: 'dd',
    dateOnlyFormat: 'DD',
    monthFormat: 'MMM',
  },
  de: {
    dateFormat: 'DD-MM-YYYY',
    dayFormat: 'dddd, DD.MMM',
    dayShortFormat: 'DD.MM',
    dayFullFormat: 'dddd, D. MMM',
    timeFormat: 'HH:mm',
    timeFormatShort: 'HH:mm',
    dayWeekFormatShort: 'dd',
    dateOnlyFormat: 'DD',
    monthFormat: 'MMM',
  },
  fr: {
    dateFormat: 'DD/MM/YYYY',
    dayFormat: 'dddd MMM DD',
    dayShortFormat: 'DD.MM',
    dayFullFormat: 'dddd MMMM D',
    timeFormat: 'HH:mm',
    timeFormatShort: 'HH:mm',
    dayWeekFormatShort: 'dd',
    dateOnlyFormat: 'DD',
    monthFormat: 'MMM',
  },
  it: {
    dateFormat: 'DD-MM-YYYY',
    dayFormat: 'dddd, DD.MMM',
    dayShortFormat: 'DD.MM',
    dayFullFormat: 'dddd, D. MMM',
    timeFormat: 'HH:mm',
    timeFormatShort: 'HH:mm',
    dayWeekFormatShort: 'dd',
    dateOnlyFormat: 'DD',
    monthFormat: 'MMM',
  },
  nl: {
    dateFormat: 'DD-MM-YYYY',
    dayFormat: 'dddd, DD.MMM',
    dayShortFormat: 'DD.MM',
    dayFullFormat: 'dddd, D. MMM',
    timeFormat: 'HH:mm',
    timeFormatShort: 'HH:mm',
    dayWeekFormatShort: 'dd',
    dateOnlyFormat: 'DD',
    monthFormat: 'MMM',
  },
  ru: {
    dateFormat: 'DD-MM-YYYY',
    dayFormat: 'dddd, DD.MMM',
    dayShortFormat: 'DD.MM',
    dayFullFormat: 'dddd, D. MMM',
    timeFormat: 'HH:mm',
    timeFormatShort: 'HH:mm',
    dayWeekFormatShort: 'dd',
    dateOnlyFormat: 'DD',
    monthFormat: 'MMM',
  },
};
