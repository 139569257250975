import React from 'react';
import SafeAreaView from 'react-native-safe-area-view';
import { Text, View, StyleSheet, ScrollView } from 'react-native';

import { useIntl } from '@utils/intl';
import { CustomIcon } from '@views/shared/CustomIcon';

import { globalStyles, fonts, spacings, colors } from '@views/shared/styles';

import AttendanceWeek from './AttendanceWeek';
import { useWeeklyDates } from './hooks';

const { smallMedium, verySmall } = spacings;
const { black, grey1, white } = colors;

export const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: fonts.sizeTitle,
    fontFamily: 'mrt-semi-bold',
  },
  header: {
    flexDirection: 'row',
    paddingVertical: smallMedium,
    paddingHorizontal: verySmall,
    justifyContent: 'space-between',
  },
});

export default function MyAttendances() {
  const { t } = useIntl();

  const {
    weekDays,
    disableNav,
    moveToNextWeek,
    moveToPreviousWeek,
  } = useWeeklyDates();

  return (
    <>
      <SafeAreaView style={{ backgroundColor: white }}>
        <View style={styles.header}>
          <CustomIcon name="arrow-left" onPress={moveToPreviousWeek} />
          <Text style={styles.title}>{t('Profile.Attendances.Title')}</Text>
          <CustomIcon
            name="arrow-right"
            color={disableNav ? grey1 : black}
            onPress={disableNav ? null : moveToNextWeek}
          />
        </View>
      </SafeAreaView>
      <ScrollView style={[globalStyles.container, { paddingTop: 0 }]}>
        <AttendanceWeek currentWeekDays={weekDays} />
      </ScrollView>
    </>
  );
}
