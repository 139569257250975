import React from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { isNil, isEmpty } from 'ramda';

import {
  universalDateFormatter,
  dateFormatShort,
  transformOr,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';

import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { Loader } from '@views/shared/Loader';
import { MainButton } from '@views/shared/Button';
import { getTotalBreak, getTotalAttendance } from '@views/shared/utils';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { globalStyles, spacings } from '@views/shared/styles';
import { styles as aStyles } from '@views/Home/ActiveBookings/styles';

import { AttendaceType } from 'views/shared/interfaces/booking';

import DailyAttendance from './DailyAttendance';
import PopUp from './PopUp';
import { useWeeklyAttendance } from './hooks';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: spacings.small,
  },
  row: {
    flexDirection: 'row',
  },
  buttonContainer: {
    alignItems: 'flex-end',
  },
});

export default function AttendanceWeek({ currentWeekDays }) {
  const { t } = useIntl();
  const isHistoricView = !isNil(currentWeekDays);
  const isDesktop = useIsDesktop();

  const {
    weekDays,
    userAttendance,
    userAttendanceBreaks,
    mappedUserAttendances,
    loading,
    error,
    isOpen,
    attendanceItem,
    mode,
    onEdit,
    refetch,
    gotoWeeklyAttendance,
  } = useWeeklyAttendance(currentWeekDays);

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <View style={{ paddingBottom: 40 }}>
      {isHistoricView ? null : (
        <Text style={globalStyles.cardTitle}>
          {t('Home.Attendance.HistoricView.Title')}
        </Text>
      )}
      <View style={styles.container}>
        <View style={styles.row}>
          <Text style={globalStyles.title}>
            {t('Home.Attendance.HistoricView.Total')}:{' '}
          </Text>

          {userAttendance.length ? (
            <AttendanceTime attendance={userAttendance} />
          ) : (
            <Text style={globalStyles.title}>{`0 ${t(
              'Home.Attendance.Layer.Minutes.Short',
            )}`}</Text>
          )}
        </View>
        <View style={styles.row}>
          <Text style={globalStyles.title}>
            {t('Home.Attendance.HistoricView.Break')}:{' '}
          </Text>
          {userAttendanceBreaks.length ? (
            <AttendanceTime attendance={userAttendanceBreaks} type="break" />
          ) : (
            <Text style={globalStyles.title}>{`0 ${t(
              'Home.Attendance.Layer.Minutes.Short',
            )}`}</Text>
          )}
        </View>
      </View>
      <View style={isDesktop && { flexDirection: 'row' }}>
        {weekDays.map((date, idx) => {
          const key = universalDateFormatter({ date, format: dateFormatShort });
          const attendance = mappedUserAttendances[key] ?? [];
          const sortedList = attendance
            .filter(item => item.totalTimeMinutes > 0)
            .sort((a, b) =>
              transformOr(a.startTime).isBefore(transformOr(b.startTime))
                ? -1
                : 1,
            );

          return (
            <DailyAttendance
              date={date}
              key={key}
              attendance={sortedList}
              last={idx === weekDays.length - 1}
              isHistoricView={isHistoricView}
              onEdit={onEdit}
              numberOfDays={weekDays.length}
            />
          );
        })}
      </View>
      {isOpen ? (
        <PopUp
          item={attendanceItem}
          mode={mode}
          onEdit={onEdit}
          onComplete={refetch}
        />
      ) : null}
      {isHistoricView ? null : (
        <View style={[aStyles.buttons, styles.buttonContainer]}>
          <MainButton
            onPress={gotoWeeklyAttendance}
            buttonStyle={[aStyles.button, aStyles.greenButton, styles.button]}
            titleStyle={aStyles.greenButtonTitle}
            title={t('Home.Attendance.GotoDetails')}
          />
        </View>
      )}
    </View>
  );
}

function AttendanceTime({
  attendance,
  type,
}: {
  attendance: AttendaceType;
  type?: string;
}) {
  const { t } = useIntl() as any;

  if (isEmpty(attendance)) {
    return null;
  }

  const { hours, minutes } =
    type === 'break'
      ? getTotalBreak(attendance)
      : getTotalAttendance(attendance);

  if (minutes < 1 && hours < 1) {
    return null;
  }

  const trsHours =
    hours > 0
      ? `${hours} ${t(
          hours === 1
            ? 'Home.Attendance.Layer.Hour.Short'
            : 'Home.Attendance.Layer.Hours.Short',
        )}`
      : '';

  const trsMinutes =
    minutes > 0
      ? `${hours > 0 ? ' ' : ''}${minutes} ${t(
          'Home.Attendance.Layer.Minutes.Short',
        )}`
      : null;

  return (
    <Text style={globalStyles.title}>
      {trsHours}
      {trsMinutes}
    </Text>
  );
}
