import { useEffect, useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useRoute } from '@react-navigation/native';
import { compose, pathOr } from 'ramda';

import { useIntl } from '@utils/intl';
import { useErrorQuery } from '@providers/Errors';
import { useGoTo } from '@views/shared/utils/useGoTo';
import { useCurrentDate } from '@views/Calendar/hooks';
import { MAX_COUNT_DESKS } from '@views/shared/consts';
import {
  dateFormatShort,
  universalDateFormatter,
  getBookableWorkingTime,
} from '@utils/DateAndTime';
import {
  filterDesksByFilterSettings,
  getAvailableDesks,
  sortDesksByTypeAndLabel,
} from '@views/shared/Booking';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useFilter } from '@views/Book/Filter/hooks';
import { BOOK_CATEGORY_ROOM } from '@views/shared/consts';
import { useMyOrganization } from '@providers/Organization';

import {
  DeskStructure,
  AreaStructureForDesks,
} from '@views/shared/interfaces/buildingStructure';

import { Data } from './interfaces';

const getDesksHourlyQuery = gql`
  query getDesks(
    $areaId: ID!
    $date: DateTime
    $start: DateTime
    $end: DateTime
  ) {
    areasDesks(areaIds: [$areaId], date: $date, start: $start, end: $end) {
      areaId
      desks {
        blocked
        id
        isForbidden
        isVipForbidden
        isFree
        isOccupied
        label
        numberOnFloor
        equipment {
          category {
            id
            name
          }
          id
          name
        }
        freeShare
        quarterState
      }
    }
  }
`;

const getDesksQuery = gql`
  query getDesks($areaId: ID!, $dateShort: Date!) {
    areasDesks: areasAvailableDesks(areaIds: [$areaId], date: $dateShort) {
      desks {
        id
        label
        numberOnFloor
        equipment {
          category {
            id
            name
          }
          id
          name
        }
        freeShare
      }
    }
  }
`;

export function useDesks(area: AreaStructureForDesks) {
  const { t } = useIntl();
  const route = useRoute<any>();
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();

  const filterData = useFilter();
  const {
    equipmentIds,
    timeRange: { start: startFilter, end: endFilter },
    isFilterUsed,
  } = filterData[BOOK_CATEGORY_ROOM];

  const [showAll, setShowAll] = useState(false);
  const [desks, setDesks] = useState<DeskStructure[]>([]);

  const { id: areaId } = area;

  const date = useCurrentDate();
  const { start, end } = getBookableWorkingTime(
    date,
    startFilter || workingHoursStart,
    endFilter || workingHoursEnd,
  );

  const enabledHourlyBooking = useHasFeature('hourly_booking');

  const queryDesks = enabledHourlyBooking ? getDesksHourlyQuery : getDesksQuery;
  const { data, loading, error } = useErrorQuery<Data>(queryDesks, {
    variables: {
      areaId,
      date: universalDateFormatter({ date }),
      dateShort: universalDateFormatter({ date, format: dateFormatShort }),
      start: universalDateFormatter({ date: start }),
      end: universalDateFormatter({ date: end }),
    },
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: t('Home.Structure.Floor.Area.Desks.error'),
    },
  });

  // In case of hourly booking we need to check if there is any condition that
  // made the desk not available anyway (e.g. occupied, blocked, etc. ).
  // For the daily booking, the check is done by backend
  const getDesks = useCallback(
    data => (enabledHourlyBooking ? getAvailableDesks(data) : data),
    [enabledHourlyBooking],
  );

  useEffect(() => {
    if (data?.areasDesks) {
      const desks = compose(
        sortDesksByTypeAndLabel,
        getDesks,
        pathOr([], ['areasDesks', 0, 'desks']),
      )(data);

      setDesks(filterDesksByFilterSettings(desks, equipmentIds, startFilter));
    }
  }, [data, getDesks, equipmentIds, startFilter]);

  const { goToBookingPage } = useGoTo();
  const book = useCallback(
    (deskId: string) => {
      goToBookingPage({ areaId, deskId });
    },
    [areaId, goToBookingPage],
  );
  const toggleMore = useCallback(() => setShowAll(showAll => !showAll), [
    setShowAll,
  ]);

  return {
    book,
    desks: showAll ? desks : desks.slice(0, MAX_COUNT_DESKS),
    error,
    isFilterUsed,
    isMore: desks.length > MAX_COUNT_DESKS,
    open: isFilterUsed || route.params?.areaId === areaId,
    loading,
    showAll,
    toggleMore,
  };
}
