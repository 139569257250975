import React from 'react';
import { View, StyleSheet } from 'react-native';
import { Divider } from 'react-native-elements';

import { CustomIcon } from '@views/shared/CustomIcon';
import { colors, spacings } from '@views/shared/styles';

interface Props {
  onMinus: () => void;
  onPlus: () => void;
}

const { small, medium } = spacings;
const { blackAlpha, aquaMarineLight } = colors;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    bottom: 90,
    right: medium,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    zIndex: 1,
    backgroundColor: aquaMarineLight,
    borderRadius: medium,
    paddingHorizontal: medium,
  },
  button: {
    fontSize: 25,
    paddingVertical: small,
  },
});

export default function Zoommer({ onMinus, onPlus }: Props) {
  return (
    <View style={styles.container}>
      <CustomIcon style={styles.button} name="zoom-in" onPress={onPlus} />
      <Divider style={{ backgroundColor: blackAlpha, width: '100%' }} />
      <CustomIcon style={styles.button} name="zoom-out" onPress={onMinus} />
    </View>
  );
}
