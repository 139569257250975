import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { Avatar } from 'react-native-elements';

import {
  colors,
  spacings,
  avatarWithBadgeStyle,
  fonts,
  globalStyles,
} from '@views/shared/styles';

import { ColleagueLight } from '@views/Colleagues/Colleague/interfaces';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Contacts } from '@views/Colleagues/Profile/Overview/Contacts';
import { FavouriteBadge } from '@views/Favourites/FavouriteBadge';
import { getInitials } from '@views/Colleagues/List/ColleagueListCard/hooks';

const { sizeDefault, sizeMedium } = fonts;
const { extraSmall, huge, medium, tiny, smallMedium } = spacings;

export const styles = StyleSheet.create({
  container: {
    borderColor: colors.blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    marginBottom: huge,
  },
  card: {
    flexDirection: 'row',
    flex: 1,
    padding: smallMedium,
  },
  userDataContainer: {
    flex: 1,
    marginLeft: medium,
  },
  top: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeMedium,
  },
  department: {
    fontFamily: 'pts-regular',
    fontSize: sizeDefault,
    paddingTop: tiny,
  },
  info: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: extraSmall,
  },
  longtext: {
    // just a horrible fix to wrap the text
    flexGrow: 1,
    width: 0,
  },
  text: {
    fontFamily: 'pts-regular',
    fontSize: sizeDefault,
  },
  icon: {
    width: 30,
    textAlign: 'center',
    paddingRight: 8,
  },
  FavouriteBadge: {
    marginTop: -2,
    marginRight: -5,
    marginLeft: 5,
  },
});

interface Props {
  id: string;
  colleague: ColleagueLight;
}

export function PersonalInfoBox({ id, colleague }: Props) {
  const {
    name,
    email,
    userprofile: { avatar, phoneNumber, licensePlate, department },
    availabilityStatus: { presenceStatus },
  } = colleague;

  const initials = getInitials(name);

  return (
    <View style={styles.container}>
      <View>
        <View style={styles.card} key={id}>
          <Avatar
            rounded
            size={60}
            source={
              avatar && avatar.trim().length ? { uri: avatar } : undefined
            }
            title={initials}
            titleStyle={[avatarWithBadgeStyle.title]}
            placeholderStyle={[avatarWithBadgeStyle.placeholder]}
            overlayContainerStyle={[
              avatarWithBadgeStyle.avatarOverlayContainer,
            ]}
            containerStyle={[
              avatarWithBadgeStyle[`${presenceStatus}AvatarContainer`],
            ]}
          />
          <View style={styles.userDataContainer}>
            <View style={styles.top}>
              <View style={styles.longtext}>
                <Text style={globalStyles.title}>{name}</Text>
                <Text style={styles.department}>{department?.name}</Text>
              </View>
              <View style={styles.FavouriteBadge}>
                <FavouriteBadge type="colleague" id={colleague.id} />
              </View>
            </View>
            <View>
              <View style={styles.info}>
                <CustomIcon name="mail" size={20} style={styles.icon} />
                <View style={styles.longtext}>
                  <Text style={styles.text}>{email}</Text>
                </View>
              </View>
              <View style={styles.info}>
                <CustomIcon name="phone" size={20} style={styles.icon} />
                {phoneNumber.length > 0 ? (
                  <Text style={styles.text}>{phoneNumber}</Text>
                ) : (
                  <Text style={styles.text}>n/a</Text>
                )}
              </View>
              {licensePlate && licensePlate.length > 0 ? (
                <View style={styles.info}>
                  <CustomIcon name="car" size={20} style={styles.icon} />
                  <Text style={styles.text}>{licensePlate}</Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>
      <Contacts id={id} colleague={colleague} />
    </View>
  );
}
