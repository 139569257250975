import React from 'react';
import { StyleSheet } from 'react-native';
import { Button, ButtonProps } from 'react-native-elements';

import { colors, fonts, spacings } from '@views/shared/styles';
const { aquaMarine, blackAlpha2, white } = colors;
const { smallMedium, tiny, extraSmall } = spacings;

export const styles = StyleSheet.create({
  btnStyle: {
    borderColor: aquaMarine,
    backgroundColor: aquaMarine,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: tiny,
    marginTop: 0,
  },
  titleStyle: {
    color: white,
    fontSize: fonts.sizeSmall,
    fontFamily: 'mrt-semi-bold',
    paddingHorizontal: smallMedium,
  },
  disabled: {
    backgroundColor: 'transparent',
    color: blackAlpha2,
    borderColor: blackAlpha2,
  },
});

export function MainButton({ buttonStyle, titleStyle, ...props }: ButtonProps) {
  const { disabled } = props;

  return (
    <Button
      buttonStyle={[styles.btnStyle, buttonStyle, disabled && styles.disabled]}
      titleStyle={[styles.titleStyle, titleStyle]}
      disabledStyle={[styles.disabled]}
      disabledTitleStyle={[styles.disabled]}
      type="outline"
      {...props}
    />
  );
}
