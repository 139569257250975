import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

import { useIntl } from '@utils/intl';

import { Loader } from '@views/shared/Loader';

import { globalStyles, spacings, colors } from '@views/shared/styles';

const styles = StyleSheet.create({
  loading: {
    position: 'absolute',
    zIndex: 1,
    flexDirection: 'row',
    backgroundColor: colors.white,
    width: '100%',
    padding: spacings.smallMedium,
    justifyContent: 'center',
  },
  animation: {
    height: 20,
    width: 20,
  },
  loader: {
    height: 'auto',
    paddingLeft: spacings.tiny,
  },
});

export default function DataLoader() {
  const { t } = useIntl();
  return (
    <View style={styles.loading}>
      <Text style={globalStyles.title}>{t('Floorplan.ZoomImage.Loading')}</Text>
      <Loader
        containerStyle={styles.loader}
        animationStyle={styles.animation}
        iconSize={20}
      />
    </View>
  );
}
