import React from 'react';
import { View, Text } from 'react-native';

import { useIntl } from '@utils/intl';
import { globalStyles, globalStylesDesktop } from '@views/shared/styles';
import { Bookings } from '@views/Book/Bookings';

export default function BookForDesktop() {
  const { t } = useIntl();
  return (
    <View style={globalStyles.cardContainer}>
      <Text style={globalStylesDesktop.cardTitle}>
        {t('Home.BookForDesktop.Title')}
      </Text>
      <Bookings />
    </View>
  );
}
