import { useCallback, useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { range } from 'ramda';

import { useMyOrganization } from '@providers/Organization';

import {
  setTimeToDate,
  universalDateFormatter,
  isBefore,
  getNumberFromHours,
} from '@utils/DateAndTime';

const createUserAttendance = gql`
  mutation createUserAttendance(
    $startTime: DateTime!
    $endTime: DateTime!
    $comment: String
    $clearDay: Boolean
    $startBreakTime: DateTime!
    $endBreakTime: DateTime!
  ) {
    createUserAttendance(
      newAttendance: {
        startTime: $startTime
        endTime: $endTime
        comment: $comment
        attendanceBreaks: [
          { startTime: $startBreakTime, endTime: $endBreakTime }
        ]
      }
      clearDay: $clearDay
    ) {
      id
      status
      startTime
      endTime
      date
      totalWorkMinutes
      comment
    }
  }
`;

const f = date =>
  universalDateFormatter({
    date,
    format: 'YYYY-MM-DDTHH:mm:ssZ',
  });

export function useUpdateAttendance(props) {
  const {
    item: { date },
    mode,
    onEdit,
    onComplete,
  } = props;

  const {
    workingHoursStart,
    workingHoursEnd,
    midDayHour,
  } = useMyOrganization();

  const startHours = getNumberFromHours(workingHoursStart);
  const endHours = getNumberFromHours(workingHoursEnd);
  const midDayHours = getNumberFromHours(midDayHour);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [startTime, setStartTime] = useState({
    hours: startHours,
    minutes: 0,
  });
  const [endTime, setEndTime] = useState({
    hours: endHours,
    minutes: 0,
  });
  const [breakStartTime, setBreakStartTime] = useState({
    hours: midDayHours,
    minutes: 0,
  });
  const [breakEndTime, setBreakEndTime] = useState({
    hours: midDayHours + 1,
    minutes: 0,
  });
  const [comment, setComment] = useState<string>('');
  const [invalidTime, setInvalidTime] = useState(false);

  useEffect(() => {
    if (!isBefore(selectedStartTime, selectedEndTime)) {
      setInvalidTime(true);
    } else {
      setInvalidTime(false);
    }
  }, [startTime, endTime]);

  const workingHours = [...range(startHours, endHours), endHours];

  const updateComment = useCallback(value => setComment(value), [setComment]);

  const onStartTimeChange = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      setStartTime({ hours: parseInt(hours), minutes: parseInt(minutes) });
    },
    [setStartTime],
  );

  const onEndTimeChange = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      setEndTime({ hours: parseInt(hours), minutes: parseInt(minutes) });
    },
    [setEndTime],
  );

  const onStartBreakTimeChange = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      setBreakStartTime({ hours: parseInt(hours), minutes: parseInt(minutes) });
    },
    [setBreakStartTime],
  );

  const onEndBreakTimeChange = useCallback(
    ({ hours, minutes }: { hours: number; minutes: number }) => {
      setBreakEndTime({ hours: parseInt(hours), minutes: parseInt(minutes) });
    },
    [setBreakEndTime],
  );

  const selectedStartTime = setTimeToDate(startTime, date);
  const selectedEndTime = setTimeToDate(endTime, date);
  const selectedBreakStartTime = setTimeToDate(breakStartTime, date);
  const selectedBreakEndTime = setTimeToDate(breakEndTime, date);

  const [updateAttendance] = useMutation(createUserAttendance);

  const onUpdate = useCallback(async () => {
    setIsLoading(true);
    await updateAttendance({
      variables: {
        startTime: f(selectedStartTime),
        endTime: f(selectedEndTime),
        comment,
        clearDay: mode !== 'add',
        startBreakTime: f(selectedBreakStartTime),
        endBreakTime: f(selectedBreakEndTime),
      },
    })
      .then(() => {
        setIsLoading(false);
        setTimeout(() => onComplete(), 2000);
        onEdit();
      })
      .catch(e => {
        setIsLoading(false);
        setIsError(true);
        console.info(e);
      });
  }, [
    updateAttendance,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    comment,
    onComplete,
  ]);

  return {
    isLoading,
    isError,
    workingHours,
    onUpdate,
    startTime,
    endTime,
    onStartTimeChange,
    onEndTimeChange,
    invalidTime,
    updateComment,
    breakStartTime,
    breakEndTime,
    onStartBreakTimeChange,
    onEndBreakTimeChange,
  };
}
