import React from 'react';
import { ScrollView } from 'react-native';

import { globalStyles } from '@views/shared/styles';
import { ColleagueLight } from '@views/Colleagues/Colleague/interfaces';
import { StatusBox } from '@views/Colleagues/Profile/Overview/StatusBox';
import { PersonalInfoBox } from '@views/Colleagues/Profile/Overview/PersonalInfoBox';
import CurrentBookings from '@views/Colleagues/Profile/Overview/CurrentBookings';

interface Props {
  id: string;
  colleague: ColleagueLight;
  findMyColleagueEnabled: boolean;
}

export function Overview({ id, colleague, findMyColleagueEnabled }: Props) {
  return (
    <ScrollView style={globalStyles.container}>
      <PersonalInfoBox id={id} colleague={colleague} />
      {findMyColleagueEnabled ? (
        <>
          <StatusBox
            id={id}
            colleague={colleague}
            findMyColleagueEnabled={findMyColleagueEnabled}
          />
          <CurrentBookings userId={id} />
        </>
      ) : null}
    </ScrollView>
  );
}
