import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Image } from 'react-native';

import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Redirect } from '@views/Auth0Redirect';
import { colors } from '@views/shared/styles';
import { MainButton } from '@views/shared/Button';
import { Redirect } from '@views/Redirect';
import { useIntl } from '@utils/intl';

export { useLoggedIn, useLogout } from './hooks.web';
export { tokenKey, accessTokenAtom, loggedInAtom } from './common';

const styles = StyleSheet.create({
  container: {
    padding: '4%',
    backgroundColor: colors.aquaMarineLight,
    width: '100%',
    height: '100%',
  },
  card: {
    bottom: '20%',
  },
  button: {
    width: '30%',
    alignSelf: 'center',
    paddingVertical: '3%',
  },
  image: {
    width: '100%',
    height: '100%',
  },
});

// eslint-disable-next-line @typescript-eslint/no-var-requires
const splashCoverImage = require('@assets/images/LIZ-splash.png');

export function Login() {
  const { t } = useIntl();
  const [loading, setLoading] = useState(true);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    let isMounted = true; // note this flag denote mount status
    setTimeout(() => {
      if (isMounted) setLoading(false);
    }, 2000);

    return () => {
      isMounted = false;
    }; // use effect cleanup to set flag false, if unmounted
  }, [setLoading]);

  if (loading) {
    return <Redirect />;
  }

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={splashCoverImage}
        resizeMode="contain"
      />
      <View style={[styles.card]}>
        <MainButton
          buttonStyle={styles.button}
          title={t('Routes.tabs.login')}
          onPress={() => loginWithRedirect({ prompt: 'login' })}
        />
      </View>
      <Auth0Redirect />
    </View>
  );
}
