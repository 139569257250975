import React from 'react';
import { View, StyleSheet } from 'react-native';

import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { StackActions } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { useIntl } from '@utils/intl';
import Home from '@views/Home';
import Bookings from '@views/Bookings';
import FloorPlan from '@views/Floorplan';
import { Colleagues } from '@views/Colleagues';
import Book from '@views/Book';
import { Login, useLoggedIn } from '@views/Login';
import { colors, fonts, spacings } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Examples } from '@views/Examples';
import { isLocal } from '@app/client/common';
import { useMyUser } from '@providers/User';
import Actions from '@views/Home/Profile/Actions';
import { WithDateSwitcher } from '@views/shared/DateSwitcher';
import { withLizTitle } from '@views/shared/utils';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import NoAccess from '@views/shared/NoAccess';

const Tab = createBottomTabNavigator();

const { aquaMarine, grey1, grey2, grey8, white } = colors;

export const styles = StyleSheet.create({
  book: {
    backgroundColor: aquaMarine,
    width: 45,
    height: 45,
    borderRadius: 999,
    top: -12,
    marginBottom: spacings.small,
    alignItems: 'center',
    justifyContent: 'center',
  },
  bookText: {
    color: white,
    fontSize: 40,
  },
});

export const getNaviIcon = ({
  color,
  focused,
  name,
  size = 28,
}: {
  color: string;
  focused: boolean;
  name: any;
  size: number;
}) => {
  const icon = `navi-${name}${focused ? '-inv' : ''}`;
  return <CustomIcon color={color} name={icon} size={size} />;
};

export const resetStackOnTabPress = ({ navigation, route }) => ({
  tabPress: () =>
    route?.state?.key &&
    navigation.dispatch({
      ...StackActions.popToTop(),
      target: route?.state.key,
    }),
});

export function Routes() {
  const { t } = useIntl();
  const loggedIn = useLoggedIn();
  const {
    showActionsPage,
    permissions: { canSeeColleagues, canUseBooker },
    loaded,
  } = useMyUser();
  const insets = useSafeAreaInsets();

  const showColleaguesScreen = !useHasFeature('disable_colleagues');

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: aquaMarine,
        inactiveTintColor: grey2,
        labelPosition: 'below-icon',
        style: {
          height: 65 + insets.bottom,
          borderTopColor: grey1,
          fontFamily: 'mrt-regular',
          backgroundColor: grey8,
        },
        labelStyle: {
          position: 'relative',
          top: -10,
          fontSize: fonts.sizeSmall,
        },
      }}
      swipeEnabled={false}
    >
      {loggedIn && loaded ? (
        !canUseBooker ? (
          <Tab.Screen
            name="home"
            options={{
              tabBarVisible: false,
              title: t('Routes.tabs.profile'),
            }}
            component={NoAccess}
          />
        ) : showActionsPage ? (
          <Tab.Screen
            name="actions"
            component={Actions}
            options={{
              tabBarVisible: false,
              title: t('Routes.tabs.profile'),
            }}
          />
        ) : (
          <>
            <Tab.Screen
              name="home"
              component={Home}
              options={({ route: { name } }) => ({
                tabBarIcon: props => getNaviIcon({ ...props, name }),
                title: t('Routes.tabs.home'),
              })}
              listeners={({ navigation }) => ({
                tabPress: e => {
                  e.preventDefault();
                  navigation.navigate('home', { screen: 'index' });
                },
              })}
            />

            <Tab.Screen
              name="floorplan"
              options={({ route: { name } }) => ({
                tabBarIcon: props => getNaviIcon({ ...props, name }),
                tabBarLabel: t('Routes.tabs.floorplan'),
                title: withLizTitle(t('Routes.tabs.floorplan')),
              })}
              listeners={resetStackOnTabPress}
            >
              {() => (
                <WithDateSwitcher>
                  <FloorPlan />
                </WithDateSwitcher>
              )}
            </Tab.Screen>
            <Tab.Screen
              name="book"
              component={Book}
              options={() => ({
                tabBarIcon: () => (
                  <View style={styles.book}>
                    <CustomIcon color={white} name="plus" size={28} />
                  </View>
                ),
                tabBarLabel: t('Routes.tabs.book'),
                title: withLizTitle(t('Routes.tabs.book')),
              })}
              listeners={({ navigation }) => ({
                tabPress: e => {
                  e.preventDefault();
                  navigation.navigate('book', { screen: 'index' });
                },
              })}
            />
            <Tab.Screen
              name="bookings"
              component={Bookings}
              options={({ route: { name } }) => ({
                tabBarIcon: props => getNaviIcon({ ...props, name }),
                tabBarLabel: t('Routes.tabs.bookings'),
                title: withLizTitle(t('Routes.tabs.bookings')),
              })}
              listeners={resetStackOnTabPress}
            />
            <Tab.Screen
              name="colleagues"
              options={({ route: { name } }) => ({
                tabBarIcon: props => getNaviIcon({ ...props, name }),
                tabBarLabel: t('Routes.tabs.colleagues'),
                title: withLizTitle(t('Routes.tabs.colleagues')),
              })}
              listeners={({ navigation }) => ({
                tabPress: e => {
                  e.preventDefault();
                  navigation.navigate('colleagues', { screen: 'list' });
                },
              })}
            >
              {() =>
                showColleaguesScreen && canSeeColleagues ? (
                  <WithDateSwitcher>
                    <Colleagues />
                  </WithDateSwitcher>
                ) : (
                  <NoAccess />
                )
              }
            </Tab.Screen>
            {isLocal() && (
              <Tab.Screen
                name="examples"
                component={Examples}
                options={() => ({
                  tabBarIcon: () => <CustomIcon name="code" size={28} />,
                  title: 'Style Guide',
                })}
              />
            )}
          </>
        )
      ) : (
        <Tab.Screen
          name="login"
          component={Login}
          options={{
            tabBarVisible: false,
            title: withLizTitle(t('Routes.tabs.login')),
          }}
        />
      )}
    </Tab.Navigator>
  );
}
