import React, { useRef, useEffect } from 'react';
import {
  StyleSheet,
  Animated,
  Easing,
  ViewStyle,
  View,
  TextStyle,
} from 'react-native';

import { CustomIcon } from '@views/shared/CustomIcon';
import { colors, spacings } from '@views/shared/styles';

const styles = StyleSheet.create({
  animation: {
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    width: 50,
  },
  container: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingVertical: spacings.tiny,
  },
});

interface Props {
  animationStyle?: ViewStyle;
  containerStyle?: ViewStyle;
  iconSize?: number;
  iconStyle?: TextStyle;
}

export function Loader({
  animationStyle,
  containerStyle,
  iconSize = 45,
  iconStyle,
}: Props) {
  const rotateAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.loop(
      Animated.timing(rotateAnim, {
        toValue: 1,
        duration: 1000,
        useNativeDriver: false,
        easing: Easing.linear,
      }),
      {
        iterations: -1,
      },
    ).start();
  }, [rotateAnim]);

  const spin = rotateAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <View style={[styles.container, containerStyle]}>
      <Animated.View
        style={[
          styles.animation,
          animationStyle,
          {
            transform: [
              {
                rotate: spin,
              },
            ],
          },
        ]}
      >
        <CustomIcon
          color={colors.aquaMarine}
          name="spinner"
          size={iconSize}
          style={iconStyle}
        />
      </Animated.View>
    </View>
  );
}
