import { gql, useMutation } from '@apollo/client';

import {
  BookingWithArea,
  BookingHooksBasicProps,
  CreateBookingAreaResponse,
} from '@views/shared/interfaces/booking';
import { useBookForTelefonBox } from '@views/Book/Book/BasicBook/Booking/hooks';

const createBookingMutation = gql`
  mutation bookTelefonBox(
    $areaId: ID!
    $start: DateTime!
    $end: DateTime!
    $comment: String
  ) {
    createBooking(
      booking: { areaId: $areaId, start: $start, end: $end, comment: $comment }
    ) {
      id
      start
      end
      area {
        areaType
        name
        floor {
          floorLabel
          floorNumber
          floorType
          building {
            name
            address
          }
        }
      }
      comment
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  params: { areaId },
  t,
  type,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingAreaResponse },
    BookingWithArea
  >(createBookingMutation, {});

  return useBookForTelefonBox({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params: {
      areaId,
    },
    formats,
    bookingMutation: mutation,
  });
}
