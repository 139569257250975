import { gql, useMutation } from '@apollo/client';

import { useBookForSickDay } from '@views/Book/Book/BasicBook/Booking/hooks';

import {
  BasicBooking,
  BookingHooksBasicProps,
  CreateBookingResponse,
} from '@views/shared/interfaces/booking';

export const bookSickDayMutation = gql`
  mutation bookSickDay($start: DateTime!, $end: DateTime!, $comment: String) {
    createBooking: createSickDayBooking(
      booking: { start: $start, end: $end, comment: $comment }
    ) {
      sickDay
      start
      end
      comment
    }
  }
`;

export function useBook({
  date,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  hasNextDaysBooking,
  formats,
  t,
  type,
  params,
}: BookingHooksBasicProps) {
  const mutation = useMutation<
    { createBooking: CreateBookingResponse },
    BasicBooking
  >(bookSickDayMutation);

  return useBookForSickDay({
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    t,
    type,
    params,
    formats,
    bookingMutation: mutation,
  });
}
