import React, { useState, useCallback } from 'react';
import {
  ScrollView,
  Text,
  View,
  TouchableHighlight,
  StyleSheet,
} from 'react-native';

import { useIntl, Translator } from '@utils/intl';
import { Accordion } from '@views/shared/Accordion';
import { ColleagueListCard } from '@views/Colleagues/List/ColleagueListCard';
import { CustomIcon } from '@views/shared/CustomIcon';
import { Loader } from '@views/shared/Loader';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import {
  colors,
  spacings,
  fonts,
  globalStyles,
  globalStylesDesktop,
} from '@views/shared/styles';

import { ListItemProps, ListRenderProps } from '@views/Colleagues/interfaces';

import {
  useOrganizationDepartments,
  useOrganizationUsersByDepartment,
  limitUsers,
  limitUsersDesktop,
} from './hooks';

const { grey9, white } = colors;
const { small, verySmall, smallMedium, extraSmall, medium, smaller } = spacings;

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: verySmall,
    paddingBottom: verySmall,
    paddingTop: small,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  showMore: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 0,
    marginTop: -smallMedium,
    padding: smallMedium,
  },
  row: {
    flexDirection: 'row',
    padding: smallMedium,
    borderRadius: extraSmall,
    backgroundColor: white,
    alignItems: 'center',
  },
  showMoreText: {
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeSemiSmall,
    paddingRight: 3,
  },
  showMoreDisabled: {
    color: grey9,
  },
  showMoreIcon: {
    alignSelf: 'center',
    top: 1,
    paddingLeft: smaller,
  },
  error: {
    paddingBottom: smallMedium,
  },
});

export function DepartmentsView() {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();
  const { data, loading, error } = useOrganizationDepartments();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  return (
    <>
      {loading ? <Loader /> : null}
      <ScrollView
        style={[globalStyles.container, isDesktop && { paddingTop: 40 }]}
      >
        {data
          .filter(({ count }) => count)
          .map((item, index) => (
            <ListItem
              item={item}
              key={index}
              title={item.name || t('Colleagues.List.no_department')}
              ItemRenderer={ColleaguesListRender}
            />
          ))}
      </ScrollView>
    </>
  );
}

export function ListItem({ item, title, ItemRenderer }: ListItemProps) {
  const { t } = useIntl();
  const { count, id } = item;
  const [page, setPage] = useState(1);
  const isDesktop = useIsDesktop();

  const toggleMore = useCallback(() => setPage(prevState => prevState + 1), [
    setPage,
  ]);
  const limit = isDesktop ? limitUsersDesktop : limitUsers;

  const titleElm = (
    <View style={[{ marginRight: 'auto' }]}>
      <Text style={globalStyles.title}>{title}</Text>

      <Text style={globalStyles.description}>
        {count} {t('Routes.tabs.colleagues')}
      </Text>
    </View>
  );

  return (
    <View style={[globalStyles.shadow, { marginBottom: medium }]}>
      <Accordion title={titleElm} headerStyle={styles.row}>
        {[...Array(page)].map((_, i) => (
          <ItemRenderer key={`${id}-${i}`} item={item} page={i} />
        ))}

        {count > limit ? (
          <ShowMore
            onPress={toggleMore}
            isDisabled={page * limit >= count}
            t={t}
            isDesktop={isDesktop}
          />
        ) : null}
      </Accordion>
    </View>
  );
}

export function ShowMore({
  onPress,
  isDisabled,
  t,
  isDesktop,
}: {
  onPress: () => void;
  isDisabled: boolean;
  t: Translator;
  isDesktop: boolean;
}) {
  return (
    <TouchableHighlight
      underlayColor="transparent"
      onPress={onPress}
      disabled={isDisabled}
    >
      <View style={[styles.showMore, isDesktop && { marginTop: medium }]}>
        <Text
          style={[styles.showMoreText, isDisabled && styles.showMoreDisabled]}
        >
          {t('Home.Structure.Floor.Area.Desks.ShowMore')}
        </Text>
        <CustomIcon name="arrow-down" size={15} style={styles.showMoreIcon} />
      </View>
    </TouchableHighlight>
  );
}

function ColleaguesListRender({ item, page }: ListRenderProps) {
  const { data, loading, error } = useOrganizationUsersByDepartment(item, page);
  const isDesktop = useIsDesktop();

  if (error) {
    return <UnexpectedNoDataOrError style={styles.error} />;
  }

  return (
    <View style={isDesktop && [globalStylesDesktop.bookingsContainer]}>
      {loading ? (
        <Loader />
      ) : data.length ? (
        data.map((colleague, index) => (
          <ColleagueListCard
            key={`${item.id}-${index}`}
            colleague={colleague}
          />
        ))
      ) : null}
    </View>
  );
}
