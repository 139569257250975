import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigation } from '@react-navigation/native';

import { useCurrentDate } from '@views/Calendar/hooks';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';

import { getOrganizationUsersQuery } from '@views/Colleagues/List/Department/hooks';

import { OrganizationUserApiResponse } from '@views/Colleagues/List/interfaces';

export function useOrganizationUsersByFavourites() {
  const date = useCurrentDate();
  const navigation = useNavigation();

  const { data, loading, error, refetch } = useQuery<
    OrganizationUserApiResponse
  >(getOrganizationUsersQuery, {
    variables: {
      favColleagues: true,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
  });

  useEffect(() => navigation.addListener('focus', () => refetch()), [
    navigation,
    refetch,
  ]);

  return {
    data: data?.organizationUsers?.users || [],
    count: data?.organizationUsers.count || null,
    loading,
    error,
  };
}
