import { gql, useQuery } from '@apollo/client';

import { useCurrentDate } from '@views/Calendar/hooks';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import {
  limitUsers,
  limitUsersDesktop,
  getOrganizationUsersQuery,
} from '@views/Colleagues/List/Department/hooks';

import {
  InitialLettersApiResponse,
  OrganizationUserApiResponse,
  Item,
} from '@views/Colleagues/List/interfaces';

const getOrganizationUsersInitialLettersQuery = gql`
  query getOrganizationUsersInitialLetters {
    organizationUsersInitialLetters(excludeRequestUser: true) {
      letter
      count
    }
  }
`;

export function useOrganizationUsersInitialLetters() {
  const { data, loading, error } = useQuery<InitialLettersApiResponse>(
    getOrganizationUsersInitialLettersQuery,
  );

  return {
    data,
    loading,
    error,
  };
}

export function useOrganizationUsersByLetter({ letter }: Item, page: number) {
  const date = useCurrentDate();
  const isDesktop = useIsDesktop();
  const limit = isDesktop ? limitUsersDesktop : limitUsers;

  const { data, loading, error } = useQuery<OrganizationUserApiResponse>(
    getOrganizationUsersQuery,
    {
      variables: {
        nameStartsWith: letter,
        limit: limit,
        offset: page * limit,
        date: universalDateFormatter({ date, format: dateFormatShort }),
      },
    },
  );

  return {
    data: data?.organizationUsers?.users || [],
    loading,
    error,
  };
}
