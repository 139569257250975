import React from 'react';
import { StyleSheet, Text, View, ViewStyle } from 'react-native';

import { Favourite } from '@views/shared/Favourite';
import { globalStyles } from '@views/shared/styles';

interface Props {
  count?: number;
  onPress?: () => void;
  style?: ViewStyle;
}

const styles = StyleSheet.create({
  count: {
    position: 'absolute',
    top: -1,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  container: {
    justifyContent: 'center',
    right: -2,
  },
});

export function FavouriteCount({ count = 0, onPress, style }: Props) {
  const hasCount = count > 0;
  return (
    <View style={[styles.container, style]}>
      {hasCount ? (
        <View pointerEvents="none" style={styles.count}>
          <Text style={globalStyles.favCount}>{count}</Text>
        </View>
      ) : null}
      <Favourite
        onPress={hasCount ? onPress : undefined}
        selected={hasCount}
        size={30}
      />
    </View>
  );
}
