import { useState, useCallback, useEffect } from 'react';
import { gql } from '@apollo/client';

import { useIntl } from '@utils/intl';
import { useErrorQuery, useErrorMutation } from '@providers/Errors';

import { Data, Toggles } from '@views/Home/Profile/Overview/Toggles/interfaces';

const getTogglesQuery = gql`
  query getMyPrivacySettings {
    me {
      userprofile {
        visibleForColleagues
        visibleCalendar
        visibleHomeoffice
        visibleCalendarInPrivateMode
        enablePushNotifications
      }
    }
  }
`;

const updateToggles = gql`
  mutation updateToggles(
    $visibleCalendarInPrivateMode: Boolean
    $visibleCalendar: Boolean
    $visibleForColleagues: Boolean
    $visibleHomeoffice: Boolean
    $enablePushNotifications: Boolean
  ) {
    updateMe(
      appName: FINDER
      user: {
        userprofile: {
          visibleCalendarInPrivateMode: $visibleCalendarInPrivateMode
          visibleCalendar: $visibleCalendar
          visibleForColleagues: $visibleForColleagues
          visibleHomeoffice: $visibleHomeoffice
          enablePushNotifications: $enablePushNotifications
        }
      }
    ) {
      id
    }
  }
`;

function useCreateToggles({
  data,
  update,
}: {
  data?: Data;
  update: (variables: any) => any;
}) {
  const [onUpdate, setOnUpdate] = useState(false);
  const [state, setState] = useState<{ [T in keyof Toggles]: boolean }>({
    visibleCalendar: false,
    visibleCalendarInPrivateMode: false,
    visibleForColleagues: false,
    visibleHomeoffice: false,
    enablePushNotifications: false,
  });

  useEffect(() => {
    if (data && data.me && data.me.userprofile) {
      setState(data.me.userprofile);
    }
  }, [data]);

  useEffect(() => {
    if (onUpdate) {
      setOnUpdate(false);

      update({
        variables: {
          ...state,
        },
      });
    }
  }, [onUpdate, state, update]);

  const toggleColleagues = useCallback(async () => {
    setOnUpdate(true);
    setState(prevState => ({
      ...prevState,
      visibleForColleagues: !prevState.visibleForColleagues,
    }));
  }, []);

  const toggleCalendar = useCallback(async () => {
    setOnUpdate(true);
    setState(prevState => ({
      ...prevState,
      // CalendarInPrivateMode will be on when user will activate the Calendar
      visibleCalendarInPrivateMode:
        prevState.visibleCalendar === false
          ? true
          : prevState.visibleCalendarInPrivateMode,
      visibleCalendar: !prevState.visibleCalendar,
    }));
  }, []);

  const toggleHomeOffice = useCallback(async () => {
    setOnUpdate(true);
    setState(prevState => ({
      ...prevState,
      visibleHomeoffice: !prevState.visibleHomeoffice,
    }));
  }, []);

  const togglePrivateMode = useCallback(async () => {
    setOnUpdate(true);
    setState(prevState => ({
      ...prevState,
      visibleCalendarInPrivateMode: !prevState.visibleCalendarInPrivateMode,
    }));
  }, []);

  const togglePushNotifications = useCallback(async () => {
    setOnUpdate(true);
    setState(prevState => ({
      ...prevState,
      enablePushNotifications: !prevState.enablePushNotifications,
    }));
  }, []);

  return {
    colleagues: state.visibleForColleagues,
    toggleColleagues,
    calendar: state.visibleCalendar,
    toggleCalendar,
    homeOffice: state.visibleHomeoffice,
    toggleHomeOffice,
    privateMode: state.visibleCalendarInPrivateMode,
    togglePrivateMode,
    enablePushNotifications: state.enablePushNotifications,
    togglePushNotifications,
  };
}

export function useToggles() {
  const { t } = useIntl();
  const { data, loading, error } = useErrorQuery<Data>(getTogglesQuery, {
    fetchPolicy: 'no-cache',
    finderError: {
      type: 'fatal',
      message: t('Profile.Content.Toggles.fetchError'),
    },
  });

  const [update] = useErrorMutation<any, Toggles>(updateToggles, {
    refetchQueries: [
      {
        query: getTogglesQuery,
      },
    ],
    finderError: {
      type: 'minor',
      message: t('Profile.Content.Toggles.changeError'),
    },
  });

  return {
    ...useCreateToggles({ data, update }),
    loading,
    error,
  };
}
