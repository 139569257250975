import React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { colors, globalStyles, spacings } from '@views/shared/styles';

const { big, extraSmall, oversize } = spacings;

const styles = StyleSheet.create({
  container: {
    borderColor: colors.blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: big,
    marginBottom: oversize,
  },
  containerDesktop: {
    maxWidth: 924,
    alignSelf: 'center',
    paddingHorizontal: 0,
  },
});

export default function NoAccess() {
  const { t } = useIntl();
  return (
    <View style={globalStyles.container}>
      <View style={styles.container}>
        <Text style={[globalStyles.title, { textAlign: 'center' }]}>
          {t('Profile.NoAccess')}
        </Text>
      </View>
    </View>
  );
}
