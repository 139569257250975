import React from 'react';

import CalendarPicker from '@views/shared/CalendarPicker';

import { useCalendar, Props } from './hooks';
import { Events } from './Events';

export function Calendar({ navigationRef: { current: navigation } }: Props) {
  const data = useCalendar(navigation);
  return <CalendarPicker {...data} Events={Events} />;
}
