import { useCallback, useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { gql } from '@apollo/client';
import { useRecoilValue } from 'recoil';

import { useMyActiveBookings } from '@providers/ActiveBookings';
import { useMyOrganization } from '@providers/Organization';
import { useMyUser } from '@providers/User';
import { useIntl } from '@utils/intl';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useGoTo } from '@views/shared/utils/useGoTo';
import {
  getBookableWorkingTime,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { useErrorQuery } from '@providers/Errors';
import { useFilter } from '@views/Book/Filter/hooks';
import { checkDeskByFilterSettings } from '@views/shared/Booking';

import { STATUS } from '@views/shared/TimeSlots/helper';
import {
  AREA_TYPE_PARKING,
  AREA_TYPE_ROOM,
  BOOK_CATEGORY_PARKING,
  BOOK_CATEGORY_ROOM,
  FLOOR_TYPE_PARKING,
} from '@views/shared/consts';
import {
  scaleSelector,
  rotatedSelector,
  imageHeightSelector,
} from '@views/Floorplan/ZoomImage/state';

import {
  AreaType,
  FloorTypes,
} from '@views/shared/interfaces/buildingStructure';
import { Booking } from '@views/shared/interfaces/booking';
import { Status } from '@views/shared/TimeSlots/interfaces';
import {
  AreaPoint,
  AvailabilityInfo,
  Point,
  PointsData,
} from '@views/shared/interfaces/floorplan';

export interface ComponentProps {
  ids: string[];
  floorType: FloorTypes;
  showAvailability: (info?: AvailabilityInfo) => any;
  onLoading: (type: string, loading: boolean) => any;
}

export interface HookProps {
  ids: string[];
  floorType: FloorTypes;
  onLoading: (type: string, loading: boolean) => any;
}

const getDesks = gql`
  query getPoints(
    $ids: [ID]!
    $date: DateTime
    $start: DateTime
    $end: DateTime
    $canBookDesk: Boolean!
  ) {
    areasPoints: areasDesks(
      areaIds: $ids
      date: $date
      start: $start
      end: $end
    ) @include(if: $canBookDesk) {
      areaId
      areaName
      areaIsBookable
      areaType
      points: desks {
        id
        coordinateX
        coordinateY
        isFree
        isVipForbidden
        isOccupied
        isForbidden
        blocked
        numberOnFloor
        label
        type
        equipment {
          category {
            id
            name
          }
          id
          name
        }
        freeShare
        quarterState
      }
    }
  }
`;

const getParkingSpots = gql`
  query getPoints($ids: [ID]!, $date: DateTime) {
    areasPoints: areasParkings(areaIds: $ids, date: $date) {
      areaId
      areaName
      areaIsBookable
      areaType
      points: parkings {
        id
        coordinateX
        coordinateY
        isFree
        isVipForbidden
        isOccupied
        isForbidden
        blocked
        numberOnFloor
        type: combinedType
        label
        quarterState
      }
    }
  }
`;

const getPointStatus = (point: AreaPoint): Status => {
  const {
    isAvailable,
    isFree,
    isOccupied,
    blocked,
    isVipForbidden,
    isBooked,
  } = point;

  if (isBooked) {
    return STATUS.BOOKED;
  }

  if (blocked || isVipForbidden) {
    return STATUS.BLOCKED;
  }

  // occupied free desk cannot be booked, set as blocked
  if (isFree && isOccupied) {
    return STATUS.BLOCKED;
  }

  if (isFree && !isAvailable) {
    return STATUS.DISABLED;
  }

  if (isFree) {
    return STATUS.FREE;
  }

  return STATUS.BOOKED_BY_COLLEAGUE;
};

const checkDeskBooking = ({ desk }: Booking, id: string) => desk?.id === id;
const checkParkingBooking = ({ parking }: Booking, id: string) =>
  parking?.id === id;

export const checkActiveBooking = (areaType: AreaType, id: string) => (
  booking: Booking,
) => {
  const areaOverlap = {
    [AREA_TYPE_PARKING]: () => checkParkingBooking(booking, id),
    [AREA_TYPE_ROOM]: () => checkDeskBooking(booking, id),
  };
  return areaOverlap[areaType]();
};

const isOwnDailyBookDesks = ({
  status,
  enabledHourlyBooking,
}: {
  status: Status;
  enabledHourlyBooking: boolean;
}) => !enabledHourlyBooking && status === STATUS.BOOKED;

const checkParkingSpotByFilterSettings = ({ type }: AreaPoint, types) => {
  return types.length ? types.includes(type) : true;
};

export function usePoints({ ids, floorType, onLoading }: HookProps) {
  const { workingHoursStart, workingHoursEnd } = useMyOrganization();
  const {
    permissions: { canBookDesk },
  } = useMyUser();

  const [points, setPoints] = useState<Point[]>([]);
  const { t } = useIntl();
  const scale = useRecoilValue(scaleSelector);
  const rotated = useRecoilValue(rotatedSelector);
  const height = useRecoilValue(imageHeightSelector) ?? 1;
  const { goToBookingPage } = useGoTo();
  const { bookings } = useMyActiveBookings();
  const showNumber = useHasFeature('desk_number_on_floor');
  const enabledHourlyBooking = useHasFeature('hourly_booking');
  const isParking = floorType === FLOOR_TYPE_PARKING;

  const query = isParking ? getParkingSpots : getDesks;
  const type = isParking ? BOOK_CATEGORY_PARKING : BOOK_CATEGORY_ROOM;

  const filterData = useFilter();
  const {
    equipmentIds,
    parkingTypes,
    timeRange: { start: startFilter, end: endFilter },
    isFilterUsed,
  } = filterData[type];

  const date = useCurrentDate();
  const { start, end } = getBookableWorkingTime(
    date,
    startFilter || workingHoursStart,
    endFilter || workingHoursEnd,
  );

  const { data, loading } = useErrorQuery<PointsData>(query, {
    variables: {
      ids,
      date: universalDateFormatter({ date }),
      start: universalDateFormatter({ date: start }),
      end: universalDateFormatter({ date: end }),
      canBookDesk,
    },
    fetchPolicy: 'no-cache',
    skip: !ids,
    finderError: {
      type: 'fatal',
      message: t('Floorplan.ZoomImage.Points.fetch'),
    },
  });

  const checkIsAvailableToBook = useCallback(
    (status: Status) => {
      return (
        status === STATUS.FREE ||
        (status !== STATUS.BLOCKED &&
          status !== STATUS.DISABLED &&
          status !== STATUS.BOOKED_BY_COLLEAGUE &&
          !isOwnDailyBookDesks({ enabledHourlyBooking, status }))
      );
    },
    [enabledHourlyBooking],
  );

  const findActiveBooking = useCallback(
    (id: string, areaType: AreaType): boolean =>
      !isNil(bookings.find(checkActiveBooking(areaType, id))),
    [bookings],
  );

  useEffect(() => {
    onLoading('points', loading);
  }, [loading, onLoading]);

  useEffect(() => {
    if (data?.areasPoints) {
      const points: Point[] = data.areasPoints.reduce(
        (points: Point[], area): Point[] =>
          points.concat(
            area.points
              .filter((point: AreaPoint) => {
                // isForbidden -> https://liz-smart-office.atlassian.net/browse/NW-1833
                // without the check all possible desks are bookable
                const { isForbidden, coordinateX, coordinateY } = point;
                return (
                  coordinateX !== null && coordinateY !== null && !isForbidden
                );
              })
              .map((point: AreaPoint) => {
                const { areaId, areaIsBookable, areaType, areaName } = area;
                const {
                  blocked,
                  id,
                  coordinateX,
                  coordinateY,
                  numberOnFloor,
                  label,
                  type,
                  quarterState,
                } = point;

                const pointIsBlocked = !areaIsBookable
                  ? !areaIsBookable
                  : blocked;

                // special case for blocked booked desk
                const isBooked =
                  !pointIsBlocked && findActiveBooking(id, areaType);

                const isAvailable = isFilterUsed
                  ? areaType === BOOK_CATEGORY_ROOM
                    ? checkDeskByFilterSettings(
                        point,
                        equipmentIds,
                        startFilter,
                      )
                    : checkParkingSpotByFilterSettings(point, parkingTypes)
                  : true;

                const status = getPointStatus({
                  ...point,
                  blocked: pointIsBlocked,
                  isBooked,
                  isAvailable,
                });

                const platformScale = 1;
                const x = coordinateX * platformScale;
                const y = coordinateY * platformScale;

                return {
                  areaId,
                  areaName,
                  areaType,
                  deskCaption: numberOnFloor,
                  id,
                  isAvailableToBook: checkIsAvailableToBook(status),
                  label,
                  status,
                  x: rotated ? y : x,
                  y: rotated ? height - x : y,
                  type,
                  quarterState,
                };
              }),
          ),
        [],
      );
      setPoints(points);
    }
  }, [
    checkIsAvailableToBook,
    data,
    findActiveBooking,
    height,
    rotated,
    equipmentIds,
    parkingTypes,
    isFilterUsed,
    startFilter,
  ]);

  const book = useCallback(
    ({ areaId, areaType, id }: Point) => {
      const isParkingArea = areaType === AREA_TYPE_PARKING;

      const props = {
        areaId,
        type: areaType,
        ...(isParkingArea ? { parkingId: id } : { deskId: id }),
      };

      goToBookingPage(props);
    },
    [goToBookingPage],
  );

  return {
    points,
    scale,
    book,
    showNumber,
  };
}
