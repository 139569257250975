import React from 'react';
import SafeAreaView from 'react-native-safe-area-view';
import { ScrollView, Text, View } from 'react-native';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { Loader } from '@views/shared/Loader';
import { CustomIcon } from '@views/shared/CustomIcon';
import { useIntl } from '@utils/intl';

import { globalStyles, colors } from '@views/shared/styles';
import { styles } from '@views/Bookings/MyBookings';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import { Day } from './Day';
import { useBookings } from './hooks';

interface Props {
  id: string;
}
export function Bookings({ id }: Props) {
  const {
    weekDays,
    mapping,
    loading,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
  } = useBookings(id);

  const { t } = useIntl();
  const isDesktop = useIsDesktop();

  if (error) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <SafeAreaView style={{ backgroundColor: colors.white }}>
        <View style={styles.header}>
          <CustomIcon name="arrow-left" onPress={moveToPreviousWeek} />
          <Text style={styles.title}>
            {t('views.Colleagues.Bookings.title')}
          </Text>
          <CustomIcon name="arrow-right" onPress={moveToNextWeek} />
        </View>
      </SafeAreaView>
      <ScrollView style={[globalStyles.container, { paddingTop: 0 }]}>
        <View style={isDesktop ? { flexDirection: 'row' } : {}}>
          {weekDays.map((date, idx) => {
            const key = universalDateFormatter({
              date,
              format: dateFormatShort,
            });
            const bookings = mapping[key] ?? [];

            return (
              <Day
                key={key}
                date={date}
                bookings={bookings}
                last={idx === weekDays.length - 1}
                numberOfDays={weekDays.length}
              />
            );
          })}
        </View>
      </ScrollView>
    </>
  );
}
