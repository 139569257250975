import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { isEmpty, isNil } from 'ramda';

import {
  dateFormatShort,
  isToday,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';

import { getBookingType } from '@views/shared/utils';
import { getBookingIcon } from '@views/shared/consts';
import { BookingIcon } from '@views/shared/BookingIcon';
import { Loader } from '@views/shared/Loader';
import { colors, fonts, spacings } from '@views/shared/styles';

import { useBookings } from './hooks';

const { extraSmall, tiny } = spacings;

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: -4,
  },
  item: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: colors.grey6Transparent,
    padding: 10,
    marginHorizontal: 4,
    borderRadius: extraSmall,
  },
  day: {
    alignSelf: 'center',
    fontSize: fonts.sizeSmallPlus,
    fontFamily: 'mrt-semi-bold',
  },
  date: {
    fontFamily: 'mrt-regular',
  },
  selected: {
    backgroundColor: colors.aquaMarine,
  },
  icon: {
    marginTop: tiny,
  },
});

export default function CurrentBookings({ userId }: { userId: string }) {
  const {
    formats: { dayShortFormat, dayWeekFormatShort },
  } = useIntl() as any;
  const { bookings, loading, error, weekDays } = useBookings(userId);

  if (error || isNil(bookings)) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <View style={styles.container}>
      {weekDays.map((day, idx) => {
        const key = universalDateFormatter({
          date: day,
          format: dateFormatShort,
        });
        const dayWeek = universalDateFormatter({
          date: day,
          format: dayWeekFormatShort,
        });
        const date = universalDateFormatter({
          date: day,
          format: dayShortFormat,
        });
        const dayBookings = bookings[key] ?? [];
        const booking = dayBookings.length ? dayBookings[0] : {};
        const type = isEmpty(booking) ? '' : getBookingType(booking);
        return (
          <View
            key={idx}
            style={[styles.item, isToday(day) && styles.selected]}
          >
            <Text style={styles.day}>{dayWeek}</Text>
            <Text style={[styles.day, styles.date]}>{date}</Text>

            <BookingIcon
              name={type ? `${getBookingIcon(type)}` : 'question'}
              style={styles.icon}
            />
          </View>
        );
      })}
    </View>
  );
}
