import { StyleSheet } from 'react-native';
import {
  ascend,
  compose,
  isEmpty,
  pluck,
  prop,
  sortWith,
  toLower,
} from 'ramda';

import {
  AreaStructure,
  DeskStructure,
  Floor,
  ParkingStructure,
} from '@views/shared/interfaces/buildingStructure';
import { colors, spacings } from '@views/shared/styles';
import { AreaPoint } from '@views/shared/interfaces/floorplan';

const { huge, medium, small } = spacings;

export const loaderSize = 20;

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    paddingHorizontal: medium,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingTop: huge,
    paddingBottom: huge,
  },
  error: {
    marginTop: medium,
    alignSelf: 'center',
    color: colors.warmPink,
  },
  loaderAnimation: {
    width: loaderSize,
    height: loaderSize,
  },
  loaderContainer: {
    marginVertical: 0,
    marginLeft: small,
  },
  comment: {
    marginTop: medium,
  },
});

export const sortAreaByName = sortWith<AreaStructure>([
  ascend(compose(isEmpty, prop('name'))),
  ascend(compose(toLower, prop('name'))),
]);

export const sortDesksByTypeAndLabel = sortWith<DeskStructure>([
  ascend(prop('type')),
  ascend(compose(isEmpty, prop('label'))),
  ascend(compose(toLower, prop('label'))),
]);

export const getAvailableDesks = (desks: DeskStructure[]): DeskStructure[] =>
  desks.filter(getAvailableDesksOrParkingSpots);

export const sortParkingByTypeAndLabel = sortWith<ParkingStructure>([
  ascend(prop('type')),
  ascend(compose(isEmpty, prop('label'))),
  ascend(compose(toLower, prop('label'))),
]);

export const sortFloorsByTypeAndLabel = sortWith<Floor>([
  ascend(prop('floorType')),
  ascend(compose(toLower, prop('label'))),
]);

export const sortFloorsByTypeAndNumber = sortWith<Floor>([
  ascend(prop('floorType')),
  ascend(prop('floorNumber')),
]);

// note: a parking can be free and occupied, and the desk too.
// a desk / parking isn't booked but the sensor detects an occupancy,
// in this case a desk/ parking isn't bookable.
// this behaviour is implemented in floorplan
export const getAvailableParkingSpots = (
  parkings: ParkingStructure[],
): ParkingStructure[] => parkings.filter(getAvailableDesksOrParkingSpots);

const getAvailableDesksOrParkingSpots = ({
  blocked,
  isForbidden,
  isVipForbidden,
  isOccupied,
  isFree,
}) => isFree && !blocked && !isForbidden && !isVipForbidden && !isOccupied;

export const filterDesksByFilterSettings = (
  desks: DeskStructure[],
  equipmentIds: string[],
  start: any,
): DeskStructure[] =>
  desks.filter(desk => checkDeskByFilterSettings(desk, equipmentIds, start));

export const checkDeskByFilterSettings = (
  { equipment, freeShare }: DeskStructure | AreaPoint,
  equipmentIds: string[],
  start: any,
) => {
  const isFree = start ? freeShare === 100 : true;

  const equipmentList = pluck('id', equipment);

  const hasEquipment = equipmentIds.length
    ? equipmentIds.every(id => equipmentList.includes(id))
    : true;

  return isFree && hasEquipment;
};
