import { useQuery } from '@apollo/client';

import { useCurrentDate } from '@views/Calendar/hooks';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import {
  limitUsers,
  limitUsersDesktop,
  getOrganizationUsersQuery,
} from '@views/Colleagues/List/Department/hooks';

import { OrganizationUserApiResponse } from '@views/Colleagues/List/interfaces';

export function useOrganizationUsersByPresence(type, page = 0) {
  const date = useCurrentDate();
  const isDesktop = useIsDesktop();
  const limit = isDesktop ? limitUsersDesktop : limitUsers;

  const { data, loading, error } = useQuery<OrganizationUserApiResponse>(
    getOrganizationUsersQuery,
    {
      variables: {
        date: universalDateFormatter({ date, format: dateFormatShort }),
        [type]: true,
        limit: limit,
        offset: page * limit,
      },
    },
  );

  return {
    data: data?.organizationUsers?.users || [],
    count: data?.organizationUsers?.count || null,
    loading,
    error,
  };
}
