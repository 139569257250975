import React from 'react';
import { Text, View } from 'react-native';

import { Accordion } from '@views/shared/Accordion';
import { BookingIcon } from '@views/shared/BookingIcon';

import { spacings, globalStyles, colors } from '@views/shared/styles';
import { styles } from '@views/Book/Bookings/Panel';
import { styles as pStyles } from '@views/Book/Bookings/Panel';

import {
  AreaType,
  FloorStructureForDesks,
  FloorStructureForMeetingRooms,
  FloorStructureForFreeSeats,
  FloorStructureForParkingSpots,
  FloorStructureForTelefonBox,
  WorklightStatus,
  AreaStructure,
} from '@views/shared/interfaces/buildingStructure';

import { BookingIcon as BookingIconType } from '@views/shared/interfaces/booking';

import { Area } from './Area';
import {
  useFloorWithDesks,
  useFloorWithFreeSeats,
  useFloorWithMeetingRooms,
  useFloorWithParkingSpots,
  useFloorWithTelefonBox,
} from './hooks';

interface Props {
  floor: any;
  type: AreaType;
  bookingIcon: BookingIconType;
  open: boolean;
  worklight: WorklightStatus;
  title: string;
}

export const withDesks = () => WrappedComponent => {
  const WithDesks = ({ floor }: { floor: FloorStructureForDesks }) => (
    <WrappedComponent {...useFloorWithDesks(floor)} />
  );

  return WithDesks;
};

export const withFreeSeats = () => WrappedComponent => {
  const WithFreeSeats = ({ floor }: { floor: FloorStructureForFreeSeats }) => (
    <WrappedComponent {...useFloorWithFreeSeats(floor)} />
  );

  return WithFreeSeats;
};

export const withMeetingRooms = () => WrappedComponent => {
  const WithMeetingRooms = ({
    floor,
  }: {
    floor: FloorStructureForMeetingRooms;
  }) => <WrappedComponent {...useFloorWithMeetingRooms(floor)} />;

  return WithMeetingRooms;
};

export const withTelefonBox = () => WrappedComponent => {
  const WithTelefonBox = ({
    floor,
  }: {
    floor: FloorStructureForTelefonBox;
  }) => <WrappedComponent {...useFloorWithTelefonBox(floor)} />;

  return WithTelefonBox;
};

export const withParkingSpots = () => WrappedComponent => {
  const WithParkingSpots = ({
    floor,
  }: {
    floor: FloorStructureForParkingSpots;
  }) => <WrappedComponent {...useFloorWithParkingSpots(floor)} />;

  return WithParkingSpots;
};

export default function Floor({
  floor,
  type,
  bookingIcon,
  open,
  worklight,
  title,
}: Props) {
  const titleElm = (
    <>
      <View style={styles.left}>
        <BookingIcon name={bookingIcon} worklight={worklight} />
      </View>
      <View style={styles.middle}>
        <Text style={globalStyles.title}>{title}</Text>
      </View>
    </>
  );

  return (
    <View
      style={[
        globalStyles.shadow,
        { marginBottom: spacings.medium, backgroundColor: colors.white },
      ]}
    >
      <Accordion title={titleElm} open={open} headerStyle={pStyles.row}>
        {floor.areas
          // @ts-ignore
          .filter(
            ({ isBookable, areaType }: AreaStructure) =>
              isBookable && areaType === type,
          )
          .map(area => (
            <Area key={area.id} area={area} type={type} />
          ))}
      </Accordion>
    </View>
  );
}
