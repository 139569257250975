import React from 'react';
import { ScrollView } from 'react-native';
import { Calendar } from 'react-native-calendars';
import Day from 'react-native-calendars/src/calendar/day/basic';

import {
  dateFormatShort,
  isAfter,
  isDayExpired,
  isToday,
  isSameDay,
  getDay,
  universalDateFormatter,
  getTodayAsDate,
} from '@utils/DateAndTime';
import { useIntl } from '@utils/intl';

import { Overlay } from '@views/shared/Overlay';
import { CustomIcon } from '@views/shared/CustomIcon';
import { colors } from '@views/shared/styles';

import { LoadCalenderConfig, styles, theme } from './helper';

export default function CalendarPicker({
  close,
  currentDate,
  Events,
  enabledWorkingDays,
  maxAvailableDate,
  onDayChange,
}) {
  const { t } = useIntl();
  LoadCalenderConfig(); //localize calendar names for months and days

  const dayComponent = props => {
    const {
      date: { dateString },
    } = props;

    const isDisabled = isDayExpired(dateString);
    const isSelected = isSameDay(dateString, currentDate);

    const today = isToday(dateString) ? 'today' : '';
    const day = getDay(dateString);

    // TODO enable the sunday as start of the week
    const isDayEnabled = enabledWorkingDays
      ? enabledWorkingDays.includes(day === 0 ? 7 : day)
      : true;

    const isDisabledMaxRange =
      maxAvailableDate && isAfter(dateString, maxAvailableDate);

    return (
      <Day
        {...props}
        state={today}
        marking={{
          customStyles: {
            text: theme.textStyles(isDayEnabled),
          },
          disabled: isDisabled || isDisabledMaxRange,
          disableTouchEvent: isDisabled || !isDayEnabled || isDisabledMaxRange,
          selectedColor: isSelected && colors.aquaMarine,
          selected: isSelected,
        }}
        markingType="custom"
        onPress={onDayChange}
      />
    );
  };

  const current = universalDateFormatter({
    date: currentDate,
    format: dateFormatShort,
  });

  return (
    <Overlay
      onClose={close}
      title={t('Calendar.Select.Date')}
      contentStyle={styles.overlay}
    >
      <ScrollView>
        <Calendar
          current={current}
          dayComponent={dayComponent}
          firstDay={1}
          maxDate={maxAvailableDate}
          minDate={getTodayAsDate()}
          renderArrow={direction => (
            <CustomIcon name={`arrow-${direction}`} size={18} />
          )}
          theme={theme}
        />
        <Events />
      </ScrollView>
    </Overlay>
  );
}
