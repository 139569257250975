import { useCallback } from 'react';
import { isNil } from 'ramda';

import { Booking, AttendaceType } from '@views/shared/interfaces/booking';
import {
  dateFormatShort,
  getNextDayFromThis,
  getPreviousWeekFromThis,
  getWeekDays,
  getWorkingDaysOfWeek,
  transformToDate,
  universalDateFormatter,
} from '@utils/DateAndTime';
import { useErrorQuery } from '@providers/Errors';
import { useMyOrganization } from '@providers/Organization';

interface Data {
  bookings: Booking[];
  userAttendances: AttendaceType[];
}

interface Props {
  query: any;
  options: any;
  weekStart: any;
  setWeekStart: any;
}

export function assignBookingsToDays(
  bookings: Booking[],
): { string: Booking[] } {
  const map = {};

  bookings
    .filter(booking => booking.status?.indexOf('CANCELLED'))
    .forEach(booking => {
      const key = universalDateFormatter({
        date: booking.start,
        format: dateFormatShort,
      });
      if (isNil(map[key])) {
        map[key] = [];
      }

      map[key].push(booking);
    });

  return map;
}

export function assignAttendanceToDays(
  userAttendances: AttendaceType[],
): { string: AttendaceType[] } {
  const map = {};

  userAttendances.forEach(attendance => {
    const key = universalDateFormatter({
      date: attendance.date,
      format: dateFormatShort,
    });
    if (isNil(map[key])) {
      map[key] = [];
    }

    map[key].push(attendance);
  });

  return map;
}
// Hooks that provide the navigations function for the Bookings page and in the Colleagues Bookings page
export function useCoreBookings({
  query,
  options,
  weekStart,
  setWeekStart,
}: Props) {
  const { workingDaysStart, workingDaysEnd } = useMyOrganization();

  const enabledWorkingDays = getWorkingDaysOfWeek(
    workingDaysStart,
    workingDaysEnd,
  );
  const { data, loading, error, refetch } = useErrorQuery<Data>(query, options);
  const weekDays = getWeekDays(weekStart, enabledWorkingDays);
  const mapping = assignBookingsToDays(data?.bookings || []);

  const moveToNextWeek = useCallback(() => {
    const nextWeek = transformToDate(getNextDayFromThis(weekStart, 7));
    setWeekStart(nextWeek);
  }, [setWeekStart, weekStart]);
  const moveToPreviousWeek = useCallback(() => {
    const previousWeek = transformToDate(getPreviousWeekFromThis(weekStart, 7));
    setWeekStart(previousWeek);
  }, [setWeekStart, weekStart]);

  const onSwipe = useCallback(
    (direction: 'right' | 'left') => {
      if (direction === 'right') {
        moveToNextWeek();
      } else {
        moveToPreviousWeek();
      }
    },
    [moveToNextWeek, moveToPreviousWeek],
  );

  //setWeekStart(weekStart);

  return {
    weekDays,
    mapping,
    loading,
    onSwipe,
    error,
    moveToNextWeek,
    moveToPreviousWeek,
    refreshBookings: refetch,
  };
}
