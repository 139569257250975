import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import { useIntl } from '@utils/intl';
import { CustomIcon } from '@views/shared/CustomIcon';
import { fonts, spacings } from '@views/shared/styles';

const { smallMedium, verySmall } = spacings;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    marginBottom: smallMedium,
  },
  icon: {
    marginBottom: verySmall,
  },
  title: {
    fontFamily: 'mrt-regular',
    fontSize: fonts.sizeSemiSmall,
  },
});

interface Prop {
  message?: string;
  level?: 'warning' | 'notify';
}

const defaultLevel = 'warning';

const levelConfig = {
  warning: { defaultTitle: 'Errors.error_title', icon: 'smiley-2' },
  notify: { defaultTitle: 'Errors.no-data.error_title', icon: 'smiley-3' },
};

export default function UnexpectedNoDataOrError({ message, level }: Prop) {
  const { t } = useIntl();
  const { defaultTitle, icon } = levelConfig[level || defaultLevel];
  const title = message || t(defaultTitle);

  return (
    <View style={styles.container}>
      <CustomIcon style={styles.icon} name={icon} size={45} />
      <Text style={styles.title}>{title}</Text>
    </View>
  );
}
