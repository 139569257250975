import React from 'react';
import {
  StyleSheet,
  ImageBackground,
  TouchableHighlight,
  View,
  Text,
} from 'react-native';

import { useIntl } from '@utils/intl';
import { colors, spacings, fonts } from '@views/shared/styles';

import { useGoogle } from './hooks';
import { globalStyles } from '@views/shared/styles';

const googleIcon = require('@assets/images/g-logo.png');

const { smallMedium, smaller } = spacings;
const { black, blackAlpha, white, googleBlue } = colors;

export const styles = StyleSheet.create({
  title: {
    color: black,
    flex: 1,
    fontFamily: 'roboto-medium',
    fontSize: fonts.sizeDefault,
    padding: smallMedium,
  },
  googleTitle: {
    color: white,
  },
  container: {
    flexDirection: 'row',
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: smaller,
  },
  imageContainer: {
    width: 18,
    height: 18,
    margin: smallMedium,
    alignSelf: 'center',
  },
  googleContainer: {
    borderColor: googleBlue,
  },
  googleTitleContainer: {
    backgroundColor: googleBlue,
    flex: 1,
  },
});

export function Google() {
  const { t } = useIntl();
  const { toggle, checkedIn } = useGoogle();

  return (
    <TouchableHighlight underlayColor="transparent" onPress={toggle}>
      <View
        style={[globalStyles.shadow, styles.container, styles.googleContainer]}
      >
        <View style={styles.imageContainer}>
          <ImageBackground
            source={googleIcon}
            style={{
              height: 18,
            }}
          />
        </View>
        <View style={styles.googleTitleContainer}>
          <Text style={[styles.title, styles.googleTitle]}>
            {t(
              checkedIn
                ? 'Profile.Content.Google.out'
                : 'Profile.Content.Google.button',
            )}
          </Text>
        </View>
      </View>
    </TouchableHighlight>
  );
}
