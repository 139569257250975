import React from 'react';
import { StyleSheet, Text, TouchableHighlight, View } from 'react-native';

import { useIntl } from '@utils/intl';
import BasicBook from '@views/Book/Book/BasicBook';

import CalendarPickerRange from '@views/shared/CalendarPicker/range';
import { useCalendarPicker } from '@views/shared/CalendarPicker/hooks';
import { CustomIcon } from '@views/shared/CustomIcon';
import { colors, fonts, spacings } from '@views/shared/styles';

import Booking from './Booking';
import { useValues } from './hooks';

const { blackAlpha, aquaMarineLight } = colors;
const {
  extraSmall,
  huge,
  oversize,
  smaller,
  smallMedium,
  tiny,
  verySmall,
} = spacings;
const { sizeSmallPlus, sizeSemiSmall } = fonts;

export const styles = StyleSheet.create({
  container: {
    borderColor: blackAlpha,
    borderWidth: 1,
    borderRadius: extraSmall,
    padding: oversize,
    marginTop: huge,
  },
  description: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSemiSmall,
  },
  touchable: {
    borderColor: colors.grey1,
    borderWidth: 1,
    borderRadius: smaller,
    paddingLeft: extraSmall,
    paddingRight: verySmall,
    paddingVertical: tiny,
    fontSize: sizeSmallPlus,
    alignSelf: 'center',
    marginTop: smallMedium,
  },

  touchableContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  touchableIcon: {
    marginRight: tiny,
  },
  touchableText: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSmallPlus,
  },
});

export default function BookVacation() {
  const { t, formats } = useIntl();

  const {
    booking,
    date,
    enabledHalfDayBooking,
    enabledHourlyBooking,
    hasNextDaysBooking,
    currentDate,
    type,
    onDayChange,
    dateRange,
  } = useValues(formats);

  const { open: openCalendar, isOpen, close } = useCalendarPicker();

  const DateSelector = (
    <View style={styles.container}>
      <Text style={styles.description}>
        {t('Home.Book.Vacation.Period.Change')}
      </Text>

      <TouchableHighlight
        onPress={openCalendar}
        underlayColor={aquaMarineLight}
        style={styles.touchable}
      >
        <View style={styles.touchableContainer}>
          <CustomIcon
            name="calendar-no"
            size={26}
            style={styles.touchableIcon}
          />
          <Text style={styles.touchableText}>{t('Calendar.Open')}</Text>
        </View>
      </TouchableHighlight>

      {isOpen ? (
        <CalendarPickerRange
          close={close}
          dateRange={dateRange}
          onDayChange={onDayChange}
        />
      ) : null}
    </View>
  );

  return (
    <BasicBook
      booking={booking}
      date={date}
      type={type}
      enabledHalfDayBooking={enabledHalfDayBooking}
      enabledHourlyBooking={enabledHourlyBooking}
      dateRange={dateRange}
    >
      <>
        {DateSelector}
        <Booking
          type={type}
          date={currentDate}
          enabledHalfDayBooking={enabledHalfDayBooking}
          enabledHourlyBooking={enabledHourlyBooking}
          hasNextDaysBooking={hasNextDaysBooking}
          onDayChange={onDayChange}
          dateRange={dateRange}
        />
      </>
    </BasicBook>
  );
}
