import React from 'react';
import { Text, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { Loader } from '@views/shared/Loader';
import TimeSlots from '@views/shared/TimeSlots';
import HalfDaySelection from '@views/shared/HalfDaySelection';

import { globalStyles } from '@views/shared/styles';
import { loaderSize, styles } from '@views/shared/Booking';
import { BaseBookingProps } from '@views/shared/interfaces/booking';

import { Input } from '@views/Home/Profile/Edit';

export default function BaseBooking({
  loading,
  blockedTimeSlots,
  bookablePeriod,
  setSlots,
  error,
  errorBooking,
  selectedSlots,
  book,
  loadingBooking,
  midDayHour,
  enabledHalfDayBooking,
  enabledHourlyBooking,
  showAvailability,
  showShortCutSlotsSelection = true,
  dateRange,
  type,
  updateComment,
  isQuotaReached,
}: BaseBookingProps) {
  const { t } = useIntl();

  const sickDayOrVacationBooking = ['sickDay', 'vacation'].includes(type);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return null;
  }

  return (
    <View>
      {(enabledHourlyBooking || enabledHalfDayBooking) && !loadingBooking ? (
        sickDayOrVacationBooking ? (
          <HalfDaySelection
            blockedTimeSlots={blockedTimeSlots}
            bookablePeriod={bookablePeriod}
            midDayHour={midDayHour}
            onSelect={setSlots}
            showAvailability={showAvailability}
            showSlotsSelection={enabledHourlyBooking}
            showShortCutSlotsSelection={showShortCutSlotsSelection}
            dateRange={dateRange}
          />
        ) : (
          <TimeSlots
            blockedTimeSlots={blockedTimeSlots}
            bookablePeriod={bookablePeriod}
            midDayHour={midDayHour}
            onSelect={setSlots}
            showAvailability={showAvailability}
            showSlotsSelection={enabledHourlyBooking}
            showShortCutSlotsSelection={showShortCutSlotsSelection}
          />
        )
      ) : null}
      {errorBooking ? (
        <Text style={[globalStyles.title, styles.error]}>
          {t(errorBooking)}
        </Text>
      ) : null}
      {isQuotaReached ? (
        <Text style={[globalStyles.title, styles.error]}>
          {t('Errors.Book.Quota')}
        </Text>
      ) : null}
      <View style={styles.comment}>
        <Input
          label="Home.Structure.Comment.Label"
          description="Home.Structure.Comment.Description"
          onChange={updateComment}
          maxLength={200}
          style={{ marginBottom: 0, paddingBottom: 0 }}
        />
      </View>

      <View style={styles.buttonContainer}>
        <MainButton
          disabled={!selectedSlots.length || isQuotaReached}
          onPress={book}
          title={t('Home.Structure.book')}
        />
        {loadingBooking ? (
          <Loader
            iconSize={loaderSize}
            animationStyle={styles.loaderAnimation}
            containerStyle={styles.loaderContainer}
          />
        ) : null}
      </View>
    </View>
  );
}
