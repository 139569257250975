import React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableHighlight,
  View,
} from 'react-native';
import { isNil } from 'ramda';

import { useIntl } from '@utils/intl';
import { MainButton } from '@views/shared/Button';
import { colors, fonts, globalStyles, spacings } from '@views/shared/styles';
import { useValues } from '@views/Home/Profile/Actions/hooks';

const { extraSmall, huge, medium, smaller, tiny } = spacings;
const { aquaMarine, aquaMarineLight, grey1, warmPink } = colors;
const { sizeSmallPlus } = fonts;

export const styles = StyleSheet.create({
  item: {
    fontFamily: 'mrt-regular',
    borderColor: grey1,
    borderWidth: 1,
    borderRadius: smaller,
    padding: smaller,
    marginVertical: extraSmall,
    fontSize: sizeSmallPlus,
    alignItems: 'center',
  },
  itemText: {
    fontFamily: 'mrt-regular',
    fontSize: sizeSmallPlus,
  },
  activeItem: {
    borderColor: aquaMarine,
    backgroundColor: aquaMarineLight,
  },
  container: {
    borderColor: colors.blackAlpha,
    borderWidth: 1,
    borderRadius: spacings.extraSmall,
    padding: spacings.oversize,
  },
  title: {
    textAlign: 'center',
    fontSize: fonts.sizeBuilding,
    fontFamily: 'mrt-semi-bold',
    marginBottom: medium,
  },
  description: {
    fontSize: sizeSmallPlus,
    fontFamily: 'mrt-regular',
    marginVertical: tiny,
  },
  selectionContainer: {
    marginVertical: extraSmall,
    maxHeight: '65%',
    marginBottom: huge,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  success: {
    color: aquaMarine,
    fontFamily: 'mrt-semi-bold',
    marginBottom: medium,
    fontSize: sizeSmallPlus,
  },
  error: {
    color: warmPink,
  },
});

export default function Actions() {
  const { t } = useIntl();
  const {
    isError,
    isLoading,
    isSaved,
    onSelect,
    onSubmit,
    organizations,
    selected,
  } = useValues();

  return (
    <View style={globalStyles.container}>
      <View style={styles.container}>
        <Text style={styles.title}>{t('Profile.Actions.Title')}</Text>

        <Text style={styles.description}>
          {t('Profile.Actions.Select.Organization')}
        </Text>
        <ScrollView style={styles.selectionContainer}>
          {organizations.map(({ name, id }, idx) => (
            <TouchableHighlight
              key={idx}
              onPress={() => onSelect(id)}
              underlayColor="transparent"
              style={[styles.item, id === selected && styles.activeItem]}
            >
              <Text style={styles.description}>{name}</Text>
            </TouchableHighlight>
          ))}
        </ScrollView>
        {isError ? (
          <Text style={[styles.success, styles.error]}>
            {t('Errors.error_title')}
          </Text>
        ) : null}
        {isSaved ? (
          <Text style={styles.success}>
            {t('Profile.Actions.Organization.Saved')}
          </Text>
        ) : null}
        <View style={styles.buttonContainer}>
          <MainButton
            disabled={isNil(selected) || isLoading || isError}
            onPress={() => onSubmit(selected)}
            title={t('Profile.Edit.save')}
          />
        </View>
      </View>
    </View>
  );
}
