import React from 'react';
import {
  View,
  StyleSheet,
  TouchableHighlight,
  TextStyle,
  Text,
  Switch as NativeSwitch,
  Platform,
} from 'react-native';

import { colors } from '@views/shared/styles';

const { grey2, aquaMarine, white } = colors;

interface Props {
  onToggle: () => void;
  isActive: boolean;
  label?: string;
  secondLabel?: string;
  labelStyle?: TextStyle;
  secondLabelStyle?: TextStyle;
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  labels: {
    flexDirection: 'column',
    flex: 1,
  },
  label: {
    flex: 1,
  },
  switch: {
    position: 'relative',
    width: 46,
    height: 24,
    backgroundColor: grey2,
    borderRadius: 15,
  },
  ball: {
    position: 'absolute',
    top: 1,
    left: 2,
    width: 21,
    height: 21,
    borderRadius: 999,
    backgroundColor: white,
  },
});

export function Switch({
  isActive,
  onToggle,
  labelStyle,
  label,
  secondLabelStyle,
  secondLabel,
}: Props) {
  return (
    <TouchableHighlight
      style={{ width: '100%' }}
      underlayColor="transparent"
      onPress={onToggle}
    >
      <View style={styles.container}>
        <View style={styles.labels}>
          {label ? (
            <Text style={[styles.label, labelStyle]}>{label}</Text>
          ) : null}
          {secondLabel ? (
            <Text style={[styles.label, secondLabelStyle]}>{secondLabel}</Text>
          ) : null}
        </View>
        {Platform.OS === 'web' ? (
          <View
            style={[
              styles.switch,
              { backgroundColor: isActive ? aquaMarine : grey2 },
            ]}
          >
            <View style={[styles.ball, { left: isActive ? 23 : 2 }]} />
          </View>
        ) : (
          <NativeSwitch
            value={isActive}
            trackColor={{ false: grey2, true: aquaMarine }}
            thumbColor="white"
            ios_backgroundColor="#3e3e3e"
            onValueChange={onToggle}
          />
        )}
      </View>
    </TouchableHighlight>
  );
}
