import React, { useCallback, useState } from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import { head, isEmpty, last } from 'ramda';

import { CustomIcon } from '@views/shared/CustomIcon';
import { Overlay } from '@views/shared/Overlay';
import { MainButton, SecondaryButton } from '@views/shared/Button';

import { useIntl } from '@utils/intl';
import {
  getNumberFromHours,
  universalDateFormatter,
  MixDate,
} from '@utils/DateAndTime';

import Item from '@views/Book/Filter/ressources/item';

import { useMyOrganization } from '@providers/Organization';

import { styles } from '@views/Book/Filter/styles';
import {
  SelectedSlot,
  SelectedTime,
  TimeProps,
} from '@views/Book/Filter/interfaces';
import Selection from '@views/shared/TimeSlots/Selection';
import { SELECTION_OPTIONS } from '@views/shared/TimeSlots/helper';

const isTimeSlotActive = (value: number, { from, to }: SelectedTime) => {
  const hasStartValue = !isEmpty(from);
  const hasEndValue = !isEmpty(to);

  if (hasStartValue && hasEndValue) {
    return value >= from.startIndex && value <= to.startIndex;
  } else if (hasStartValue) {
    return value === from.startIndex;
  } else if (hasEndValue) {
    return value === to.startIndex;
  }

  return false;
};

const getFormattedSelection = ({ from, to }: SelectedTime, format: string) => {
  if (isEmpty(from)) {
    return '';
  }

  return `${getFormattedTime(from.start, format)} - ${getFormattedTime(
    !isEmpty(to) ? to.end : from.end,
    format,
  )}`;
};

const getFormattedTime = (date: MixDate, format: string) =>
  universalDateFormatter({
    date,
    format,
  });

export default function Time({
  isMeetingRoom,
  items,
  onSelect,
  selected,
}: TimeProps) {
  const {
    t,
    formats: { timeFormatShort },
  } = useIntl();
  const { midDayHour } = useMyOrganization();

  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedSelection, setSelectedSelection] = useState('');

  const onSlotSelect = useCallback(
    (slot: SelectedSlot) => {
      let { from, to } = selected;

      if (isEmpty(from)) {
        // first click
        from = slot;
      } else if (isEmpty(to)) {
        // second click
        to = slot;
      } else {
        // third click
        from = slot;
        to = {};
      }

      // if end is before start, turn around the values
      if (to.startIndex < from.startIndex) {
        const rev = from;
        from = to;
        to = rev;
      }

      onSelect(from, to);
      setSelectedSelection('');
    },
    [selected, onSelect, setSelectedSelection],
  );

  const onSelectionSelect = useCallback(
    (from, to, selection) => {
      onSelect(from, to);
      setSelectedSelection(selection);
    },
    [onSelect, setSelectedSelection],
  );

  const toggleOverlay = useCallback(
    () => setShowOverlay(prevState => !prevState),
    [setShowOverlay],
  );

  const unsetTimeSelection = useCallback(() => {
    onSelect({}, {});
    setSelectedSelection('');
    setShowOverlay(false);
  }, [onSelect, setShowOverlay]);

  const formattedTime = useCallback(
    (selected: SelectedTime) =>
      getFormattedSelection(selected, timeFormatShort),
    [timeFormatShort],
  );

  const selectedFormattedTime = formattedTime(selected);
  const isTimeSelected = !isEmpty(selectedFormattedTime);

  const firstSlot = head(items);
  const beforeMiddaySlot = items.find(
    slot => slot.endIndex === getNumberFromHours(midDayHour),
  );
  const middaySlot = items.find(
    slot => slot.startIndex === getNumberFromHours(midDayHour),
  );
  const lastSlot = last(items);

  return (
    <View style={styles.container}>
      <View style={styles.headline}>
        <View>
          <Text style={styles.title}>{t('Filter.BookingTime.Title')}</Text>
          {selected?.start ? <View style={styles.badge} /> : null}
        </View>
      </View>
      <TouchableHighlight onPress={toggleOverlay} underlayColor="transparent">
        <View style={styles.itemsContainer}>
          <View style={[styles.item, isTimeSelected && styles.activeItem]}>
            <CustomIcon name="clock" size={20} style={styles.icon} />
            <Text style={styles.itemText}>
              {t('Filter.BookingTime.Description')}
              {isTimeSelected ? `: ${selectedFormattedTime}` : null}
            </Text>
          </View>
        </View>
      </TouchableHighlight>

      {showOverlay && (
        <Overlay
          onClose={toggleOverlay}
          title={t('Filter.BookingTime.Overlay.Title')}
        >
          <>
            {!isMeetingRoom ? (
              <View style={[styles.itemsContainer, styles.itemsTimeContainer]}>
                <Selection
                  from={firstSlot.start}
                  isSelected={
                    selectedSelection === SELECTION_OPTIONS.BEFORE_LUNCH
                  }
                  onPress={() =>
                    onSelectionSelect(
                      firstSlot,
                      beforeMiddaySlot,
                      SELECTION_OPTIONS.BEFORE_LUNCH,
                    )
                  }
                  selection={SELECTION_OPTIONS.BEFORE_LUNCH}
                  showInOverlay
                  to={beforeMiddaySlot.end}
                />
                <Selection
                  from={firstSlot.start}
                  isSelected={selectedSelection === SELECTION_OPTIONS.FULL_DAY}
                  onPress={() =>
                    onSelectionSelect(
                      firstSlot,
                      lastSlot,
                      SELECTION_OPTIONS.FULL_DAY,
                    )
                  }
                  selection={SELECTION_OPTIONS.FULL_DAY}
                  showInOverlay
                  to={lastSlot.end}
                />
                <Selection
                  from={middaySlot.start}
                  isSelected={
                    selectedSelection === SELECTION_OPTIONS.AFTER_LUNCH
                  }
                  onPress={() =>
                    onSelectionSelect(
                      middaySlot,
                      lastSlot,
                      SELECTION_OPTIONS.AFTER_LUNCH,
                    )
                  }
                  selection={SELECTION_OPTIONS.AFTER_LUNCH}
                  showInOverlay
                  to={lastSlot.end}
                />
              </View>
            ) : null}
            <View style={[styles.itemsContainer, styles.itemsTimeContainer]}>
              {items.map((slot, idx) => {
                const isActive = isTimeSlotActive(slot.startIndex, selected);

                return (
                  <Item
                    key={idx}
                    isActive={isActive}
                    onPress={onSlotSelect}
                    style={styles.timeItem}
                    text={formattedTime({ from: slot, to: slot })}
                    value={slot}
                  />
                );
              })}
            </View>
            <View style={styles.overlayButtonContainer}>
              <SecondaryButton
                disabled={isEmpty(selected.from) && isEmpty(selected.to)}
                onPress={unsetTimeSelection}
                title={t('Filter.BookingTime.Overlay.Button.Cancel')}
              />

              <MainButton
                disabled={isEmpty(selected.from) && isEmpty(selected.to)}
                onPress={toggleOverlay}
                title={t('Filter.BookingTime.Overlay.Button.Apply')}
              />
            </View>
          </>
        </Overlay>
      )}
    </View>
  );
}
