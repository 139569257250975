import React, { ReactNode } from 'react';
import { View, StyleSheet, Text, TouchableHighlight } from 'react-native';

import { Loader } from '@views/shared/Loader';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import { colors, spacings, globalStyles } from '@views/shared/styles';

import { FavouriteCount } from './FavouriteCount';

const { extraSmall, smallMedium } = spacings;
const { white, blackAlpha } = colors;

export const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    padding: smallMedium,
    paddingLeft: 0,
    borderRadius: extraSmall,
    backgroundColor: white,
    alignItems: 'center',
  },
  middle: {
    flex: 1,
    paddingLeft: smallMedium,
    borderLeftWidth: 1,
    borderColor: blackAlpha,
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  left: {
    paddingHorizontal: smallMedium,
    width: 50,
  },
});

interface Props {
  icon: ReactNode;
  name: string | ReactNode;
  text?: string;
  favs?: number;
  onFavsPress?: () => void;
  onAction?: () => void;
  loading?: boolean;
}

export function Panel({
  icon,
  name,
  text = '',
  favs,
  onFavsPress,
  onAction,
  loading = false,
}: Props) {
  const isDesktop = useIsDesktop();

  return (
    <View
      style={[
        globalStyles.shadow,
        { marginBottom: spacings.medium },
        isDesktop && {
          minHeight: 61,
          justifyContent: 'center',
          width: '30%',
          marginHorizontal: 0,
        },
      ]}
    >
      {loading ? (
        <Loader />
      ) : (
        <View style={styles.row}>
          <View style={styles.left}>{icon}</View>
          <View style={styles.middle}>
            <TouchableHighlight onPress={onAction} underlayColor="transparent">
              <View>
                <Text style={globalStyles.title}>{name}</Text>
                {text.length ? (
                  <Text style={globalStyles.description}>{text}</Text>
                ) : null}
              </View>
            </TouchableHighlight>
          </View>
          {favs !== undefined ? (
            <FavouriteCount count={favs} onPress={onFavsPress} />
          ) : null}
        </View>
      )}
    </View>
  );
}
