import React, { useCallback, useState } from 'react';
import { Text, TouchableHighlight, View } from 'react-native';

import { useIntl } from '@utils/intl';
import { SecondaryButton } from '@views/shared/Button';
import { CustomIcon } from '@views/shared/CustomIcon';
import { colors } from '@views/shared/styles';
import { MAX_COUNT_DESKS, getBookingIcon } from '@views/shared/consts';

import { useHasFeature } from '@views/shared/hooks/hasFeature';
import { getDeskOrParkingLabel } from '@utils/Building';
import { useGoTo } from '@views/shared/utils/useGoTo';

import { styles } from '@views/shared/Structure';
import {
  AreaStructureForParkingSpots,
  ParkingStructure,
} from '@views/shared/interfaces/buildingStructure';

interface Props {
  area: AreaStructureForParkingSpots;
  items: ParkingStructure[];
}

const styleItem = last => ({
  borderBottomWidth: last ? 0 : 1,
  borderColor: colors.blackAlpha,
  borderRadius: 0,
});

export default function Items({ area, items }: Props) {
  const { t } = useIntl();
  const showDeskNumber = useHasFeature('desk_number_on_floor');
  const { goToBookingPage } = useGoTo();

  const { id: areaId, areaType } = area;
  const isMore = items.length > MAX_COUNT_DESKS;

  const [showAll, setShowAll] = useState(false);
  const onBook = useCallback(
    (parkingId: string) =>
      goToBookingPage({ areaId, parkingId, type: areaType }),
    [areaId, areaType, goToBookingPage],
  );
  const toggleMore = useCallback(() => setShowAll(showAll => !showAll), [
    setShowAll,
  ]);

  const textShowMore = showAll
    ? 'Home.Structure.Floor.Area.Desks.ShowLess'
    : 'Home.Structure.Floor.Area.Desks.ShowMore';

  const entries = showAll ? items : items.slice(0, MAX_COUNT_DESKS);

  return (
    <View>
      {entries.map((item: ParkingStructure, index: number) => {
        const last = index === entries.length - 1;
        const { id, type } = item;

        const buttonText = getDeskOrParkingLabel({
          areaType,
          point: item,
          t,
          showDeskNumber,
          showDeskTranslation: true,
        });

        return (
          <View key={id} style={[styles.headerContainer, styleItem(last)]}>
            <View style={styles.header}>
              <View style={styles.buttonContainer}>
                <SecondaryButton
                  onPress={() => onBook(id)}
                  title={buttonText}
                  icon={
                    <CustomIcon
                      color={colors.aquaMarine}
                      name={getBookingIcon(type)}
                      size={25}
                      style={styles.buttonIcon}
                    />
                  }
                />
              </View>
            </View>
          </View>
        );
      })}
      {isMore ? (
        <TouchableHighlight underlayColor="transparent" onPress={toggleMore}>
          <View style={[styles.more, { paddingTop: 0 }]}>
            <Text style={styles.moreText}>{t(textShowMore)}</Text>
            <View style={styles.toggle}>
              <CustomIcon
                name={showAll ? 'arrow-up' : 'arrow-down'}
                size={15}
              />
            </View>
          </View>
        </TouchableHighlight>
      ) : null}
    </View>
  );
}
