import React from 'react';

import Collapsable from '@views/Book/Favourites/List/Collapsable';
import { Loader } from '@views/shared/Loader';
import {
  AREA_TYPE_FREE_SPACES_ROOM,
  AREA_TYPE_MEETING_ROOM,
  AREA_TYPE_ROOM,
} from '@views/shared/consts';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { useIntl } from '@utils/intl';

import { AreaType } from '@views/shared/interfaces/buildingStructure';

import { useAreaList, useDeskList } from './hooks';

interface Props {
  ids: string[];
  type: AreaType;
}

const itemsByTypeMap = {
  [AREA_TYPE_FREE_SPACES_ROOM]: useAreaList,
  [AREA_TYPE_MEETING_ROOM]: useAreaList,
  [AREA_TYPE_ROOM]: useDeskList,
};

const errorMessageByTypeMap = {
  [AREA_TYPE_FREE_SPACES_ROOM]: 'Home.Favourites.Error.NoFreeSpaceAreas',
  [AREA_TYPE_MEETING_ROOM]: 'Home.Favourites.Error.NoMeetingRooms',
  [AREA_TYPE_ROOM]: 'Home.Favourites.Error.NoDesks',
};

export default function List({ ids, type }: Props) {
  const { t } = useIntl();
  const { items, error, loading } = itemsByTypeMap[type](ids);
  if (loading) {
    return <Loader />;
  }

  if (error || !items.length) {
    return <UnexpectedNoDataOrError message={t(errorMessageByTypeMap[type])} />;
  }

  return <Collapsable items={items} type={type} />;
}
