import { defineMessages } from 'react-intl';
import { messages as overviewMessages } from './Overview';
import { messages as editMessages } from './Edit';

// this will be not export, but formatjs can read this and generate our basic translations JSON File
// eslint-disable-next-line no-unused-vars
const dictionary = defineMessages({
  'Profile.title': {
    id: 'Profile.title',
    defaultMessage: 'Profile',
  },
  'Profile.Content.Logout.text': {
    id: 'Profile.Content.Logout.text',
    defaultMessage: 'Logout',
  },
  'Profile.Content.PrivacyPolicy.link': {
    id: 'Profile.Content.PrivacyPolicy.link',
    defaultMessage: 'Privacy Policy',
  },
  'Profile.Actions.Title': {
    id: 'Profile.Actions.Title',
    defaultMessage: 'Your action is required',
  },
  'Profile.Actions.Select.Organization': {
    id: 'Profile.Actions.Select.Organization',
    defaultMessage:
      'Unfortunately, we found out that several organizations are created in your company. Please select the right one for you.',
  },
  'Profile.Actions.Organization.Saved': {
    id: 'Profile.Actions.Organization.Saved',
    defaultMessage: 'The organisation is saved. The changes will appear soon',
  },
  'Profile.Content.Language.Title': {
    id: 'Profile.Content.Language.Title',
    defaultMessage: 'Application language',
  },
  'Profile.Content.Attendance.Title': {
    id: 'Profile.Content.Attendance.Title',
    defaultMessage: 'History of Attendances',
  },
  'Profile.Attendances.Title': {
    id: 'Profile.Attendances.Title',
    defaultMessage: 'My Attendances',
  },
  'Profile.Content.Hello': {
    id: 'Profile.Content.Hello',
    defaultMessage: 'Hello {name}',
  },
  'Profile.Content.Welcome': {
    id: 'Profile.Content.Welcome',
    defaultMessage: 'Welcome',
  },
  'Profile.NoAccess': {
    id: 'Profile.NoAccess',
    defaultMessage: 'We are sorry but this section is not available for you',
  },
});

export default {
  ...dictionary,
  ...overviewMessages,
  ...editMessages,
};
