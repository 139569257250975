import React from 'react';
import { ScrollView, View, Text } from 'react-native';

import { useIntl } from '@utils/intl';
import UnexpectedNoDataOrError from '@views/shared/UnexpectedNoDataOrError';
import { ColleagueListCard } from '@views/Colleagues/List/ColleagueListCard';
import { Loader } from '@views/shared/Loader';

import { useIsDesktop } from '@views/shared/hooks/isDesktop';
import {
  spacings,
  globalStyles,
  globalStylesDesktop,
} from '@views/shared/styles';

import { useOrganizationUsersByFavourites } from './hooks';

const { smallMedium } = spacings;

export default function FavouritesView() {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();
  const { data, count, loading, error } = useOrganizationUsersByFavourites();

  if (error) {
    return <UnexpectedNoDataOrError />;
  }

  return (
    <ScrollView>
      <View style={globalStyles.container}>
        <View style={globalStyles.shadow}>
          {loading ? (
            <Loader />
          ) : data.length ? (
            <>
              <View style={{ padding: smallMedium }}>
                <Text style={globalStyles.title}>{`${count} ${t(
                  'Colleagues.List.Colleagues',
                )}`}</Text>
              </View>
              <View style={isDesktop && globalStylesDesktop.bookingsContainer}>
                {data.map((colleague, index) => (
                  <ColleagueListCard key={index} colleague={colleague} />
                ))}
              </View>
            </>
          ) : (
            <Text style={globalStyles.noDataText}>
              {t('Colleagues.Favourites.EmptyList')}
            </Text>
          )}
        </View>
      </View>
    </ScrollView>
  );
}
