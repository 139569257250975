import { LocaleConfig } from 'react-native-calendars';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { useIntl } from 'react-intl';
import { StyleSheet } from 'react-native';
import { isNil } from 'ramda';

import { colors, fonts, spacings } from '@views/shared/styles';
import {
  dateFormatShort,
  getTodayAsDate,
  universalDateFormatter,
} from '@utils/DateAndTime';

dayjs.extend(localeData);

const getDateIdx = date =>
  universalDateFormatter({
    date,
    format: dateFormatShort,
  });

export function LoadCalenderConfig() {
  const { locale } = useIntl();

  LocaleConfig.locales[locale] = {
    monthNames: dayjs()
      .localeData()
      .months(),
    monthNamesShort: dayjs()
      .localeData()
      .monthsShort(),
    dayNames: dayjs()
      .localeData()
      .weekdays(),
    dayNamesShort: dayjs()
      .localeData()
      .weekdaysShort(),
  };
  LocaleConfig.defaultLocale = locale;
}

const { extraSmall, small } = spacings;
const { sizeDefault } = fonts;

export const styles = StyleSheet.create({
  overlay: {
    paddingBottom: small,
    padding: extraSmall,
    paddingTop: 0,
  },
  apply: {
    alignSelf: 'center',
    marginBottom: small,
  },
});

export const theme = {
  textMonthFontFamily: 'mrt-semi-bold',
  textDayFontFamily: 'mrt-semi-bold',
  textDayFontSize: sizeDefault,
  textDayHeaderFontSize: sizeDefault,
  selectedDayBackgroundColor: colors.aquaMarine,
  'stylesheet.calendar.header': {
    header: {
      marginTop: small,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  textStyles: (isDayEnabled: boolean) => ({
    fontFamily: 'mrt-semi-bold',
    fontSize: sizeDefault,
    textDecorationLine: !isDayEnabled ? 'line-through' : 'none',
  }),
};

const calculatePeriod = (start, end) => {
  const period = {};
  let current = start;
  while (current < end) {
    const date = getDateIdx(current);
    period[date] = {
      color: colors.aquaMarine,
      textColor: colors.white,
      startingDay: current === start,
    };
    current += 24 * 60 * 60 * 1000;
  }
  const date = getDateIdx(end);

  period[date] = {
    color: colors.aquaMarine,
    textColor: colors.white,
    endingDay: true,
  };

  return period;
};

export const getPeriod = ({ start, end }) => {
  const range = {};

  const today = getDateIdx(getTodayAsDate());

  range[today] = {
    textColor: colors.aquaMarine,
  };

  if (isNil(end)) {
    const date = getDateIdx(start);

    range[date] = {
      color: colors.aquaMarine,
      textColor: colors.white,
      endingDay: true,
      startingDay: true,
    };

    return range;
  } else {
    // if end date is older than start date switch
    if (start > end) {
      return { ...range, ...calculatePeriod(end, start) };
    } else {
      return { ...range, ...calculatePeriod(start, end) };
    }
  }
};
