import { useCallback, useState } from 'react';
import { gql } from '@apollo/client';
import { useIntl, defineMessages } from 'react-intl';
import { useNavigation } from '@react-navigation/native';

import { useErrorMutation } from '@providers/Errors';
import {
  BOOKING_TYPE_FREE_SPACES_ROOM,
  BOOKING_TYPE_MEETING_ROOM,
  BOOKING_TYPE_ROOM,
} from '@views/shared/consts';
import { useMyUser } from '@providers/User';

import { BadgeType, FavType } from './interface';

const i18nMessages = defineMessages({
  'Favourites.fetchError': {
    id: 'Favourites.fetchError',
    defaultMessage: 'Could not fetch favourites',
  },
  'Favourites.addError': {
    id: 'Favourites.addError',
    defaultMessage: 'Could not add favourite',
  },
  'Favourites.removeError': {
    id: 'Favourites.removeError',
    defaultMessage: 'Could not remove favourite',
  },
});

// TODO: Refactoring needed
export function useFavourites(type?: BadgeType) {
  const navigation = useNavigation<any>();
  const {
    favouriteDesks,
    favouriteMeetingRooms,
    favouriteSeats,
    favouriteColleagues,
    refetchFavourites,
  } = useMyUser();

  const goToFavourites = useCallback(
    () => navigation.navigate('favourites', { type }),
    [navigation, type],
  );

  const getFavCount = (type: BadgeType) =>
    ({
      [BOOKING_TYPE_ROOM]: favouriteDesks.length,
      [BOOKING_TYPE_FREE_SPACES_ROOM]: favouriteSeats.length,
      [BOOKING_TYPE_MEETING_ROOM]: favouriteMeetingRooms.length,
      ['colleague']: favouriteColleagues.length,
    }[type]);

  return {
    favCount: type ? getFavCount(type) : undefined,
    favDesks: favouriteDesks,
    favSeats: favouriteSeats,
    favMeetingRooms: favouriteMeetingRooms,
    favColleagues: favouriteColleagues,
    goToFavourites,
    refetch: refetchFavourites,
  };
}

const addFavouriteMutation = gql`
  mutation addFavourite($id: ID!, $type: FavouriteTypeEnum!) {
    addFavourite(favouriteId: $id, favouriteType: $type)
  }
`;

export function useAddFavourite(): (id: string, type: FavType) => Promise<any> {
  const intl = useIntl();
  const [add] = useErrorMutation<any, { id: string; type: FavType }>(
    addFavouriteMutation,
    {
      finderError: {
        type: 'minor',
        message: intl.formatMessage(i18nMessages['Favourites.addError']),
      },
    },
  );

  return useCallback(
    (id: string, type: FavType) => add({ variables: { id, type } }),
    [add],
  );
}

const removeFavouriteMutation = gql`
  mutation removeFavourite($id: ID!, $type: FavouriteTypeEnum!) {
    removeFavourite(favouriteId: $id, favouriteType: $type)
  }
`;

export function useRemoveFavourite(): (
  id: string,
  type: FavType,
) => Promise<any> {
  const intl = useIntl();
  const [remove] = useErrorMutation<any, { id: string; type: FavType }>(
    removeFavouriteMutation,
    {
      finderError: {
        type: 'minor',
        message: intl.formatMessage(i18nMessages['Favourites.removeError']),
      },
    },
  );

  return useCallback(
    (id: string, type: FavType) => remove({ variables: { id, type } }),
    [remove],
  );
}

const mapFavType = {
  room: 'desk',
  free_spaces_room: 'area',
  meeting_room: 'area',
  colleague: 'colleague',
};

export function useFavouriteBadge(id: string, type: BadgeType) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const removeFavourite = useRemoveFavourite();
  const addFavourite = useAddFavourite();
  const {
    favDesks,
    favMeetingRooms,
    favSeats,
    favColleagues,
    refetch,
  } = useFavourites(type);

  const checkSelected = (type: BadgeType) =>
    ({
      [BOOKING_TYPE_ROOM]: favDesks.includes(id),
      [BOOKING_TYPE_FREE_SPACES_ROOM]: favSeats.includes(id),
      [BOOKING_TYPE_MEETING_ROOM]: favMeetingRooms.includes(id),
      ['colleague']: favColleagues.includes(id),
    }[type]);

  const isSelected = checkSelected(type);

  const favType = mapFavType[type] as FavType;
  const toggle = useCallback(async () => {
    setIsLoading(true);

    if (isSelected) {
      await removeFavourite(id, favType).finally(() => setIsLoading(false));
    } else {
      await addFavourite(id, favType).finally(() => setIsLoading(false));
    }

    await refetch();
  }, [isSelected, removeFavourite, addFavourite, refetch, id, favType]);

  return {
    isSelected,
    toggle: isLoading ? null : toggle,
  };
}
