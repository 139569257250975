import { useState, useCallback } from 'react';

import { SelectItem } from './interfaces';

export function useSelect(items: SelectItem[], value?: string | null) {
  const [shown, setShown] = useState(false);
  const toggleShow = useCallback(() => setShown((shown) => !shown), [setShown]);
  const show = useCallback(() => setShown(true), [setShown]);
  const hide = useCallback(() => setShown(false), [setShown]);
  const selectedItem = items.find((item) => item.value === value);

  return {
    shown,
    toggleShow,
    show,
    hide,
    selectedItem,
  };
}
