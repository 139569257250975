import React from 'react';
import { Dimensions, StyleSheet, View, Text } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';

import { useIntl } from '@utils/intl';
import { withLizTitle } from '@views/shared/utils';
import { MainButton } from '@views/shared/Button';
import { topBarNavigatorStyles } from '@views/shared/styles';

import { Overview } from './Overview';
import { Bookings } from './Bookings';
import { useProfile } from './hooks';

const Tab = createMaterialTopTabNavigator();

const tabBarNavigatorOptions = {
  style: topBarNavigatorStyles.navigator,
  indicatorStyle: topBarNavigatorStyles.indicator,
  tabStyle: { flex: 1, padding: 0 },
};

const styles = StyleSheet.create({
  containerNoProfile: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 15,
    marginVertical: 20,
  },
});

export function Profile() {
  const { t } = useIntl();
  const {
    id,
    colleague,
    goToList,
    findMyColleagueEnabled,
    showColleagueBookings,
  } = useProfile();

  if (!id) {
    return (
      <View style={styles.containerNoProfile}>
        <MainButton title={t('Colleagues.reload_failed')} onPress={goToList} />
      </View>
    );
  }

  return (
    <View style={topBarNavigatorStyles.container}>
      <Tab.Navigator
        backBehavior="history"
        tabBarOptions={tabBarNavigatorOptions}
        sceneContainerStyle={topBarNavigatorStyles.sceneContainer}
        lazy
        initialLayout={{ width: Dimensions.get('window').width }}
        swipeEnabled={false}
        screenOptions={{ unmountOnBlur: true }}
      >
        <Tab.Screen
          options={() => {
            return {
              tabBarLabel: ({ focused }) => (
                <Text
                  style={[
                    topBarNavigatorStyles.tabLabel,
                    focused && topBarNavigatorStyles.tabLabelActive,
                  ]}
                >
                  {t('Colleague.Screen.overview')}
                </Text>
              ),
              title: withLizTitle(t('Colleague.Screen.overview')),
            };
          }}
          name={t('Colleague.Screen.overview')}
        >
          {() => (
            <Overview
              id={id}
              colleague={colleague}
              findMyColleagueEnabled={findMyColleagueEnabled}
            />
          )}
        </Tab.Screen>
        {showColleagueBookings && findMyColleagueEnabled && (
          <Tab.Screen
            options={() => {
              return {
                tabBarLabel: ({ focused }) => (
                  <Text
                    style={[
                      topBarNavigatorStyles.tabLabel,
                      focused && topBarNavigatorStyles.tabLabelActive,
                    ]}
                  >
                    {t('Colleague.Screen.bookings')}
                  </Text>
                ),
                title: withLizTitle(t('Colleague.Screen.bookings')),
              };
            }}
            name={t('Colleague.Screen.bookings')}
          >
            {() => <Bookings id={id} />}
          </Tab.Screen>
        )}
      </Tab.Navigator>
    </View>
  );
}
