import { gql } from '@apollo/client';
import { pathOr } from 'ramda';

import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';
import getWorklightStatus from '@views/shared/utils/getWorklightStatus';
import { useGoTo } from '@views/shared/utils/useGoTo';
import { useMyBuildings } from '@providers/Buildings';
//import { useFavourites } from '@views/Favourites/hooks';
import { useCurrentDate } from '@views/Calendar/hooks';
import { useErrorQuery } from '@providers/Errors';
import { BookCategory } from '@views/shared/interfaces/booking';

const getTelefonBox = gql`
  query getBuildingTelefonBox($id: ID!, $date: Date!) {
    building: BuildingCountTelefonBox(buildingId: $id, date: $date) {
      numberOfTelefonBoxes
      telefonBoxTimeStats {
        booked
        total
      }
    }
  }
`;

interface Data {
  building: {
    numberOfTelefonBoxes: number;
    telefonBoxTimeStats: {
      booked: number;
      total: number;
    };
  };
}

export function useTelefonBox(type: BookCategory, errorMessage) {
  const date = useCurrentDate();
  //const { favCount, goToFavourites } = useFavourites(type);
  const { selectedBuildingId: buildingId } = useMyBuildings();
  const { goToStructurePage } = useGoTo();

  const { data, loading, error } = useErrorQuery<Data>(getTelefonBox, {
    variables: {
      id: buildingId,
      date: universalDateFormatter({ date, format: dateFormatShort }),
    },
    skip: !buildingId,
    finderError: {
      type: 'fatal',
      message: errorMessage,
    },
  });
  const numberOfTelefonBoxes = data?.building.numberOfTelefonBoxes;

  const booked = pathOr(0, ['building', 'telefonBoxTimeStats', 'booked'], data);
  const total = pathOr(0, ['building', 'telefonBoxTimeStats', 'total'], data);
  const free = total - booked;

  return {
    numberOfTelefonBoxes,
    loading: !buildingId || loading,
    error,
    goToStructure: () => goToStructurePage(type),
    worklight: getWorklightStatus(free, total),
  };
}
