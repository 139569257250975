import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity } from 'react-native';
import { compose, isNil, find, sortWith, descend, prop } from 'ramda';

import { useMyOrganization } from '@providers/Organization';

import { useIntl } from '@utils/intl';
import {
  universalDateFormatter,
  toHoursAndMinutes,
  toLocalTime,
  isDayExpired,
  isToday,
} from '@utils/DateAndTime';
import { CustomIcon } from '@views/shared/CustomIcon';
import { SecondaryButton } from '@views/shared/Button';
import {
  globalStyles,
  fonts,
  spacings,
  colors,
  globalStylesDesktop,
} from '@views/shared/styles';
import { useIsDesktop } from '@views/shared/hooks/isDesktop';

const {
  tiny,
  smallMedium,
  small,
  extraSmall,
  medium,
  large,
  smaller,
} = spacings;
const { blackAlpha, aquaMarine, grey9, warmPink, paleOrange, grey7 } = colors;

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  item: {
    paddingVertical: smallMedium,
    borderTopWidth: 1,
    borderColor: blackAlpha,
  },
  itemLast: {
    borderBottomWidth: 1,
  },
  highlight: {
    color: aquaMarine,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderLeftColor: aquaMarine,
    borderLeftWidth: tiny,
  },
  right: {
    justifyContent: 'flex-end',
    flex: 1,
    paddingLeft: medium,
  },
  left: {
    paddingHorizontal: smallMedium,
    justifyContent: 'center',
    alignItems: 'center',
    borderRightWidth: 1,
    borderRightColor: blackAlpha,
    alignSelf: 'stretch',
    width: 50,
  },
  dateText: {
    fontSize: fonts.sizeTitle,
    fontWeight: 'bold',
  },
  monthText: {
    color: grey9,
    fontSize: fonts.sizeSemiSmall,
  },
  presenceText: {
    fontSize: fonts.sizeSemiSmall,
    fontWeight: 'bold',
    fontFamily: 'mrt-semi-bold',
  },
  iconStyle: {
    marginRight: medium,
  },
  missedAttendance: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  date: {
    fontSize: fonts.sizeMedium,
    fontFamily: 'mrt-semi-bold',
    marginBottom: spacings.smallMedium,
    borderBottomColor: aquaMarine,
    borderBottomWidth: spacings.tiniest,
    paddingBottom: spacings.tiniest,
  },
  buttonDesktop: {
    borderColor: grey7,
    marginBottom: medium,
    width: '90%',
    alignSelf: 'center',
  },
  buttonDesktopText: {
    color: grey9,
    fontSize: fonts.sizeDefault,
  },
  disabledButtonDesktop: {
    borderWidth: 1,
    borderRadius: extraSmall,
    marginBottom: medium,
    width: '90%',
    alignSelf: 'center',
  },
  disabledButtonDesktopText: {
    fontSize: fonts.sizeDefault,
    alignSelf: 'center',
    paddingVertical: extraSmall,
    fontFamily: 'mrt-semi-bold',
    textTransform: 'uppercase',
  },
  inProgress: {
    borderColor: paleOrange,
  },
  inProgressText: {
    color: paleOrange,
  },
  reject: {
    borderColor: warmPink,
  },
  rejectText: {
    color: warmPink,
  },
  approved: {
    borderColor: aquaMarine,
  },
  approvedText: {
    color: aquaMarine,
  },
  attendanceCommentDesktop: {
    paddingVertical: extraSmall,
    paddingHorizontal: small,
    borderBottomRightRadius: extraSmall,
    borderBottomLeftRadius: extraSmall,
  },
});

const statusMap = {
  OFFICE: 'Colleagues.List.Screen.Office',
  MEETING: 'Home.Attendance.HistoricView.Meeting',
  HOMEOFFICE: 'Colleagues.List.Screen.Home_office',
  TRAVELDAY: 'Colleagues.List.Screen.Travel_day',
  VACATION: 'Home.ActiveBookings.Booking.title.vacation',
  SICKDAY: 'Home.ActiveBookings.Booking.title.sickday',
  ABSENT: 'Home.Attendance.HistoricView.Work',
};

export default function DailyAttendance({
  date,
  attendance,
  last,
  isHistoricView,
  onEdit,
  numberOfDays,
}) {
  const { t } = useIntl();
  const isDesktop = useIsDesktop();

  const notFuture = isDayExpired(date);
  const isTodayDate = isToday(date);
  const widthForDesktop = `${100 / numberOfDays}%`;

  const emptyItem = {
    id: '',
    startTime: null,
    endTime: null,
    date: date,
    attendanceBreaks: [],
  };

  return (
    <View
      style={[
        styles.item,
        last && !isDesktop && styles.itemLast,
        isDesktop && [
          { width: widthForDesktop, borderTopWidth: 0 },
          globalStylesDesktop.desktopConWithBorder,
          last && { borderRightWidth: 0 },
        ],
      ]}
    >
      <View style={[isDesktop ? { alignItems: 'center' } : styles.row]}>
        <DateDisplayForBookingsTab
          isDesktop={isDesktop}
          date={date}
          isToday={isTodayDate}
        />
        <View
          style={[styles.right, isDesktop && { width: '100%', paddingLeft: 0 }]}
        >
          {attendance.length ? (
            <>
              {attendance.map((item, idx) => (
                <View key={item.id}>
                  <AttendanceItem
                    firstItem={idx === 0}
                    item={item}
                    isHistoricView={isHistoricView}
                    onEdit={onEdit}
                    isToday={isTodayDate}
                    isDesktop={isDesktop}
                  />
                  {item.attendanceBreaks.length
                    ? item.attendanceBreaks.map(breakItem =>
                        breakItem.totalTimeMinutes > 0 ? (
                          <AttendanceItem
                            isHistoricView={isHistoricView}
                            key={breakItem.id}
                            item={breakItem}
                            isToday={isToday}
                            isDesktop={isDesktop}
                          />
                        ) : null,
                      )
                    : null}
                </View>
              ))}
              <View style={isDesktop && styles.attendanceCommentDesktop}>
                <AttendanceComment
                  attendance={attendance}
                  isHistoricView={isHistoricView}
                  t={t}
                  isDesktop={isDesktop}
                />
              </View>
            </>
          ) : (
            <View style={!isDesktop && styles.missedAttendance}>
              {isHistoricView && !isDesktop && notFuture && !isTodayDate ? (
                <TouchableOpacity onPress={() => onEdit(emptyItem, 'add')}>
                  <CustomIcon
                    style={[
                      styles.iconStyle,
                      { marginLeft: smaller, marginRight: large },
                    ]}
                    name="plus"
                    size={14}
                  />
                </TouchableOpacity>
              ) : null}
              {isHistoricView && isDesktop && notFuture && !isTodayDate ? (
                <SecondaryButton
                  buttonStyle={styles.buttonDesktop}
                  titleStyle={styles.buttonDesktopText}
                  title={'+'}
                  onPress={() => onEdit(emptyItem, 'add')}
                />
              ) : null}
              <Text
                style={[
                  globalStyles.description,
                  { marginTop: 0 },
                  isDesktop && { alignSelf: 'center' },
                ]}
              >
                {t('Home.Attendance.HistoricView.NoRecord')}
              </Text>
            </View>
          )}
        </View>
      </View>
    </View>
  );
}

export function WeekDate({ date, isToday }) {
  const {
    formats: { dayWeekFormatShort, dateOnlyFormat, monthFormat },
  } = useIntl();

  return (
    <View style={styles.left}>
      <Text style={[styles.monthText, isToday && styles.highlight]}>
        {universalDateFormatter({ date, format: dayWeekFormatShort })}
      </Text>
      <Text style={[styles.dateText, isToday && styles.highlight]}>
        {universalDateFormatter({ date, format: dateOnlyFormat })}
      </Text>
      <Text style={[styles.monthText, isToday && styles.highlight]}>
        {universalDateFormatter({ date, format: monthFormat })}
      </Text>
    </View>
  );
}

export function DateDisplayForBookingsTab({ isDesktop, date, isToday }) {
  const {
    formats: { dayFullFormat },
  } = useIntl();

  const dateForDesktop = universalDateFormatter({
    date: date,
    format: dayFullFormat,
  });

  return isDesktop ? (
    <Text style={[styles.date, isToday && styles.highlight]}>
      {dateForDesktop}
    </Text>
  ) : (
    <WeekDate date={date} isToday={isToday} />
  );
}

function AttendanceItem({
  firstItem,
  item,
  isHistoricView,
  onEdit,
  isToday,
  isDesktop,
}) {
  const {
    t,
    formats: { timeFormat },
  } = useIntl();

  const {
    attendanceId,
    totalTimeMinutes,
    totalWorkMinutes,
    presenceStatus,
    status,
    startTime,
    endTime,
  } = item;

  const { hrSystem } = useMyOrganization();
  const isEditable = hrSystem === 'LTX';

  const breakRow = !!attendanceId;

  const totalTime = breakRow ? totalTimeMinutes : totalWorkMinutes;

  const { hours, minutes } = toHoursAndMinutes(totalTime);

  const icon =
    status === 'APPROVED' ? (
      <CustomIcon
        style={styles.iconStyle}
        color={aquaMarine}
        name="checkmark"
        size={20}
      />
    ) : status === 'REJECTED' ? (
      <CustomIcon
        style={styles.iconStyle}
        color={warmPink}
        name="close"
        size={20}
      />
    ) : status === 'IN_PROGRESS' ? (
      <CustomIcon
        style={styles.iconStyle}
        color={paleOrange}
        name="clock"
        size={20}
      />
    ) : ['OPEN', 'ON_BREAK'].includes(status) && (isToday || !isEditable) ? (
      <CustomIcon
        style={styles.iconStyle}
        color={paleOrange}
        name="clock"
        size={20}
      />
    ) : status === 'OPEN' && !isToday && isEditable ? (
      <TouchableOpacity onPress={() => onEdit(item, 'update')}>
        <CustomIcon style={styles.iconStyle} name="edit" size={20} />
      </TouchableOpacity>
    ) : null;

  const iconForDesktop =
    status === 'APPROVED' ? (
      <View style={[styles.disabledButtonDesktop, styles.approved]}>
        <Text style={[styles.disabledButtonDesktopText, styles.approvedText]}>
          {t('Home.Attendance.Approved')}
        </Text>
      </View>
    ) : status === 'REJECTED' ? (
      <View style={[styles.disabledButtonDesktop, styles.reject]}>
        <Text style={[styles.disabledButtonDesktopText, styles.rejectText]}>
          {t('Home.Attendance.Rejected')}
        </Text>
      </View>
    ) : status === 'IN_PROGRESS' ? (
      <View style={[styles.disabledButtonDesktop, styles.inProgress]}>
        <Text style={[styles.disabledButtonDesktopText, styles.inProgressText]}>
          {t('Home.Attendance.HistoricView.Progress')}
        </Text>
      </View>
    ) : status === 'OPEN' && (isToday || !isEditable) ? (
      <View style={[styles.disabledButtonDesktop, styles.inProgress]}>
        <Text style={[styles.disabledButtonDesktopText, styles.inProgressText]}>
          {t('Home.Attendance.HistoricView.Progress')}
        </Text>
      </View>
    ) : status === 'OPEN' && !isToday && isEditable ? (
      <SecondaryButton
        buttonStyle={styles.buttonDesktop}
        titleStyle={styles.buttonDesktopText}
        title={'UPDATE'}
        onPress={() => onEdit(item, 'update')}
      />
    ) : null;

  const trsHours =
    hours > 0
      ? `${hours} ${t(
          hours === 1
            ? 'Home.Attendance.Layer.Hour.Short'
            : 'Home.Attendance.Layer.Hours.Short',
        )}`
      : '';

  const trsMinutes =
    minutes > 0
      ? `${hours > 0 ? ' ' : ''}${minutes} ${t(
          'Home.Attendance.Layer.Minutes.Short',
        )}`
      : null;

  const start = universalDateFormatter({
    date: toLocalTime(startTime),
    format: timeFormat,
  });

  const end = ['IN_PROGRESS', 'ON_BREAK'].includes(status)
    ? t('Home.Attendance.HistoricView.Progress')
    : universalDateFormatter({
        date: toLocalTime(endTime),
        format: timeFormat,
      });

  return (
    <View
      style={isDesktop && [!firstItem && !breakRow && { marginTop: small }]}
    >
      {isHistoricView && firstItem && isDesktop && (
        <View style={{ backgroundColor: 'white' }}>{iconForDesktop}</View>
      )}
      <View
        style={[
          styles.container,
          isHistoricView && !isDesktop && (breakRow || !firstItem)
            ? { marginLeft: 34 }
            : {},
          isDesktop
            ? { paddingVertical: extraSmall, paddingHorizontal: small }
            : { marginVertical: tiny },
        ]}
      >
        <View style={styles.container}>
          {isHistoricView && firstItem && !isDesktop ? icon : null}
          <View>
            <Text style={globalStyles.title}>
              {breakRow
                ? t('Home.Attendance.HistoricView.Break')
                : presenceStatus
                ? t(statusMap[presenceStatus])
                : t('Home.Attendance.HistoricView.Work')}
            </Text>

            <View style={styles.container}>
              <Text style={[globalStyles.description, { marginTop: 0 }]}>
                {`${start} - ${end}`}
              </Text>
            </View>
          </View>
        </View>
        <Text style={globalStyles.title}>
          {trsHours}
          {trsMinutes}
        </Text>
      </View>
    </View>
  );
}

function AttendanceComment({ attendance, t, isHistoricView, isDesktop }) {
  const { comment } =
    compose(
      find(({ comment }) => comment && comment.length > 0),
      sortWith([descend(compose(parseInt, prop('id')))]),
    )(attendance) || {};

  if (isNil(comment)) {
    return null;
  }

  const text =
    comment === 'Attendance automatically ended at end of day'
      ? t('Home.Attendance.HistoricView.SystemEnd')
      : comment;

  return (
    <Text
      style={[
        globalStyles.description,
        { marginTop: tiny },
        isHistoricView && !isDesktop && { marginLeft: 34 },
      ]}
    >
      {text}
    </Text>
  );
}
