import React, { CSSProperties, ReactNode } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableHighlight,
  ViewStyle,
} from 'react-native';

import { globalStyles } from '@views/shared/styles';
import { CustomIcon } from '@views/shared/CustomIcon';

import { useAccordion } from './hooks';

export interface AccordionProps {
  children: ReactNode;
  title: ReactNode;
  onToggle?: (isOpen: boolean) => void;
  containerStyle?: ViewStyle;
  headerStyle?: ViewStyle;
  childrenStyle?: ViewStyle;
  open?: boolean;
  openStyle?: undefined | CSSProperties;
}

export function Accordion({
  children,
  title,
  onToggle,
  containerStyle,
  headerStyle,
  childrenStyle,
  open,
  openStyle,
}: AccordionProps) {
  const { isOpen, toggle } = useAccordion(open, onToggle);
  const headStyle = StyleSheet.flatten([headerStyle, isOpen ? openStyle : {}]);

  return (
    <View style={containerStyle}>
      <TouchableHighlight onPress={toggle} underlayColor="transparent">
        <View style={headStyle}>
          {typeof title === 'string' ? (
            <Text style={globalStyles.title}>{title}</Text>
          ) : (
            title
          )}
          <View
            style={{
              alignSelf: 'center',
            }}
          >
            <CustomIcon name={isOpen ? 'arrow-up' : 'arrow-down'} size={15} />
          </View>
        </View>
      </TouchableHighlight>
      {isOpen && <View style={childrenStyle}>{children}</View>}
    </View>
  );
}
