import React from 'react';
import { View } from 'react-native';
import { compose, keys, pickBy, find, mapObjIndexed } from 'ramda';

import { useMyUser } from '@providers/User';
import { dateFormatShort, universalDateFormatter } from '@utils/DateAndTime';

import Slot from './Slot';
import useValues from './hooks';

import { AvailableDayBooking, WeekDaysProps } from '../interfaces';

// this can be expanded for other statuses of the booking
const BOOKING_STATUS = {
  FREE: 'FREE',
  BOOKING_OTHER: 'BOOKING_OTHER',
};

const getAvailableBookings = (
  day: string,
  bookings: any,
  isMultiBooker: boolean,
) =>
  compose(
    keys,
    pickBy(val => val === BOOKING_STATUS.FREE),
    mapObjIndexed((val, key) =>
      isMultiBooker && key === 'desk' && val === BOOKING_STATUS.BOOKING_OTHER
        ? BOOKING_STATUS.FREE
        : val,
    ),
    find(({ date }) => date === day),
  )(bookings) as AvailableDayBooking[];

export default function WeekDays({
  currentBookedType,
  onSelect,
  params = {},
}: WeekDaysProps) {
  const { isMultiBooker } = useMyUser();

  const { data, bookableDays, isDisabledToBook, setSlot } = useValues({
    onSelect,
    params,
  });

  return (
    <View>
      {bookableDays.map((day, index) => {
        const date = universalDateFormatter({
          date: day,
          format: dateFormatShort,
        });
        const availableDayBookings = getAvailableBookings(
          date,
          data,
          isMultiBooker,
        );

        return (
          <Slot
            availableDayBookings={availableDayBookings}
            currentBookedType={currentBookedType}
            date={date}
            disabledToBook={isDisabledToBook(date)}
            key={index}
            onSlotSelect={setSlot}
          />
        );
      })}
    </View>
  );
}
