import { useState, useEffect } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { flatten, isEmpty } from 'ramda';

import { MixDate } from '@utils/DateAndTime';
import { SLOT_STATUS } from '@views/shared/TimeSlots/helper';

import { TimeSlot, Colleague } from '@views/shared/TimeSlots/interfaces';

interface Data {
  colleague: Colleague;
}

const getColleague = gql`
  query getColleague($userID: ID!) {
    colleague(userId: $userID) {
      id
      email
      name
      initials
      avatar
      phoneNumber
      licensePlate
      department {
        id
        name
      }
      visibleForColleagues
    }
  }
`;

interface TimeWithColleague {
  end: MixDate;
  start: MixDate;
  colleagues: Colleague[];
}

export function useInfos({ slot }: { slot: TimeSlot }) {
  const client = useApolloClient();

  const [unavailableTimePeriods, setUnavailableTimePeriods] = useState<
    TimeWithColleague[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    if (!isEmpty(slot) && slot.status === SLOT_STATUS.BOOKED_BY_COLLEAGUE) {
      setLoading(true);
      const request = flatten(
        slot.userId.map(userID =>
          client.query<Data>({
            query: getColleague,
            fetchPolicy: 'no-cache',
            variables: {
              userID,
            },
          }),
        ),
      );

      if (request.length) {
        Promise.all(request)
          .then(response => {
            const colleaguesData = flatten(
              response.map(dataXHR => dataXHR.data?.colleague),
            )
              // remove undefined
              .filter(colleagueData => colleagueData)
              // normalize the type
              .map(colleagueData => colleagueData as Colleague);

            const { start, end, userId } = slot;
            const colleagues = userId
              .map(userID =>
                colleaguesData.find(
                  colleague => colleague && userID === colleague.id,
                ),
              )
              // remove undefined
              .filter(colleagueData => colleagueData)
              // normalize the type
              .map(colleagueData => colleagueData as Colleague);

            const timeWithColleague = [{ start, end, colleagues }];

            setUnavailableTimePeriods(timeWithColleague);
            setLoading(false);
          })
          .catch(e => {
            setLoading(false);
            setError(e);
          });
      }
    }
  }, [client, slot]);

  return {
    error,
    loading,
    unavailableTimePeriods,
  };
}
