import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import { useIsDesktop } from '@views/shared/hooks/isDesktop';

import Main from '@views/Home/Profile/Overview/Main';
import { Edit } from '@views/Home/Profile/Edit';

const Stack = createStackNavigator();

export function Overview() {
  const isDesktop = useIsDesktop();

  return (
    <>
      {isDesktop ? (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="overview" component={Main} />
          <Stack.Screen name="edit" component={Edit} />
        </Stack.Navigator>
      ) : (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="index" component={Main} />
        </Stack.Navigator>
      )}
    </>
  );
}
