import React from 'react';
import { View } from 'react-native';

import Items from './Items';
import { ItemTitle } from './Order';
import useItems from './hooks';
import { styles } from './styles';
import { OptionalOrderProps } from './types';

export default function AdditionalOrder(props: OptionalOrderProps) {
  const { enabledCateringBooking, enabledEquippingBooking } = props;

  if (!enabledCateringBooking && !enabledEquippingBooking) {
    return null;
  }

  return <Component {...props} />;
}

const Component = ({
  enabledCateringBooking,
  enabledEquippingBooking,
  bookingId,
  start,
}: OptionalOrderProps) => {
  const { cateringItems, equippingItems } = useItems();

  if (!cateringItems.length && !equippingItems.length) {
    return null;
  }

  return (
    <View style={styles.container}>
      {enabledCateringBooking && cateringItems.length ? (
        <View>
          <ItemTitle type="catering" />
          <Items
            bookingId={bookingId}
            items={cateringItems}
            type="catering"
            start={start}
          />
        </View>
      ) : null}
      {enabledEquippingBooking && equippingItems.length ? (
        <View>
          <ItemTitle
            type="equipping"
            style={
              enabledCateringBooking &&
              cateringItems.length > 0 &&
              styles.divider
            }
          />
          <Items
            bookingId={bookingId}
            items={equippingItems}
            type="equipping"
            start={start}
          />
        </View>
      ) : null}
    </View>
  );
};
