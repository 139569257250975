import {
  BookingType,
  BookCategory,
  BookingIcon,
} from '@views/shared/interfaces/booking';
import {
  FloorTypes,
  ParkingSpotType,
  AreaType,
} from '@views/shared/interfaces/buildingStructure';

export const appName = 'FINDER';
export const MAX_COUNT_DESKS = 3;

export const BOOKING_TYPE_FREE_SPACES_ROOM: BookingType = 'free_spaces_room';
export const BOOKING_TYPE_MEETING_ROOM: BookingType = 'meeting_room';
export const BOOKING_TYPE_HOME: BookingType = 'home';
export const BOOKING_TYPE_TELEFON_BOX: BookingType = 'telefon_box';

export const BOOKING_TYPE_PARKING_CHARGING: BookingType =
  'VirtualParkingCHARGING';
export const BOOKING_TYPE_PARKING_FAST_CHARGING: BookingType =
  'VirtualParkingFAST_CHARGING';
export const BOOKING_TYPE_PARKING_GENERAL: BookingType =
  'VirtualParkingGENERAL';
export const BOOKING_TYPE_PARKING_HANDICAPPED: BookingType =
  'VirtualParkingHANDICAPPED';
export const BOOKING_TYPE_ROOM: BookingType = 'room';
export const BOOKING_TYPE_TRAVEL_DAY: BookingType = 'travelDay';
export const BOOKING_TYPE_VIRTUAL_DESK: BookingType = 'VirtualDesk';
export const BOOKING_TYPE_VACATION: BookingType = 'vacation';
export const BOOKING_TYPE_SICKDAY: BookingType = 'sickDay';

export const FLOOR_TYPE_MIXED: FloorTypes = 'mixed';
export const FLOOR_TYPE_PARKING: FloorTypes = 'parking';

export const PARKING_SPOT_TYPE_CHARGING: ParkingSpotType =
  'VirtualParkingCHARGING';
export const PARKING_SPOT_TYPE_GENERAL: ParkingSpotType =
  'VirtualParkingGENERAL';
export const PARKING_SPOT_TYPE_HANDICAPPED: ParkingSpotType =
  'VirtualParkingHANDICAPPED';

export const AREA_TYPE_FREE_SPACES_ROOM: AreaType = 'free_spaces_room';
export const AREA_TYPE_PARKING: AreaType = 'parking';
export const AREA_TYPE_ROOM: AreaType = 'room';
export const AREA_TYPE_MEETING_ROOM: AreaType = 'meeting_room';
export const AREA_TYPE_TELEFON_BOX: AreaType = 'telefon_box';

export const BOOK_CATEGORY_FREE_SPACES_ROOM: BookCategory = 'free_spaces_room';
export const BOOK_CATEGORY_HOME: BookCategory = 'home';
export const BOOK_CATEGORY_MEETING_ROOM: BookCategory = 'meeting_room';
export const BOOK_CATEGORY_PARKING: BookCategory = 'parking';
export const BOOK_CATEGORY_ROOM: BookCategory = 'room';
export const BOOK_CATEGORY_TRAVEL: BookCategory = 'travelDay';
export const BOOK_CATEGORY_VACATION: BookCategory = 'vacation';
export const BOOK_CATEGORY_TELEFON_BOX: BookCategory = 'telefon_box';
export const BOOK_CATEGORY_SICKDAY: BookCategory = 'sickDay';

const MAP_BOOKING_BY_AREA_TYPE = {
  [AREA_TYPE_FREE_SPACES_ROOM]: BOOKING_TYPE_FREE_SPACES_ROOM,
  [AREA_TYPE_PARKING]: PARKING_SPOT_TYPE_GENERAL,
  [AREA_TYPE_ROOM]: BOOKING_TYPE_ROOM,
  [AREA_TYPE_MEETING_ROOM]: BOOKING_TYPE_MEETING_ROOM,
  [AREA_TYPE_TELEFON_BOX]: BOOKING_TYPE_TELEFON_BOX,
};

const MAP_AREA_BY_BOOK_CATEGORY = {
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: AREA_TYPE_FREE_SPACES_ROOM,
  [BOOK_CATEGORY_PARKING]: AREA_TYPE_PARKING,
  [BOOK_CATEGORY_ROOM]: AREA_TYPE_ROOM,
  [BOOK_CATEGORY_MEETING_ROOM]: AREA_TYPE_MEETING_ROOM,
  [BOOK_CATEGORY_TELEFON_BOX]: AREA_TYPE_TELEFON_BOX,
};

const MAP_BOOK_CATEGORY_BY_AREA = {
  [AREA_TYPE_FREE_SPACES_ROOM]: BOOK_CATEGORY_FREE_SPACES_ROOM,
  [AREA_TYPE_PARKING]: BOOK_CATEGORY_PARKING,
  [AREA_TYPE_ROOM]: BOOK_CATEGORY_ROOM,
  [AREA_TYPE_MEETING_ROOM]: BOOK_CATEGORY_MEETING_ROOM,
  [AREA_TYPE_TELEFON_BOX]: BOOK_CATEGORY_TELEFON_BOX,
};

const ICON_DESK: BookingIcon = 'desk';
const ICON_HOME_OFFICE: BookingIcon = 'homework';
const ICON_MEETING: BookingIcon = 'meeting';
const ICON_PARKING_AREA: BookingIcon = 'parking-area';
const ICON_PARKING: BookingIcon = 'parking';
const ICON_PARKING_CHARGING: BookingIcon = 'parking-charging';
const ICON_PARKING_FAST_CHARGING: BookingIcon = 'parking-fast-charging';
const ICON_PARKING_HANDICAPPED: BookingIcon = 'parking-handicapped';
const ICON_SEAT: BookingIcon = 'free-space';
const ICON_TRAVEL: BookingIcon = 'flight';
const ICON_VACATION: BookingIcon = 'holiday';
const ICON_TELEFON_BOX: BookingIcon = 'phonebox';
const ICON_SICKDAY: BookingIcon = 'sick';
const ICON_DISALLOWED = 'disallowed';
const ICON_ABSENT = 'exit';

const MAP_TYPE_ICON = {
  // BookingType
  [BOOKING_TYPE_HOME]: ICON_HOME_OFFICE,
  [BOOKING_TYPE_ROOM]: ICON_DESK,
  [BOOKING_TYPE_MEETING_ROOM]: ICON_MEETING,
  [BOOKING_TYPE_FREE_SPACES_ROOM]: ICON_SEAT,
  [BOOKING_TYPE_TRAVEL_DAY]: ICON_TRAVEL,
  [BOOKING_TYPE_PARKING_CHARGING]: ICON_PARKING_CHARGING,
  [BOOKING_TYPE_PARKING_FAST_CHARGING]: ICON_PARKING_FAST_CHARGING,
  [BOOKING_TYPE_PARKING_GENERAL]: ICON_PARKING,
  [BOOKING_TYPE_PARKING_HANDICAPPED]: ICON_PARKING_HANDICAPPED,
  [BOOKING_TYPE_VIRTUAL_DESK]: ICON_DESK,
  [BOOKING_TYPE_TELEFON_BOX]: ICON_TELEFON_BOX,
  // BookCategory
  [BOOK_CATEGORY_FREE_SPACES_ROOM]: ICON_SEAT,
  [BOOK_CATEGORY_PARKING]: ICON_PARKING_AREA,
  [BOOK_CATEGORY_ROOM]: ICON_DESK,
  [BOOK_CATEGORY_MEETING_ROOM]: ICON_MEETING,
  [BOOK_CATEGORY_HOME]: ICON_HOME_OFFICE,
  [BOOK_CATEGORY_TRAVEL]: ICON_TRAVEL,
  [BOOK_CATEGORY_VACATION]: ICON_VACATION,
  [BOOK_CATEGORY_TELEFON_BOX]: ICON_TELEFON_BOX,
  [BOOK_CATEGORY_SICKDAY]: ICON_SICKDAY,
};

export const getBookingIcon = (type: BookCategory | BookingType): BookingIcon =>
  MAP_TYPE_ICON[type];

export const getAreaTypeByBookCategory = (type: BookCategory): AreaType => {
  // @ts-ignore
  return MAP_AREA_BY_BOOK_CATEGORY[type] || AREA_TYPE_ROOM;
};

export const getBookCategoryByAreaType = (type: AreaType): BookCategory => {
  // @ts-ignore
  return MAP_BOOK_CATEGORY_BY_AREA[type] || BOOK_CATEGORY_ROOM;
};

export const getBookingTypeByAreaType = (type: AreaType): BookingType => {
  // @ts-ignore
  return MAP_BOOKING_BY_AREA_TYPE[type] || BOOKING_TYPE_ROOM;
};

export const MAP_PRESENCE_STATUS_ICON = {
  office: ICON_DESK,
  homeoffice: ICON_HOME_OFFICE,
  absent: ICON_ABSENT,
  disallowed: ICON_DISALLOWED,
  travelday: ICON_TRAVEL,
  vacation: ICON_VACATION,
  meeting: ICON_MEETING,
  sickday: ICON_SICKDAY,
};
